import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Layout from './Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';  // Import FontAwesomeIcon component
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { app2 } from '../firebase-config';
import ProfilePictureComponent from './ProfilePictureComponent';

function EditProfilePage() {
    const navigate = useNavigate();
    const [profilePicture, setProfilePicture] = useState(null);
    const auth = getAuth(app2);
    const db = getFirestore(app2);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [bio, setBio] = useState('');
    const [interests, setInterests] = useState([]);
    const [location, setLocation] = useState({ city: '', state: '', country: '' });

    useEffect(() => {
        const fetchProfileData = async () => {
            const user = auth.currentUser;
            if (user) {
                const userDocRef = doc(db, `users/${user.uid}`);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setProfilePicture(userData.profilePicture || 'defaultProfilePictureUrl'); // Fallback to a default URL if none
                    setFirstName(userData.firstName || '');
                    setLastName(userData.lastName || '');
                    setUsername(userData.userName || '');
                    setBio(userData.bio || '');
                    setInterests(userData.interests || []);
                    setLocation({
                        city: userData.selectedCity || '',
                        state: userData.selectedState || '',
                        country: userData.selectedCountry || ''
                    });
                }
            } else {
                navigate('/signin');
            }
        };

        fetchProfileData();
    }, [auth, db, navigate]);

    return (
        <Layout>
            <div className="max-w-5xl mx-auto">
                <div className="text-lg sm:text-xl font-semibold text-gray-900 my-4 mb-8 sm:p-0 pl-4 pt-4 pr-4">Edit profile</div>
                <div className="flex flex-col gap-2 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 mb-4">

                    <div className="py-2 px-4 mt-2 flex justify-between items-center">
                        <h1 className="text-base">Profile picture</h1>
                        <Link to="/edit-profile/profile-picture">
                            <ProfilePictureComponent className="w-20 h-20 border-4 border-gray-200" />
                        </Link>
                    </div>

                    <hr></hr>

                    <div className="py-2 px-4 mt-2 flex justify-between">
                        <h1 className="text-base">Name</h1>
                        <Link to="/edit-profile/name" className="text-sm sm:text-base bg-gray-200 py-1 px-2 rounded-lg">
                            {firstName} {lastName}
                        </Link>
                    </div>

                    <hr></hr>

                    <div className="py-2 px-4 mt-2 flex justify-between">
                        <h1 className="text-base">Username</h1>
                        <Link to="/edit-profile/username" className="text-sm sm:text-base bg-gray-200 py-1 px-2 rounded-lg">
                            @{username}
                        </Link>
                    </div>

                    <hr></hr>

                    <div className="py-2 px-4 mt-2 flex justify-between">
                        <h1
                            className="text-black text-base flex items-center"
                        >Bio
                        </h1>
                        <Link to="/edit-profile/bio" className="text-base bg-gray-200 py-2 px-4 rounded-lg flex justify-center w-2/3">
                            <p style={{ whiteSpace: 'pre-line' }} className="text-sm sm:text-base">{bio}</p>
                        </Link>
                    </div>

                    <hr></hr>

                    <div className="flex flex-col gap-2 items-start mb-2">
                        <h1
                            className="text-black text-base py-2 px-4 mt-2 flex"
                            onClick={() => navigate('/edit-profile')}
                        >Interests
                        </h1>
                        <div className="px-4 w-full">
                            <div className="flex flex-row flex-nowrap overflow-x-auto gap-2 pb-2" style={{ scrollbarWidth: 'thin' }}>
                                {interests.map(interest => (
                                    <Link to="/edit-profile/interests" key={interest} className="flex-shrink-0">
                                        <div className="px-3 py-2 bg-gray-200 rounded-full text-sm sm:text-base">{interest}</div>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </div>

                    <hr></hr>

                    <div className="py-2 px-4 mt-2 flex justify-between">
                        <h1 className="text-base">Location</h1>
                        <Link to="/edit-profile/location" className="text-sm sm:text-base bg-gray-200 py-1 px-2 rounded-lg">
                        {location.city}, {location.state} ({location.country})
                        </Link>
                    </div>





                </div>
            </div>
        </Layout>
    );
}

export default EditProfilePage;