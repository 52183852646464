import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { app2 } from '../firebase-config';

const WorkoutCard = ({ workout, userId = null, copied = false }) => {
    const navigate = useNavigate();
    const imgRef = useRef(null);
    const [maxWidth, setMaxWidth] = useState('auto');
    const [creatorUserName, setCreatorUserName] = useState('');

    useEffect(() => {
        const handleResize = () => {
            const width = imgRef.current?.getBoundingClientRect().width;
            setMaxWidth(`${width}px`);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchUserName = async () => {
            if (userId) {
                const db = getFirestore(app2);
                const userDocRef = doc(db, 'publicProfiles', userId);
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    const userData = userDocSnap.data();
                    setCreatorUserName(userData.userName);
                } else {
                    console.log("User document not found");
                }
            }
        };

        fetchUserName();
    }, [userId]);

    const handleImageLoad = () => {
        const width = imgRef.current?.getBoundingClientRect().width;
        setMaxWidth(`${width}px`);
    };

    const handleClick = () => {
        if (copied) {
            navigate(`/workout/copied/${workout.id}`);
        } else if (creatorUserName) {
            navigate(`/workout/${creatorUserName}/${workout.id}`);
        } else {
            navigate(`/workout/${workout.id}`);
        }
    };

    return (
        <div
            onClick={handleClick}
            className="cursor-pointer bg-white border border-gray-300 rounded-lg overflow-hidden mx-2 my-2 flex flex-col items-start shadow-sm hover:shadow-md transition-shadow duration-300 ease-in-out"
            title={workout.name}
        >
            <img
                ref={imgRef}
                src={workout.coverImage}
                alt={workout.name}
                onLoad={handleImageLoad}
                className="h-40 w-auto object-contain"
            />
            <p className="text-center p-2 text-sm sm:text-base font-medium truncate" style={{ maxWidth }}>
                {workout.name}
            </p>
        </div>
    );
};

export default WorkoutCard;
