import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs, addDoc, query, where } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import Layout from './Layout';
import { app2 } from '../firebase-config';

function ReferralsPage() {
    const [referrals, setReferrals] = useState([]);
    const [referrer, setReferrer] = useState('');
    const [numReferrals, setNumReferrals] = useState('');
    const [copiedCode, setCopiedCode] = useState('');
    const db = getFirestore(app2);

    useEffect(() => {
        fetchReferrals();
    }, []);

    const fetchReferrals = async () => {
        const referralCollection = collection(db, 'referralCodes');
        const referralSnapshot = await getDocs(referralCollection);
        const referralList = referralSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));

        // Sort referrals by referrer name alphabetically and by activated status
        referralList.sort((a, b) => {
            if (a.referrer.toLowerCase() < b.referrer.toLowerCase()) return -1;
            if (a.referrer.toLowerCase() > b.referrer.toLowerCase()) return 1;
            if (a.activated === b.activated) return 0;
            return a.activated ? -1 : 1;
        });

        setReferrals(referralList);
    };

    const generateReferralCode = async () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let code = '';
        let isUnique = false;

        while (!isUnique) {
            code = '';
            for (let i = 0; i < 20; i++) {
                code += characters.charAt(Math.floor(Math.random() * characters.length));
            }

            const q = query(collection(db, 'referralCodes'), where('code', '==', code));
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                isUnique = true;
            }
        }

        return code;
    };

    const handleGenerate = async () => {
        if (!referrer || !numReferrals) {
            alert('Please fill in both fields');
            return;
        }

        const num = parseInt(numReferrals);
        if (isNaN(num) || num <= 0) {
            alert('Please enter a valid number');
            return;
        }

        for (let i = 0; i < num; i++) {
            const code = await generateReferralCode();
            await addDoc(collection(db, 'referralCodes'), { referrer, code, activated: false, referee: '' });
        }

        fetchReferrals();
        setReferrer('');
        setNumReferrals('');
    };

    const handleCopy = (code) => {
        navigator.clipboard.writeText(code);
        setCopiedCode(code);
        setTimeout(() => setCopiedCode(''), 2000); // Clear the copied message after 2 seconds
    };

    return (
        <Layout>
            <div className="max-w-7xl mx-auto">
                <div className="text-lg sm:text-xl font-semibold text-gray-900 sm:p-0 pl-4 pt-4 pr-4 mt-4 mb-8">Referrals</div>
                <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 mb-4">
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">Referrer</label>
                        <input
                            type="text"
                            value={referrer}
                            onChange={(e) => setReferrer(e.target.value)}
                            className="mt-1 p-2 block w-full rounded-md shadow-sm text-normal border"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">Number of Referrals</label>
                        <input
                            type="number"
                            value={numReferrals}
                            onChange={(e) => setNumReferrals(e.target.value)}
                            className="mt-1 p-2 block w-full rounded-md text-normal border"
                        />
                    </div>
                    <button
                        onClick={handleGenerate}
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-black bg-fitmania-green hover:bg-fitmania-green-dark focus:outline-none focus:ring-2 focus:ring-offset-2"
                    >
                        Generate
                    </button>
                </div>
                <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 mb-4">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                            <tr>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Referrer</th>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Referee</th>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Referral Code</th>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Activated</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {referrals.map((referral) => (
                                <tr key={referral.id} className="relative">
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{referral.referrer || 'N/A'}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{referral.referee || 'N/A'}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 flex items-center relative">
                                        {referral.id}
                                        <FontAwesomeIcon
                                            icon={faCopy}
                                            className="ml-2 cursor-pointer text-gray-500 hover:text-gray-700"
                                            onClick={() => handleCopy(referral.id)}
                                        />
                                        {copiedCode === referral.id && (
                                            <span className="absolute left-full ml-2 bg-green-500 text-white px-2 py-1 rounded-md shadow-lg">
                                                Copied!
                                            </span>
                                        )}
                                    </td>
                                    <td className={`px-6 py-4 whitespace-nowrap text-sm ${referral.activated ? 'text-green-600' : 'text-red-600'}`}>
                                        {referral.activated ? 'True' : 'False'}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </Layout>
    );
}

export default ReferralsPage;
