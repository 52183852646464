import React from 'react';
import { Link } from 'react-router-dom';

function AccountVerificationPage() {
  return (
    <div className="bg-white flex flex-col items-center justify-center min-h-screen text-center">
      <img
          className="mx-auto w-auto mb-6"
          src={`${process.env.PUBLIC_URL}/images/Fitmania_AV2.webp`}
          alt="Fitmania Logo"
          style={{ height: '12rem' }}
        />
      <h2 className="text-2xl font-bold">Check Your Email</h2>
      <p className="mt-4">We've sent an email to the address you provided. <br></br> Please click on the verification link in that email to continue.</p>
      <Link to="/signin" className="mt-8 px-4 py-2 bg-fitmania-green text-black rounded hover:bg-fitmania-green-dark">Back to Sign In</Link>
    </div>
  );
}

export default AccountVerificationPage;
