import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { app2 } from '../firebase-config';

const ActivityCard = ({ activity, userId = null, copied = false }) => {
    const navigate = useNavigate();
    const imgRef = useRef(null);
    const [maxWidth, setMaxWidth] = useState('auto');
    const [username, setUsername] = useState('');

    useEffect(() => {
        const handleResize = () => {
            const width = imgRef.current?.getBoundingClientRect().width;
            setMaxWidth(`${width}px`);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchUsername = async () => {
            if (userId) {
                const db = getFirestore(app2);
                const userDocRef = doc(db, 'publicProfiles', userId);
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    const userData = userDocSnap.data();
                    setUsername(userData.userName);
                } else {
                    console.log("User document not found");
                }
            }
        };

        fetchUsername();
    }, [userId]);

    const handleImageLoad = () => {
        const width = imgRef.current?.getBoundingClientRect().width;
        setMaxWidth(`${width}px`);
    };

    const handleClick = () => {
        if (username && copied) {
            navigate(`/activity/completed/copied/${username}/${activity.id}`);
        } else if (copied) {
            navigate(`/activity/completed/copied/${activity.id}`);
        } else if (username) {
            navigate(`/activity/completed/${username}/${activity.id}`);
        } else {
            navigate(`/activity/completed/${activity.id}`);
        }
    };

    return (
        <div
            onClick={handleClick}
            className="cursor-pointer bg-white border border-gray-300 rounded-lg overflow-hidden mx-2 my-2 flex flex-col items-start shadow-sm hover:shadow-md transition-shadow duration-300 ease-in-out"
            title={activity.name}
        >
            <div className="w-auto">
                <img
                    ref={imgRef}
                    src={activity.coverImage}
                    alt={activity.name}
                    className="h-40 w-auto object-contain"
                    onLoad={handleImageLoad}
                />
            </div>
            <p className="text-center p-2 text-sm sm:text-base font-medium truncate" style={{ maxWidth }}>
                {activity.name}
            </p>
            <p className="text-center px-2 pb-2 text-xs sm:text-sm text-gray-500 truncate" style={{ maxWidth }}>
                {activity.completedAt}
            </p>
        </div>
    );
};

export default ActivityCard;
