import React, { useEffect, useState } from 'react';
import { getFirestore, collection, query, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { app2 } from '../firebase-config';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

function ExerciseStatsComponent() {
    const [exercises, setExercises] = useState({});
    const [chartsData, setChartsData] = useState({});
    const db = getFirestore(app2);
    const auth = getAuth(app2);

    useEffect(() => {
        const fetchExercises = async () => {
            const currentUser = auth.currentUser;
            if (!currentUser) {
                console.log("No user logged in");
                return;
            }

            const workoutsCompletedRef = collection(db, `users/${currentUser.uid}/workoutsCompleted`);
            const q = query(workoutsCompletedRef);
            const querySnapshot = await getDocs(q);
            let exercisesTemp = {};
            let chartsTemp = {};

            querySnapshot.forEach(doc => {
                const workoutData = doc.data();
                const completedAt = workoutData.completedAt ? workoutData.completedAt.toDate() : new Date(); // Use Date object for easier manipulation
                if (workoutData.exercises && Array.isArray(workoutData.exercises)) {
                    workoutData.exercises.forEach(exercise => {
                        if (!exercisesTemp[exercise.name]) {
                            exercisesTemp[exercise.name] = [];
                            chartsTemp[exercise.name] = [];
                        }
                        exercise.sets && exercise.sets.forEach((set) => {
                            const details = [];
                            let resistanceValue = null;
                            if (set.repetitions) details.push(`Repetitions: ${set.repetitions}`);
                            if (set.resistance && set.resistance.value) {
                                resistanceValue = parseFloat(set.resistance.value);
                                details.push(`Resistance: ${resistanceValue} ${set.resistance.units || ''}`);
                            }
                            if (set.distance && set.distance.value) details.push(`Distance: ${set.distance.value} ${set.distance.units || ''}`);
                            if (set.timer) details.push(`Timer: ${set.timer}`);

                            exercisesTemp[exercise.name].push({
                                details: details,
                                completedAt: completedAt.toLocaleString()
                            });

                            if (resistanceValue !== null) {
                                chartsTemp[exercise.name].push({
                                    x: completedAt.getTime(), // Use timestamp including time
                                    y: resistanceValue
                                });
                            }
                        });
                    });
                }
            });

            setExercises(exercisesTemp);
            prepareChartData(chartsTemp);
        };

        fetchExercises();
    }, [auth, db]);

    const prepareChartData = (chartsTemp) => {
        Object.keys(chartsTemp).forEach(exercise => {
            chartsData[exercise] = {
                chart: {
                    type: 'line'
                },
                title: {
                    text: `Resistance Over Time for ${exercise}`
                },
                xAxis: {
                    type: 'datetime',
                    title: {
                        text: 'Date and Time'
                    }
                },
                yAxis: {
                    title: {
                        text: 'Resistance (lbs)'
                    },
                    min: 0
                },
                series: [{
                    name: 'Resistance',
                    data: chartsTemp[exercise].sort((a, b) => a.x - b.x) // Ensure the data is sorted by time
                }],
                plotOptions: {
                    line: {
                        marker: {
                            enabled: true
                        }
                    }
                }
            };
        });
        setChartsData({ ...chartsData });
    };

    return (
        <div className="ml-4 mt-4">
            <h1 className="mb-8">Exercise Details</h1>
            {Object.keys(exercises).map((name, index) => (
                <div key={index}>
                    <h3 className="mb-4 underline font-semibold text-red-500">{name}</h3>
                    {chartsData[name] && <HighchartsReact highcharts={Highcharts} options={chartsData[name]} />}
                    {exercises[name].map((set, idx) => (
                        <div key={idx}>
                            <strong>Set {idx + 1}:</strong>
                            <ul className="mb-4">
                                {set.details.map((detail, detailIdx) => (
                                    <li key={detailIdx}>{detail}</li>
                                ))}
                                <li>Completed At: {set.completedAt}</li>
                            </ul>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default ExerciseStatsComponent;
