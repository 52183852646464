import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, getDocs, doc, setDoc } from 'firebase/firestore';
import { app2 } from '../firebase-config';
import Layout from './Layout';

function NotificationsPage() {
    const [notifications, setNotifications] = useState([]);
    const auth = getAuth(app2);
    const db = getFirestore(app2);

    useEffect(() => {
        const fetchNotifications = async () => {
            if (auth.currentUser) {
                const userId = auth.currentUser.uid;
                const notificationsCollection = collection(db, `users/${userId}/notifications`);
                
                const notificationsSnapshot = await getDocs(notificationsCollection);
                let notificationsList = notificationsSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                // Add the default notification if no notifications exist
                if (notificationsList.length === 0) {
                    const defaultNotification = {
                        message: "Finish creating your profile by adding a bio!",
                        date: new Date().toISOString(),
                        read: false
                    };
                    await setDoc(doc(notificationsCollection, 'defaultNotification'), defaultNotification);
                    notificationsList = [{ id: 'defaultNotification', ...defaultNotification }];
                }

                setNotifications(notificationsList);
            }
        };

        fetchNotifications();
    }, [auth.currentUser, db]);

    return (
        <Layout>
            <div className="max-w-7xl mx-auto">
                <div className="text-lg sm:text-xl font-semibold text-gray-900 sm:p-0 pl-4 pt-4 pr-4 mt-4 mb-8">Notifications</div>
                <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 mb-4">
                    {notifications.length > 0 ? (
                        <ul>
                            {notifications.map(notification => (
                                <li key={notification.id} className="flex flex-col gap-2 p-2 hover:bg-gray-50">
                                    <p className="text-base text-gray-900">{notification.message}</p>
                                    <p className="text-xs text-gray-400">
                                        {new Date(notification.date).toLocaleString([], {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true
                                        })}
                                    </p>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className="text-sm text-gray-500">No notifications to show.</p>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default NotificationsPage;
