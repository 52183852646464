import React, { useState, useEffect, useRef } from 'react'; // Import useRef
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import { app2 } from '../firebase-config';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import Layout from './Layout';
import ProfilePictureComponent from './ProfilePictureComponent';

function EditProfilePicturePage() {
    const [userData, setUserData] = useState({
        name: '',
        email: '',
        imageUrl: '', // Image URL will be set once a picture is chosen
    });
    const [isProfilePictureComponentVisible, setIsProfilePictureComponentVisible] = useState(true);
    const fileInputRef = useRef(null); // Reference to the file input element
    const navigate = useNavigate();

    useEffect(() => {
        const auth = getAuth(app2);
        const db = getFirestore(app2);
        const user = auth.currentUser;

        if (user) {
            const userRef = doc(db, 'users', user.uid);

            getDoc(userRef).then((docSnap) => {
                if (docSnap.exists()) {
                    const { firstName, lastName, email } = docSnap.data();
                    setUserData(prev => ({
                        ...prev,
                        name: `${firstName} ${lastName}`,
                        email: email,
                    }));
                }
            });
        }
    }, []);

    const handleProfileImageUpload = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setUserData(prev => ({
                    ...prev,
                    imageUrl: e.target.result, // This is the base64 image
                }));
                setIsProfilePictureComponentVisible(false);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleProfileImageSelected = () => {
        fileInputRef.current.click(); // Open file input dialog
    };

    const handleSaveProfilePicture = async () => {
        if (!userData.imageUrl || !fileInputRef.current.files[0]) return;
        const file = fileInputRef.current.files[0];
        const storage = getStorage(app2);
        const user = getAuth(app2).currentUser;

        if (user) {
            const storagePath = `profilePictures/${user.uid}/${file.name}`;
            const imageRef = storageRef(storage, storagePath);

            try {
                await uploadBytes(imageRef, file);
                const url = await getDownloadURL(imageRef);
                const db = getFirestore(app2);

                const userRef = doc(db, 'users', user.uid);
                const publicProfileRef = doc(db, 'publicProfiles', user.uid);

                await setDoc(userRef, { profilePicture: url }, { merge: true });
                await setDoc(publicProfileRef, { profilePicture: url }, { merge: true });

                navigate('/edit-profile');

            } catch (error) {
                console.error("Error uploading image: ", error);
            }
        }
    };

    const handleCancel = () => {
        navigate('/edit-profile');
    };


    return (
        <Layout>
            <div className="max-w-lg mx-auto">
                <div className="flex justify-between items-center mx-4 sm:mx-0">
                    <div className="text-lg sm:text-xl font-semibold text-gray-900 my-4 mb-8 sm:p-0 pt-4 pr-4">Edit profile picture</div>
                    <button onClick={handleCancel} className="bg-gray-200 p-2 rounded-lg">Cancel</button>
                </div>
                <div className="flex flex-col gap-2 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 mb-4 items-center">
                    <div className="flex flex-row gap-8 items-start">
                        <div className="flex flex-col items-center">
                        <div className="relative h-40 w-40 mb-4">
                                <div
                                    className={`h-40 w-40 rounded-full flex items-center justify-center ${userData.imageUrl ? '' : 'bg-gray-100'}`}
                                    style={{ backgroundImage: `url('${userData.imageUrl}')`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                                >
                                    {!userData.imageUrl && <span className="text-black text-9xl leading-none"></span>}
                                </div>
                                {isProfilePictureComponentVisible && (
                                    <ProfilePictureComponent className="w-40 h-40 absolute bottom-0" />
                                )}
                            </div>
                            <button
                                onClick={handleProfileImageSelected}
                                className="bg-gray-200 text-black mb-2 py-2 px-4 rounded-lg focus:outline-none"
                            >
                                Change profile picture
                            </button>
                            <input
                                type="file"
                                ref={fileInputRef}
                                onChange={handleProfileImageUpload}
                                style={{ display: 'none' }} // Hide the file input
                                accept="image/*" // Only accept images
                            />
                            <button
                                onClick={handleSaveProfilePicture}
                                disabled={!userData.imageUrl}
                                className={`mt-2 mb-4 py-2 px-4 rounded-lg focus:outline-none ${userData.imageUrl ? 'bg-fitmania-green hover:bg-fitmania-green-dark text-black' : 'bg-gray-200 text-gray-400'
                                    }`}
                            >
                                Save profile picture
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    );
}

export default EditProfilePicturePage;
