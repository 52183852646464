import React, { useState, useEffect, useRef } from 'react';
import Layout from './Layout';
import { getFirestore, collection, addDoc, deleteDoc, setDoc, doc, getDocs, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { app2 } from '../firebase-config';
import ConfirmDialog from './ConfirmDialog';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import ProgramWorkoutModal from './ProgramWorkoutModal';
import { getStorage, ref as storageRef, deleteObject, getDownloadURL, uploadBytes } from "firebase/storage";
import { useWorkout } from './WorkoutContext';
import Tooltip from './Tooltip'; // Adjust the path as necessary

function CreateAProgramPage() {
  const { programId } = useParams();
  const db = getFirestore(app2);
  const auth = getAuth(app2);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [cycles, setCycles] = useState([{ days: [] }]);
  const [selectedDay, setSelectedDay] = useState({ cycleIndex: null, dayIndex: null });
  const [hoveredDay, setHoveredDay] = useState({ cycleIndex: null, dayIndex: null });

  const [selectedWorkoutIndex, setSelectedWorkoutIndex] = useState(0);

  const [isWorkoutModalOpen, setIsWorkoutModalOpen] = useState(false);
  const [selectedDayNumber, setSelectedDayNumber] = useState(null);

  const [selectedWorkoutNumber, setSelectedWorkoutNumber] = useState(0);

  const [programName, setProgramName] = useState('');
  const [programDescription, setProgramDescription] = useState("");

  const [imagePreviewUrl, setImagePreviewUrl] = useState('');

  const fileInputRef = useRef(null);

  const [isImageFullscreen, setIsImageFullscreen] = useState(false);

  const { currentProgramId, updateCurrentProgramId } = useWorkout();

  const triggerFileInputClick = () => {
    fileInputRef.current.click();
  };

  const handleSaveAsDraft = () => {
    console.log("Coming soon");
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const toggleImageFullscreen = () => {
    setIsImageFullscreen(!isImageFullscreen);
  };

  const removeImage = () => {
    setImagePreviewUrl('');

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const toggleWorkoutModal = () => {
    setIsWorkoutModalOpen(!isWorkoutModalOpen);
  };

  const handleOpenModal = (cycleIndex, dayIndex) => {
    let globalDayNumber = 0;
    let workoutNumber = 1;

    for (let c = 0; c < cycleIndex; c++) {
      globalDayNumber += cycles[c].days.length;
    }
    globalDayNumber += dayIndex + 1;

    for (let c = 0; c <= cycleIndex; c++) {
      for (let d = 0; d < (c < cycleIndex ? cycles[c].days.length : dayIndex + 1); d++) {
        if (cycles[c].days[d].option === "Workout") {
          workoutNumber++;
        }
      }
    }

    setSelectedDayNumber(globalDayNumber);
    setSelectedWorkoutNumber(workoutNumber - 1);
    setIsWorkoutModalOpen(true);
  };

  const handleChangeWorkout = (change) => {
    const newIndex = selectedWorkoutIndex + change;
    if (newIndex >= 0 && newIndex < totalWorkoutDays) {
      setSelectedWorkoutIndex(newIndex);
    }
  };

  const navigate = useNavigate();

  const handleSelectionChange = (cycleIndex, dayIndex, selection) => {
    const newCycles = [...cycles];
    newCycles[cycleIndex].days[dayIndex].option = selection;
    setCycles(newCycles);
  };

  const addCycle = () => {
    const newCycles = [...cycles];
    const lastCycle = newCycles[newCycles.length - 1];
    const lastDayNumber = lastCycle.days.reduce((acc, day) => Math.max(acc, day.dayNumber), 0);
    const newCycle = { days: lastCycle.days.map((day, index) => ({ dayNumber: lastDayNumber + index + 1, option: day.option })) };
    newCycles.push(newCycle);
    setCycles(newCycles);
  };



  const addDay = (cycleIndexToAdd, dayIndexToAddAfter = null) => {
    const newCycles = [...cycles];
    const cycle = newCycles[cycleIndexToAdd];

    if (dayIndexToAddAfter !== null) {

      cycle.days.splice(dayIndexToAddAfter + 1, 0, { option: '' });
    } else {

      cycle.days.push({ option: '' });
    }


    let dayCounter = 0;
    const updatedCycles = newCycles.map(cycle => ({
      ...cycle,
      days: cycle.days.map(day => ({
        ...day,
        dayNumber: ++dayCounter,
        option: day.option
      }))
    }));

    setCycles(updatedCycles);
  };

  const saveProgram = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error('No authenticated user found');
      return;
    }

    let globalDayCounter = 0;

    const storage = getStorage(app2);
    let coverImageUrl = '';

    try {
      if (imagePreviewUrl && fileInputRef.current && fileInputRef.current.files[0]) {
        const file = fileInputRef.current.files[0];
        const imageRef = storageRef(storage, `programs/${currentUser.uid}/${file.name}`);

        const snapshot = await uploadBytes(imageRef, file);

        coverImageUrl = await getDownloadURL(snapshot.ref);
      }

      const programData = {
        name: programName,
        description: programDescription,
        coverImage: coverImageUrl,
        createdAt: new Date(),
        cycles: cycles.map((cycle, cycleIndex) => ({
          cycleNumber: cycleIndex + 1,
          days: cycle.days.map((day, dayIndex) => ({
            dayNumber: dayIndex + 1,
            globalDayNumber: ++globalDayCounter,
            selection: day.option,
          })),
        })),
      };

      if (handleSaveAsDraft) {
        const deletionDate = new Date();
        deletionDate.setHours(deletionDate.getHours() + 24);
        programData.programDraftDeletion = deletionDate;
      }

      if (programId) {

        await setDoc(doc(db, `users/${currentUser.uid}/programs`, programId), programData, { merge: true });
        console.log('Program updated successfully');
      } else {

        const docRef = await addDoc(collection(db, `users/${currentUser.uid}/programs`), programData);
        console.log('Program saved successfully', docRef.id);

      }

    } catch (error) {
      console.error('Error saving program or uploading image:', error);
    }
  };



  const deleteDay = (cycleIndex, dayIndexToDelete) => {
    let dayCounter = 0;
    const newCycles = cycles.map((cycle, index) => {
      if (index === cycleIndex) {

        const updatedDays = cycle.days.filter((_, index) => index !== dayIndexToDelete);
        return { ...cycle, days: updatedDays.map(day => ({ ...day, dayNumber: ++dayCounter })) };
      } else {

        return { ...cycle, days: cycle.days.map(day => ({ ...day, dayNumber: ++dayCounter })) };
      }
    });

    setCycles(newCycles);
  };

  const deleteCycle = (cycleIndexToDelete) => {

    const newCycles = cycles.filter((_, cycleIndex) => cycleIndex !== cycleIndexToDelete);


    let dayCounter = 0;
    const updatedCycles = newCycles.map(cycle => ({
      ...cycle,
      days: cycle.days.map(day => ({
        ...day,
        dayNumber: ++dayCounter,
        option: day.option
      }))
    }));

    setCycles(updatedCycles);
  };

  const handleKeyDown = (e, cycleIndex, dayIndex) => {
    if (e.key.toLowerCase() === 'w') {
      handleSelectionChange(cycleIndex, dayIndex, "Workout");
    } else if (e.key.toLowerCase() === 'r') {
      handleSelectionChange(cycleIndex, dayIndex, "Rest day");
    }
  };

  const handleCancel = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error('No authenticated user found');
      return;
    }

    if (programId) {
      try {

        const daysRef = collection(db, `users/${currentUser.uid}/programs/${programId}/days`);
        const daysSnapshot = await getDocs(daysRef);
        for (const dayDoc of daysSnapshot.docs) {
          const workoutsRef = collection(db, `users/${currentUser.uid}/programs/${programId}/days/${dayDoc.id}/workouts`);
          const workoutsSnapshot = await getDocs(workoutsRef);
          for (const workoutDoc of workoutsSnapshot.docs) {

            const workoutData = workoutDoc.data();
            if (workoutData.coverImage) {
              const coverImageRef = storageRef(getStorage(app2), workoutData.coverImage);
              await deleteObject(coverImageRef).catch(error => console.error('Error deleting workout cover image:', error));
            }
            await deleteDoc(doc(db, `users/${currentUser.uid}/programs/${programId}/days/${dayDoc.id}/workouts/${workoutDoc.id}`));
          }
          await deleteDoc(doc(db, `users/${currentUser.uid}/programs/${programId}/days/${dayDoc.id}`));
        }


        const programDocRef = doc(db, `users/${currentUser.uid}/programs/${programId}`);
        const programDocSnap = await getDoc(programDocRef);
        if (programDocSnap.exists()) {
          const programData = programDocSnap.data();
          if (programData.coverImage) {
            const coverImageRef = storageRef(getStorage(app2), programData.coverImage);
            await deleteObject(coverImageRef);
          }
        }


        await deleteDoc(programDocRef);

        console.log('Program and all related data deleted successfully');
        navigate('/workouts');
      } catch (error) {
        console.error('Error deleting program and its contents:', error);
      }
    } else {
      console.log('No program ID provided');
      navigate('/workouts');
    }
  };

  const totalWorkoutDays = cycles.reduce((total, cycle) => {
    const workoutDaysInCycle = cycle.days.filter(day => day.option === "Workout").length;
    return total + workoutDaysInCycle;
  }, 0);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.day-div') && selectedDay.cycleIndex !== null) {
        setSelectedDay({ cycleIndex: null, dayIndex: null });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [selectedDay]);

  useEffect(() => {

    if (programId) {
      updateCurrentProgramId(programId);
    }
  }, [programId, updateCurrentProgramId]);



  return (
    <Layout>

      <div className="text-2xl font-semibold text-gray-900 mb-4 px-8 sm:px-0 pt-8 sm:pt-4">Create a program</div>
      <div className="flex my-4 mb-8 px-8 sm:px-0 ">
        <button
          onClick={() => setShowConfirmDialog(true)}
          className="mr-4 text-black bg-gray-200 hover:bg-gray-300 px-2 py-1 rounded transition duration-150 ease-in-out"
        >
          Cancel
        </button>
        <button
          onClick={handleSaveAsDraft}
          className="mr-4 text-black bg-gray-200 hover:bg-gray-300 px-2 py-1 rounded transition duration-150 ease-in-out"
        >
          Save as draft
        </button>
        <button
          className="text-black  bg-fitmania-green hover:bg-fitmania-green-dark px-2 py-1 rounded transition duration-150 ease-in-out"
          onClick={saveProgram}
        >
          Save program
        </button>
      </div>
      {showConfirmDialog && (
        <ConfirmDialog
          title="Are you sure you want to cancel?"
          message="All progress will be permanently deleted."
          onConfirm={handleCancel}
          onCancel={() => setShowConfirmDialog(false)}
          confirmText="Yes"
          cancelText="No"
        />
      )}
      <div className="bg-white px-8 py-8 shadow rounded-lg ">
        <div className="flex items-center mb-4">

          {imagePreviewUrl ? (
            <img src={imagePreviewUrl} alt="Cover" className="h-40 bg-gray-100 border text-gray-900 rounded-md cursor-pointer" onClick={toggleImageFullscreen} />
          ) : (
            <div className="flex items-center justify-center w-40 h-40 bg-gray-100 border text-gray-900 rounded-md">
              Cover Image
            </div>
          )}
          <div className="flex flex-col">

            <button
              className="ml-4 bg-fitmania-green text-black px-4 py-2 rounded-md hover:bg-fitmania-green-dark transition duration-150 ease-in-out"
              onClick={triggerFileInputClick}
            >
              + Add Image
            </button>
            {imagePreviewUrl && (
              <button
                className="mt-2 ml-4 bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-800 transition duration-150 ease-in-out"
                onClick={removeImage}
              >
                - Remove Image
              </button>
            )}
          </div>

          {isImageFullscreen && (
            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={toggleImageFullscreen}>
              <img src={imagePreviewUrl} alt="Fullscreen Cover" className="max-w-full max-h-full" />
            </div>
          )}

          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept="image/png, image/jpeg"
            onChange={handleImageUpload}
          />
        </div>
        <div>
          <p className="font-semibold pt-2 pr-2">Name:</p>
          <textarea
            type="text"
            value={programName}
            onChange={(e) => setProgramName(e.target.value)}
            className="auto-grow-textarea w-full py-4 rounded-md focus:outline-none focus:border-gray-500"
            placeholder="Enter a program name..."
            rows="1"
          ></textarea>
        </div>
        <hr className=" mb-4" />
        <div>
          <p className="font-semibold pt-2 pr-2">Description:</p>
          <textarea
            value={programDescription}
            onChange={(e) => setProgramDescription(e.target.value)}
            className="auto-grow-textarea w-full py-4 rounded-md focus:outline-none focus:border-gray-500"
            placeholder="Enter a program description..."
            rows="1"
          ></textarea>
        </div>
        <hr className="mb-8" />
        <div className="flex mb-8">
          <div className="text-2xl font-semibold text-gray-900">Layout</div>
          <Tooltip content="Keyboard shortcuts: Workout: select day + 'w', Rest: select day + 'r'">
            <button className="text-gray-400 hover:text-gray-700 focus:outline-none ml-4">
              <FontAwesomeIcon icon={faCircleInfo} />
            </button>
          </Tooltip>
        </div>
        <div className="flex overflow-hidden scrollable-horizontal pt-2">
          <div>
            {cycles.map((cycle, cycleIndex) => (
              <div key={cycleIndex} className="flex items-center mb-4 ">
                <div className="bg-black text-white font-sm rounded-full flex items-center justify-center text-center h-12 w-12 mr-4 px-8 py-8">
                  Cycle {cycleIndex + 1}
                </div>

                {cycle.days.map((day, dayIndex) => (
                  <div key={dayIndex} className="flex items-center mr-2">
                    <div
                      className={`p-2 text-center rounded-lg ${day.option === "Rest day" ? "bg-black" : "bg-gray-100"} ${selectedDay.cycleIndex === cycleIndex && selectedDay.dayIndex === dayIndex ? "shadow-outline-green" : ""} ${hoveredDay.cycleIndex === cycleIndex && hoveredDay.dayIndex === dayIndex ? "shadow-hover-red" : ""}`}
                      tabIndex={0}
                      onClick={() => setSelectedDay({ cycleIndex, dayIndex })}
                      onKeyDown={(e) => handleKeyDown(e, cycleIndex, dayIndex)}
                    >
                      <div className={`text-center font-semibold p-2 rounded-md ${day.option === "Rest day" ? "bg-gray-600 text-white" : "bg-gray-200 text-black"}`}>
                        Day {day.dayNumber}
                      </div>
                      <div className="flex flex-col">
                        <select
                          className={`focus:outline-none text-center font-semibold p-2 px-0 rounded-md ${day.option === "Rest day" ? "bg-black text-white" : "bg-gray-100 text-black"}`}
                          value={day.option}
                          onChange={(e) => handleSelectionChange(cycleIndex, dayIndex, e.target.value)}
                        >
                          <option value="">Select:</option>
                          <option value="Workout">Workout</option>
                          <option value="Rest day">Rest day</option>
                        </select>
                        {day.option === "Workout" && (
                          <button onClick={() => handleOpenModal(cycleIndex, dayIndex)}>
                            <div className="text-sm bg-gray-200 hover:bg-gray-300 rounded-lg px-3 py-1">
                              + Add
                            </div>
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col ml-2">
                      <button
                        className="bg-gray-200 hover:bg-gray-300 pb-1 text-black rounded-full h-8 w-8 flex items-center justify-center text-center"
                        onClick={() => addDay(cycleIndex, dayIndex)}
                      >
                        +
                      </button>
                      <button
                        className="bg-gray-200 hover:bg-gray-300 pb-1 text-red-500 rounded-full h-8 w-8 flex items-center justify-center text-center mt-2"
                        onMouseEnter={() => setHoveredDay({ cycleIndex, dayIndex })}
                        onMouseLeave={() => setHoveredDay({ cycleIndex: null, dayIndex: null })}
                        onClick={() => deleteDay(cycleIndex, dayIndex)}
                      >
                        x
                      </button>
                    </div>
                  </div>
                ))}

                {cycle.days.length < 1 && (
                  <button
                    className="bg-gray-200 text-sm text-black font-semibold py-8 px-8 rounded-full flex flex-col items-center justify-center h-12 w-12"
                    onClick={() => addDay(cycleIndex)}
                  >
                    Add Day
                  </button>
                )}
                {cycles.length > 1 && (
                  <button
                    className="ml-4 bg-red-500 text-sm text-white font-semibold py-8 px-8 rounded-full flex flex-col items-center justify-center h-12 w-12"
                    onClick={() => deleteCycle(cycleIndex)}
                  >
                    Delete Cycle
                  </button>
                )}
              </div>
            ))}
            <ProgramWorkoutModal
              isOpen={isWorkoutModalOpen}
              onClose={toggleWorkoutModal}
              selectedDay={selectedDayNumber}
              selectedWorkout={selectedWorkoutNumber}
              programId={currentProgramId}
              programDetails={{
                programName,
                programDescription,
                coverImageFile: fileInputRef.current ? fileInputRef.current.files[0] : null
              }}
            />
          </div>
          <div className="pr-8">

          </div>
        </div>
        <button
          className="mt-2 mb-4 bg-gray-200 text-sm text-black font-semibold py-8 px-8 rounded-full flex flex-col items-center justify-center h-12 w-12"
          onClick={addCycle}
        >
          Add Cycle
        </button>
        {
          totalWorkoutDays > 0 && (
            <>
              <hr className="my-8"></hr>
              <div className="flex mb-8">
                <div className="text-2xl font-semibold text-gray-900">Workouts</div>
                <div className="flex items-center ml-8">
                  <button
                    aria-label="Previous Workout"
                    className={`mr-2 rounded-full p-1 ${selectedWorkoutIndex > 0 ? 'text-black hover:bg-gray-100' : 'text-gray-400 cursor-default'}`}
                    onClick={() => selectedWorkoutIndex > 0 && handleChangeWorkout(-1)}
                  >
                    <ChevronLeftIcon className="h-5 w-5" />
                  </button>
                  <select
                    className="text-md font-semibold bg-gray-200 hover:bg-gray-300 rounded-lg p-2"
                    aria-label="Select Workout"
                    value={`Workout ${selectedWorkoutIndex + 1}`}
                    onChange={(e) => setSelectedWorkoutIndex(parseInt(e.target.value.replace('Workout ', '')) - 1)}
                  >
                    {Array.from({ length: totalWorkoutDays }).map((_, index) => (
                      <option key={index} value={`Workout ${index + 1}`}>
                        Workout {index + 1}
                      </option>
                    ))}
                  </select>
                  <button
                    aria-label="Next Workout"
                    className={`ml-2 rounded-full p-1 ${selectedWorkoutIndex < totalWorkoutDays - 1 ? 'text-black hover:bg-gray-100' : 'text-gray-400 cursor-default'}`}
                    onClick={() => selectedWorkoutIndex < totalWorkoutDays - 1 && handleChangeWorkout(1)}
                  >
                    <ChevronRightIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>

              <div className="text-lg font-medium">
                Workout {selectedWorkoutIndex + 1}</div>
            </>
          )
        }
      </div>

    </Layout>
  );
}

export default CreateAProgramPage;
