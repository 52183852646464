import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { app2 } from '../firebase-config'; // Import app2
import ProfilePictureComponent from './ProfilePictureComponent'; // Import ProfilePictureComponent

const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
};

function UsersPage() {
    const [users, setUsers] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUsers = async () => {
            const db = getFirestore(app2); // Use app2
            const usersCollection = collection(db, 'publicProfiles');
            const usersSnapshot = await getDocs(usersCollection);
            const usersList = usersSnapshot.docs.map(doc => ({
                ...doc.data(),
                userId: doc.id,
            }));
            setUsers(usersList);
        };

        fetchUsers();
    }, []);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        } else {
            direction = 'ascending';
        }
        setSortConfig({ key, direction });
    };

    const validUsers = users ? Object.values(users) : [];

    const filteredUsers = validUsers.filter((user) => {
        const searchLower = searchQuery.toLowerCase();
        return (
            user.firstName?.toLowerCase().includes(searchLower) ||
            user.lastName?.toLowerCase().includes(searchLower) ||
            user.userName?.toLowerCase().includes(searchLower)
        );
    });

    const sortedUsers = filteredUsers.sort((a, b) => {
        if (sortConfig.key === null) return 0;

        const aValue = a[sortConfig.key] ? a[sortConfig.key].toLowerCase() : '';
        const bValue = b[sortConfig.key] ? b[sortConfig.key].toLowerCase() : '';

        if (aValue < bValue) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });

    const handleNavigate = (username) => {
        navigate(`/profile/${username}`);
    };

    return (
        <Layout>
            <div className="px-4 sm:px-6 lg:px-8 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <div className="mt-4">
                    <input
                        type="text"
                        className="px-4 py-2 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Search by name, username, or user ID..."
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
                <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Profile Picture
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:text-gray-800"
                                            onClick={() => requestSort('firstName')}>
                                            First Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:text-gray-800"
                                            onClick={() => requestSort('lastName')}>
                                            Last Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:text-gray-800"
                                            onClick={() => requestSort('userName')}>
                                            User Name
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {sortedUsers.map((person) => (
                                        <tr 
                                            key={person.userId} 
                                            onClick={() => handleNavigate(person.userName)} 
                                            className="cursor-pointer hover:bg-gray-100"
                                        >
                                            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                                <ProfilePictureComponent
                                                    userId={person.userId}
                                                    className="h-10 w-10"
                                                    profilePicture={person.profilePicture}
                                                    name={`${person.firstName} ${person.lastName}`}
                                                />
                                            </td>
                                            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                                {capitalizeFirstLetter(person.firstName)}
                                            </td>
                                            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                                {capitalizeFirstLetter(person.lastName)}
                                            </td>
                                            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                                {person.userName}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="text-right mt-4 mr-6">
                                <span className="text-lg font-semibold">Total Users: {sortedUsers.length}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default UsersPage;
