import React, { useState, useEffect, useRef } from 'react'; // Import useRef
import { getAuth } from 'firebase/auth';
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import { app2 } from '../../firebase-config';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';


function ProfilePictureComponent({ moveToNextStep }) {
    const [userData, setUserData] = useState({
        name: '',
        email: '',
        imageUrl: '', // Image URL will be set once a picture is chosen
    });
    const fileInputRef = useRef(null); // Reference to the file input element

    useEffect(() => {
        const auth = getAuth(app2);
        const db = getFirestore(app2);
        const user = auth.currentUser;

        if (user) {
            const userRef = doc(db, 'users', user.uid);

            getDoc(userRef).then((docSnap) => {
                if (docSnap.exists()) {
                    const { firstName, lastName, email } = docSnap.data();
                    setUserData(prev => ({
                        ...prev,
                        name: `${firstName} ${lastName}`,
                        email: email,
                    }));
                }
            });
        }
    }, []);

    const handleProfileImageUpload = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setUserData(prev => ({
                    ...prev,
                    imageUrl: e.target.result, // This is the base64 image
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleProfileImageSelected = () => {
        fileInputRef.current.click(); // Open file input dialog
    };

    const handleSaveProfilePicture = async () => {
        if (!userData.imageUrl || !fileInputRef.current.files[0]) return;
        const file = fileInputRef.current.files[0];
        const storage = getStorage(app2);
        const user = getAuth(app2).currentUser;

        if (user) {
            const storagePath = `profilePictures/${user.uid}/${file.name}`;
            const imageRef = storageRef(storage, storagePath);

            try {
                await uploadBytes(imageRef, file);
                const url = await getDownloadURL(imageRef);
                const db = getFirestore(app2);
                const userRef = doc(db, 'users', user.uid);
                await setDoc(userRef, { profilePicture: url }, { merge: true });

                // Update the public profile with the profile picture
                const publicProfileRef = doc(db, 'publicProfiles', user.uid);
                await setDoc(publicProfileRef, { profilePicture: url }, { merge: true });

                moveToNextStep();  // Move to the next step instead of completing onboarding
            } catch (error) {
                console.error("Error uploading image: ", error);
            }
        }
    };


    return (
        <div className="flex flex-col min-h-screen bg-white items-center justify-center p-4">
            <h1 className="text-2xl font-semibold mb-4">Profile Picture</h1>
            <div
                className={`h-60 w-60 rounded-full mb-4  flex items-center justify-center ${userData.imageUrl ? '' : 'bg-fitmania-green'}`}
                style={{ backgroundImage: `url('${userData.imageUrl}')`, backgroundSize: 'cover', backgroundPosition: 'center' }}
            >
                {!userData.imageUrl && <span className="text-black text-9xl leading-none">{userData.name.charAt(0)}</span>}
            </div>
            <button
                onClick={handleProfileImageSelected}
                className="bg-gray-200 text-black mb-2 py-2 px-4 rounded-lg focus:outline-none"
            >
                + Add profile picture
            </button>
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleProfileImageUpload}
                style={{ display: 'none' }} // Hide the file input
                accept="image/*" // Only accept images
            />
            <button
                onClick={handleSaveProfilePicture}
                disabled={!userData.imageUrl}
                className={`mt-16 mb-4 py-2 px-4 rounded-lg focus:outline-none ${userData.imageUrl ? 'bg-fitmania-green hover:bg-fitmania-green-dark text-black' : 'bg-gray-200 text-gray-400'
                    }`}
            >
                Save profile picture
            </button>
            <a href="#!" className="text-gray-600 hover:text-gray-800" onClick={(e) => {
                e.preventDefault();
                moveToNextStep();  // Move to the next step on skip
            }}>
                Skip
            </a>


        </div>
    );
}

export default ProfilePictureComponent;
