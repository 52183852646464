import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'; // Import useRef
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import { app2 } from '../firebase-config';
import Layout from './Layout';

function EditProfileBioPage() {
    const navigate = useNavigate();
    const [bio, setBio] = useState('');
    const textareaRef = useRef(null);

    const handleBioChange = (e) => {
        const { value } = e.target;
        setBio(value);
        adjustTextareaHeight(e.target);
    };

    const adjustTextareaHeight = (textarea) => {
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    };

    const handleCancel = () => {
        navigate('/edit-profile');
    };

    useEffect(() => {
        const auth = getAuth(app2);
        const db = getFirestore(app2);
        const user = auth.currentUser;

        if (user) {
            const userRef = doc(db, 'users', user.uid);

            getDoc(userRef).then((docSnap) => {
                if (docSnap.exists()) {
                    const { bio } = docSnap.data();
                    setBio(bio || '');
                }
            });
        }
    }, []);

    useLayoutEffect(() => {
        if (textareaRef.current) {
            adjustTextareaHeight(textareaRef.current);
        }
    }, [bio]);

    const handleSave = async () => {
        const auth = getAuth(app2);
        const db = getFirestore(app2);
        const user = auth.currentUser;

        if (user) {
            const userRef = doc(db, 'users', user.uid);
            const publicProfileRef = doc(db, 'publicProfiles', user.uid);

            await setDoc(userRef, {
                bio: bio,
            }, { merge: true });

            await setDoc(publicProfileRef, { 
                bio: bio 
            }, { merge: true });

            // Navigate back to the profile edit page
            navigate('/edit-profile');
        }
    };


    return (
        <Layout>
            <div className="max-w-lg mx-auto">
                <div className="flex justify-between items-center mx-4 sm:mx-0">
                    <div className="text-lg sm:text-xl font-semibold text-gray-900 my-4 mb-8 sm:p-0 pt-4 pr-4">Edit bio</div>
                    <button onClick={handleCancel} className="bg-gray-200 p-2 rounded-lg">Cancel</button>
                </div>
                <div className="flex flex-col gap-2 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 mb-4">

                    <div className="flex flex-col items-center gap-4">

                        <textarea
                            ref={textareaRef}
                            className="border border-gray-400 rounded-lg p-4 w-full"
                            placeholder="Type bio"
                            value={bio}
                            onChange={handleBioChange}
                            rows={1} // Initial number of rows
                            style={{ overflow: 'hidden' }} // Hide scrollbars
                        />
                        <button
                            onClick={handleSave}
                            className="mt-2 mb-4 py-2 px-4 rounded-lg focus:outline-none bg-fitmania-green text-black"
                        >
                            Save bio
                        </button>

                    </div>

                </div>
            </div>
        </Layout>
    );
}

export default EditProfileBioPage;
