import React, { useState, useEffect } from 'react';
import PublicLayout from './PublicLayout';
import { useParams } from 'react-router-dom'; 
import { getEntryBySlug } from '../contentfulClient';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-solid-svg-icons';

const BlogPost = () => {
    const { slug } = useParams(); 
    const [post, setPost] = useState(null); 

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const fetchedPost = await getEntryBySlug(slug);
                setPost({
                    ...fetchedPost.fields,
                    date: formatDate(fetchedPost.fields.date) 
                });
            } catch (error) {
                console.error('Error fetching blog post:', error);
            }
        };

        fetchPost();
    }, [slug]);

    // Function to format date
    const formatDate = (dateString) => {
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    
    if (!post) {
        return <div>Loading...</div>;
    }

    const options = {
        renderNode: {
            'heading-1': (node, children) => <h1 className="text-3xl font-bold my-8">{children}</h1>,
            'heading-2': (node, children) => <h2 className="text-2xl font-semibold my-4">{children}</h2>,
            'heading-3': (node, children) => <h2 className="text-xl font-semibold my-4">{children}</h2>,
          
            'paragraph': (node, children) => <p className="my-4">{children}</p>,
            'unordered-list': (node, children) => <ul className="list-disc list-inside my-4">{children}</ul>,
            'ordered-list': (node, children) => <ol className="list-decimal list-inside my-4">{children}</ol>,
            'list-item': (node, children) => <li className="ml-4">{children}</li>,
            'blockquote': (node, children) => <blockquote className="italic border-l-4 border-gray-300 pl-4 my-4">{children}</blockquote>,
            'embedded-asset-block': (node) => {
                const { url } = node.data.target.fields.file;
                return <img src={url} alt={node.data.target.fields.title} className="w-3/4 mx-auto my-4" />;
            },
          
        },
        renderMark: {
            'bold': (text) => <strong>{text}</strong>,
            'italic': (text) => <em>{text}</em>,
       
        },
    
    };


 
    return (
        <PublicLayout>
            <div className="mx-auto max-w-4xl flex flex-col justify-center items-center gap-8 px-8">
                <div className="bg-gray-100 p-4 rounded-lg flex flex-col gap-2">
                    <div className="flex flex-row gap-2 items-center">
                        <FontAwesomeIcon icon={faBell} className="icon-small" />
                        <h1 className="font-semibold text-lg">Disclaimer</h1>
                    </div>
                    <p className="text-md">The content on this blog is for informational purposes only and is not professional medical advice. The authors are not licensed fitness or healthcare professionals, and the information and opinions shared are based on personal experiences and general research. Regardless, always consult with a qualified healthcare professional before making health-related decisions.</p>
                </div>
                <div className=" p-4 flex flex-col justify-start items-start w-full gap-8">
                    <p>{post.date}</p>
                    <h2 className="text-4xl font-semibold">{post.title}</h2>
                    {post.coverImage && post.coverImage.fields.file ? (
                        <img
                            src={post.coverImage.fields.file.url}
                            alt={post.title}
                            className="h-61 w-full object-cover"
                        />
                    ) : <p className="text-gray-500">No cover image available</p>}
                    <p>{post.body && documentToReactComponents(post.body, options)}</p>
                </div>
            </div>
        </PublicLayout>
    );
};

export default BlogPost;
