import { useCallback } from 'react';

function useFullscreenMedia() {
  // Handler for requesting fullscreen for video elements
  const requestVideoFullscreen = useCallback((videoElement) => {
    if (videoElement.requestFullscreen) {
      videoElement.requestFullscreen();
    } else if (videoElement.webkitRequestFullscreen) { // Safari's prefixed API
      videoElement.webkitRequestFullscreen();
    } else {
      console.error("Fullscreen API is not supported in this browser.");
    }
  }, []);

  // Handler for fullscreen on images
  const requestImageFullscreen = useCallback((imageSrc) => {
    const fullscreenElement = document.createElement("div");
    fullscreenElement.style.backgroundImage = `url(${imageSrc})`;
    fullscreenElement.style.backgroundSize = 'contain';
    fullscreenElement.style.backgroundRepeat = 'no-repeat';
    fullscreenElement.style.backgroundPosition = 'center';
    fullscreenElement.style.width = '100%';
    fullscreenElement.style.height = '100%';
    document.body.appendChild(fullscreenElement);

    if (fullscreenElement.requestFullscreen) {
      fullscreenElement.requestFullscreen();
    } else if (fullscreenElement.webkitRequestFullscreen) { // Safari's prefixed API
      fullscreenElement.webkitRequestFullscreen();
    } else {
      console.error("Fullscreen API is not supported in this browser.");
    }

    fullscreenElement.onfullscreenchange = () => {
      if (!document.fullscreenElement) {
        document.body.removeChild(fullscreenElement);
      }
    };
  }, []);

  return { requestVideoFullscreen, requestImageFullscreen };
}

export default useFullscreenMedia;
