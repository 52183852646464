import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, collection, query, getDocs, orderBy, where, limit } from 'firebase/firestore';
import { app2 } from '../firebase-config';
import ProfilePictureComponent from './ProfilePictureComponent';
import GoalGaugeComponent from './GoalGaugeComponent';
import ActivityCard from './ActivityCard';
import WorkoutCard from './WorkoutCard';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import ShareProfileComponent from './ShareProfileComponent';

function LitePublicProfilePage() {
    const { username } = useParams();
    const navigate = useNavigate();
    const [profileData, setProfileData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [recentActivities, setRecentActivities] = useState([]);
    const [publicWorkouts, setPublicWorkouts] = useState([]);
    const [goals, setGoals] = useState([]);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [completedActivitiesCount, setCompletedActivitiesCount] = useState(0);
    const [streak, setStreak] = useState(0);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const auth = getAuth(app2);
        const unsubscribe = onAuthStateChanged(auth, user => {
            setIsAuthenticated(!!user);
        });

        const fetchProfileData = async () => {
            try {
                const db = getFirestore(app2);
                const usersCollection = collection(db, 'publicProfiles');
                const userQuery = query(usersCollection, where('userName', '==', username));
                const userSnapshot = await getDocs(userQuery);
                if (userSnapshot.empty) {
                    console.error('No such user!');
                    setLoading(false);
                    return;
                }
                const userDoc = userSnapshot.docs[0];
                const userDocData = userDoc.data();
                setProfileData(userDocData);
                setUserId(userDoc.id);
            } catch (error) {
                console.error("Error fetching public profile data:", error);
            } finally {
                setLoading(false);
            }
        };

        const fetchActivitiesAndWorkouts = async (fetchedUserId) => {
            try {
                const db = getFirestore(app2);

                // Fetch recent activities (both copied and non-copied)
                const workoutsCompletedRef = collection(db, `users/${fetchedUserId}/workoutsCompleted`);
                const copiedWorkoutsCompletedRef = collection(db, `users/${fetchedUserId}/copiedWorkouts`);

                const recentPublicActivitiesQuery = query(
                    workoutsCompletedRef,
                    where('visibility', '==', 'Public'),
                    orderBy("completedAt", "desc"),
                    limit(5)
                );

                const recentCopiedActivitiesQuery = query(
                    copiedWorkoutsCompletedRef,
                    where('visibility', '==', 'Public'),
                    orderBy("completedAt", "desc"),
                    limit(5)
                );

                const [recentPublicActivitiesSnapshot, recentCopiedActivitiesSnapshot] = await Promise.all([
                    getDocs(recentPublicActivitiesQuery),
                    getDocs(recentCopiedActivitiesQuery)
                ]);

                const activitiesPromises = [...recentPublicActivitiesSnapshot.docs, ...recentCopiedActivitiesSnapshot.docs].map(async (docSnap) => {
                    const completedData = docSnap.data();
                    const workoutRefPath = completedData.copied ? `users/${fetchedUserId}/copiedWorkouts` : `users/${fetchedUserId}/workouts`;
                    const workoutRef = doc(db, workoutRefPath, completedData.workoutId);
                    const workoutSnapshot = await getDoc(workoutRef);

                    if (workoutSnapshot.exists() && workoutSnapshot.data().visibility === 'Public') {
                        return {
                            id: docSnap.id,
                            workoutId: completedData.workoutId,
                            name: workoutSnapshot.data().name,
                            coverImage: workoutSnapshot.data().coverImage,
                            completedAt: completedData.completedAt.toDate().toLocaleString('en-US', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: '2-digit',
                                hour12: true
                            }),
                            copied: completedData.copied
                        };
                    } else {
                        return null;
                    }
                });

                const fetchedActivities = (await Promise.all(activitiesPromises)).filter(Boolean);
                setRecentActivities(fetchedActivities);

                // Fetch public workouts
                const workoutsRef = collection(db, `users/${fetchedUserId}/workouts`);
                const workoutsQuery = query(workoutsRef, where('visibility', '==', 'Public'));
                const workoutsSnapshot = await getDocs(workoutsQuery);

                const workouts = workoutsSnapshot.docs.map(docSnap => ({
                    id: docSnap.id,
                    ...docSnap.data(),
                }));

                setPublicWorkouts(workouts);

                // Fetch goals
                const goalsRef = collection(db, `users/${fetchedUserId}/goals`);
                const goalsQuery = query(goalsRef, where('visibility', '==', 'Public'));
                const goalsSnapshot = await getDocs(goalsQuery);

                const fetchedGoals = goalsSnapshot.docs.map(docSnap => docSnap.data());
                setGoals(fetchedGoals);

                // Fetch completed activities count
                const totalActivitiesSnapshot = await getDocs(query(workoutsCompletedRef, where('visibility', '==', 'Public')));
                setCompletedActivitiesCount(totalActivitiesSnapshot.docs.length);

                // Calculate streak
                const activityDates = fetchedActivities.map(activity => new Date(activity.completedAt.split(",")[0]));
                activityDates.sort((a, b) => a - b);

                let currentStreak = 0;
                let previousDate = null;

                activityDates.forEach(date => {
                    if (previousDate) {
                        const diffTime = Math.abs(date - previousDate);
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                        if (diffDays === 1) {
                            currentStreak += 1;
                        } else {
                            currentStreak = 0;
                        }
                    } else {
                        currentStreak = 1;
                    }
                    previousDate = date;
                });

                const today = new Date();
                const lastActivityDate = activityDates[activityDates.length - 1];
                const diffTimeLastActivity = Math.abs(today - lastActivityDate);
                const diffDaysLastActivity = Math.ceil(diffTimeLastActivity / (1000 * 60 * 60 * 24));

                if (diffDaysLastActivity > 1) {
                    currentStreak = 0;
                }

                setStreak(currentStreak);

            } catch (error) {
                console.error("Error fetching activities, workouts, and goals:", error);
            }
        };

        fetchProfileData().then(() => {
            if (userId) {
                fetchActivitiesAndWorkouts(userId);
            }
        });

        return () => unsubscribe();
    }, [username, userId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!profileData) {
        return <div>Profile not found.</div>;
    }

    const handleWorkoutCardClick = (workoutId) => {
        if (!isAuthenticated) {
            navigate(`/workout/${username}/${workoutId}`);
        }
    };

    return (
        <>
            <div className="max-w-5xl mx-auto m-4">
                <div className="text-center px-4 py-8 bg-white shadow rounded-lg overflow-hidden sm:p-8 mb-4">
                    <img
                        className="mx-auto h-12 w-auto mb-4"
                        src={`${process.env.PUBLIC_URL}/images/Fitmania F Logo.png`}
                        alt="Fitmania Logo"
                    />
                    <p className="text-sm sm:text-base mb-4">Sign in to view more.</p>
                    <Link to="/signin" className="bg-fitmania-green px-4 py-2 rounded-lg hover:bg-fitmania-green-dark text-sm sm:text-base mx-2">
                        Sign In
                    </Link>
                    <Link to="/signup" className="bg-fitmania-green px-4 py-2 rounded-lg hover:bg-fitmania-green-dark text-sm sm:text-base mx-2">
                        Sign Up
                    </Link>
                </div>
                <div className="px-4 py-8 bg-white shadow rounded-lg overflow-hidden sm:p-8 mb-4">
                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end gap-4 relative">
                        <div className="flex flex-col sm:flex-row gap-4 sm:gap-8 items-start sm:items-center">
                            <ProfilePictureComponent
                                className="w-24 h-24"
                                profilePicture={profileData.profilePicture}
                                name={`${profileData.firstName} ${profileData.lastName}`}
                            />
                            <div className="flex flex-col gap-1">
                                <h1 className="text-base sm:text-lg font-semibold flex items-center">{profileData.firstName} {profileData.lastName}</h1>
                                <span className="text-sm sm:text-base">@{profileData.userName}</span>
                                <span className="text-sm sm:text-base">Joined {new Date(profileData.dateJoined).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
                                <span className="text-sm sm:text-base">{profileData.selectedCity}, {profileData.selectedState} ({profileData.selectedCountry})</span>
                            </div>
                        </div>
                        <div className="flex gap-2 w-full sm:w-auto">
                            <button className="bg-fitmania-green px-4 py-2 rounded-lg hover:bg-fitmania-green-dark w-1/2 sm:w-auto text-sm sm:text-base ">Follow</button>
                            <ShareProfileComponent
                                baseUrl={`${window.location.origin}/profile`}
                                itemId={username}
                                firstName={profileData.firstName}
                                lastName={profileData.lastName}
                                userName={profileData.userName}
                            />
                        </div>
                        <div className="absolute top-6 sm:top-4 right-8 sm:right-0 flex gap-8">
                            <div className="flex flex-col md:flex-row gap-0 sm:gap-1 items-center sm:items-baseline">
                                <span className="text-base sm:text-lg font-semibold">{completedActivitiesCount}</span>
                                <span className="text-sm sm:text-base">activity</span>
                            </div>
                            <div className="flex flex-col md:flex-row gap-0 sm:gap-1 items-center sm:items-baseline">
                                <span className="text-base sm:text-lg font-semibold">{streak}</span>
                                <span className="text-sm sm:text-base">streak</span>
                            </div>
                            <div className="flex flex-col md:flex-row gap-0 sm:gap-1 items-center sm:items-baseline">
                                <span className="text-base sm:text-lg font-semibold">0</span>
                                <span className="text-sm sm:text-base">followers</span>
                            </div>
                        </div>
                    </div>
                    <hr className="mt-4 mb-4"></hr>
                    {profileData.bio && (
                        <div>
                            <h1 className="text-sm sm:text-base font-semibold mb-2">Bio</h1>
                            <span className="text-sm sm:text-base" style={{ whiteSpace: 'pre-line' }}>{profileData.bio}</span>
                            <hr className="mt-4 mb-4"></hr>
                        </div>
                    )}
                    <h1 className="text-sm sm:text-base font-semibold mb-4">Interests</h1>
                    <div className="flex flex-row flex-nowrap overflow-x-auto gap-2 items-start pb-2" style={{ scrollbarWidth: 'thin' }}>
                        {profileData.interests?.map(interest => (
                            <div key={interest} className="px-2 py-1 bg-gray-200 flex-shrink-0 rounded-lg text-sm">{interest}</div>
                        ))}
                    </div>
                    
                    {goals.length > 0 && (
                        <div className="mb-2">
                            <hr className="mt-4 mb-4" />
                            <h1 className="text-sm sm:text-base font-semibold">Goals</h1>
                            <div className="flex text-sm sm:text-base text-center">
                                <GoalGaugeComponent userId={userId} visibility="public" isPublicProfile="true" />
                            </div>
                        </div>
                    )}
                    {recentActivities.length > 0 && (
                        <div>
                            <hr className="mt-4 mb-4" />
                            <h1 className="text-sm sm:text-base font-semibold mb-2">Recent Activity</h1>
                            <div className="flex flex-nowrap overflow-x-auto -mx-2" style={{ scrollbarWidth: 'thin' }}>
                                {recentActivities.map((activity) => (
                                    <div key={activity.id} className="flex-shrink-0">
                                        <ActivityCard activity={activity} userId={userId} copied={activity.copied} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {publicWorkouts.length > 0 && (
                        <div>
                            <hr className="mt-4 mb-4" />
                            <h1 className="text-sm sm:text-base font-semibold mb-2">My Workouts</h1>
                            <div className="flex flex-nowrap overflow-x-auto -mx-2" style={{ scrollbarWidth: 'thin' }}>
                                {publicWorkouts.map((workout) => (
                                    <div key={workout.id} className="flex-shrink-0" onClick={() => handleWorkoutCardClick(workout.id)}>
                                        <WorkoutCard workout={workout} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="max-w-5xl mx-auto mt-8 mb-16 text-center flex flex-col gap-2">
                <span>© 2024 Fitmania, Inc. All Rights Reserved.</span>
                <a href="https://www.fitmania.io" className="font-semibold hover:underline" target="_blank" rel="noopener noreferrer">www.fitmania.io</a>
            </div>
        </>
    );
}

export default LitePublicProfilePage;
