//create a workout component before file upload refactor
import React, { useState, useRef, Fragment, useEffect } from 'react';
import ExercisesComponent from './ExercisesComponent';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faHashtag, faWeightHanging, faTimer, faRuler, faCamera } from '@fortawesome/pro-regular-svg-icons';
import { faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import ConfirmDialog from './ConfirmDialog';
import { useWorkout } from './WorkoutContext';
import TimePickerComponent from './TimePickerComponent';

import { useFileUpload } from './FileUpload';

import useFullscreenMedia from './useFullscreenMedia';

import Tooltip from './Tooltip'; // Adjust the path as necessary

function CreateAWorkoutComponent({
    onCancel,
    onSaveWorkout
}) {

    const {
        selectedExercises,
        handleExercisesChange,
        inputValues,
        setInputValues,
        imagePreviewUrl,
        setImagePreviewUrl,
        workoutName,
        setWorkoutName,
        workoutDescription,
        setWorkoutDescription,
        isUploading,
        uploadProgress,
        handleFileSelection,
        coverImagePreviewUrl,
        setCoverImagePreviewUrl,

    } = useWorkout();

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    const [isImageFullscreen, setIsImageFullscreen] = useState(false);
    const fileInputRef = useRef(null);

    const textareaRef = useRef(null);


    const [isDropdownOpen, setIsDropdownOpen] = useState({});
    const [areAllExercisesOpen, setAreAllExercisesOpen] = useState(false);
    const [showExercisesModal, setShowExercisesModal] = useState(false);
    const [fullScreenMedia, setFullScreenMedia] = useState({ url: '', type: '' });
    const newMediaInputRef = useRef(null);
    const [activeExerciseIdForMedia, setActiveExerciseIdForMedia] = useState(null);

    const [selectedUnits, setSelectedUnits] = useState({});
    const [selectedResistance, setSelectedResistance] = useState({});
    const [selectedDistanceUnits, setSelectedDistanceUnits] = useState({});

    const [showTimePicker, setShowTimePicker] = useState(false);

    const { readFileAsUrl } = useFileUpload();

    const { createObjectUrl, revokeObjectUrl } = useFileUpload();

    const mediaInputRefs = useRef({});

    // Initialize the fullscreen media hook
    const { requestImageFullscreen, requestVideoFullscreen } = useFullscreenMedia();

    const [editingSet, setEditingSet] = useState(null); // { exerciseId: null, setId: null }


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (!file) {
            console.error("No file selected.");
            return;
        }

        // Revoke the previous cover image preview URL to avoid memory leaks
        if (coverImagePreviewUrl) {
            revokeObjectUrl(coverImagePreviewUrl);
        }

        // Use createObjectUrl to generate a new preview URL for the cover image
        const url = createObjectUrl(file);
        // Update to use the correct state setter
        setCoverImagePreviewUrl(url); // This should be setCoverImagePreviewUrl(url) if that's the correct setter from your context or state management.
    };



    const removeImage = () => {
        if (coverImagePreviewUrl) {
            revokeObjectUrl(coverImagePreviewUrl);
        }
        setCoverImagePreviewUrl(''); // Clear the cover image preview URL

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const toggleImageFullscreen = () => {
        setIsImageFullscreen(!isImageFullscreen);
    };

    const triggerFileInputClick = () => {
        fileInputRef.current.click();
    };

    const handleWorkoutNameChange = (event) => {
        setWorkoutName(event.target.value);


        const target = event.target;
        target.style.height = 'inherit';
        target.style.height = `${target.scrollHeight}px`;
    };


    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'inherit';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    const handleDescriptionChange = (event) => {
        setWorkoutDescription(event.target.value);


        const target = event.target;
        target.style.height = 'inherit';
        target.style.height = `${target.scrollHeight}px`;
    };


    const toggleAllExerciseDropdowns = () => {

        const anyClosed = Object.values(isDropdownOpen).some(open => !open);

        const updatedDropdowns = selectedExercises.reduce((acc, exercise) => {
            acc[exercise.id] = anyClosed;
            return acc;
        }, {});
        setIsDropdownOpen(updatedDropdowns);
        setAreAllExercisesOpen(anyClosed);
    };


    const onExerciseSelect = (exercise) => {
        setShowExercisesModal(false);


        const versionNumber = selectedExercises.filter(e => e.exerciseName.startsWith(exercise.exerciseName)).length + 1;


        const exerciseNameWithVersion = versionNumber > 1 ? `${exercise.exerciseName} #${versionNumber}` : exercise.exerciseName;


        const newExerciseWithDefaults = {
            ...exercise,
            id: `${exercise.id}-${Date.now()}`,
            exerciseName: exerciseNameWithVersion,
            mediaItems: [],
            exerciseNote: '',
            sets: [
                {
                    id: 1,
                    repetitions: '',
                    resistance: '',
                    timer: '',
                    distance: '',
                    tillFailure: false,
                    timerTillFailure: false,
                    selectedIcons: {
                        faHashtag: false,
                        faWeightHanging: false,
                        faTimer: false,
                        faRuler: false,
                    },
                },
            ],
        };


        const updatedExercises = [...selectedExercises, newExerciseWithDefaults];
        handleExercisesChange(updatedExercises);

    };

    const moveExerciseUp = (exerciseIndex) => {
        if (exerciseIndex <= 0) return;
        const newExercises = [...selectedExercises];

        [newExercises[exerciseIndex], newExercises[exerciseIndex - 1]] = [newExercises[exerciseIndex - 1], newExercises[exerciseIndex]];
        handleExercisesChange(newExercises);
    };

    const moveExerciseDown = (exerciseIndex) => {
        if (exerciseIndex >= selectedExercises.length - 1) return;
        const newExercises = [...selectedExercises];

        [newExercises[exerciseIndex], newExercises[exerciseIndex + 1]] = [newExercises[exerciseIndex + 1], newExercises[exerciseIndex]];
        handleExercisesChange(newExercises);
    };


    const removeMediaItem = (exerciseId, mediaIndex) => {
        handleExercisesChange(exercises => exercises.map(exercise => {
            if (exercise.id === exerciseId) {

                const updatedMediaItems = exercise.mediaItems.filter((_, index) => index !== mediaIndex);
                return { ...exercise, mediaItems: updatedMediaItems };
            }
            return exercise;
        }));

        // Reset the input value for the exercise to allow re-uploading the same file
        if (mediaInputRefs.current[exerciseId]) {
            mediaInputRefs.current[exerciseId].value = '';
        }

    };


    const triggerNewMediaInputClick = (exerciseId) => {
        setActiveExerciseIdForMedia(exerciseId);
        newMediaInputRef.current.click();
    };


    const handleNewMediaUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) {
            console.error("No file selected.");
            return;
        }

        // Generate an object URL for the new media
        const url = createObjectUrl(file);
        const newMediaItem = {
            url: url,
            type: file.type.startsWith('video/') ? 'video' : 'image',
        };

        const updatedExercises = selectedExercises.map(exercise =>
            exercise.id === activeExerciseIdForMedia
                ? { ...exercise, mediaItems: [...exercise.mediaItems, newMediaItem] }
                : exercise
        );
        handleExercisesChange(updatedExercises);
        console.log("Media within exercise updated successfully");
    };

    // Make sure to revoke any object URLs when the component unmounts
    useEffect(() => {
        return () => {
            if (imagePreviewUrl) {
                revokeObjectUrl(imagePreviewUrl);
            }
            selectedExercises.forEach(exercise => {
                exercise.mediaItems.forEach(item => {
                    revokeObjectUrl(item.url);
                });
            });
        };
    }, [imagePreviewUrl, selectedExercises, revokeObjectUrl]);



    const handleExerciseNoteChange = (e, exerciseId) => {
        const newValue = e.target.value;

        handleExercisesChange(
            selectedExercises.map(exercise => {
                if (exercise.id === exerciseId) {
                    return { ...exercise, exerciseNote: newValue };
                }
                return exercise;
            })
        );
    };


    const handleTextareaChange = (e, exerciseId, setId, type) => {
        const target = e.target;


        target.style.height = 'inherit';
        target.style.height = `${target.scrollHeight}px`;
    };

    const deleteSet = (exerciseId, setId) => {

        handleExercisesChange(currentExercises =>
            currentExercises.map(exercise => {
                if (exercise.id === exerciseId) {
                    const updatedSets = exercise.sets.filter(set => set.id !== setId);
                    return { ...exercise, sets: updatedSets };
                }
                return exercise;
            })
        );


        setInputValues(currentInputValues => {
            const { [setId]: removed, ...remainingInputValues } = currentInputValues;
            return remainingInputValues;
        });
    };

    const toggleTillFailure = (exerciseId, setId) => {
        handleExercisesChange(exercises => exercises.map(exercise => {
            if (exercise.id === exerciseId) {
                return {
                    ...exercise,
                    sets: exercise.sets.map(set => {
                        if (set.id === setId) {
                            const tillFailure = !set.tillFailure;
                            setInputValues({ ...inputValues, [`${exerciseId}_${setId}_repetitions`]: tillFailure ? 'TF' : '' });
                            return { ...set, tillFailure };
                        }
                        return set;
                    })
                };
            }
            return exercise;
        }));
    };

    const preventNonNumericInput = (e) => {
        const currentValue = e.target.value;
        if (
            (e.key === '.' && currentValue.includes('.')) || // Prevent entering more than one '.'
            (!/[0-9]/.test(e.key) && e.key !== '.' && e.key !== "Backspace" && e.key !== "Enter") // Allow numbers, a single '.', backspace, and enter
        ) {
            e.preventDefault();
        }
    };





    const handleInputChange = (e, exerciseId, setId, type) => {
        const newValue = e.target.value;
        const target = e.target;

        let inputKey = `${exerciseId}_${setId}_${type}`;

        if (type === 'resistance_value' || type === 'resistance_units' || type === 'resistance_type' || type === 'distance' || type === 'distance_units') {
            inputKey = `${exerciseId}_${setId}_${type}`;
        }

        if (type === 'note') {

            setInputValues(prevValues => ({
                ...prevValues,
                [inputKey]: newValue,
            }));


            handleExercisesChange(exercises => exercises.map(exercise => {
                if (exercise.id === exerciseId) {
                    return {
                        ...exercise,
                        sets: exercise.sets.map(set => {
                            if (set.id === setId) {
                                return { ...set, note: newValue };
                            }
                            return set;
                        })
                    };
                }
                return exercise;
            }));


            target.style.height = 'inherit';
            target.style.height = `${target.scrollHeight}px`;
        } else if (type === 'repetitions' && newValue.toUpperCase() === 'TF') {

            handleExercisesChange(exercises => exercises.map(exercise => {
                if (isNaN(newValue) && newValue !== '') {
                    // If the new value is not a number and not empty, ignore the input
                    return;
                }

                if (exercise.id === exerciseId) {
                    return {
                        ...exercise,
                        sets: exercise.sets.map(set => {
                            if (set.id === setId) {

                                const updatedSet = { ...set, tillFailure: !set.tillFailure };

                                setInputValues(prevValues => ({
                                    ...prevValues,

                                    [inputKey]: updatedSet.tillFailure ? 'TF' : ''
                                }));
                                return updatedSet;
                            }
                            return set;
                        })
                    };
                }
                return exercise;
            }));
        } else {

            setInputValues(prevValues => {
                const updatedValues = {
                    ...prevValues,
                    [inputKey]: newValue,
                };

                return updatedValues;
            });
        }
    };

    const toggleTimerTillFailure = (exerciseId, setId) => {
        handleExercisesChange(exercises => exercises.map(exercise => {
            if (exercise.id === exerciseId) {
                return {
                    ...exercise,
                    sets: exercise.sets.map(set => {
                        if (set.id === setId) {
                            const newTimerTillFailure = !set.timerTillFailure;

                            const newInputValues = { ...inputValues, [`${exerciseId}_${setId}_timer`]: newTimerTillFailure ? 'Open' : '' };
                            setInputValues(newInputValues);
                            return { ...set, timerTillFailure: newTimerTillFailure };
                        }
                        return set;
                    })
                };
            }
            return exercise;
        }));
    };

    const toggleSetAttribute = (exerciseId, setId, attributeName) => {
        handleExercisesChange(exercises => exercises.map(exercise => {
            if (exercise.id === exerciseId) {
                const setToUpdate = exercise.sets.find(set => set.id === setId);
                if (!setToUpdate) return exercise;

                // Toggling the attribute
                const newAttributeState = !setToUpdate.selectedIcons[attributeName];

                // Update the selectedIcons state
                const updatedSets = exercise.sets.map(set => {
                    if (set.id === setId) {
                        return {
                            ...set,
                            selectedIcons: {
                                ...set.selectedIcons,
                                [attributeName]: newAttributeState,
                            }
                        };
                    }
                    return set;
                });

                // Resetting input values and toggles if the attribute is toggled off
                if (!newAttributeState) {
                    const inputKeyBase = `${exerciseId}_${setId}_`;
                    switch (attributeName) {
                        case 'faHashtag': // Repetitions
                            setInputValues(prev => ({
                                ...prev,
                                [inputKeyBase + 'repetitions']: '',
                            }));
                            // Resetting "Till Failure" toggle state
                            handleExercisesChange(exercises => exercises.map(ex => {
                                if (ex.id === exerciseId) {
                                    return {
                                        ...ex,
                                        sets: ex.sets.map(st => {
                                            if (st.id === setId) {
                                                return { ...st, tillFailure: false };
                                            }
                                            return st;
                                        })
                                    };
                                }
                                return ex;
                            }));
                            break;
                        case 'faWeightHanging': // Resistance
                            setInputValues(prev => ({
                                ...prev,
                                [inputKeyBase + 'resistance_units']: '',
                                [inputKeyBase + 'resistance_type']: '',
                                [inputKeyBase + 'resistance_value']: '',
                            }));
                            break;
                        case 'faTimer': // Timer
                            setInputValues(prev => ({
                                ...prev,
                                [inputKeyBase + 'timer']: '',
                            }));
                            // Resetting "Timer Till Failure" toggle state
                            handleExercisesChange(exercises => exercises.map(ex => {
                                if (ex.id === exerciseId) {
                                    return {
                                        ...ex,
                                        sets: ex.sets.map(st => {
                                            if (st.id === setId) {
                                                return { ...st, timerTillFailure: false };
                                            }
                                            return st;
                                        })
                                    };
                                }
                                return ex;
                            }));
                            break;
                        case 'faRuler': // Distance
                            setInputValues(prev => ({
                                ...prev,
                                [inputKeyBase + 'distance_units']: '',
                                [inputKeyBase + 'distance']: '',
                            }));
                            break;
                        default:
                            break;
                    }
                }

                return { ...exercise, sets: updatedSets };
            }
            return exercise;
        }));
    };




    const addSetToExercise = (exerciseId) => {
        handleExercisesChange((exercises) =>
            exercises.map((exercise) => {
                if (exercise.id === exerciseId) {
                    const lastSet = exercise.sets[exercise.sets.length - 1];
                    const newSetId = Math.max(...exercise.sets.map(set => set.id)) + 1;

                    const newSet = {
                        ...lastSet,
                        id: newSetId,
                    };

                    const newInputValues = { ...inputValues };
                    Object.keys(inputValues).forEach((key) => {
                        if (key.startsWith(`${exerciseId}_${lastSet.id}_`)) {
                            const newKey = key.replace(`_${lastSet.id}_`, `_${newSetId}_`);
                            newInputValues[newKey] = inputValues[key];
                        }
                    });

                    setInputValues(newInputValues);

                    const updateSelections = (selectionState, prefix) => {
                        const newState = { ...selectionState };
                        Object.keys(selectionState).forEach((key) => {
                            if (key.startsWith(`${exerciseId}_${lastSet.id}`)) {
                                const newKey = key.replace(`_${lastSet.id}`, `_${newSetId}`);
                                newState[newKey] = selectionState[key];
                            }
                        });
                        return newState;
                    };

                    setSelectedUnits(currentUnits => updateSelections(currentUnits, 'units'));
                    setSelectedResistance(currentResistance => updateSelections(currentResistance, 'resistance'));
                    setSelectedDistanceUnits(currentDistanceUnits => updateSelections(currentDistanceUnits, 'distanceUnits'));

                    return {
                        ...exercise,
                        sets: [...exercise.sets, newSet],
                    };
                }
                return exercise;
            })
        );
    };

    const deleteExercise = (exerciseId) => {
        handleExercisesChange(selectedExercises.filter(exercise => exercise.id !== exerciseId));
    };


    useEffect(() => {
        adjustTextareaHeight();
    }, []);

    const handleTimeConfirm = (time) => {
        if (editingSet) {
            const { exerciseId, setId, type } = editingSet;

            // This ensures we are using the correct key for either rest or timer.
            // It's essentially the same logic you already have but reiterates the intent for clarity.
            const key = type === 'rest' ? `${exerciseId}_${setId}_rest` : `${exerciseId}_${setId}_timer`;

            // Update the inputValues state with the new time value for the specific set's rest or timer.
            const newInputValues = { ...inputValues, [key]: time };

            setInputValues(newInputValues);

            // Hide the TimePickerComponent and reset the editing state as we're done editing.
            setShowTimePicker(false);
            setEditingSet(null);
        }
    };

    const handleResetTimerOrRest = () => {
        if (editingSet) {
            const { exerciseId, setId, type } = editingSet;
            const key = type === 'rest' ? `${exerciseId}_${setId}_rest` : `${exerciseId}_${setId}_timer`;

            // Reset the input value to an empty string to show the placeholder
            const newInputValues = { ...inputValues, [key]: '' };
            setInputValues(newInputValues);

            // Hide the TimePickerComponent and reset the editing state
            setShowTimePicker(false);
            setEditingSet(null);
        }
    };




    return (
        <>

            {isUploading && (
                <div className="mb-4 flex items-center">
                    <label>Upload progress:</label>
                    <progress className="ml-2" value={uploadProgress} max="100">{uploadProgress}%</progress>
                </div>
            )}

            <div className="flex mb-8 px-8 sm:px-0">
                <button
                    className="text-black bg-gray-200 hover:bg-gray-300 px-2 py-1 rounded transition duration-150 ease-in-out"
                    onClick={() => setShowConfirmDialog(true)}
                >
                    Cancel
                </button>
                {/*  <button
                    className="ml-2 text-black bg-gray-200 hover:bg-gray-300 px-2 py-1 rounded transition duration-150 ease-in-out"
                    onClick={() => console.log("Save as draft")}
                >
                    Save as draft
                </button> */}
                <button
                    className="ml-2 text-back bg-fitmania-green hover:bg-fitmania-green-dark px-4 py-1 rounded transition duration-150 ease-in-out"
                    onClick={() => onSaveWorkout()}
                >
                    Save workout
                </button>
            </div>

            {showConfirmDialog && (
                <ConfirmDialog
                    title="Are you sure you want to cancel?"
                    message="All un-saved progress will be lost."
                    onConfirm={onCancel}
                    onCancel={() => setShowConfirmDialog(false)}
                    confirmText="Yes"
                    cancelText="No"
                />
            )}

            <div className="w-full max-w-xl">
                <div className="bg-white px-8 py-8 shadow rounded-lg overflow-auto">
                    <div className="flex items-center mb-4">
                        {coverImagePreviewUrl ? (
                            <img src={coverImagePreviewUrl} alt="Cover" className="h-40 bg-gray-100 border text-gray-900 rounded-md cursor-pointer" onClick={toggleImageFullscreen} />
                        ) : (
                            <div className="flex items-center justify-center w-40 h-40 bg-gray-100 border text-gray-900 rounded-md">
                                Cover Image
                            </div>
                        )}

                        <div className="flex flex-col">

                            <button
                                className="ml-4 bg-fitmania-green text-black px-4 py-2 rounded-md hover:bg-fitmania-green-dark transition duration-150 ease-in-out"
                                onClick={() => fileInputRef.current.click()}
                            >
                                + Add Image
                            </button>

                            {coverImagePreviewUrl && (
                                <button
                                    className="mt-2 ml-4 bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-800 transition duration-150 ease-in-out"
                                    onClick={removeImage}
                                >
                                    - Remove Image
                                </button>
                            )}
                        </div>

                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            accept="image/png, image/jpeg"
                            onChange={(e) => handleFileSelection(e.target.files[0], true)} // true indicates it's a cover image
                        />

                        {isImageFullscreen && (
                            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={toggleImageFullscreen}>
                                <img src={coverImagePreviewUrl} alt="Fullscreen Cover" className="max-w-full max-h-full" />
                            </div>
                        )}
                    </div>

                    <div>
                        <p className="font-semibold pt-2 pr-2">Name:</p>
                        <textarea
                            className="auto-grow-textarea w-full py-4 rounded-md focus:outline-none focus:border-gray-500"
                            placeholder="Enter a workout name..."
                            rows="1"
                            value={workoutName}
                            onChange={handleWorkoutNameChange}
                        ></textarea>
                    </div>

                    <hr className=" mb-4" />

                    <div>
                        <p className="font-semibold pt-2 pr-2">Description:</p>
                        <textarea
                            ref={textareaRef}
                            className="auto-grow-textarea w-full py-4 rounded-md focus:outline-none focus:border-gray-500"
                            placeholder="Enter a workout description..."
                            rows="1"
                            value={workoutDescription}
                            onChange={handleDescriptionChange}
                        ></textarea>
                    </div>

                    <hr className="mb-4" />

                    <div className="flex justify-between items-center mt-4 mb-4">
                        <div className="text-left text-lg font-semibold">Exercises</div>

                        {selectedExercises.length > 1 && (
                            <button
                                onClick={toggleAllExerciseDropdowns}
                                className="bg-gray-200 p-2 mr-8 rounded-full shadow inline-flex items-center justify-center"
                            >
                                <FontAwesomeIcon icon={areAllExercisesOpen ? faChevronUp : faChevronDown} className="text-gray-800" />
                            </button>

                        )}
                    </div>

                    <div className="mt-4">
                        {selectedExercises.map((exercise, index) => (
                            <div key={exercise.id || index} className="mb-2">
                                <div className="flex items-start">
                                    {selectedExercises.length > 1 && (
                                        <div className="flex flex-col mr-3 pt-3">
                                            <FontAwesomeIcon
                                                icon={faChevronUp}
                                                className={`text-gray-400 hover:text-gray-800 cursor-pointer ${index === 0 ? 'text-gray-400 hover:text-gray-400' : ''}`}
                                                onClick={() => moveExerciseUp(index)}
                                            />
                                            <FontAwesomeIcon
                                                icon={faChevronDown}
                                                className={`text-gray-400 hover:text-gray-800 cursor-pointer ${index === selectedExercises.length - 1 ? 'text-gray-400 hover:text-gray-400' : ''}`}
                                                onClick={() => moveExerciseDown(index)}
                                            />
                                        </div>
                                    )}
                                    <div className="flex flex-col flex-1">
                                        <button
                                            onClick={() => setIsDropdownOpen({ ...isDropdownOpen, [exercise.id]: !isDropdownOpen[exercise.id] })}
                                            className="flex items-center justify-between w-full text-left text-lg font-medium text-gray-900 focus:outline-none p-4 rounded-md bg-gray-200"
                                        >
                                            {exercise.exerciseName}
                                            <FontAwesomeIcon icon={isDropdownOpen[exercise.id] ? faChevronUp : faChevronDown} className="ml-2" />
                                        </button>

                                        {isDropdownOpen[exercise.id] && (
                                            <>

                                                <div className="flex items-center space-x-4 mt-2 overflow-auto" style={{ scrollbarWidth: 'thin' }}>

                                                    {exercise.mediaItems.map((item, index) => (
                                                        <div key={index} className="flex-shrink-0 mb-2 relative">
                                                            {item.type === 'image' ? (
                                                                <img
                                                                    src={item.url}
                                                                    alt={`Uploaded ${item.type}`}
                                                                    className="h-40 w-auto object-cover rounded cursor-pointer"
                                                                    onClick={() => requestImageFullscreen(item.url)}
                                                                />
                                                            ) : (
                                                                <video
                                                                    src={item.url}
                                                                    autoPlay
                                                                    playsInline
                                                                    muted
                                                                    preload
                                                                    controls
                                                                    loop
                                                                    className="h-40 w-auto rounded cursor-pointer"
                                                                    onClick={(e) => requestVideoFullscreen(e.currentTarget)}
                                                                />

                                                            )}
                                                            <button
                                                                onClick={() => removeMediaItem(exercise.id, index)}
                                                                className="absolute top-0 right-0 flex m-1 bg-white rounded-full"
                                                            >
                                                                <FontAwesomeIcon icon={faTimesCircle} />
                                                            </button>
                                                        </div>
                                                    ))}


                                                    <button
                                                        onClick={() => mediaInputRefs.current[exercise.id].click()}
                                                        className="flex items-center justify-center border border-gray-300 rounded-md p-4"
                                                    >
                                                        <FontAwesomeIcon icon={faCamera} size="lg" />
                                                    </button>
                                                </div>


                                                <input
                                                    type="file"
                                                    ref={(el) => (mediaInputRefs.current[exercise.id] = el)}
                                                    style={{ display: 'none' }}
                                                    accept="image/*,video/*"
                                                    onChange={(e) => handleFileSelection(e.target.files[0], false, exercise.id)} // false or omitted for media files
                                                />

                                                <div className="border border-gray rounded-md p-4 mt-2">
                                                    <div className="flex justify-between items-center">
                                                        <textarea
                                                            ref={textareaRef}
                                                            className="auto-grow-textarea w-full p-2 rounded-md focus:outline-none focus:border-gray-500"
                                                            placeholder="Enter an exercise note..."
                                                            rows="1"
                                                            value={exercise.exerciseNote}
                                                            onChange={(e) => {
                                                                handleExerciseNoteChange(e, exercise.id);
                                                                handleTextareaChange(e);
                                                            }}
                                                        ></textarea>
                                                    </div>
                                                </div>

                                                {exercise.sets.map((set, setIndex) => (
                                                    <div key={set.id} className="border border-black rounded-md p-2 mt-2 mb-2">
                                                        <div className="border border-gray rounded-md p-4 mb-2">
                                                            <div className="flex justify-between items-center mb-4">
                                                                <p className="text-left text-lg font-semibold text-black">Set {setIndex + 1}:</p>
                                                                {exercise.sets.length > 1 && (
                                                                    <Menu as="div" className="relative inline-block text-left">
                                                                        <Menu.Button className="flex items-center p-2 rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                                                            <span className="sr-only">Open options</span>
                                                                            <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
                                                                        </Menu.Button>

                                                                        <Transition
                                                                            as={Fragment}
                                                                            enter="transition ease-out duration-100"
                                                                            enterFrom="transform opacity-0 scale-95"
                                                                            enterTo="transform opacity-100 scale-100"
                                                                            leave="transition ease-in duration-75"
                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                            leaveTo="transform opacity-0 scale-95"
                                                                        >
                                                                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                                <div className="py-1">
                                                                                    <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <button

                                                                                                onClick={() => deleteSet(exercise.id, set.id)}
                                                                                                className={`${active ? 'bg-gray-100 text-gray-500' : 'text-gray-700'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                                            >
                                                                                                Delete set
                                                                                            </button>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                </div>
                                                                            </Menu.Items>
                                                                        </Transition>
                                                                    </Menu>
                                                                )}
                                                            </div>

                                                            {set.selectedIcons.faHashtag && (
                                                                <div className="flex justify-between items-center mb-2">
                                                                    <span className="text-gray-900 text-lg">Repetitions:</span>
                                                                    <div>
                                                                        <button
                                                                            onClick={() => toggleTillFailure(exercise.id, set.id)}
                                                                            className={`text-sm mr-2 px-1 py-1 rounded ${set.tillFailure ? 'bg-gray-300 text-black' : 'bg-gray-100 text-black'}`}>
                                                                            Till Failure
                                                                        </button>
                                                                        <input
                                                                            type="tel"
                                                                            value={inputValues[`${exercise.id}_${set.id}_repetitions`] || ''}
                                                                            placeholder="12"
                                                                            onClick={() => {
                                                                                if (set.tillFailure) {
                                                                                    toggleTillFailure(exercise.id, set.id);
                                                                                }
                                                                            }}
                                                                            onKeyDown={preventNonNumericInput}
                                                                            onChange={(e) => handleInputChange(e, exercise.id, set.id, 'repetitions')}
                                                                            className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12"
                                                                            style={{ borderRadius: '5px', width: '65px', height: 'auto' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {set.selectedIcons.faWeightHanging && (
                                                                <div className="flex justify-between items-center mb-2">
                                                                    <div className="flex">
                                                                        <span className="text-gray-900 text-lg">Resistance:</span>
                                                                        <Tooltip content="Add resistance value if fixed. Leave blank if to be filled by end user. If 'Other' selected in 'Units' or 'Type' please explain in the set note.">
                                                                            <button className="text-gray-400 hover:text-gray-700 focus:outline-none  ml-2 mr-4">
                                                                                <FontAwesomeIcon icon={faCircleInfo} />
                                                                            </button>
                                                                        </Tooltip>
                                                                    </div>
                                                                    <div className="flex items-center">
                                                                        <select
                                                                            value={inputValues[`${exercise.id}_${set.id}_resistance_units`] || ''}
                                                                            onChange={(e) => handleInputChange(e, exercise.id, set.id, 'resistance_units')}
                                                                            className="text-sm text-center text-black bg-gray-100 rounded px-1 py-1  mr-2"
                                                                        >
                                                                            <option value="">Units:</option>
                                                                            <option value="Kg">Kg</option>
                                                                            <option value="Lbs">Lb</option>
                                                                            <option value="Pood">Pood</option>
                                                                            <option value="Stone">Stone</option>
                                                                            <option value="Other">Other</option>
                                                                        </select>
                                                                        <select
                                                                            value={inputValues[`${exercise.id}_${set.id}_resistance_type`] || ''}
                                                                            onChange={(e) => handleInputChange(e, exercise.id, set.id, 'resistance_type')}
                                                                            className="text-sm text-center text-black bg-gray-100 rounded px-1 py-1 mr-2"
                                                                        >
                                                                            <option value="">Type:</option>
                                                                            <option value="Ball">Ball</option>
                                                                            <option value="Barbell">Barbell</option>
                                                                            <option value="Cable">Cable</option>
                                                                            <option value="Dumbbell">Dumbbell</option>
                                                                            <option value="Kettlebell">Kettlebell</option>
                                                                            <option value="Machine">Machine</option>
                                                                            <option value="Parachute">Parachute</option>
                                                                            <option value="Resistance band">Res. band</option>
                                                                            <option value="Rock">Rock</option>
                                                                            <option value="Rock">Rope</option>
                                                                            <option value="Sled">Sled</option>
                                                                            <option value="TRX">TRX</option>
                                                                            <option value="Vest">Vest</option>
                                                                            <option value="Other">Other</option>
                                                                        </select>
                                                                        <input
                                                                            type="text"
                                                                            pattern="[0-9]*\.?[0-9]{0,1}"  // Allow numeric values and limit to one decimal place
                                                                            inputMode="decimal"  // Bring up the numeric keypad with a decimal point on mobile devices
                                                                            value={inputValues[`${exercise.id}_${set.id}_resistance_value`] || ''}
                                                                            placeholder="135"
                                                                            onChange={(e) => handleInputChange(e, exercise.id, set.id, 'resistance_value')}
                                                                            onKeyDown={preventNonNumericInput}
                                                                            className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12"
                                                                            style={{ borderRadius: '5px', width: '65px', height: 'auto' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {set.selectedIcons.faTimer && (
                                                                <div className="flex justify-between items-center mb-2">
                                                                    <span className="text-gray-900 text-lg">Timer:</span>
                                                                    <div className="flex items-center">
                                                                        <button
                                                                            onClick={() => toggleTimerTillFailure(exercise.id, set.id)}
                                                                            className={`text-sm mr-2 px-1 py-1 rounded ${set.timerTillFailure ? 'bg-gray-300 text-black' : 'bg-gray-100 text-black'}`}
                                                                        >
                                                                            Open
                                                                        </button>
                                                                        <input
                                                                            type="tel"
                                                                            value={set.timerTillFailure ? 'Open' : inputValues[`${exercise.id}_${set.id}_timer`] || ''}
                                                                            placeholder="01:30"
                                                                            onClick={() => {
                                                                                if (set.timerTillFailure) {

                                                                                    const updatedSets = selectedExercises.map((exerciseItem) => {
                                                                                        if (exerciseItem.id === exercise.id) {
                                                                                            return {
                                                                                                ...exerciseItem,
                                                                                                sets: exerciseItem.sets.map((setItem) => {
                                                                                                    if (setItem.id === set.id) {
                                                                                                        return { ...setItem, timerTillFailure: false };
                                                                                                    }
                                                                                                    return setItem;
                                                                                                }),
                                                                                            };
                                                                                        }
                                                                                        return exerciseItem;
                                                                                    });
                                                                                    handleExercisesChange(updatedSets);
                                                                                    setInputValues({ ...inputValues, [`${exercise.id}_${set.id}_timer`]: '' });
                                                                                }
                                                                            }}
                                                                            onChange={(e) => handleInputChange(e, exercise.id, set.id, 'timer')}
                                                                            onFocus={() => {
                                                                                setShowTimePicker(true);
                                                                                setEditingSet({ exerciseId: exercise.id, setId: set.id, type: 'timer' });
                                                                            }}
                                                                            readOnly
                                                                            className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12"
                                                                            style={{ borderRadius: '5px', width: '65px', height: 'auto' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {set.selectedIcons.faRuler && (
                                                                <div className="flex justify-between items-center mb-2">
                                                                    <span className="text-gray-900 text-lg">Distance:</span>
                                                                    <div className="flex items-center">
                                                                        <select
                                                                            value={inputValues[`${exercise.id}_${set.id}_distance_units`] || ''}
                                                                            onChange={(e) => handleInputChange(e, exercise.id, set.id, 'distance_units')}
                                                                            className="text-sm text-center text-black bg-gray-100 rounded px-1 py-1 mr-2"
                                                                        >
                                                                            <option value="">Units:</option>
                                                                            <option value="Ft">Ft</option>
                                                                            <option value="Yd">Yd</option>
                                                                            <option value="Mi">Mi</option>
                                                                            <option value="M">M</option>
                                                                            <option value="Km">Km</option>
                                                                        </select>
                                                                        <input
                                                                            type="text"
                                                                            pattern="[0-9]*\.?[0-9]{0,1}"  // Allow numeric values and limit to one decimal place
                                                                            inputMode="decimal"  // Bring up the numeric keypad with a decimal point on mobile devices
                                                                            value={inputValues[`${exercise.id}_${set.id}_distance`] || ''}
                                                                            placeholder="100"
                                                                            onChange={(e) => handleInputChange(e, exercise.id, set.id, 'distance')}
                                                                            onKeyDown={preventNonNumericInput}
                                                                            className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12"
                                                                            style={{ borderRadius: '5px', width: '65px', height: 'auto' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}

                                                            <div className="flex items-center justify-left space-x-2 mt-4">
                                                                {[
                                                                    { icon: faHashtag, name: 'faHashtag', label: 'Repetitions' },
                                                                    { icon: faWeightHanging, name: 'faWeightHanging', label: 'Resistance' },
                                                                    { icon: faTimer, name: 'faTimer', label: 'Timer' },
                                                                    { icon: faRuler, name: 'faRuler', label: 'Distance' },
                                                                ].map(({ icon, name, label }) => (
                                                                    <button
                                                                        key={name}
                                                                        className={`rounded-full w-10 h-10 flex items-center justify-center ${set.selectedIcons[name] ? 'bg-gray-300' : 'bg-gray-100'}`}
                                                                        onClick={() => toggleSetAttribute(exercise.id, set.id, name)}
                                                                    >
                                                                        <FontAwesomeIcon icon={icon} className="text-lg" />
                                                                    </button>
                                                                ))}
                                                            </div>



                                                        </div>

                                                        <div className="border border-gray rounded-md p-4 mt-2">
                                                            <div className="flex justify-between items-center">
                                                                <span className="text-gray-900 text-lg">Rest:</span>
                                                                <input
                                                                    type="tel"
                                                                    value={inputValues[`${exercise.id}_${set.id}_rest`] || ''}
                                                                    placeholder="00:30"
                                                                    onFocus={() => {
                                                                        setShowTimePicker(true);
                                                                        setEditingSet({ exerciseId: exercise.id, setId: set.id, type: 'rest' });
                                                                    }}
                                                                    readOnly
                                                                    onChange={(e) => handleInputChange(e, exercise.id, set.id, 'rest')}
                                                                    className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12"
                                                                    style={{ borderRadius: '5px', width: '65px', height: 'auto' }}
                                                                />


                                                            </div>
                                                        </div>
                                                        <div className="border border-gray rounded-md p-4 mt-2">
                                                            <div className="flex justify-between items-center">
                                                                <textarea
                                                                    ref={textareaRef}
                                                                    className="auto-grow-textarea w-full p-2 rounded-md focus:outline-none focus:border-gray-500"
                                                                    placeholder="Enter a set note..."
                                                                    rows="1"
                                                                    value={set.note || ''}
                                                                    onChange={(e) => handleInputChange(e, exercise.id, set.id, 'note')}
                                                                ></textarea>
                                                            </div>
                                                        </div>

                                                    </div>
                                                ))}

                                                <div className="flex justify-end mt-2 mb-4">
                                                    <button
                                                        className="bg-fitmania-green text-black px-4 py-2 rounded hover:bg-fitmania-green-dark transition duration-150 ease-in-out"
                                                        onClick={() => addSetToExercise(exercise.id)}
                                                    >
                                                        + Add a set
                                                    </button>
                                                </div>

                                            </>
                                        )}

                                    </div>
                                    <button
                                        onClick={() => deleteExercise(exercise.id)}
                                        className="ml-3 pt-4 text-gray-400 hover:text-gray-800 transition duration-150 ease-in-out"
                                        aria-label="Delete exercise"
                                    >
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                </div>
                            </div>
                        ))}
                        {showTimePicker && (
                            <TimePickerComponent
                                onClose={() => setShowTimePicker(false)}
                                onConfirm={handleTimeConfirm}
                                onReset={handleResetTimerOrRest}
                            />
                        )}
                    </div>

                    <hr className="mt-4 mb-4" />

                    <div className="flex justify-end mt-4 mb-4">
                        <button
                            className="bg-fitmania-green text-black px-4 py-2 rounded hover:bg-fitmania-green-dark transition duration-150 ease-in-out"
                            onClick={() => setShowExercisesModal(true)}
                        >
                            + Add an exercise
                        </button>
                    </div>
                    {showExercisesModal && (
                        <div className="px-6 fixed inset-0 bg-gray-600 bg-opacity-40 z-50 overflow-y-auto h-full w-full flex items-center justify-center" onClick={() => setShowExercisesModal(false)}>
                            <div className="relative border w-full max-w-md m-auto flex-col flex bg-white rounded-lg shadow-lg" onClick={e => e.stopPropagation()}>
                                <div className="flex justify-between items-center text-lg font-medium">
                                    <h4 className="px-8 pt-8">Select an Exercise</h4>
                                    <button className="px-8 pt-8 text-gray-400 hover:text-gray-600" onClick={() => setShowExercisesModal(false)}>
                                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                                <div>
                                    <ExercisesComponent onExerciseSelected={onExerciseSelect} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div >
        </>
    );
}

export default CreateAWorkoutComponent;
