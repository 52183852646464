import React, { useEffect, useState } from 'react';
import Layout from './Layout';
import ActivityCard from './ActivityCard'; // Ensure the path is correct
import VisibilityDropdown from './VisibilityDropdown'; // Ensure the path is correct
import { getFirestore, collection, query, getDocs, doc, getDoc, orderBy, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { app2 } from '../firebase-config';

function ActivityPage() {
    const db = getFirestore(app2);
    const auth = getAuth(app2);
    const [activities, setActivities] = useState([]);
    const [selectedVisibility, setSelectedVisibility] = useState('All');

    useEffect(() => {
        const fetchActivities = async () => {
            const currentUser = auth.currentUser;
            if (!currentUser) return;

            let workoutsCompletedRef = collection(db, `users/${currentUser.uid}/workoutsCompleted`);
            if (selectedVisibility !== 'All') {
                workoutsCompletedRef = query(workoutsCompletedRef, where('visibility', '==', selectedVisibility));
            }
            const workoutsQuery = query(workoutsCompletedRef, orderBy("completedAt", "desc"));

            const querySnapshot = await getDocs(workoutsQuery);
            const activitiesPromises = querySnapshot.docs.map(async (docSnap) => {
                const completedData = docSnap.data();
                const workoutRefPath = completedData.copied ? `users/${currentUser.uid}/copiedWorkouts` : `users/${currentUser.uid}/workouts`;
                const workoutRef = doc(db, workoutRefPath, completedData.workoutId);
                const workoutSnapshot = await getDoc(workoutRef);

                if (workoutSnapshot.exists()) {
                    return {
                        id: docSnap.id,
                        workoutId: completedData.workoutId,
                        name: workoutSnapshot.data().name,
                        coverImage: workoutSnapshot.data().coverImage,
                        completedAt: completedData.completedAt.toDate().toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: '2-digit',
                            hour12: true
                        }),
                        copied: completedData.copied 
                    };
                } else {
                    return null;
                }
            });

            const fetchedActivities = (await Promise.all(activitiesPromises)).filter(Boolean);
            setActivities(fetchedActivities);
        };

        fetchActivities();
    }, [db, auth.currentUser, selectedVisibility]); // Now includes selectedVisibility in the dependency array.

    return (
        <Layout>
            <div className="max-w-7xl mx-auto">
                <div className="text-lg sm:text-xl font-semibold text-gray-900 my-4 mb-8 sm:p-0 pl-4 pt-4 pr-4">Activity</div>
                <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 mb-4">
                    <div className="flex flex-col items-start mb-4">
                        <h1 className="text-base sm:text-lg font-semibold mb-4">Completed Activity</h1>
                        <VisibilityDropdown
                            db={db}
                            userId={auth.currentUser.uid}
                            collectionName="workoutsCompleted"
                            onVisibilityChange={setSelectedVisibility}
                        />
                    </div>
                    <div className="flex flex-wrap">
                        {activities.length > 0 ? (
                            <div className="flex flex-nowrap sm:flex-wrap -mx-2 overflow-x-auto" style={{ scrollbarWidth: 'thin' }}>
                                {activities.map((activity) => (
                                    <div className="flex-shrink-0">
                                        <ActivityCard
                                            key={activity.id}
                                            activity={activity}
                                            copied={activity.copied}
                                        />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="-mt-4">No activity completed. Start by completing your first workout.</p>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ActivityPage;
