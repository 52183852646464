import React from 'react';
import PublicLayout from './PublicLayout';

const ContactPage = () => {
  return (
    <PublicLayout>
      <div className="bg-white px-6 my-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="font-bold tracking-tight text-gray-900 text-4xl">Contact our team</h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Send us a message and we'll be in touch
          </p>
        </div>
        <form
          action="https://formspree.io/f/mzbnodak"
          method="POST"
          className="mx-auto mt-16 max-w-xl"
        >
          <div className="flex flex-col gap-y-6">
            <div>
              <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
                First name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  required
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-fitmania-green sm:text-sm sm:leading-6 outline-none"
                />
              </div>
            </div>
            <div>
              <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
                Last name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  required
                  autoComplete="family-name"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-fitmania-green sm:text-sm sm:leading-6 outline-none"
                />
              </div>
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                Email
              </label>
              <div className="mt-2">
                <input
                  type="email"
                  name="email"
                  id="email"
                  required
                  autoComplete="email"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-fitmania-green sm:text-sm sm:leading-6 outline-none"
                />
              </div>
            </div>
            <div>
              <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900">
                Phone number
              </label>
              <div className="relative mt-2">
                <input
                  type="tel"
                  name="phone-number"
                  id="phone-number"
                  autoComplete="tel"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-fitmania-green sm:text-sm sm:leading-6 outline-none"
                />
              </div>
            </div>
            <div>
              <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                Message
              </label>
              <div className="mt-2">
                <textarea
                  name="message"
                  id="message"
                  required
                  rows={4}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-fitmania-green sm:text-sm sm:leading-6 outline-none"
                />
              </div>
            </div>
            <div className="flex flex-row justify-center mt-10">
              <button
                type="submit"
                className="rounded-lg bg-fitmania-green px-4 py-3 text-center font-semibold text-black shadow-sm hover:bg-fitmania-green-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fitmania-green"
              >
                Send
              </button>
            </div>
          </div>
        </form>
      </div>
    </PublicLayout>
  );
};

export default ContactPage;
