import React, { useState, useEffect, useRef } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { app2 } from '../firebase-config';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Link, useNavigate } from 'react-router-dom';
import ProfilePictureComponent from './ProfilePictureComponent';

function SearchComponent() {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const db = getFirestore(app2);
    const searchRef = useRef(null);
    const navigate = useNavigate();
    const auth = getAuth(app2);

    useEffect(() => {
        let isMounted = true;

        const fetchUsers = async () => {
            if (searchTerm.trim() === '') {
                if (isMounted) setSearchResults([]);
                return;
            }

            const lowerSearchTerm = searchTerm.toLowerCase();
            const usersRef = collection(db, 'publicProfiles');
            const querySnapshot = await getDocs(usersRef);

            const users = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            const filteredUsers = users.filter(user => {
                const firstNameLower = user.firstNameLower || '';
                const lastNameLower = user.lastNameLower || '';
                const userName = user.userName || '';
                const fullNameLower = user.fullNameLower || '';

                return (firstNameLower.includes(lowerSearchTerm) || 
                       lastNameLower.includes(lowerSearchTerm) || 
                       userName.includes(lowerSearchTerm) || 
                       fullNameLower.includes(lowerSearchTerm)) &&
                       user.id !== auth.currentUser.uid; // Exclude current user
            });

            if (isMounted) setSearchResults(filteredUsers);
        };

        const delayDebounceFn = setTimeout(() => {
            fetchUsers();
        }, 300);

        return () => {
            isMounted = false;
            clearTimeout(delayDebounceFn);
        };
    }, [searchTerm, db, auth.currentUser.uid]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setSearchTerm('');
                setSearchResults([]);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchRef]);

    const handleResultClick = (username) => {
        setSearchTerm('');
        setSearchResults([]);
        navigate(`/profile/${username}`);
    };

    return (
        <div className="relative flex-1" ref={searchRef}>
            <div className="relative">
                <MagnifyingGlassIcon
                    className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                    aria-hidden="true"
                />
                <input
                    id="search-field"
                    className="block h-full w-full border-0 py-0 pl-8 pr-0 bg-white text-gray-900 placeholder:text-gray-400 focus:ring-0 outline-none text-base"
                    placeholder="Search Fitmania"
                    type="search"
                    name="search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    autoComplete="off"
                />
            </div>
            {searchResults.length > 0 && (
                <ul className="absolute z-10 mt-2 w-full bg-white border border-gray-300 rounded-lg shadow-lg">
                    {searchResults.map((user) => (
                        <li key={user.id} className="p-2 hover:bg-gray-100 flex" onClick={() => handleResultClick(user.userName)}>
                            <div className="flex items-center p-2 w-full cursor-pointer">
                                <ProfilePictureComponent userId={user.id} className="w-8 h-8 rounded-full mr-2 object-cover" />
                                <div>
                                    <span className="block font-semibold text-sm">{user.userName}</span>
                                    <span className="block text-sm">{user.firstName} {user.lastName}</span>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default SearchComponent;
