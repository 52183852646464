import { createClient } from 'contentful';

const client = createClient({
  space: 'tu2th2zq99ey',
  accessToken: 'q8lvzOyGSGOaqaWViRa0gsinScq4-rg663OH_NIHGs0'
});

export const getEntryBySlug = async (slug) => {
  // Use Contentful's SDK to get the entry by slug
  const entries = await client.getEntries({
    content_type: 'blog', // Use the correct content type ID
    'fields.slug': slug, // Assuming you have a 'slug' field
    limit: 1,
  });

  if (entries.items.length > 0) {
    return entries.items[0];
  } else {
    throw new Error('Blog post not found');
  }
};


export const fetchEntries = async () => {
  try {
    const entries = await client.getEntries({
      content_type: 'blog', // Ensure this matches the content type ID in Contentful
      order: '-sys.createdAt', // Optional: Order posts by creation date
      limit: 1000 // Optional: Increase if you expect more than 100 entries
    });
    if (entries.items) {
      return entries.items.map(item => ({
        ...item,
        sys: item.sys // Include sys property in each item
      }));
    }
  } catch (error) {
    console.error(`Error fetching entries: ${error}`);
    return [];
  }
};

export default client;
