import React, { Fragment, useEffect, useState } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { app2 } from '../firebase-config';
import { getAuth } from 'firebase/auth';
import { Dialog, Transition, Switch } from '@headlessui/react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import ProfilePictureComponent from './ProfilePictureComponent';
import SearchComponent from './SearchComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome, faDumbbell, faWavePulse, faCalendar, faChartMixed, faXmark, faBullseye, faCog, faSunBright, faMoon,
  faUserCircle, faBars, faBell, faArrowRightFromBracket
} from '@fortawesome/pro-light-svg-icons';

const navigation = [
  { name: 'Overview', href: '/dashboard', icon: faHome, current: false },
  { name: 'My Profile', href: '/profile', icon: faUserCircle, current: false },
  { name: 'Activity', href: '/activity', icon: faWavePulse, current: false },
  { name: 'Workouts', href: '/workouts', icon: faDumbbell, current: false },
  { name: 'Statistics', href: '/statistics', icon: faChartMixed, current: false },
  { name: 'Goals', href: '/goals', icon: faBullseye, current: false },
  { name: 'Calendar', href: '/calendar', icon: faCalendar, current: false },
];

const adminTeams = [
  { id: 1, name: 'All Users', href: '/users', initial: 'U', current: false },
  { id: 3, name: 'Referrals', href: '/referrals', initial: 'R', current: false },
];

const userNavigation = [
  { name: 'My Profile', href: '/profile' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Layout({ children }) {
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    imageUrl: `${process.env.PUBLIC_URL}/images/fitmania_avatar.png`,
  });
  const [userType, setUserType] = useState('');
  const navigate = useNavigate();
  const db = getFirestore(app2);
  const auth = getAuth(app2);

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        const docRef = doc(db, "users", auth.currentUser.uid);
        try {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const { firstName, lastName, email, profilePicture = null, userType } = docSnap.data();
            setUserData((prevUserData) => ({
              ...prevUserData,
              name: `${firstName} ${lastName}`,
              email: email,
              imageUrl: profilePicture || prevUserData.imageUrl,
            }));
            setUserType(userType);
          } else {
            console.log("No user document found for ID:", auth.currentUser.uid);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else {
        console.log("No user logged in");
      }
    };

    fetchUserData();
  }, [auth.currentUser]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log("Signed out successfully");
      navigate('/');
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  userNavigation.forEach(item => {
    if (item.name === 'Sign out') {
      item.action = handleSignOut;
    }
  });

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const updatedNavigation = navigation.map(item => ({
    ...item,
    current: item.href === location.pathname,
  }));
  const updatedAdminTeams = adminTeams.map(team => ({
    ...team,
    current: location.pathname.includes(team.href),
  }));

  const [darkMode, setDarkMode] = useState(() => JSON.parse(localStorage.getItem('darkMode')) || false);

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1 ">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <FontAwesomeIcon icon={faXmark} className="mr-3 h-5 w-5 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>

                  <div className={`flex grow flex-col gap-y-5 overflow-y-auto ${darkMode ? 'bg-gray-900' : 'bg-white'} px-6 pb-4 ring-1 ring-white/10`}>
                    <div className="mt-4 flex h-16 shrink-0 items-center flex-row justify-between">
                      <Link to="/">
                        <img
                          className="h-8 w-auto"
                          src={`${process.env.PUBLIC_URL}/images/Fitmania F Logo.png`}
                          alt="Fitmania Logo"
                        />
                      </Link>

                      <Switch
                        checked={darkMode}
                        onChange={setDarkMode}
                        className={classNames(
                          darkMode ? 'bg-gray-800' : 'bg-gray-200',
                          'relative inline-flex h-[35px] w-[70px] items-center rounded-md transition-colors duration-200 ease-in-out'
                        )}
                      >
                        <span className="sr-only">Enable Dark Mode</span>
                        <span
                          className={classNames(
                            darkMode ? 'translate-x-[38px]' : 'translate-x-[4px]',
                            'inline-block h-[27px] w-[27px] transform shadow-lg rounded-md transition duration-200 ease-in-out flex items-center justify-center',
                            { 'ring-0': darkMode, 'ring-2 ring-indigo-600': !darkMode }
                          )}
                          style={{ backgroundColor: darkMode ? '#101725' : 'white' }}
                        >
                          {darkMode ? (
                            <FontAwesomeIcon icon={faMoon} className="h-4 w-4 text-white" aria-hidden="true" />
                          ) : (
                            <FontAwesomeIcon icon={faSunBright} className="h-4 w-4" aria-hidden="true" />
                          )}
                        </span>
                      </Switch>

                    </div>

                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {
                              updatedNavigation.map((item) => (
                                <li key={item.name}>
                                  <Link
                                    to={item.href}
                                    className={classNames(
                                      item.current ? (darkMode ? 'bg-gray-800 text-white' : 'fitmania-green text-black') :
                                        (darkMode ? 'text-gray-300 hover:bg-gray-700 hover:text-white' : 'text-black hover:bg-fitmania-green hover:text-black'),
                                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                    )}
                                  >
                                    <FontAwesomeIcon icon={item.icon} className="mr-3 h-5 w-5" aria-hidden="true" />
                                    {item.name}
                                  </Link>
                                </li>
                              ))
                            }
                          </ul>
                        </li>
                        {userType === 'Admin' && (
                          <li>
                            <div className="text-xs font-semibold leading-6 text-gray-400">Admin</div>
                            <ul role="list" className="-mx-2 mt-2 space-y-1">
                              {
                                updatedAdminTeams.map((team) => (
                                  <li key={team.name}>
                                    <Link
                                      to={team.href}
                                      className={classNames(
                                        team.current ? (darkMode ? 'bg-gray-800 text-white' : 'bg-fitmania-green text-black') : (darkMode ? 'text-gray-300 hover:bg-gray-700 hover:text-white' : 'text-black hover:bg-fitmania-green hover:text-black'),
                                        'group flex gap-x-3 items-center px-2 py-2 text-sm font-medium rounded-md'
                                      )}
                                    >
                                      <span className={classNames(
                                        "flex h-6 w-6 items-center justify-center rounded-lg text-sm font-medium",
                                        darkMode ? "bg-gray-800 text-gray-400" : "bg-fitmania-green text-black"
                                      )}>
                                        {team.initial}
                                      </span>
                                      {team.name}
                                    </Link>
                                  </li>
                                ))
                              }
                            </ul>
                          </li>
                        )}
                        <li className="mt-auto">
                          <Link
                            to="/settings"
                            className={classNames(
                              "group flex gap-x-3 items-center px-2 py-2 text-sm font-semibold leading-6 rounded-md",
                              darkMode ? "text-gray-400 hover:bg-gray-800 hover:text-white" : "text-black hover:bg-fitmania-green hover:text-black"
                            )}
                          >
                            <FontAwesomeIcon icon={faCog} className="mr-3 h-5 w-5" aria-hidden="true" />
                            Settings
                          </Link>

                          <Link
                            onClick={handleSignOut}
                            className={classNames(
                              "group flex gap-x-3 items-center px-2 py-2 text-sm font-semibold leading-6 rounded-md",
                              darkMode ? "text-gray-400 hover:bg-gray-800 hover:text-white" : "text-black hover:bg-fitmania-green hover:text-black"
                            )}
                          >
                            <FontAwesomeIcon icon={faArrowRightFromBracket} className="mr-3 h-5 w-5" aria-hidden="true" />
                            Sign out
                          </Link>

                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>


        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col border-r border-gray-200">

          <div className={`flex grow flex-col gap-y-5 overflow-y-auto ${darkMode ? 'bg-gray-900' : 'bg-white'} px-6 pb-4 ring-1 ring-white/10`}>
            <div className="flex h-16 shrink-0 items-center justify-between mt-4">
              <Link to="/">
                <img
                  className="h-8 w-auto"
                  src={`${process.env.PUBLIC_URL}/images/Fitmania F Logo.png`}
                  alt="Fitmania Logo"
                />
              </Link>
              <Switch
                checked={darkMode}
                onChange={setDarkMode}
                className={classNames(
                  darkMode ? 'bg-gray-800' : 'bg-gray-200',
                  'relative inline-flex h-[35px] w-[70px] items-center rounded-md transition-colors duration-200 ease-in-out'
                )}
              >
                <span className="sr-only">Enable Dark Mode</span>
                <span
                  className={classNames(
                    darkMode ? 'translate-x-[38px]' : 'translate-x-[4px]',
                    'inline-block h-[27px] w-[27px] transform shadow-lg rounded-md transition duration-200 ease-in-out flex items-center justify-center',
                    { 'ring-0': darkMode, 'ring-2 ring-indigo-600': !darkMode }
                  )}
                  style={{ backgroundColor: darkMode ? '#101725' : 'white' }}
                >
                  {darkMode ? (
                    <FontAwesomeIcon icon={faMoon} className="h-4 w-4 text-white" aria-hidden="true" />
                  ) : (
                    <FontAwesomeIcon icon={faSunBright} className="h-4 w-4" aria-hidden="true" />
                  )}
                </span>
              </Switch>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {
                      updatedNavigation.map((item) => (
                        <li key={item.name}>
                          <Link
                            to={item.href}
                            className={classNames(
                              item.current ? (darkMode ? 'bg-gray-800 text-white' : 'fitmania-green text-black') :
                                (darkMode ? 'text-gray-300 hover:bg-gray-700 hover:text-white' : 'text-black hover:bg-fitmania-green hover:text-black'),
                              'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                            )}
                          >
                            <FontAwesomeIcon icon={item.icon} className="mr-3 h-5 w-5" aria-hidden="true" />
                            {item.name}
                          </Link>
                        </li>
                      ))
                    }
                  </ul>
                </li>
                {userType === 'Admin' && (
                  <li>
                    <div className={classNames(
                      "text-xs font-semibold leading-6",
                      darkMode ? "text-gray-400" : "text-gray-400"
                    )}>
                      Admin
                    </div>
                    <ul role="list" className="-mx-2 mt-2 space-y-1">
                      {
                        updatedAdminTeams.map((team) => (
                          <li key={team.name}>
                            <Link
                              to={team.href}
                              className={classNames(
                                team.current ? (darkMode ? 'bg-gray-800 text-white' : 'bg-fitmania-green text-black') : (darkMode ? 'text-gray-300 hover:bg-gray-700 hover:text-white' : 'text-black hover:bg-fitmania-green hover:text-black'),
                                'group flex gap-x-3 items-center px-2 py-2 text-sm font-medium rounded-md'
                              )}
                            >
                              <span className={classNames(
                                "flex h-6 w-6 items-center justify-center rounded-lg text-sm font-medium",
                                darkMode ? "bg-gray-800 text-gray-400" : "bg-fitmania-green text-black"
                              )}>
                                {team.initial}
                              </span>
                              {team.name}
                            </Link>
                          </li>
                        ))
                      }
                    </ul>
                  </li>
                )}
                <li className="mt-auto">
                  <Link
                    to="/settings"
                    className={classNames(
                      "group flex gap-x-3 items-center px-2 py-2 text-sm font-semibold leading-6 rounded-md",
                      darkMode ? "text-gray-400 hover:bg-gray-800 hover:text-white" : "text-black hover:bg-fitmania-green hover:text-black"
                    )}
                  >
                    <FontAwesomeIcon icon={faCog} className="mr-3 h-5 w-5" aria-hidden="true" />
                    Settings
                  </Link>

                  <Link
                    onClick={handleSignOut}
                    className={classNames(
                      "group flex gap-x-3 items-center px-2 py-2 text-sm font-semibold leading-6 rounded-md",
                      darkMode ? "text-gray-400 hover:bg-gray-800 hover:text-white" : "text-black hover:bg-fitmania-green hover:text-black"
                    )}
                  >
                    <FontAwesomeIcon icon={faArrowRightFromBracket} className="mr-3 h-5 w-5" aria-hidden="true" />
                    Sign out
                  </Link>

                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" className="-m-2.5 p-2.5 pt-3 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <FontAwesomeIcon icon={faBars} className="mr-2 h-5 w-5" aria-hidden="true" />
            </button>

            <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <div className="items-center flex flex-1">
                <SearchComponent />
              </div>

              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <Link to="/notifications" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">View notifications</span>
                  <FontAwesomeIcon icon={faBell} className="mr-3 h-5 w-5" aria-hidden="true" />
                </Link>

                <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />

                <Link to="/profile" className="-m-1.5 flex items-center p-1.5">
                  <ProfilePictureComponent className="w-8 h-8" />
                  <span className="hidden lg:flex lg:items-center">
                    <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                      {userData.name}
                    </span>
                  </span>
                </Link>
              </div>
            </div>
          </div>

          <main>
            <div className="mx-auto sm:p-4 p-0 mb-16">
              {children}
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
