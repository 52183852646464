import React, { useState, useEffect, useRef } from 'react'; // Import useRef
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import { app2 } from '../firebase-config';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import Layout from './Layout';
import ProfilePictureComponent from './ProfilePictureComponent';

function EditProfileNamePage() {
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
    });
    const navigate = useNavigate();
    const [inputData, setInputData] = useState({
        firstName: '',
        lastName: '',
    });
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);

    useEffect(() => {
        const auth = getAuth(app2);
        const db = getFirestore(app2);
        const user = auth.currentUser;

        if (user) {
            const userRef = doc(db, 'users', user.uid);

            getDoc(userRef).then((docSnap) => {
                if (docSnap.exists()) {
                    const { firstName, lastName } = docSnap.data();
                    setUserData(prev => ({
                        ...prev,
                        firstName: firstName || '',
                        lastName: lastName || '',
                    }));
                }
            });
        }
    }, []);

    useEffect(() => {
        // Enable save button only if both firstName and lastName are not empty
        setIsSaveEnabled(inputData.firstName.trim() !== '' && inputData.lastName.trim() !== '');
    }, [inputData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputData(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleCancel = () => {
        navigate('/edit-profile');
    };

    const handleSave = async () => {
        const auth = getAuth(app2);
        const db = getFirestore(app2);
        const user = auth.currentUser;

        if (user) {
            const userRef = doc(db, 'users', user.uid);
            const publicProfileRef = doc(db, 'publicProfiles', user.uid);

            await setDoc(userRef, {
                firstName: inputData.firstName,
                lastName: inputData.lastName,
                firstNameLower: inputData.firstName.toLowerCase(),
                lastNameLower: inputData.lastName.toLowerCase(),
                fullNameLower: `${inputData.firstName.toLowerCase()} ${inputData.lastName.toLowerCase()}`
            }, { merge: true });

            await setDoc(publicProfileRef, {
                firstName: inputData.firstName,
                lastName: inputData.lastName,
                firstNameLower: inputData.firstName.toLowerCase(),
                lastNameLower: inputData.lastName.toLowerCase(),
                fullNameLower: `${inputData.firstName.toLowerCase()} ${inputData.lastName.toLowerCase()}`
            }, { merge: true });

            // Update the local state to reflect the changes
            setUserData({
                firstName: inputData.firstName,
                lastName: inputData.lastName,
            });

            // Navigate back to the profile edit page
            navigate('/edit-profile');
        }
    };


    return (
        <Layout>
            <div className="max-w-lg mx-auto">
                <div className="flex justify-between items-center mx-4 sm:mx-0">
                    <div className="text-lg sm:text-xl font-semibold text-gray-900 my-4 mb-8 sm:p-0 pt-4 pr-4">Edit name</div>
                    <button onClick={handleCancel} className="bg-gray-200 p-2 rounded-lg">Cancel</button>
                </div>
                <div className="flex flex-col gap-2 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 mb-4 items-center">
                    <div className="flex flex-row gap-8 items-start">
                        <div className="flex flex-col items-center gap-4">
                            <input
                                type="text"
                                name="firstName"
                                id="first-name"
                                className="mt-4 pl-4 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-400 text-base sm:leading-6 outline-none"
                                placeholder={userData.firstName || "First name"}
                                value={inputData.firstName}
                                onChange={handleInputChange}
                            />

                            <input
                                type="text"
                                name="lastName"
                                id="last-name"
                                className="pl-4 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-400 text-base sm:leading-6 outline-none"
                                placeholder={userData.lastName || "Last name"}
                                value={inputData.lastName}
                                onChange={handleInputChange}
                            />

                            <button
                                onClick={handleSave}
                                disabled={!isSaveEnabled}
                                className={`mt-2 mb-4 py-2 px-4 rounded-lg focus:outline-none ${isSaveEnabled ? 'bg-fitmania-green text-black' : 'bg-gray-200 text-gray-400'}`}
                            >
                                Save name
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    );
}

export default EditProfileNamePage;
