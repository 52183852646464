import React from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';
import InterestsComponent from './onboarding/InterestsComponent'; // Adjust the import path accordingly

function EditProfileInterestsPage() {
    const navigate = useNavigate();

    const handleCancel = () => {
        navigate('/edit-profile');
    };

    const handleContinue = () => {
        navigate('/edit-profile');
    };

    return (
        <Layout>
            <div className="max-w-lg mx-auto">
                <div className="flex justify-between items-center mx-4 sm:mx-0">
                    <div className="text-lg sm:text-xl font-semibold text-gray-900 my-4 mb-8 sm:p-0 pt-4 pr-4">Edit interests</div>
                    <button onClick={handleCancel} className="bg-gray-200 p-2 rounded-lg">Cancel</button>
                </div>
                <div className="flex flex-col gap-2 bg-white shadow rounded-lg overflow-hidden items-center">
                    <div className="-mt-16">
                    <InterestsComponent moveToNextStep={handleContinue} />
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default EditProfileInterestsPage;
