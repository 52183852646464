import React from 'react';

/**
 * Custom hook to handle file selection and conversion to an object URL for previews.
 */
export const useFileUpload = () => {
    /**
     * Generates an object URL for the selected file.
     * @param {File} file - The file selected by the user.
     * @returns {string} An object URL pointing to the selected file.
     */
    const createObjectUrl = (file) => {
        if (!file) {
            console.error("No file selected.");
            return '';
        }

        // Create an object URL directly without needing to read the file
        return URL.createObjectURL(file);
    };

    /**
     * Revokes an object URL to free up memory.
     * @param {string} url - The object URL to revoke.
     */
    const revokeObjectUrl = (url) => {
        URL.revokeObjectURL(url);
    };

    return { createObjectUrl, revokeObjectUrl };
};
