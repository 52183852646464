import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { app2 } from '../firebase-config';
import GoalChartComponent from './GoalChartComponent';
import ProfilePictureComponent from './ProfilePictureComponent';
import { format } from 'date-fns';
import ShareGoalComponent from './ShareGoalComponent';

function LitePublicGoalPage() {
    const { username, goalId } = useParams();
    const [goalData, setGoalData] = useState(null);
    const [checkIns, setCheckIns] = useState([]);
    const [userProfile, setUserProfile] = useState(null);
    const [userId, setUserId] = useState(null);
    const db = getFirestore(app2);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserIdByUsername = async (username) => {
            const usersCollection = collection(db, 'publicProfiles');
            const userQuery = query(usersCollection, where('userName', '==', username));
            const userSnapshot = await getDocs(userQuery);
            if (userSnapshot.empty) {
                console.error('No such user!');
                return null;
            }
            return userSnapshot.docs[0].id;
        };

        const fetchGoalData = async (uid) => {
            try {
                const goalDoc = doc(db, `users/${uid}/goals`, goalId);
                const goalSnapshot = await getDoc(goalDoc);

                if (goalSnapshot.exists()) {
                    const docData = goalSnapshot.data();

                    if (docData.visibility !== 'Public') {
                        throw new Error('This goal is not public.');
                    }

                    const goal = {
                        id: goalSnapshot.id,
                        ...docData,
                        startDate: new Date(docData.startDate + 'T00:00').getTime(),
                        completionDate: new Date(docData.completionDate + 'T00:00').getTime(),
                        currentStatus: parseFloat(docData.currentStatus),
                        targetMilestone: parseFloat(docData.targetMilestone),
                        completionDateFormatted: format(new Date(docData.completionDate + 'T00:00'), 'PPP'),
                    };

                    setGoalData(goal);

                    const checkInsRef = collection(db, `users/${uid}/goals/${goalSnapshot.id}/checkIns`);
                    const checkInsSnapshot = await getDocs(checkInsRef);

                    let additionalCheckIns = checkInsSnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                        date: new Date(doc.data().date + 'T00:00').getTime(),
                    }));

                    additionalCheckIns.sort((a, b) => a.date - b.date);
                    additionalCheckIns = additionalCheckIns.map(ci => ({
                        ...ci,
                        date: format(new Date(ci.date), 'PPP'),
                    }));

                    const initialCheckIn = {
                        date: format(new Date(goal.startDate), 'PPP'),
                        status: goal.currentStatus.toString(),
                        note: 'Initial Check-In',
                        id: 'initial',
                    };

                    setCheckIns([initialCheckIn, ...additionalCheckIns]);

                    const userProfileRef = doc(db, 'publicProfiles', uid);
                    const userProfileSnap = await getDoc(userProfileRef);
                    if (userProfileSnap.exists()) {
                        setUserProfile(userProfileSnap.data());
                    } else {
                        console.log("User profile document not found");
                    }
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error('Error fetching goal data:', error);
            }
        };

        if (username) {
            fetchUserIdByUsername(username)
                .then(uid => {
                    if (uid) {
                        setUserId(uid);
                        fetchGoalData(uid);
                    }
                });
        } else {
            console.log("username or goalId is missing.");
        }
    }, [username, goalId, db]);

    if (!goalData) {
        return <>Loading...</>;
    }

    return (
        <>
            <div className="max-w-5xl mx-auto mt-4">
                <div className="text-center px-4 py-8 bg-white shadow rounded-lg overflow-hidden sm:p-8 mb-4">
                    <img
                        className="mx-auto h-12 w-auto mb-4"
                        src={`${process.env.PUBLIC_URL}/images/Fitmania F Logo.png`}
                        alt="Fitmania Logo"
                    />
                    <p className="text-sm sm:text-base mb-4">Sign in to view more.</p>
                    <Link to="/signin" className="bg-fitmania-green px-4 py-2 rounded-lg hover:bg-fitmania-green-dark text-sm sm:text-base mx-2">
                        Sign In
                    </Link>
                    <Link to="/signup" className="bg-fitmania-green px-4 py-2 rounded-lg hover:bg-fitmania-green-dark text-sm sm:text-base mx-2">
                        Sign Up
                    </Link>
                </div>
                <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 mb-4">
                    <div className="flex justify-between items-center mb-4">
                        {userProfile && (
                            <Link to={`/profile/${username}`} className="flex items-center gap-2">
                                <ProfilePictureComponent profilePicture={userProfile.profilePicture} className="w-10 h-10 rounded-full" />
                                <p className="ml-2">{userProfile.firstName}</p>
                            </Link>
                        )}
                        <div className="flex gap-2">
                            <button className="bg-fitmania-green px-4 py-2 rounded-lg hover:bg-fitmania-green-dark text-sm sm:text-base">Follow</button>
                            <ShareGoalComponent
                                baseUrl={`${window.location.origin}/goal`}
                                userName={username}
                                goalId={goalId}
                                goalName={goalData.goalName}
                            />
                        </div>
                    </div>
                    <div className="flex justify-between items-center">
                        <h1 className="text-xl font-semibold mb-2">{goalData.goalName}</h1>
                    </div>
                    <p className="text-base mb-2">By: {goalData.completionDateFormatted}</p>
                    <p className="text-base mb-2">Starting: {goalData.currentStatus} | Goal: {goalData.targetMilestone}</p>
                    {goalData.percentageCompleted && (
                        <p className="mb-8 text-base">% to goal: {goalData.percentageCompleted}%</p>
                    )}
                    <GoalChartComponent goal={goalData} userId={userId} />
                </div>

                <div className="px-4 py-5 sm:p-6 bg-white shadow rounded-lg overflow-hidden mb-4">
                    <h1 className="text-lg font-semibold mb-4">Check In History</h1>
                    <div className="mt-4">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead>
                                <tr>
                                    <th className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Date
                                    </th>
                                    <th className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Status
                                    </th>
                                    <th className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Note
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {checkIns.map((checkIn) => (
                                    <tr key={checkIn.id}>
                                        <td className="py-4 whitespace-nowrap text-sm text-gray-500">
                                            {checkIn.date}
                                        </td>
                                        <td className="py-4 whitespace-nowrap text-sm text-gray-500">
                                            {checkIn.status}
                                        </td>
                                        <td className="py-4 whitespace-nowrap text-sm text-gray-500">
                                            {checkIn.note}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LitePublicGoalPage;
