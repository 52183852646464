// Tooltip.js
import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

const Tooltip = ({ content, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const tooltipRef = useRef(null);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [tooltipRef]);

  return (
    <div className="relative flex items-center" ref={tooltipRef}>
      <div onClick={toggleVisibility}>
        {children}
      </div>
      {isVisible && (
        <div className="absolute z-10 w-48 p-2 text-sm text-gray-700 border rounded bg-gray-100 left-full ml-2">
          {content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
