import React, { useState, useEffect } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { app2 } from '../firebase-config';

const AdminRoute = ({ element: Component, ...rest }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      const auth = getAuth(app2);
      const db = getFirestore(app2);

      if (auth.currentUser) {
        setIsAuthenticated(true);
        const docRef = doc(db, 'users', auth.currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const { userType } = docSnap.data();
          setIsAdmin(userType === 'Admin');
        }
      }

      setIsLoading(false);
    };

    checkAdminStatus();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; 
  }

  if (!isAuthenticated) {
    return <Navigate to="/signin" />;
  }

  return isAdmin ? <Component {...rest} /> : <Navigate to="/dashboard" />;
};

export default AdminRoute;
