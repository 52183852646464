import React, { useEffect, useState } from 'react';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const VisibilityDropdown = ({ db, userId, collectionName, onVisibilityChange }) => {
    const [selectedVisibility, setSelectedVisibility] = useState('All');
    const [countAll, setCountAll] = useState(0);
    const [countPrivate, setCountPrivate] = useState(0);
    const [countPublic, setCountPublic] = useState(0);

    useEffect(() => {
        const fetchCounts = async () => {
            const baseQuery = collection(db, `users/${userId}/${collectionName}`);
            const allQuery = query(baseQuery);
            const privateQuery = query(baseQuery, where('visibility', '==', 'Private'));
            const publicQuery = query(baseQuery, where('visibility', '==', 'Public'));

            const [allSnapshot, privateSnapshot, publicSnapshot] = await Promise.all([
                getDocs(allQuery),
                getDocs(privateQuery),
                getDocs(publicQuery)
            ]);

            setCountAll(allSnapshot.size);
            setCountPrivate(privateSnapshot.size);
            setCountPublic(publicSnapshot.size);
        };

        fetchCounts();
    }, [db, userId, collectionName, selectedVisibility]);

    const handleChangeVisibility = (visibility) => {
        setSelectedVisibility(visibility);
        onVisibilityChange(visibility);
    };

    return (
        <>
            {countAll > 0 && (
                <Menu as="div" className="relative inline-block text-left">
                    <div>
                        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-full bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-300">
                            <span>Visibility:</span>
                            {`${selectedVisibility} (${selectedVisibility === 'All' ? countAll : selectedVisibility === 'Private' ? countPrivate : countPublic})`}
                            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                        </Menu.Button>
                    </div>
                    <Transition
                        as={React.Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            disabled={countAll === 0}
                                            onClick={() => handleChangeVisibility('All')}
                                            className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block w-full px-4 py-2 text-left text-sm',
                                                countAll === 0 ? 'opacity-50' : ''
                                            )}
                                        >
                                            All ({countAll})
                                        </button>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            disabled={countPrivate === 0}
                                            onClick={() => handleChangeVisibility('Private')}
                                            className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block w-full px-4 py-2 text-left text-sm',
                                                countPrivate === 0 ? 'opacity-50' : ''
                                            )}
                                        >
                                            Private ({countPrivate})
                                        </button>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            disabled={countPublic === 0}
                                            onClick={() => handleChangeVisibility('Public')}
                                            className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block w-full px-4 py-2 text-left text-sm',
                                                countPublic === 0 ? 'opacity-50' : ''
                                            )}
                                        >
                                            Public ({countPublic})
                                        </button>
                                    )}
                                </Menu.Item>
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            )}
        </>
    );
};

export default VisibilityDropdown;
