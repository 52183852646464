import React from 'react';

function RestModal({ isOpen, close, children, countdown }) {
    if (!isOpen) return null;

    const handleOverlayClick = (event) => {
        if (event.target === event.currentTarget) {
            close();
        }
    };

    const buttonClass = countdown === 0 ? "vibrate mt-8 text-xl font-normal text-white bg-black rounded-full py-2 px-4" : "mt-8 text-xl font-normal text-white bg-black rounded-full py-2 px-4";

    return (
        <div
            className="fixed inset-0 bg-green-500 flex items-center justify-center z-50 overflow-hidden"
            onClick={handleOverlayClick}
        >
            <div
                className="w-full h-full bg-green-500 flex flex-col"
                onClick={e => e.stopPropagation()} // Prevents click inside the modal from closing it
            >
                <div className="flex-grow flex-col flex items-center justify-center">
                    {children}
                    <button
                        onClick={close}
                        className={buttonClass}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
}

export default RestModal;
