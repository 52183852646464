import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../firebase-config';

export default function SignInPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      if (user.emailVerified) {
        navigate('/dashboard');
      } else {
        setError("Please verify your email before signing in.");
      }
    } catch (error) {
      console.error(error.message);
      setError("Sorry, your email or password was incorrect. Please double-check your email or password.");
    }
  };

  return (
    <div className="flex min-h-screen bg-white items-center justify-center">
      <form onSubmit={handleSignIn} className="max-w-xs w-full space-y-8">
        <div className="max-w-sm w-full flex flex-col gap-4">

          <Link to="/">
            <img
              className="mx-auto h-12 w-auto"
              src={`${process.env.PUBLIC_URL}/images/Fitmania F Logo.png`}
              alt="Fitmania Logo"
            />
          </Link>

          <h2 className="mt-4 text-3xl font-bold text-gray-700 text-center">
            Welcome back
          </h2>

          <input
            type="email"
            name="email"
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            className="mt-4 pl-4 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-400 text-base sm:leading-6 outline-none"
            placeholder="Email address"
          />

          <input
            type="password"
            name="password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            className="pl-4 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-400 text-base sm:leading-6 outline-none"
            placeholder="Password"
          />

          <a href="/reset-password" className="text-sm font-semibold text-green-500 hover:text-green-600">
            Forgot password?
          </a>



          <button
            type="submit"
            className="w-full inline-flex justify-center rounded-md border border-transparent bg-fitmania-green px-4 py-3 text-sm font-medium text-black hover:bg-fitmania-green-dark focus:outline-none focus-visible:ring-2 focus-visible:ring-fitmania-green-dark focus-visible:ring-offset-2"
          >
            Sign in
          </button>

          {error && (
            <p className="text-sm text-red-500 text-center">
              {error}
            </p>
          )}

          <p className="mt-4 text-center text-sm text-gray-500">
            Don't have an account?{' '}
            <Link to="/signup" className="font-semibold leading-6 text-green-500 hover:text-green-600">
              Sign up
            </Link>
          </p>

        </div>
      </form>
    </div>
  );
}