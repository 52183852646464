import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faTiktok, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const PublicLayout = ({ children }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <div className="flex flex-col min-h-screen bg-white">
            <header className="text-black">
                <div className="max-w-8xl px-8 md:px-16 py-2 mx-auto flex justify-between items-center">
                    <Link to="/" className="py-4">
                        <img src={`${process.env.PUBLIC_URL}/images/Fitmania F Logo.png`} alt="Fitmania Logo" className="h-10" />
                    </Link>
                    <div className={`hidden md:flex gap-8`}>
                        <Link to="/blog" className="border-b-2 border-transparent hover:border-black py-4 text-lg font-semibold">
                            Blog
                        </Link>
                       {/*  <Link to="/contact" className="border-b-2 border-transparent hover:border-black py-4 text-lg font-semibold">
                            About
                        </Link> */}
                        <Link to="/contact" className="border-b-2 border-transparent hover:border-black py-4 text-lg font-semibold">
                            Contact
                        </Link>
                    </div>
                    <nav className="hidden md:block">
                        <Link to="/signin" className="text-black font-semibold px-4 hover:text-gray-600 ">Sign In</Link>
                    </nav>
                    <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="md:hidden">
                        <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} className="text-2xl"/>
                    </button>
                </div>
                {isMenuOpen && (
                    <div className="fixed inset-0 bg-white z-50 flex flex-col items-start py-4 px-4">
                        <button onClick={() => setIsMenuOpen(false)} className="ml-auto mr-4 mt-4">
                            <FontAwesomeIcon icon={faTimes} className="text-2xl"/>
                        </button>
                        <div className="px-8 py-4 flex flex-col gap-4">
                            <Link to="/blog" className="text-2xl font-semibold" onClick={() => setIsMenuOpen(false)}>Blog</Link>
                            <Link to="/signin" className="text-2xl font-semibold" onClick={() => setIsMenuOpen(false)}>Sign In</Link>
                        </div>
                    </div>
                )}
            </header>

            <main className="flex-grow">
                {children}
            </main>

            <footer className=" text-black bg-gray-100">
                <div className="mx-auto flex flex-col gap-8 md:flex-row items-center md:items-start justify-between max-w-8xl px-16 py-24 md:py-32">
                    <div className="flex flex-col gap-4 md:gap-16 items-center md:items-start">
                        <Link to="/">
                            <img src={`${process.env.PUBLIC_URL}/images/Fitmania_Dark_Transparent-2.png`} alt="Fitmania Logo" className="h-20" />
                        </Link>
                        <p className="text-sm">© Fitmania 2024. All rights reserved.</p>
                    </div>
                    <div className="flex flex-col justify-between gap-4 sm:gap-4 items-center md:items-start">
                        <Link to="/blog">
                            <p className="text-xl font-semibold hover:text-gray-600">Blog</p>
                        </Link>
                       {/*  <Link to="/about">
                            <p className="text-xl font-semibold hover:text-gray-600">About</p>
                        </Link> */}
                        <Link to="/contact">
                            <p className="text-xl font-semibold hover:text-gray-600">Contact</p>
                        </Link>
                        <Link to="/privacy-policy">
                            <p className="text-xl font-semibold hover:text-gray-600">Privacy Policy & Terms</p>
                        </Link>
                    </div>
                    <div className="flex flex-row gap-4 items-center md:items-start">
                        <a href="https://www.instagram.com/fitmaniahq" target="_blank" rel="noopener noreferrer" className="text-2xl font-semibold">
                            <FontAwesomeIcon icon={faInstagram} className="p-2 rounded-lg bg-gray-200 hover:bg-white" />
                        </a>
                        <a href="https://www.linkedin.com/company/fitmaniahq" target="_blank" rel="noopener noreferrer" className="text-2xl font-semibold">
                            <FontAwesomeIcon icon={faLinkedin} className="p-2 rounded-lg bg-gray-200 hover:bg-white" />
                        </a>
                        <a href="https://www.tiktok.com/@fitmaniahq" target="_blank" rel="noopener noreferrer" className="text-2xl font-semibold">
                            <FontAwesomeIcon icon={faTiktok} className="p-2 rounded-lg bg-gray-200 hover:bg-white" />
                        </a>
                        <a href="https://www.twitter.com/fitmaniahq" target="_blank" rel="noopener noreferrer" className="text-2xl font-semibold">
                            <FontAwesomeIcon icon={faTwitter} className="p-2 rounded-lg bg-gray-200 hover:bg-white" /> {/* Temporarily representing "X" as Twitter */}
                        </a>
                    </div>

                </div>
            </footer>
        </div>
    );
};

export default PublicLayout;
