//workout context before file upload refactor
import React, { createContext, useState, useContext } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, uploadBytes } from "firebase/storage";
import { collection, addDoc } from 'firebase/firestore';
import { app2 } from '../firebase-config';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Modal from './Modal';

const WorkoutContext = createContext();

export const useWorkout = () => useContext(WorkoutContext);

export const WorkoutProvider = ({ children }) => {
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [selectedExercises, setSelectedExercises] = useState([]);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [workoutName, setWorkoutName] = useState('');
    const [workoutDescription, setWorkoutDescription] = useState('');
    const [visibility, setVisibility] = useState('Private');

    const db = getFirestore(app2);
    const auth = getAuth(app2);
    const currentUser = auth.currentUser;
    const [inputValues, setInputValues] = useState({});
    const navigate = useNavigate();
    const [alertMessage, setAlertMessage] = useState('');

    // Function to reset alert state
    const resetAlertMessage = () => setAlertMessage('');

    const [currentProgramId, setCurrentProgramId] = useState(null);

    const [selectedMediaFiles, setSelectedMediaFiles] = useState([]);


    const [workoutImagePreviewUrl, setWorkoutImagePreviewUrl] = useState('');

    const [selectedCoverImageFile, setSelectedCoverImageFile] = useState(null);
    const [coverImagePreviewUrl, setCoverImagePreviewUrl] = useState('');
    const defaultCoverImage = process.env.PUBLIC_URL + '/images/default_workout_cover_image.jpg';

    const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);


    const setAlert = (message) => {
        setAlertMessage(message);
        setIsAlertModalOpen(true);
    };
    
    // Function to reset alert state and close modal
    const resetAlert = () => {
        setAlertMessage('');
        setIsAlertModalOpen(false);
    };

    const handleWorkoutImageUpload = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setWorkoutImagePreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const updateCurrentProgramId = (id) => {
        setCurrentProgramId(id);
    };

    const resetWorkoutState = () => {
        setCoverImagePreviewUrl('');
        setSelectedCoverImageFile(null);
        setSelectedExercises([]);
        setWorkoutImagePreviewUrl('');
        setWorkoutName('');
        setWorkoutDescription('');
        setInputValues({});
        setVisibility('Private');
    };

    const handleExercisesChange = (newExercises) => {
        setSelectedExercises(newExercises);
    };

    // In your context or component where handleFileSelection is defined
    const handleFileSelection = (file, isCoverImage = false, exerciseId = null) => {
        if (!file) {
            console.log("No file selected or file selection was cancelled.");
            return; // Early return if no file is selected
        }
        
        const objectUrl = URL.createObjectURL(file);
        if (isCoverImage) {
            setSelectedCoverImageFile(file); // For cover images, you're already correctly handling the file object
            setCoverImagePreviewUrl(objectUrl);
        } else if (exerciseId) {
            // Update selected exercises to include the new media file with its object URL and the original file object
            setSelectedExercises(currentExercises =>
                currentExercises.map(exercise => {
                    if (exercise.id === exerciseId) {
                        return {
                            ...exercise,
                            mediaItems: [...exercise.mediaItems, { url: objectUrl, type: file.type.startsWith('video/') ? 'video' : 'image', file: file }],
                        };
                    }
                    return exercise;
                })
            );
        }
    };

    const handleSaveWorkout = async () => {
        if (!currentUser) {
            console.error('No user signed in');
            return;
        }

        const uploadMedia = async (mediaFile, path) => {
            const storage = getStorage(app2);
            const storageRef = ref(storage, path);
            const metadata = { contentType: mediaFile.type };

            // Start upload
            const uploadTask = uploadBytesResumable(storageRef, mediaFile, metadata);

            // Monitor the upload progress
            uploadTask.on('state_changed', (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                setUploadProgress(progress);
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            });

            // Wait for upload to complete
            await uploadTask;

            // After upload is complete, get and return the download URL
            return getDownloadURL(uploadTask.snapshot.ref);
        };


        // Separate validation checks for "Name" and "Description"
        if (!workoutName.trim()) {
            setAlert("A workout 'Name' is required");
            return;
        }

        if (!workoutDescription.trim()) {
            setAlert("A workout 'Description' is required");
            return;
        }

        // Check if there's at least one exercise
        if (selectedExercises.length === 0) {
            setAlert("Please add at least one exercise to your workout.");
            return;
        }

        let validationMessages = [];

        // Check for each exercise's sets for valid attributes based on what's selected
        selectedExercises.forEach((exercise, exerciseIndex) => {
            exercise.sets.forEach((set, setIndex) => {
                // Ensure at least one attribute is selected
                const isSelectedAttributePresent = Object.values(set.selectedIcons).some(value => value);
                if (!isSelectedAttributePresent) {
                    validationMessages.push(`Exercise ${exerciseIndex + 1}, Set ${setIndex + 1}: At least one exercise attribute must be selected. (Repetitions, etc.)`);
                    return; // Skip further validation since no attribute is selected
                }

                let setMessages = [];

                // Validate repetitions
                if (set.selectedIcons.faHashtag) {
                    const hasRepetitions = set.tillFailure || inputValues[`${exercise.id}_${set.id}_repetitions`]?.trim();
                    if (!hasRepetitions) {
                        setMessages.push("Repetitions: either select 'Till Failure' or enter a value.");
                    }
                }

                // Validate resistance
                if (set.selectedIcons.faWeightHanging) {
                    const hasResistanceUnits = inputValues[`${exercise.id}_${set.id}_resistance_units`]?.trim();
                    const hasResistanceType = inputValues[`${exercise.id}_${set.id}_resistance_type`]?.trim();
                    if (!hasResistanceUnits || !hasResistanceType) {
                        setMessages.push("Resistance: select units and a type.");
                    }
                }

                // Validate timer
                if (set.selectedIcons.faTimer) {
                    const hasTimer = set.timerTillFailure || inputValues[`${exercise.id}_${set.id}_timer`]?.trim();
                    if (!hasTimer) {
                        setMessages.push("Timer: either select 'Till Failure' or enter a value.");
                    }
                }

                // Validate distance
                if (set.selectedIcons.faRuler) {
                    const hasDistanceUnits = inputValues[`${exercise.id}_${set.id}_distance_units`]?.trim();
                    const hasDistanceValue = inputValues[`${exercise.id}_${set.id}_distance`]?.trim();
                    if (!hasDistanceUnits || !hasDistanceValue) {
                        setMessages.push("Distance: select units and enter a value.");
                    }
                }

                if (setMessages.length > 0) {
                    validationMessages.push(`Exercise ${exerciseIndex + 1}, Set ${setIndex + 1}: ${setMessages.join(" ")}`);
                }
            });
        });

        if (validationMessages.length > 0) {
            // Start with the introductory line in an array
            const detailedAlertMessage = ["Please fix the following:"];
        
            // Append each validation message on a new line preceded by a dash
            validationMessages.forEach(message => {
                detailedAlertMessage.push(`- ${message}`);
            });
        
            // Pass the array to setAlertMessage
            setAlert(detailedAlertMessage);
            return;
        }
        
        

        setIsUploading(true);
        setUploadProgress(0);

        // Initialize coverImageUrl to the default cover image path
        let coverImageUrl = coverImagePreviewUrl || defaultCoverImage;

        // Check if there is a selected cover image file
        // Directly use selectedCoverImageFile to determine the cover image logic
        if (selectedCoverImageFile) {
            coverImageUrl = await uploadMedia(selectedCoverImageFile, `workouts/${currentUser.uid}/coverImages/${Date.now()}_${selectedCoverImageFile.name}`);
        } else {
            // If no file is selected, stick with the default cover image
            coverImageUrl = defaultCoverImage;
        }



        console.log("Selected exercises before processing:", JSON.stringify(selectedExercises, null, 2));

        // Simulated processing of selected exercises to demonstrate uploading media
        const exercisesWithUploadedMedia = await Promise.all(selectedExercises.map(async (exercise) => {
            const mediaItemsWithUrls = await Promise.all(exercise.mediaItems.map(async (mediaItem) => {
                // Check if mediaItem includes a file object for upload
                if (mediaItem.file) {
                    // Upload the file to Firebase Storage and use the resulting URL
                    const firebaseUrl = await uploadMedia(mediaItem.file, `workouts/${currentUser.uid}/media/${Date.now()}_${mediaItem.file.name}`);
                    return { ...mediaItem, url: firebaseUrl }; // Use the uploaded file URL
                } else {
                    // If no file is present, this likely means the URL is already pointing to Firebase Storage or an external URL
                    return mediaItem;
                }
            }));
            return { ...exercise, mediaItems: mediaItemsWithUrls };
        }));

        console.log("Final data being sent to Firebase:", {
            coverImage: coverImageUrl,
            name: workoutName,
            description: workoutDescription,
            exercises: exercisesWithUploadedMedia.map(exercise => ({
                name: exercise.exerciseName,
                note: exercise.exerciseNote,
                mediaItems: exercise.mediaItems.map(item => ({
                    url: item.url,
                    type: item.type
                })),
                sets: exercise.sets.map(set => ({
                    repetitions: inputValues[`${exercise.id}_${set.id}_repetitions`] || '',
                    resistance: {
                        value: inputValues[`${exercise.id}_${set.id}_resistance_value`] || '',
                        units: inputValues[`${exercise.id}_${set.id}_resistance_units`] || '',
                        type: inputValues[`${exercise.id}_${set.id}_resistance_type`] || '',
                    },
                    timer: inputValues[`${exercise.id}_${set.id}_timer`] || '',
                    distance: {
                        value: inputValues[`${exercise.id}_${set.id}_distance`] || '',
                        units: inputValues[`${exercise.id}_${set.id}_distance_units`] || '',
                    },
                    rest: inputValues[`${exercise.id}_${set.id}_rest`] || '',
                    note: inputValues[`${exercise.id}_${set.id}_note`] || '',
                })),
            })),
        });

        try {

            const workoutsRef = collection(db, `users/${currentUser.uid}/workouts`);
            const docRef = await addDoc(workoutsRef, {
                coverImage: coverImageUrl,
                name: workoutName,
                description: workoutDescription,
                createdBy: currentUser.uid,
                exercises: exercisesWithUploadedMedia.map(exercise => ({
                    name: exercise.exerciseName,
                    note: exercise.exerciseNote,
                    mediaItems: exercise.mediaItems.map(item => ({
                        url: item.url,
                        type: item.type
                    })),
                    sets: exercise.sets.map(set => ({
                        repetitions: inputValues[`${exercise.id}_${set.id}_repetitions`] || '',
                        resistance: {
                            value: inputValues[`${exercise.id}_${set.id}_resistance_value`] || '',
                            units: inputValues[`${exercise.id}_${set.id}_resistance_units`] || '',
                            type: inputValues[`${exercise.id}_${set.id}_resistance_type`] || '',
                        },
                        timer: inputValues[`${exercise.id}_${set.id}_timer`] || '',
                        distance: {
                            value: inputValues[`${exercise.id}_${set.id}_distance`] || '',
                            units: inputValues[`${exercise.id}_${set.id}_distance_units`] || '',
                        },
                        rest: inputValues[`${exercise.id}_${set.id}_rest`] || '',
                        note: inputValues[`${exercise.id}_${set.id}_note`] || '',
                    })),
                })),
                visibility: visibility, // Save visibility state in workout data
                timestamp: new Date(),
            });

            console.log('Workout saved successfully with ID:', docRef.id);
            navigate(`/workout/${docRef.id}`);
        } catch (error) {
            console.error('Error saving workout or uploading media:', error);
        }
        setIsUploading(false);
    };

    const handleSaveProgramWorkout = async (selectedDay, programDetails, onCloseModal) => {
        console.log("Saving workout within a program...");

        const { coverImageFile } = programDetails;

        setIsUploading(true);
        setUploadProgress(0);

        if (!currentUser) {
            console.error('No user signed in');
            setIsUploading(false);
            return;
        }

        try {
            let coverImageUrl = '';

            const uploadMedia = async (mediaFile, path) => {
                const storageRef = ref(getStorage(app2), path);
                const uploadTaskSnapshot = await uploadBytes(storageRef, mediaFile);
                return getDownloadURL(uploadTaskSnapshot.ref);
            };

            if (workoutImagePreviewUrl && workoutImagePreviewUrl.startsWith('data:')) {

                const blob = await (await fetch(workoutImagePreviewUrl)).blob();
                coverImageUrl = await uploadMedia(blob, `programs/${currentUser.uid}/workouts/${Date.now()}`);
            } else if (coverImageFile) {

                const blob = await coverImageFile.arrayBuffer();
                coverImageUrl = await uploadMedia(new Blob([blob]), `programs/${currentUser.uid}/coverImages/${Date.now()}_${coverImageFile.name}`);
            } else {
                coverImageUrl = imagePreviewUrl;
            }


            const exercisesWithFirebaseUrls = await Promise.all(selectedExercises.map(async (exercise) => {
                const mediaItemsWithFirebaseUrls = await Promise.all(exercise.mediaItems.map(async (mediaItem) => {
                    if (mediaItem.url.startsWith('data:')) {
                        const blob = await (await fetch(mediaItem.url)).blob();
                        return { ...mediaItem, url: await uploadMedia(blob, `programs/${currentUser.uid}/media/${Date.now()}_${mediaItem.type}`) };
                    }
                    return mediaItem;
                }));

                return { ...exercise, mediaItems: mediaItemsWithFirebaseUrls };
            }));

            const workoutData = {
                coverImage: coverImageUrl,
                name: workoutName,
                description: workoutDescription,
                exercises: exercisesWithFirebaseUrls.map(exercise => ({
                    name: exercise.exerciseName,
                    note: exercise.exerciseNote,
                    mediaItems: exercise.mediaItems.map(item => ({
                        url: item.url,
                        type: item.type
                    })),
                    sets: exercise.sets.map(set => ({
                        repetitions: inputValues[`${exercise.id}_${set.id}_repetitions`] || '',
                        resistance: {
                            value: inputValues[`${exercise.id}_${set.id}_resistance_value`] || '',
                            units: inputValues[`${exercise.id}_${set.id}_resistance_units`] || '',
                            type: inputValues[`${exercise.id}_${set.id}_resistance_type`] || '',
                        },
                        timer: inputValues[`${exercise.id}_${set.id}_timer`] || '',
                        distance: {
                            value: inputValues[`${exercise.id}_${set.id}_distance_value`] || '',
                            units: inputValues[`${exercise.id}_${set.id}_distance_units`] || '',
                        },
                        rest: inputValues[`${exercise.id}_${set.id}_rest`] || '',
                        note: inputValues[`${exercise.id}_${set.id}_note`] || '',
                    })),
                })),
                timestamp: new Date(),
            };

            const docRef = await addDoc(collection(db, `users/${currentUser.uid}/programs/${currentProgramId}/days/${selectedDay}/workouts`), workoutData);
            console.log(`Workout saved successfully with ID: ${docRef.id} under program ID: ${currentProgramId}`);

            if (onCloseModal) {
                onCloseModal();
            }

        } catch (error) {
            console.error('Error saving workout:', error);
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <WorkoutContext.Provider
            value={{
                isUploading,
                uploadProgress,
                handleSaveWorkout,
                handleSaveProgramWorkout,
                selectedExercises,
                setSelectedExercises,
                handleExercisesChange,
                inputValues,
                setInputValues,
                workoutImagePreviewUrl,
                imagePreviewUrl,
                setImagePreviewUrl,
                workoutName,
                workoutDescription,
                setWorkoutDescription,
                setWorkoutName,
                resetWorkoutState,
                currentProgramId,
                updateCurrentProgramId,
                handleWorkoutImageUpload,
                alertMessage,
                resetAlertMessage,
                handleFileSelection,
                coverImagePreviewUrl,
                setCoverImagePreviewUrl,
                visibility,
                setVisibility,
            }}
        >
            {children}
            <Modal isOpen={isAlertModalOpen} close={resetAlert}>
            <div className="p-4">
                <h2 className="text-lg font-bold mb-4">Missing Inputs</h2>
                <p className="text-red-500">{alertMessage}</p>
            </div>
        </Modal>
        </WorkoutContext.Provider>
    );
};
