import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase-config';
import { WorkoutProvider } from './components/WorkoutContext';

import HomePage from './components/HomePage';
import SignInPage from './components/SignInPage';
import SignUpPage from './components/SignUpPage';
import AccountVerificationPage from './components/AccountVerificationPage';
import ResetPasswordPage from './components/ResetPasswordPage';
import UsersPage from './components/UsersPage';
import ActivityPage from './components/ActivityPage';
import WorkoutsPage from './components/WorkoutsPage';
import ActiveWorkoutPage from './components/ActiveWorkoutPage';
import CreateNewWorkoutPage from './components/CreateNewWorkoutPage';
import WorkoutPage from './components/WorkoutPage';
import CalendarPage from './components/CalendarPage';
import GoalsPage from './components/GoalsPage';
import CreateNewGoalPage from './components/CreateNewGoalPage';
import GoalPage from './components/GoalPage';
import CreateAProgramPage from './components/CreateAProgramPage';
import SettingsPage from './components/SettingsPage';
import CompletedWorkoutPage from './components/CompletedWorkoutPage';
import StatisticsPage from './components/StatisticsPage';
import ExerciseStatsComponent from './components/ExerciseStatsComponent';
import PublicHomePage from './public_webpages/PublicHomePage';
import BlogPage from './public_webpages/BlogPage';
import BlogPost from './public_webpages/BlogPost';
import ContactPage from './public_webpages/ContactPage';
import PrivacyPolicyPage from './public_webpages/PrivacyPolicyPage';
import ProfilePage from './components/ProfilePage';
import EditProfilePage from './components/EditProfilePage';
import EditProfilePicturePage from './components/EditProfilePicturePage';
import EditProfileNamePage from './components/EditProfileNamePage';
import EditProfileUsernamePage from './components/EditProfileUsernamePage';
import EditProfileBioPage from './components/EditProfileBioPage';
import EditProfileInterestsPage from './components/EditProfileInterestsPage';
import EditProfileLocationPage from './components/EditProfileLocationPage';
import PublicProfilePage from './components/PublicProfilePage';
import PublicCompletedWorkoutPage from './components/PublicCompletedWorkoutPage';
import PublicWorkoutPage from './components/PublicWorkoutPage';
import LitePublicProfilePage from './components/LitePublicProfilePage';
import LitePublicCompletedWorkoutPage from './components/LitePublicCompletedWorkoutPage'; // Import the new component
import LitePublicWorkoutPage from './components/LitePublicWorkoutPage'; // Import the new component
import PublicGoalPage from './components/PublicGoalPage';
import LitePublicGoalPage from './components/LitePublicGoalPage';
import ReferralsPage from './components/ReferralsPage';
import NotificationsPage from './components/NotificationsPage';
import CopiedWorkoutPage from './components/CopiedWorkoutPage';
import CompletedCopiedWorkoutPage from './components/CompletedCopiedWorkoutPage';
import PublicCompletedCopiedWorkoutPage from './components/PublicCompletedCopiedWorkoutPage';
import LitePublicCompletedCopiedWorkoutPage from './components/LitePublicCompletedCopiedWorkoutPage';

import AdminRoute from './components/AdminRoute'; // Import AdminRoute

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <WorkoutProvider>
        <div className="App">
          <Routes>
            {/* Public routes that don't require authentication */}
            <Route path="/" element={<PublicHomePage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog/:slug" element={<BlogPost />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

            <Route path="/signin" element={<SignInPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/account-verification" element={<AccountVerificationPage />} />
            <Route path="/profile/:username" element={isAuthenticated ? <PublicProfilePage /> : <LitePublicProfilePage />} />
            <Route path="/workout/:username/:workoutId" element={isAuthenticated ? <PublicWorkoutPage /> : <LitePublicWorkoutPage />} />
            <Route path="/activity/completed/:username/:activityId" element={isAuthenticated ? <PublicCompletedWorkoutPage /> : <LitePublicCompletedWorkoutPage />} />
            <Route path="/goal/:username/:goalId" element={isAuthenticated ? <PublicGoalPage /> : <LitePublicGoalPage />} />
            <Route path="/activity/completed/copied/:username/:activityId" element={isAuthenticated ? <PublicCompletedCopiedWorkoutPage /> : <LitePublicCompletedCopiedWorkoutPage />} />

            {/* Protected routes that require authentication */}
            <Route path="/dashboard" element={isAuthenticated ? <HomePage /> : <Navigate to="/signin" />} />
            <Route path="/calendar" element={isAuthenticated ? <CalendarPage /> : <Navigate to="/signin" />} />
            <Route path="/goals" element={isAuthenticated ? <GoalsPage /> : <Navigate to="/signin" />} />
            <Route path="/goals/create-new-goal" element={isAuthenticated ? <CreateNewGoalPage /> : <Navigate to="/signin" />} />
            <Route path="/goals/:goalNameUrl" element={isAuthenticated ? <GoalPage /> : <Navigate to="/signin" />} />
            <Route path="/activity" element={isAuthenticated ? <ActivityPage /> : <Navigate to="/signin" />} />
            <Route path="/activity/completed/:activityId" element={isAuthenticated ? <CompletedWorkoutPage /> : <Navigate to="/signin" />} />
            <Route path="/workouts" element={isAuthenticated ? <WorkoutsPage /> : <Navigate to="/signin" />} />
            <Route path="/workouts/create-a-workout" element={isAuthenticated ? <CreateNewWorkoutPage /> : <Navigate to="/signin" />} />
            <Route path="/workouts/create-new-workout/:workoutId" element={isAuthenticated ? <CreateNewWorkoutPage /> : <Navigate to="/signin" />} />
            <Route path="/workout/:workoutId" element={isAuthenticated ? <WorkoutPage /> : <Navigate to="/signin" />} />
            <Route path="/workout/:workoutId/active" element={isAuthenticated ? <ActiveWorkoutPage /> : <Navigate to="/signin" />} />
            <Route path="/create-a-program/:programId" element={isAuthenticated ? <CreateAProgramPage /> : <Navigate to="/signin" />} />
            <Route path="/statistics" element={isAuthenticated ? <StatisticsPage /> : <Navigate to="/signin" />} />

            <Route path="/profile" element={isAuthenticated ? <ProfilePage /> : <Navigate to="/signin" />} />
            <Route path="/edit-profile" element={isAuthenticated ? <EditProfilePage /> : <Navigate to="/signin" />} />
            <Route path="/edit-profile/profile-picture" element={isAuthenticated ? <EditProfilePicturePage /> : <Navigate to="/signin" />} />
            <Route path="/edit-profile/name" element={isAuthenticated ? <EditProfileNamePage /> : <Navigate to="/signin" />} />
            <Route path="/edit-profile/username" element={isAuthenticated ? <EditProfileUsernamePage /> : <Navigate to="/signin" />} />
            <Route path="/edit-profile/bio" element={isAuthenticated ? <EditProfileBioPage /> : <Navigate to="/signin" />} />
            <Route path="/edit-profile/interests" element={isAuthenticated ? <EditProfileInterestsPage /> : <Navigate to="/signin" />} />
            <Route path="/edit-profile/location" element={isAuthenticated ? <EditProfileLocationPage /> : <Navigate to="/signin" />} />
            <Route path="/notifications" element={isAuthenticated ? <NotificationsPage /> : <Navigate to="/signin" />} />

            <Route path="/workout/copied/:workoutId" element={isAuthenticated ? <CopiedWorkoutPage /> : <Navigate to="/signin" />} /> {/* Add the new route here */}
            <Route path="/activity/completed/copied/:activityId" element={isAuthenticated ? <CompletedCopiedWorkoutPage /> : <Navigate to="/signin" />} />

            <Route path="/test" element={isAuthenticated ? <ExerciseStatsComponent /> : <Navigate to="/signin" />} />
            <Route path="/settings" element={isAuthenticated ? <SettingsPage /> : <Navigate to="/signin" />} />

            {/* Protected routes that require authentication by admins only */}
            <Route path="/users" element={<AdminRoute element={UsersPage} />} />
            <Route path="/referrals" element={<AdminRoute element={ReferralsPage} />} />

          </Routes>
        </div>
      </WorkoutProvider>
    </Router>
  );
}

export default App;
