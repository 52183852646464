import React, { useState, useEffect, useRef, useMemo } from 'react'; // Import useRef
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import { app2 } from '../firebase-config';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { countries } from 'countries-list';
import Layout from './Layout';
import ProfilePictureComponent from './ProfilePictureComponent';

function EditProfileLocationPage() {
    const [userData, setUserData] = useState({
        selectedCity: '',
        selectedState: '',
        selectedCountry: '',
    });
    const navigate = useNavigate();
    const [inputData, setInputData] = useState({
        selectedCity: '',
        selectedState: '',
        selectedCountry: '',
    });
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [countriesList, setCountriesList] = useState([]);

    useEffect(() => {
        const auth = getAuth(app2);
        const db = getFirestore(app2);
        const user = auth.currentUser;

        if (user) {
            const userRef = doc(db, 'users', user.uid);

            getDoc(userRef).then((docSnap) => {
                if (docSnap.exists()) {
                    const { selectedCity, selectedState, selectedCountry } = docSnap.data();
                    setUserData(prev => ({
                        ...prev,
                        selectedCity: selectedCity || '',
                        selectedState: selectedState || '',
                        selectedCountry: selectedCountry || '',
                    }));
                }
            });
        }
    }, []);

    useEffect(() => {
        // Enable save button only if all city, state, and country are not empty
        setIsSaveEnabled(
            inputData.selectedCity.trim() !== '' &&
            inputData.selectedState.trim() !== '' &&
            inputData.selectedCountry.trim() !== ''
        );
    }, [inputData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputData(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleCountryChange = (e) => {
        setInputData(prev => ({
            ...prev,
            selectedCountry: e.target.value,
        }));
    };

    useEffect(() => {
        // Populate the countries list when the component mounts
        const countryOptions = Object.values(countries)
            .map(country => ({
                code: country.iso2,
                name: country.name
            }))
            .sort((a, b) => a.name.localeCompare(b.name));  // Sort by country name alphabetically

        setCountriesList(countryOptions);
    }, []);

    const handleCancel = () => {
        navigate('/edit-profile');
    };

    const handleSave = async () => {
        const auth = getAuth(app2);
        const db = getFirestore(app2);
        const user = auth.currentUser;

        if (user) {
            const userRef = doc(db, 'users', user.uid);
            const publicProfileRef = doc(db, 'publicProfiles', user.uid);

            await setDoc(userRef, {
                selectedCity: inputData.selectedCity,
                selectedState: inputData.selectedState,
                selectedCountry: inputData.selectedCountry,
            }, { merge: true });

            await setDoc(publicProfileRef, {
                selectedCity: inputData.selectedCity,
                selectedState: inputData.selectedState,
                selectedCountry: inputData.selectedCountry,
            }, { merge: true });

            // Update the local state to reflect the changes
            setUserData({
                selectedCity: inputData.selectedCity,
                selectedState: inputData.selectedState,
                selectedCountry: inputData.selectedCountry,
            });

            // Navigate back to the profile edit page
            navigate('/edit-profile');
        }
    };


    return (
        <Layout>
            <div className="max-w-lg mx-auto">
                <div className="flex justify-between items-center mx-4 sm:mx-0">
                    <div className="text-lg sm:text-xl font-semibold text-gray-900 my-4 mb-8 sm:p-0 pt-4 pr-4">Edit location</div>
                    <button onClick={handleCancel} className="bg-gray-200 p-2 rounded-lg">Cancel</button>
                </div>
                <div className="flex flex-col gap-2 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 mb-4 items-center">
                    <div className="flex flex-row gap-8 items-start">
                        <div className="flex flex-col items-center gap-4">
                            <input
                                type="text"
                                name="selectedCity"
                                id="selected-city"
                                className="mt-4 pl-4 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-400 text-base sm:leading-6 outline-none"
                                placeholder={userData.selectedCity || "City"}
                                value={inputData.selectedCity}
                                onChange={handleInputChange}
                            />

                            <input
                                type="text"
                                name="selectedState"
                                id="selected-state"
                                className="pl-4 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-400 text-base sm:leading-6 outline-none"
                                placeholder={userData.selectedState || "State/Region"}
                                value={inputData.selectedState}
                                onChange={handleInputChange}
                            />

                            <select
                                className="pl-4 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-400 text-base sm:leading-6 outline-none"
                                value={inputData.selectedCountry}
                                onChange={handleCountryChange} // Update selected country on change
                            >
                                <option value="" disabled>Select your country</option>
                                {countriesList.map(country => (
                                    <option key={country.code} value={country.code}>
                                        {country.name}
                                    </option>
                                ))}
                            </select>

                            <button
                                onClick={handleSave}
                                disabled={!isSaveEnabled}
                                className={`mt-2 mb-4 py-2 px-4 rounded-lg focus:outline-none ${isSaveEnabled ? 'bg-fitmania-green text-black' : 'bg-gray-200 text-gray-400'}`}
                            >
                                Save location
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    );
}

export default EditProfileLocationPage;
