import React, { useEffect, useState } from 'react';
import Layout from './Layout';
import { useParams, useNavigate, Link } from 'react-router-dom';
import GoalGaugeComponent from './GoalGaugeComponent';
import ActivityCard from './ActivityCard';
import WorkoutCard from './WorkoutCard';
import { getFirestore, doc, getDoc, collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { app2 } from '../firebase-config';
import ProfilePictureComponent from './ProfilePictureComponent';
import ShareProfileComponent from './ShareProfileComponent';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

function PublicProfilePage() {
    const { username } = useParams();
    const [userData, setUserData] = useState(null);
    const [userId, setUserId] = useState(null);
    const [publicWorkouts, setPublicWorkouts] = useState([]);
    const [recentActivities, setRecentActivities] = useState([]);
    const [completedActivitiesCount, setCompletedActivitiesCount] = useState(0);
    const [streak, setStreak] = useState(0);
    const [goals, setGoals] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const db = getFirestore(app2);
    const navigate = useNavigate();

    useEffect(() => {
        const auth = getAuth(app2);
        const fetchData = async () => {
            try {
                // Fetch userId by username
                const usersCollection = collection(db, 'publicProfiles');
                const userQuery = query(usersCollection, where('userName', '==', username));
                const userSnapshot = await getDocs(userQuery);
                if (userSnapshot.empty) {
                    console.error('No such user!');
                    return;
                }
                const userDoc = userSnapshot.docs[0];
                const userDocData = userDoc.data();
                setUserData(userDocData);
                setUserId(userDoc.id);

                const userId = userDoc.id;

                const workoutsCollection = collection(db, `users/${userId}/workouts`);
                const workoutsQuery = query(workoutsCollection, where('visibility', '==', 'Public'));
                const workoutsSnapshot = await getDocs(workoutsQuery);

                const workouts = workoutsSnapshot.docs.map(docSnap => ({
                    id: docSnap.id,
                    ...docSnap.data(),
                }));

                setPublicWorkouts(workouts);

                const workoutsCompletedCollection = collection(db, `users/${userId}/workoutsCompleted`);
                const copiedWorkoutsCollection = collection(db, `users/${userId}/copiedWorkouts`);

                const totalActivitiesSnapshot = await getDocs(workoutsCompletedCollection);
                setCompletedActivitiesCount(totalActivitiesSnapshot.docs.length);

                const recentPublicActivitiesQuery = query(
                    workoutsCompletedCollection,
                    where('visibility', '==', 'Public'),
                    orderBy("completedAt", "desc"),
                    limit(5)
                );

                const recentCopiedActivitiesQuery = query(
                    copiedWorkoutsCollection,
                    where('visibility', '==', 'Public'),
                    orderBy("completedAt", "desc"),
                    limit(5)
                );

                const [recentPublicActivitiesSnapshot, recentCopiedActivitiesSnapshot] = await Promise.all([
                    getDocs(recentPublicActivitiesQuery),
                    getDocs(recentCopiedActivitiesQuery)
                ]);

                const activitiesPromises = [...recentPublicActivitiesSnapshot.docs, ...recentCopiedActivitiesSnapshot.docs].map(async (docSnap) => {
                    const completedData = docSnap.data();
                    const workoutRefPath = completedData.copied ? `users/${userId}/copiedWorkouts` : `users/${userId}/workouts`;
                    const workoutRef = doc(db, workoutRefPath, completedData.workoutId);
                    const workoutSnapshot = await getDoc(workoutRef);

                    if (workoutSnapshot.exists() && workoutSnapshot.data().visibility === 'Public') {
                        return {
                            id: docSnap.id,
                            workoutId: completedData.workoutId,
                            name: workoutSnapshot.data().name,
                            coverImage: workoutSnapshot.data().coverImage,
                            completedAt: completedData.completedAt.toDate().toLocaleString('en-US', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: '2-digit',
                                hour12: true
                            }),
                            copied: completedData.copied
                        };
                    } else {
                        return null;
                    }
                });

                const fetchedActivities = (await Promise.all(activitiesPromises)).filter(Boolean);
                setRecentActivities(fetchedActivities);

                const activityDates = fetchedActivities.map(activity => new Date(activity.completedAt.split(",")[0]));
                activityDates.sort((a, b) => a - b);

                let currentStreak = 0;
                let previousDate = null;

                activityDates.forEach(date => {
                    if (previousDate) {
                        const diffTime = Math.abs(date - previousDate);
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                        if (diffDays === 1) {
                            currentStreak += 1;
                        } else {
                            currentStreak = 0;
                        }
                    } else {
                        currentStreak = 1;
                    }
                    previousDate = date;
                });

                const today = new Date();
                const lastActivityDate = activityDates[activityDates.length - 1];
                const diffTimeLastActivity = Math.abs(today - lastActivityDate);
                const diffDaysLastActivity = Math.ceil(diffTimeLastActivity / (1000 * 60 * 60 * 24));

                if (diffDaysLastActivity > 1) {
                    currentStreak = 0;
                }

                setStreak(currentStreak);

                // Fetch goals
                const goalsRef = collection(db, `users/${userId}/goals`);
                const goalsQuery = query(goalsRef, where('visibility', '==', 'Public'));
                const goalsSnapshot = await getDocs(goalsQuery);

                const fetchedGoals = goalsSnapshot.docs.map(docSnap => docSnap.data());
                setGoals(fetchedGoals);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const checkAuthState = () => {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    setCurrentUser(user);
                }
            });
        };

        fetchData();
        checkAuthState();
    }, [db, userId]);

    if (!userData) {
        return <div>Loading...</div>;
    }

    return (
        <Layout>
            <div className="max-w-5xl mx-auto">
                <div className="px-4 py-8 bg-white shadow rounded-lg overflow-hidden sm:p-8 mb-4">
                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end gap-4 relative">
                        <div className="flex flex-col sm:flex-row gap-4 sm:gap-8 items-start sm:items-center">
                            <ProfilePictureComponent userId={userId} className="w-24 h-24" />
                            <div className="flex flex-col gap-1">
                                <h1 className="text-base sm:text-lg font-semibold flex items-center">{userData.firstName} {userData.lastName}</h1>
                                <span className="text-sm sm:text-base">@{userData.userName}</span>
                                <span className="text-sm sm:text-base">Joined {new Date(userData.dateJoined).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
                                <span className="text-sm sm:text-base">{userData.selectedCity}, {userData.selectedState} ({userData.selectedCountry})</span>
                            </div>
                        </div>

                        <div className="flex gap-2 w-full sm:w-auto">
                            <button
                                className={`px-4 py-2 rounded-lg w-1/2 sm:w-auto text-sm sm:text-base ${currentUser && currentUser.uid === userId
                                        ? 'bg-gray-300 text-gray-500'
                                        : 'bg-fitmania-green hover:bg-fitmania-green-dark'
                                    }`}
                                disabled={currentUser && currentUser.uid === userId}
                            >
                                Follow
                            </button>
                            <ShareProfileComponent
                                baseUrl={`${window.location.origin}/profile`}
                                itemId={username}
                                firstName={userData.firstName}
                                lastName={userData.lastName}
                                userName={userData.userName}
                            />
                        </div>

                        <div className="absolute top-6 sm:top-4 right-8 sm:right-0 flex gap-8">
                            <div className="flex flex-col md:flex-row gap-0 sm:gap-1 items-center sm:items-baseline">
                                <span className="text-base sm:text-lg font-semibold">{completedActivitiesCount}</span>
                                <span className="text-sm sm:text-base">activity</span>
                            </div>
                            <div className="flex flex-col md:flex-row gap-0 sm:gap-1 items-center sm:items-baseline">
                                <span className="text-base sm:text-lg font-semibold">{streak}</span>
                                <span className="text-sm sm:text-base">streak</span>
                            </div>
                            <div className="flex flex-col md:flex-row gap-0 sm:gap-1 items-center sm:items-baseline">
                                <span className="text-base sm:text-lg font-semibold">0</span>
                                <span className="text-sm sm:text-base">followers</span>
                            </div>
                        </div>
                    </div>

                    <hr className="mt-4 mb-4" />
                    {userData.bio && (
                        <div>
                            <h1 className="text-sm sm:text-base font-semibold mb-2">Bio</h1>
                            <span className="text-sm sm:text-base" style={{ whiteSpace: 'pre-line' }}>{userData.bio}</span>
                            <hr className="mt-4 mb-4" />
                        </div>
                    )}

                    <h1 className="text-sm sm:text-base font-semibold mb-4">Interests</h1>
                    <div className="flex flex-row flex-nowrap overflow-x-auto gap-2 items-start pb-2" style={{ scrollbarWidth: 'thin' }}>
                        {userData.interests.map((interest, index) => (
                            <div key={index} className="px-2 py-1 bg-gray-200 flex-shrink-0 rounded-lg text-sm">{interest}</div>
                        ))}
                    </div>

                    {goals.length > 0 && (
                        <div className="mb-2">
                            <hr className="mt-4 mb-4" />
                            <h1 className="text-sm sm:text-base font-semibold">Goals</h1>
                            <div className="flex text-sm sm:text-base text-center">
                                <GoalGaugeComponent userId={userId} visibility="public" isPublicProfile={true} />
                            </div>
                        </div>
                    )}

                    {recentActivities.length > 0 && (
                        <div>
                            <hr className="mt-4 mb-4" />
                            <h1 className="text-sm sm:text-base font-semibold mb-2">Recent Activity</h1>
                            <div className="flex flex-nowrap overflow-x-auto -mx-2" style={{ scrollbarWidth: 'thin' }}>
                                {recentActivities.map((activity) => (
                                    <div key={activity.id} className="flex-shrink-0">
                                        <div>
                                            <ActivityCard activity={activity} userId={userId} copied={activity.copied} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {publicWorkouts.length > 0 && (
                        <div>
                            <hr className="mt-4 mb-4" />
                            <h1 className="text-sm sm:text-base font-semibold mb-2">My Workouts</h1>
                            <div className="flex flex-nowrap overflow-x-auto -mx-2" style={{ scrollbarWidth: 'thin' }}>
                                {publicWorkouts.map((workout) => (
                                    <div key={workout.id} className="flex-shrink-0">
                                        <WorkoutCard workout={workout} userId={userId} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default PublicProfilePage;
