import React, { useState, useMemo, useEffect } from 'react';
import { countries } from 'countries-list'; // Import the countries object directly
import moment from 'moment-timezone'; // Import moment-timezone
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { app2 } from '../../firebase-config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons';


function UserDataComponent({ moveToNextStep }) {
    const auth = getAuth(app2);
    const [heightUnit, setHeightUnit] = useState('imperial'); // Default to imperial (Feet/Inches)
    const [heightFeet, setHeightFeet] = useState('5'); // Default feet
    const [heightInches, setHeightInches] = useState('7'); // Default inches
    const [heightCm, setHeightCm] = useState(170); // Default centimeters
    const [sex, setSex] = useState(''); // State for sex selection
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [weightUnit, setWeightUnit] = useState('lbs'); // Default to lbs
    const [weight, setWeight] = useState('150'); // Default weight
    const [selectedCountry, setSelectedCountry] = useState(''); // State to hold the selected country
    const [selectedState, setSelectedState] = useState(''); // State for state/region
    const [selectedCity, setSelectedCity] = useState(''); // State for city
    const [countriesList, setCountriesList] = useState([]); // State to store the list of countries
    const [timeZoneOptions, setTimeZoneOptions] = useState([]);
    const [selectedTimeZone, setSelectedTimeZone] = useState(''); // Initialize the state for selectedTimeZone
    const [selectedFitnessLevel, setSelectedFitnessLevel] = useState(''); // State for the fitness level selection
    const [selectedActivityDays, setSelectedActivityDays] = useState('');  // State for activity days per week
    const [selectedWorkoutDuration, setSelectedWorkoutDuration] = useState('');  // State for workout duration
    const [otherFitnessGoal, setOtherFitnessGoal] = useState(''); // State for custom input when 'Other' is selected
    const [importantFeatures, setImportantFeatures] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);


    const feetOptions = Array.from({ length: 8 }, (_, i) => i + 1); // Range from 1 to 8 feet
    const inchOptions = Array.from({ length: 48 }, (_, i) => (i * 0.25).toFixed(2)); // Generate inches options in increments of 0.25 from 0 to 11.75
    const cmOptions = useMemo(() => Array.from({ length: (250 - 60) * 4 + 1 }, (_, i) => (60 + i * 0.25).toFixed(2)), []); // Generate cm options in increments of 0.25 from 60 to 250

    const [fitnessGoals, setFitnessGoals] = useState({
        stayActive: false,
        loseWeight: false,
        gainWeight: false,
        increaseStrength: false,
        buildMuscle: false,
        improveStaminaAndEndurance: false,
        trainForAnEvent: false,
        improveFlexibility: false,
        improveSportsPerformance: false,
        enhanceMentalHealth: false,
        improveSleepQuality: false,
        boostImmuneSystem: false,
        bodybuilding: false,
        other: false,
    });

    // Helper function to format the goal keys from camelCase to readable format
    const formatLabel = (label) => {
        return label
            .replace(/([A-Z])/g, ' $1') // insert a space before all caps
            .replace(/^./, str => str.toUpperCase()) // capitalize the first letter
            .toLowerCase()
            .replace(/^./, str => str.toUpperCase()) // Ensure first letter is capitalized
            .replace(/([a-z])([A-Z])/g, '$1 $2'); // Insert space before camel case
    };

    const handleFitnessGoalChange = (event) => {
        const { name, checked } = event.target;
        setFitnessGoals(prevGoals => ({
            ...prevGoals,
            [name]: checked
        }));
    };

    const handleOtherFitnessGoalChange = (event) => {
        setOtherFitnessGoal(event.target.value);
    };

    const [howHeardOptions, setHowHeardOptions] = useState({
        google: false,
        facebook: false,
        instagram: false,
        tiktok: false,
        snapchat: false,
        linkedin: false,
        friend: false,
        other: false,
    });
    const [otherHowHeard, setOtherHowHeard] = useState('');

    const handleHowHeardChange = (event) => {
        const { name, checked } = event.target;
        setHowHeardOptions(prev => ({
            ...prev,
            [name]: checked
        }));
    };

    const handleOtherHowHeardChange = (event) => {
        setOtherHowHeard(event.target.value);
    };


    const toggleHeightUnit = () => {
        setHeightUnit(prevUnit => prevUnit === 'imperial' ? 'metric' : 'imperial');
    };

    // Calculate the date 18 years ago from today
    const today = new Date();
    const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()).toISOString().split('T')[0];

    // Prevent key presses in the date input
    const handleKeyDown = (e) => {
        e.preventDefault();
    };

    const handleWeightInput = (event) => {
        const value = event.target.value;
        // Use a regular expression to ensure the input is a number with at most one decimal place
        if (/^\d*\.?\d{0,1}$/.test(value)) {
            setWeight(value);
        }
    };

    const toggleWeightUnit = () => {
        setWeightUnit(prevUnit => prevUnit === 'lbs' ? 'kg' : 'lbs');
        // Convert the current weight when toggling units
        setWeight(prevWeight => {
            return weightUnit === 'lbs'
                ? (parseFloat(prevWeight) / 2.20462).toFixed(1)  // Convert kg to lbs
                : (parseFloat(prevWeight) * 2.20462).toFixed(1); // Convert lbs to kg
        });
    };

    // Populate the countries list when the component mounts
    useEffect(() => {
        setTimeZoneOptions(moment.tz.names().map(zoneName => ({
            zoneName,
            gmt: moment.tz(zoneName).format('Z')
        })));
        const countryOptions = Object.values(countries)
            .map(country => ({
                code: country.iso2,
                name: country.name
            }))
            .sort((a, b) => a.name.localeCompare(b.name));  // Sort by country name alphabetically

        setCountriesList(countryOptions);
    }, []);

    // Handler function to update the selected country
    const handleCountryChange = (e) => {
        setSelectedCountry(e.target.value);
    };

    useEffect(() => {
        // Function to check the validity of the form
        const validateForm = () => {
            const fitnessGoalsSelected = Object.values(fitnessGoals).some(value => value);
            const otherFitnessGoalValid = !fitnessGoals.other || (fitnessGoals.other && otherFitnessGoal.trim() !== '');
            
            return (
                heightFeet.trim() !== '' && heightInches.trim() !== '' &&
                sex.trim() !== '' && dateOfBirth.trim() !== '' &&
                weight.trim() !== '' && selectedCountry.trim() !== '' &&
                selectedState.trim() !== '' && selectedCity.trim() !== '' &&
                selectedTimeZone.trim() !== '' && selectedFitnessLevel.trim() !== '' &&
                selectedActivityDays.trim() !== '' && selectedWorkoutDuration.trim() !== '' &&
                fitnessGoalsSelected && otherFitnessGoalValid
            );
        };

        // Update the form validity state based on the form validation function
        setIsFormValid(validateForm());
    }, [
        heightFeet, heightInches, heightCm, sex, dateOfBirth, weight,
        selectedCountry, selectedState, selectedCity, selectedTimeZone,
        selectedFitnessLevel, selectedActivityDays, selectedWorkoutDuration,
        fitnessGoals, otherFitnessGoal
    ]);

    const saveData = async () => {
        if (!auth.currentUser || !isFormValid) {
            console.log("Validation failed or no user logged in!");
            return;
        }

        const userRef = doc(getFirestore(app2), "users", auth.currentUser.uid);
        const publicProfileRef = doc(getFirestore(app2), "publicProfiles", auth.currentUser.uid);

        try {
            await setDoc(userRef, {
                heightUnit,
                heightFeet,
                heightInches,
                heightCm,
                sex,
                dateOfBirth,
                weightUnit,
                weight,
                selectedCountry,
                selectedState,
                selectedCity,
                selectedTimeZone,
                selectedFitnessLevel,
                selectedActivityDays,
                selectedWorkoutDuration,
                fitnessGoals,
                otherFitnessGoal,
                howHeardOptions,
                otherHowHeard,
                importantFeatures,
            }, { merge: true });

            // Save only public data in publicProfiles
            await setDoc(publicProfileRef, {
                selectedCountry,
                selectedState,
                selectedCity,
            }, { merge: true });

            console.log("Data saved successfully!");
            moveToNextStep();  // Assuming you want to navigate the user after saving
        } catch (error) {
            console.error("Error saving data: ", error);
        }
    };


    return (
        <div className="flex flex-col bg-white pt-20 pb-20 p-4 gap-8 max-w-lg min-h-screen">
            <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-4">
                    <div className="flex flex-row justify-between">
                        <h1 className="text-2xl font-semibold">Tell us about yourself</h1>
                        <button
                            className={`px-3 py-2 rounded-full ${isFormValid ? 'bg-fitmania-green hover:bg-fitmania-green-dark' : 'bg-gray-200'}`}
                            onClick={saveData}
                            disabled={!isFormValid}  // Disable the button based on form validity
                        >
                            Finish
                        </button>
                    </div>
                    <span className="text-gray-500 mb-4">This information helps us better tailor Fitmania to you. We always keep your data private.</span>
                    <hr></hr>
                    <div className="flex justify-between items-center">
                        <div className="flex flex-row items-center gap-2">
                            <FontAwesomeIcon icon={faBadgeCheck} className="text-gray-500 text-xl" />
                            <div>
                                Height
                            </div>
                            <button
                                className="bg-gray-200 text-black px-3 py-1 rounded-full text-sm"
                                onClick={toggleHeightUnit}
                            >
                                {heightUnit === 'imperial' ? 'Feet/Inches' : 'Centimeters'}
                            </button>
                        </div>
                        <div className="flex items-center">
                            <div>
                                {heightUnit === 'imperial' ? (
                                    <div className="flex">
                                        <select
                                            className="px-4 py-2 border rounded-l-lg border-black"
                                            value={heightFeet}
                                            onChange={(e) => setHeightFeet(e.target.value)}
                                        >
                                            {feetOptions.map(feet => (
                                                <option key={feet} value={feet}>
                                                    {feet} ft
                                                </option>
                                            ))}
                                        </select>
                                        <select
                                            className="px-4 py-2 border rounded-r-lg border-black"
                                            value={heightInches}
                                            onChange={(e) => setHeightInches(e.target.value)}
                                        >
                                            {inchOptions.map(inches => (
                                                <option key={inches} value={inches}>
                                                    {inches} in
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                ) : (
                                    <select
                                        className="px-4 py-2 border rounded-lg border-black"
                                        value={heightCm}
                                        onChange={(e) => setHeightCm(e.target.value)}
                                    >
                                        {cmOptions.map(cm => (
                                            <option key={cm} value={cm}>
                                                {cm} cm
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>

                        </div>
                    </div>
                    <hr></hr>
                    <div className="flex justify-between items-center">
                        <div className="flex flex-row items-center gap-2">
                            <FontAwesomeIcon icon={faBadgeCheck} className="text-gray-500 text-xl" />
                            <div>
                                Sex
                            </div>
                        </div>
                        <select className="px-4 py-2 border rounded-lg border-black" value={sex} onChange={e => setSex(e.target.value)}>
                            <option value="" disabled>Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>
                    <hr></hr>
                    <div className="flex justify-between items-center">

                        <div className="flex flex-row items-center gap-2">
                            <FontAwesomeIcon icon={faBadgeCheck} className="text-gray-500 text-xl" />
                            <div>
                                Date of Birth
                            </div>
                        </div>
                        <input
                            type="date"
                            className="px-4 py-2 border rounded-lg border-black"
                            value={dateOfBirth}
                            onChange={e => setDateOfBirth(e.target.value)}
                            max={maxDate}
                            onKeyDown={handleKeyDown} // Block typing in the input
                        />
                    </div>
                    <hr></hr>
                    <div className="flex justify-between items-center">
                        <div className="flex items-center gap-2">
                            <div className="flex flex-row items-center gap-2">
                                <FontAwesomeIcon icon={faBadgeCheck} className="text-gray-500 text-xl" />
                                <div>
                                    Weight
                                </div>
                            </div>
                            <button
                                className="bg-gray-200 text-black px-3 py-1 rounded-full text-sm"
                                onClick={toggleWeightUnit}
                            >
                                {weightUnit}
                            </button>
                        </div>
                        <input
                            type="text"
                            value={weight}
                            onInput={handleWeightInput}
                            className="px-4 py-2 border rounded-lg border-black w-32 text-center"
                            placeholder="Enter weight"
                            pattern="[0-9]*\.?[0-9]{0,1}"  // Allow numeric values and limit to one decimal place
                            inputMode="decimal"  // Bring up the numeric keypad with a decimal point on mobile devices
                        />
                    </div>
                    <hr></hr>
                    <div className="flex justify-between items-center">
                        <div className="flex flex-row items-center gap-2">
                            <FontAwesomeIcon icon={faBadgeCheck} className="text-gray-500 text-xl" />
                            <div>
                                Country
                            </div>
                        </div>
                        <select
                            className="px-4 py-2 border rounded-lg border-black w-60"
                            value={selectedCountry}
                            onChange={handleCountryChange} // Update selected country on change
                        >
                            <option value="" disabled>Select your country</option>
                            {countriesList.map(country => (
                                <option key={country.code} value={country.code}>
                                    {country.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <hr></hr>
                    <div className="flex justify-between items-center">
                        <div className="flex flex-row items-center gap-2">
                            <FontAwesomeIcon icon={faBadgeCheck} className="text-gray-500 text-xl" />
                            <div>
                                State/Region
                            </div>
                        </div>
                        <input
                            type="text"
                            placeholder="Type your state/region"
                            className="px-4 py-2 border rounded-lg border-black"
                            value={selectedState}
                            onChange={(e) => setSelectedState(e.target.value)} // Allow typing and updating state/region
                        />
                    </div>
                    <hr></hr>
                    <div className="flex justify-between items-center">
                        <div className="flex flex-row items-center gap-2">
                            <FontAwesomeIcon icon={faBadgeCheck} className="text-gray-500 text-xl" />
                            <div>
                                City
                            </div>
                        </div>
                        <input
                            type="text"
                            placeholder="Type your city"
                            className="px-4 py-2 border rounded-lg border-black"
                            value={selectedCity}
                            onChange={(e) => setSelectedCity(e.target.value)} // Allow typing and updating city
                        />
                    </div>
                    <hr></hr>

                    <div className="flex justify-between items-center">
                        <div className="flex flex-row items-center gap-2">
                            <FontAwesomeIcon icon={faBadgeCheck} className="text-gray-500 text-xl" />
                            <div>
                                Time Zone
                            </div>
                        </div>
                        <select
                            className="px-4 py-2 border rounded-lg border-black w-60"
                            value={selectedTimeZone}
                            onChange={(e) => setSelectedTimeZone(e.target.value)}
                        >
                            <option value="" disabled>Select your time zone</option>
                            {timeZoneOptions.map(({ zoneName, gmt }) => (
                                <option key={zoneName} value={zoneName}>
                                    {`${zoneName} (GMT${gmt})`}
                                </option>
                            ))}
                        </select>
                    </div>
                    <hr></hr>
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-row items-center gap-2">
                            <FontAwesomeIcon icon={faBadgeCheck} className="text-gray-500 text-xl" />
                            <div>
                                Fitness Goals
                            </div>
                        </div>
                        <div className="flex flex-row justify-between">
                            <div></div>
                            <div className="flex flex-col">
                                {Object.keys(fitnessGoals).map(goal => (
                                    <label key={goal}>
                                        <input
                                            type="checkbox"
                                            name={goal}
                                            checked={fitnessGoals[goal]}
                                            onChange={handleFitnessGoalChange}
                                        /> {formatLabel(goal)}
                                    </label>
                                ))}
                                {fitnessGoals.other && (
                                    <input
                                        type="text"
                                        value={otherFitnessGoal}
                                        onChange={handleOtherFitnessGoalChange}
                                        placeholder="Specify your fitness goal"
                                        className="mt-2 px-4 py-2 border rounded-lg border-black w-full"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="flex justify-between items-center">
                        <div className="flex flex-row items-center gap-2">
                            <FontAwesomeIcon icon={faBadgeCheck} className="text-gray-500 text-xl" />
                            <div>
                                Fitness Level
                            </div>
                        </div>
                        <select
                            className="px-4 py-2 border rounded-lg border-black"
                            value={selectedFitnessLevel}
                            onChange={e => setSelectedFitnessLevel(e.target.value)}
                        >
                            <option value="" disabled>Select your fitness level</option>
                            <option value="beginner">Beginner</option>
                            <option value="intermediate">Intermediate</option>
                            <option value="advanced">Advanced</option>
                            <option value="pro athlete">Pro Athlete</option>
                        </select>
                    </div>
                    <hr></hr>
                    <div className="flex justify-between items-center">
                        <div className="flex flex-row items-center gap-2">
                            <FontAwesomeIcon icon={faBadgeCheck} className="text-gray-500 text-xl" />
                            <div>
                                How often are you physically active?
                            </div>
                        </div>
                        <select
                            className="px-4 py-2 border rounded-lg border-black"
                            value={selectedActivityDays}
                            onChange={e => setSelectedActivityDays(e.target.value)}
                        >
                            <option value="" disabled>Select days per week</option>
                            <option value="1 day per week">1 day per week</option>
                            <option value="2 days per week">2 days per week</option>
                            <option value="3 days per week">3 days per week</option>
                            <option value="4 days per week">4 days per week</option>
                            <option value="5 days per week">5 days per week</option>
                            <option value="6 days per week">6 days per week</option>
                            <option value="7 days per week">7 days per week</option>
                        </select>
                    </div>
                    <hr></hr>
                    <div className="flex justify-between items-center">
                        <div className="flex flex-row items-center gap-2">
                            <FontAwesomeIcon icon={faBadgeCheck} className="text-gray-500 text-xl" />
                            <div>
                                Preferred workout duration
                            </div>
                        </div>
                        <select
                            className="px-4 py-2 border rounded-lg border-black"
                            value={selectedWorkoutDuration}
                            onChange={e => setSelectedWorkoutDuration(e.target.value)}
                        >
                            <option value="" disabled>Select workout duration</option>
                            <option value="<15 minutes">&lt;15 minutes</option>
                            <option value="15 - 30 minutes">15 - 30 minutes</option>
                            <option value="30 - 45 minutes">30 - 45 minutes</option>
                            <option value="45 - 60 minutes">45 - 60 minutes</option>
                            <option value="60+ minutes">60+ minutes</option>
                        </select>
                    </div>
                    <hr></hr>
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-row items-center gap-2">
                            <FontAwesomeIcon icon={faBadgeCheck} className="text-gray-500 text-xl" />
                            <div>
                                How did you hear about Fitmania? (Optional)
                            </div>
                        </div>
                        <div className="flex flex-row justify-between">
                            <div></div>
                            <div className="flex flex-col">
                                {Object.entries(howHeardOptions).map(([key, value]) => (
                                    <label key={key}>
                                        <input
                                            type="checkbox"
                                            name={key}
                                            checked={value}
                                            onChange={handleHowHeardChange}
                                        /> {formatLabel(key)}
                                    </label>
                                ))}
                                {howHeardOptions.other && (
                                    <input
                                        type="text"
                                        value={otherHowHeard}
                                        onChange={handleOtherHowHeardChange}
                                        placeholder="Please specify"
                                        className="mt-2 px-4 py-2 border rounded-lg border-black w-full"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="flex flex-col justify-between items-start gap-2">
                        <div className="flex flex-row items-center gap-2">
                            <FontAwesomeIcon icon={faBadgeCheck} className="text-gray-500 text-xl" />
                            <div>
                                What features are important to you in a fitness app? (Optional)
                            </div>
                        </div>
                        <textarea
                            className="px-4 py-2 border rounded-lg border-black w-full h-20"
                            placeholder="Access to workouts, tracking, statistics, maps, etc."
                            value={importantFeatures}
                            onChange={e => setImportantFeatures(e.target.value)}
                        ></textarea>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default UserDataComponent;
