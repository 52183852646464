import React, { useState } from 'react';
import { getAuth } from 'firebase/auth';
import { doc, getFirestore, setDoc, getDoc } from 'firebase/firestore';
import { app2 } from '../../firebase-config'; // Ensure this is the correct path to your Firebase config

function UserNameComponent({ moveToNextStep }) {
    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSave = async () => {
        setError('');
        setLoading(true);
        const trimmedUsername = username.trim().toLowerCase();  // Convert username to lowercase

        if (trimmedUsername) {
            const auth = getAuth(app2);
            const user = auth.currentUser;
            const db = getFirestore(app2);
            const userNameRef = doc(db, 'userNames', trimmedUsername);

            try {
                // Check if the username already exists
                const docSnap = await getDoc(userNameRef);
                if (docSnap.exists()) {
                    setError('Username is already taken. Please try another.');
                } else {
                    // Username is available
                    // Save the username in the user's profile
                    const userRef = doc(db, 'users', user.uid);
                    await setDoc(userRef, { userName: trimmedUsername }, { merge: true });

                    // Update the public profile with the username
                    const publicProfileRef = doc(db, 'publicProfiles', user.uid);
                    await setDoc(publicProfileRef, { userName: trimmedUsername }, { merge: true });

                    // Create a new document in the 'userNames' collection
                    await setDoc(userNameRef, { userId: user.uid });

                    // Move to the next step after successful save
                    moveToNextStep();
                }
            } catch (error) {
                console.error('Error updating username:', error);
                setError('Failed to save username. Please try again.'); // Provide user feedback on failure
            } finally {
                setLoading(false);
            }
        } else {
            setError('Please enter a valid username.'); // Simple validation feedback
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col min-h-screen bg-white items-center justify-center p-4">
            <img
                className="h-10 w-auto mb-8"
                src={`${process.env.PUBLIC_URL}/images/Fitmania F Logo.png`}
                alt="Fitmania Logo"
            />
            <h1 className="text-xl font-semibold mb-4">Welcome! Select a username:</h1>
            <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                required
            />
            {error && <p className="text-red-500">{error}</p>}
            <button
                onClick={handleSave}
                disabled={loading}
                className="mt-4 mb-4 py-2 px-4 rounded-lg focus:outline-none bg-fitmania-green hover:bg-fitmania-green-dark text-black"
            >
                {loading ? 'Saving...' : 'Continue'}
            </button>
        </div>
    );
}

export default UserNameComponent;
