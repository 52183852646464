import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { app2 } from '../firebase-config';
import ProfilePictureComponent from './ProfilePictureComponent';
import ShareWorkoutComponent from './ShareWorkoutComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

function LitePublicWorkoutPage() {
    const { username, workoutId } = useParams();
    const [coverImageUrl, setCoverImageUrl] = useState('');
    const [workoutName, setWorkoutName] = useState('');
    const [workoutDescription, setWorkoutDescription] = useState('');
    const [exercises, setExercises] = useState([]);
    const [creatorFirstName, setCreatorFirstName] = useState('');
    const [creatorProfilePic, setCreatorProfilePic] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState({});
    const [areAllExercisesOpen, setAreAllExercisesOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState(null);
    const navigate = useNavigate();
    const db = getFirestore(app2);

    useEffect(() => {
        const fetchUserIdByUsername = async () => {
            const usersCollection = collection(db, 'publicProfiles');
            const userQuery = query(usersCollection, where('userName', '==', username));
            const userSnapshot = await getDocs(userQuery);
            if (userSnapshot.empty) {
                console.error('No such user!');
                setLoading(false);
                return;
            }
            const userDoc = userSnapshot.docs[0];
            return userDoc.id;
        };

        const fetchWorkoutDetails = async (fetchedUserId) => {
            const workoutRef = doc(db, 'users', fetchedUserId, 'workouts', workoutId);
            const workoutSnap = await getDoc(workoutRef);

            if (workoutSnap.exists()) {
                const workoutData = workoutSnap.data();
                setCoverImageUrl(workoutData.coverImage || '');
                setWorkoutName(workoutData.name || '');
                setWorkoutDescription(workoutData.description || '');
                setExercises(workoutData.exercises || []);

                const dropdownStates = workoutData.exercises.reduce((acc, exercise, index) => {
                    acc[index] = false;
                    return acc;
                }, {});
                setIsDropdownOpen(dropdownStates);

                if (workoutData.createdBy) {
                    const creatorRef = doc(db, 'publicProfiles', workoutData.createdBy);
                    const creatorSnap = await getDoc(creatorRef);
                    if (creatorSnap.exists()) {
                        const creatorData = creatorSnap.data();
                        setCreatorFirstName(creatorData.firstName || '');
                        setCreatorProfilePic(creatorData.profilePicture || '');
                    } else {
                        console.log("Creator's public profile document not found");
                    }
                }
            } else {
                console.log("No such document!");
            }
            setLoading(false);
        };

        const fetchData = async () => {
            try {
                const fetchedUserId = await fetchUserIdByUsername();
                setUserId(fetchedUserId);
                if (fetchedUserId) {
                    fetchWorkoutDetails(fetchedUserId);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };

        if (workoutId) {
            fetchData();
        } else {
            console.log("workoutId is missing.");
            setLoading(false);
        }
    }, [username, workoutId, db]);

    const toggleDropdown = (exerciseIndex) => {
        setIsDropdownOpen({
            ...isDropdownOpen,
            [exerciseIndex]: !isDropdownOpen[exerciseIndex]
        });
    };

    const toggleAllExerciseDropdowns = () => {
        setAreAllExercisesOpen(!areAllExercisesOpen);
        setIsDropdownOpen(exercises.reduce((acc, _, index) => {
            acc[index] = !areAllExercisesOpen;
            return acc;
        }, {}));
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="max-w-xl mx-auto mt-4 mb-16">
            <div className="text-center px-4 py-8 bg-white shadow rounded-lg overflow-hidden sm:p-8 mb-4">
                <img
                    className="mx-auto h-12 w-auto mb-4"
                    src={`${process.env.PUBLIC_URL}/images/Fitmania F Logo.png`}
                    alt="Fitmania Logo"
                />
                <p className="text-sm sm:text-base mb-4">Sign in to view more.</p>
                <Link to="/signin" className="bg-fitmania-green px-4 py-2 rounded-lg hover:bg-fitmania-green-dark text-sm sm:text-base mx-2">
                    Sign In
                </Link>
                <Link to="/signup" className="bg-fitmania-green px-4 py-2 rounded-lg hover:bg-fitmania-green-dark text-sm sm:text-base mx-2">
                    Sign Up
                </Link>
            </div>
            <div className="bg-white py-8 shadow rounded-lg overflow-hidden">
                <div className="px-8 mb-4 gap-1 flex items-center justify-between">
                    <Link to={`/profile/${username}`} className="flex items-center gap-1">
                        <ProfilePictureComponent profilePicture={creatorProfilePic} className="w-10 h-10 rounded-full" />
                        <p className="ml-2">{creatorFirstName}</p>
                    </Link>
                    <div className="">
                        <div className="flex gap-4">
                            <ShareWorkoutComponent baseUrl={`${window.location.origin}`} workoutId={workoutId} userId={username} buttonText="Follow" />
                            <ShareWorkoutComponent baseUrl={`${window.location.origin}`} workoutId={workoutId} username={username} buttonText="Share" />
                        </div>
                    </div>
                </div>
                <div className="flex justify-between items-start px-8 pb-4">
                    {coverImageUrl ? (
                        <img src={coverImageUrl} alt="Cover" className="h-40 bg-gray-100 border text-gray-900 rounded-md" />
                    ) : (
                        <div className="h-40 bg-gray-100 border text-gray-900 rounded-md flex items-center justify-center">Loading image...</div>
                    )}
                </div>
                <div>
                    <p className="text-base font-semibold pt-2 pr-2 px-8">Name:</p>
                    <p className="text-left mt-2 px-8">{workoutName || 'Loading name...'}</p>
                    <hr className="mt-4 mb-4 mx-8" />
                </div>
                <div>
                    <p className="text-base font-semibold px-8 pt-2 pr-2">Description:</p>
                    <p className="text-left px-8 mt-2">{workoutDescription || 'Loading description...'}</p>
                    <hr className="mt-4 mb-4 mx-8" />
                </div>
                <div className="flex justify-between items-center px-8 mt-4 mb-4">
                    <div className="text-left text-base font-semibold">Exercises</div>
                    {exercises.length > 1 && (
                        <button
                            onClick={toggleAllExerciseDropdowns}
                            className="bg-gray-200 p-2 rounded-full shadow inline-flex items-center justify-center"
                        >
                            <FontAwesomeIcon icon={areAllExercisesOpen ? faChevronUp : faChevronDown} className="text-gray-800" />
                        </button>
                    )}
                </div>
                {exercises.map((exercise, exerciseIndex) => (
                    <div key={exerciseIndex} className="mb-2 px-8">
                        <div className="flex items-start">
                            <div className="flex flex-col flex-1">
                                <button
                                    onClick={() => toggleDropdown(exerciseIndex)}
                                    className="flex items-center justify-between w-full text-left text-lg font-medium text-gray-900 focus:outline-none p-4 rounded-md bg-gray-200"
                                >
                                    <div className="flex flex-col">
                                        <h1 className="text-base">{exercise.name}</h1>
                                        <span className="font-normal text-sm">{exercise.sets ? `${exercise.sets.length} sets` : 'No sets'}</span>
                                    </div>
                                    <FontAwesomeIcon icon={isDropdownOpen[exerciseIndex] ? faChevronUp : faChevronDown} className="ml-2" />
                                </button>
                                {isDropdownOpen[exerciseIndex] && (
                                    <>
                                        {exercise.mediaItems && exercise.mediaItems.length > 0 && (
                                            <div className="flex items-center space-x-4 mt-2 overflow-auto" style={{ scrollbarWidth: 'thin' }}>
                                                {exercise.mediaItems.map((mediaItem, index) => (
                                                    <div key={index} className="flex-shrink-0 mb-2 relative">
                                                        {mediaItem.type === 'image' ? (
                                                            <img
                                                                src={mediaItem.url}
                                                                alt={`Exercise Media ${index + 1}`}
                                                                className="h-40 w-auto object-cover rounded cursor-pointer"
                                                            />
                                                        ) : (
                                                            <video
                                                                src={mediaItem.url}
                                                                autoPlay
                                                                playsInline
                                                                muted
                                                                preload
                                                                controls
                                                                loop
                                                                className="h-40 w-auto rounded cursor-pointer"
                                                            />
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        {exercise.note && (
                                            <div className="border border-gray rounded-md p-4 mt-2">
                                                <div className="flex justify-between items-center">
                                                    <p className="text-base">{exercise.note}</p>
                                                </div>
                                            </div>
                                        )}
                                        {exercise.sets.map((set, setIndex) => (
                                            <div key={setIndex} className="border border-black rounded-md p-2 mt-2 mb-2">
                                                <div className="border border-gray rounded-md p-4 mb-2">
                                                    <div className="flex justify-between items-center mb-2">
                                                        <p className="text-left text-base font-semibold text-black">Set {setIndex + 1}:</p>
                                                    </div>
                                                    {set.repetitions && (
                                                        <div className="flex justify-between items-center mb-2">
                                                            <span className="text-gray-900 text-base">Repetitions:</span>
                                                            <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: 'auto' }}>
                                                                {set.repetitions}
                                                            </p>
                                                        </div>
                                                    )}
                                                    {set.resistance && set.resistance.units && set.resistance.type && (
                                                        <div className="flex justify-between items-center mb-2">
                                                            <span className="text-gray-900 text-base">Resistance:</span>
                                                            <div className="flex items-center">
                                                                <p className="text-sm text-center text-black bg-gray-100 rounded px-1 py-1  mr-2">
                                                                    {set.resistance.units}
                                                                </p>
                                                                <p className="text-sm text-center text-black bg-gray-100 rounded px-1 py-1  mr-2">
                                                                    {set.resistance.type}
                                                                </p>
                                                                <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: '38px' }}>
                                                                    {set.resistance.value}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {set.timer && (
                                                        <div className="flex justify-between items-center mb-2">
                                                            <span className="text-gray-900 text-base">Timer:</span>
                                                            <div className="flex items-center">
                                                                <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '85px', height: 'auto' }}>
                                                                    {set.timer}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {set.distance && set.distance.units && set.distance.value && (
                                                        <div className="flex justify-between items-center mb-2">
                                                            <span className="text-gray-900 text-base">Distance:</span>
                                                            <div className="flex items-center">
                                                                <p className="text-sm text-center text-black bg-gray-100 rounded px-1 py-1 mr-2">
                                                                    {set.distance.units}
                                                                </p>
                                                                <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: 'auto' }}>
                                                                    {set.distance.value}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                {set.rest && (
                                                    <div className="border border-gray rounded-md p-4 mt-2">
                                                        <div className="flex justify-between items-center">
                                                            <span className="text-gray-900 text-base">Rest:</span>
                                                            <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: 'auto' }}>
                                                                {set.rest}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                                {set.note && (
                                                    <div className="border border-gray rounded-md p-4 py-6 mt-2">
                                                        <div className="flex justify-between items-center">
                                                            <p className="text-base">{set.note}</p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
                <hr className="mt-4 mb-4 mx-8" />
                <Link to={`/profile/${username}`} className="flex items-center justify-center px-8 gap-1">
                    <ProfilePictureComponent profilePicture={creatorProfilePic} className="w-10 h-10 rounded-full" />
                    <p className="ml-2">Created by {creatorFirstName}</p>
                </Link>
            </div>

            <div className="max-w-5xl mx-auto mt-8 mb-16 text-center flex flex-col gap-2">
                <span>© 2024 Fitmania, Inc. All Rights Reserved.</span>
                <a href="https://www.fitmania.io" className="font-semibold hover:underline" target="_blank" rel="noopener noreferrer">www.fitmania.io</a>
            </div>
        </div>
    );
}

export default LitePublicWorkoutPage;
