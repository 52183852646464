import React from 'react';

const ShareActivityComponent = ({ baseUrl, activityId, userName, buttonText = 'Share', copied = false }) => {
    const shareUrl = copied 
        ? `${baseUrl}/activity/completed/copied/${userName}/${activityId}`
        : `${baseUrl}/activity/completed/${userName}/${activityId}`;
    const title = `Check out this workout on Fitmania!`;

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: title,
                    text: 'Check out this activity on Fitmania!',
                    url: shareUrl,
                });
                console.log('Content shared successfully!');
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            alert('Web Share API not supported. Copy the link: ' + shareUrl);
        }
    };

    return (
        <button onClick={handleShare} className="bg-fitmania-green px-4 py-2 rounded-lg hover:bg-fitmania-green-dark text-sm sm:text-base sm:w-auto w-1/2">
            {buttonText}
        </button>
    );
};

export default ShareActivityComponent;
