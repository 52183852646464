import React, { useEffect, useState } from 'react';
import Layout from './Layout';

function StatisticsPage() {
    const [exercises, setExercises] = useState([]);

    useEffect(() => {
        // Mock async data fetch
        const fetchData = async () => {
            // Simulate fetching data
            const data = await new Promise(resolve => setTimeout(() => resolve([]), 2000)); // Simulates a fetch call
            setExercises(data);
        };

        fetchData();
    }, []);

    return (
        <Layout>
            <div className="max-w-7xl mx-auto">
                <div className="text-lg sm:text-xl font-semibold text-gray-900 sm:p-0 pl-4 pt-4 pr-4 mt-4 mb-8">Statistics</div>
                <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 mb-4">
                    <h1 className="text-base sm:text-lg font-semibold mb-4">Workout Statistics</h1>
                    {exercises.length > 0 ? (
                        <div>
                            {/* Render exercise details */}
                            {exercises.map(exercise => (
                                <p key={exercise.id}>{exercise.name}</p> // Assuming exercise objects have an 'id' and 'name'
                            ))}
                        </div>
                    ) : (
                        <p>Complete a workout to start viewing statistics.</p>
                    )}
                </div>
                <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 mb-4">
                    <h1 className="text-base sm:text-lg font-semibold mb-4">Exercises</h1>
                    {exercises.length > 0 ? (
                        <div>
                            {/* Render exercise details */}
                            {exercises.map(exercise => (
                                <p key={exercise.id}>{exercise.name}</p> // Assuming exercise objects have an 'id' and 'name'
                            ))}
                        </div>
                    ) : (
                        <p>No exercises data available. Start by completing your first workout.</p>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default StatisticsPage;
