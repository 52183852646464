import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PublicLayout from './PublicLayout';
import { fetchEntries } from '../contentfulClient';

const BlogPage = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getPosts = async () => {
      const entries = await fetchEntries();
      const formattedPosts = entries.map(entry => ({
        ...entry.fields,
        date: formatDate(entry.fields.date), 
        id: entry.sys.id 
      }));
      setPosts(formattedPosts);
    };

    getPosts();
  }, []);

  // Function to format date
  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  }

  return (
    <PublicLayout>
      <div className="mx-auto max-w-8xl flex flex-col justify-center items-center gap-8 mb-8 px-8">
        <div className="flex flex-col gap-8 my-8 text-center">
          <h1 className="text-4xl font-bold">Blog</h1>
          <p>A wealth of articles and resources providing workout advice, nutrition tips, wellness insights, and lifestyle guidance for a healthier, balanced life.</p>
        </div>
        <div className="flex flex-row gap-4 flex-wrap justify-center">
          {posts.map((post, index) => (
            <Link key={index} to={`/blog/${post.slug}`}>
              <div className="bg-gray-100 hover:bg-gray-200 rounded-lg p-4 flex flex-col justify-start items-start w-96 gap-4">
                {post.coverImage && post.coverImage.fields.file ? (
                  <img
                    src={post.coverImage.fields.file.url}
                    alt={post.title}
                    className="h-61 w-96 object-cover"
                  />
                ) : <p className="text-gray-500">No cover image available</p>}
                <h2 className="text-xl font-semibold">{post.title}</h2>
                <p>{post.date}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </PublicLayout>
  );
};

export default BlogPage;
