import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import firebaseConfig from "./firebase-config.json";

// Initialize the original Firebase app
const app = initializeApp(firebaseConfig.firebaseConfig);

// Initialize the second Firebase app
const app2 = initializeApp(firebaseConfig.firebaseConfig2, "secondary");

// Initialize Auth with app2
const auth = getAuth(app2);

// Get Analytics instances for each app
const analytics = getAnalytics(app);

// To use Analytics for the second app in the future
// const analytics2 = getAnalytics(app2);

export { app as default, app2, auth };
