import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Layout from './Layout';
import { getDatabase, ref, onValue } from 'firebase/database';
import { collection, getDocs, getFirestore, doc, getDoc, setDoc, query, orderBy, limit } from 'firebase/firestore';
import app, { app2 } from '../firebase-config';
import GoalGaugeComponent from './GoalGaugeComponent';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import ActivityCard from './ActivityCard';

import ProfilePictureComponent from './onboarding/ProfilePictureComponent';
import InterestsComponent from './onboarding/InterestsComponent';
import UserDataComponent from './onboarding/UserDataComponent';
import UserNameComponent from './onboarding/UserNameComponent';

function getTimeOfDay() {
  const hour = new Date().getHours();
  if (hour < 5) {
    return "Good night";
  } else if (hour < 12) {
    return "Good morning";
  } else if (hour < 18) {
    return "Good afternoon";
  } else {
    return "Good evening";
  }
}

function HomePage() {
  const [totalUsers, setTotalUsers] = useState('Loading...');
  const [totalWorkouts, setTotalWorkouts] = useState('Loading...');
  const [hasGoals, setHasGoals] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [userFirstName, setUserFirstName] = useState('');
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [recentActivities, setRecentActivities] = useState([]);
  const [greeting, setGreeting] = useState('');
  const [currentDateTime, setCurrentDateTime] = useState(new Date().toLocaleString());

  const [onboardingStep, setOnboardingStep] = useState(1);

  const moveToNextStep = () => {
    if (onboardingStep === 4) {
      completeOnboarding();
      window.location.href = '/dashboard';
    } else {
      setOnboardingStep(prevStep => prevStep + 1);
    }
  };

  useEffect(() => {
    setGreeting(getTimeOfDay());

    const updateDateTime = () => {
      const now = new Date();
      const formattedDateTime = now.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
        timeZoneName: 'short'
      }) + ' on ' + now.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
      setCurrentDateTime(formattedDateTime);
    };
    updateDateTime();
    const timer = setInterval(updateDateTime, 1000 * 60);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const auth = getAuth(app2);
    const db = getFirestore(app2);

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);

        const userRef = doc(db, `users/${user.uid}`);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          setUserFirstName(docSnap.data().firstName);
          setShowOnboardingModal(!docSnap.data().onboardingComplete);
        } else {
          setShowOnboardingModal(false);
        }
      } else {
        setCurrentUser(null);
        setShowOnboardingModal(false);
      }
    });
  }, []);

  useEffect(() => {
    const body = document.body;
    if (showOnboardingModal) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'visible';
    }
    return () => {
      body.style.overflow = 'visible';
    };
  }, [showOnboardingModal]);

  const completeOnboarding = () => {
    setShowOnboardingModal(false);
    const db = getFirestore(app2);
    const userRef = doc(db, `users/${currentUser.uid}`);
    setDoc(userRef, { onboardingComplete: true }, { merge: true });
  };

  useEffect(() => {
    const auth = getAuth(app2);
    const db = getFirestore(app2);

    onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) {
        const userRef = doc(db, `users/${user.uid}`);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          setUserFirstName(userData.firstName);
        }

        const userGoalsCol = collection(db, `users/${user.uid}/goals`);
        getDocs(userGoalsCol).then(snapshot => {
          setHasGoals(!snapshot.empty);
        });
      }
    });

    const dbRT = getDatabase(app);
    const usersRef = ref(dbRT, 'USERS');
    onValue(usersRef, (snapshot) => {
      const usersData = snapshot.val();
      const usersCount = usersData ? Object.keys(usersData).length : 0;
      setTotalUsers(usersCount.toString());
    });

    const dbFS = getFirestore(app);
    const workoutsCol = collection(dbFS, 'workouts');
    getDocs(workoutsCol).then(snapshot => {
      setTotalWorkouts(snapshot.docs.length.toString());
    });

  }, []);

  useEffect(() => {
    if (!currentUser) {
      setRecentActivities([]);
      return;
    }

    const db = getFirestore(app2);
    const workoutsCompletedRef = collection(db, `users/${currentUser.uid}/workoutsCompleted`);
    const workoutsQuery = query(workoutsCompletedRef, orderBy("completedAt", "desc"), limit(10));

    const fetchActivities = async () => {
      const querySnapshot = await getDocs(workoutsQuery);
      const activitiesPromises = querySnapshot.docs.map(async (docSnap) => {
        const completedData = docSnap.data();
        const workoutRefPath = completedData.copied ? `users/${currentUser.uid}/copiedWorkouts` : `users/${currentUser.uid}/workouts`;
        const workoutRef = doc(db, workoutRefPath, completedData.workoutId);
        const workoutSnapshot = await getDoc(workoutRef);

        if (workoutSnapshot.exists()) {
          return {
            id: docSnap.id,
            workoutId: completedData.workoutId,
            name: workoutSnapshot.data().name,
            coverImage: workoutSnapshot.data().coverImage,
            completedAt: completedData.completedAt.toDate().toLocaleString('en-US', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: '2-digit',
              hour12: true
            }),
            copied: completedData.copied
          };
        } else {
          return null;
        }
      });

      const fetchedActivities = (await Promise.all(activitiesPromises)).filter(Boolean);
      setRecentActivities(fetchedActivities);
    };

    fetchActivities();
  }, [currentUser]);

  const stats = [
    { name: 'Total Users', stat: totalUsers, link: "/users", icon: 'group.png' },
    { name: 'Total Workouts', stat: totalWorkouts, link: "/all-workouts", icon: 'workout.png' },
    { name: 'Total Challenges', stat: '25*', link: "#", icon: 'trophy.png' },
  ];

  return (
    <Layout>
      <div className="max-w-7xl mx-auto w-full">
        <div className="flex flex-col my-4 mb-8 sm:p-0 pl-4 pt-4 pr-4">
          <div className="text-xl sm:text-2xl font-semibold mb-2">
            {greeting}, {userFirstName || 'Guest'}
          </div>
          <p className="text-sm sm:text-base">{currentDateTime}</p>
        </div>
        <div className="flex">
          <div className="flex-3 w-full lg:w-3/4">
            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 mb-4">
              <h1 className="text-sm sm:text-base font-semibold mb-2">Recent Activity</h1>
              <div className="-mx-2">
                {recentActivities.length > 0 ? (
                  <div className="flex flex-nowrap overflow-x-auto" style={{ scrollbarWidth: 'thin' }}>
                    {recentActivities.map((activity) => (
                      <div key={activity.id} className="flex-shrink-0">
                        <ActivityCard activity={activity} copied={activity.copied} />
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="mx-2">No workouts have been completed. You can <Link to="/workouts" className="text-green-600 hover:text-green-700">create a workout here</Link>, or <Link to="#" className="text-green-600 hover:text-green-700">find a workout here</Link>.</p>
                )}
              </div>
            </div>

            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 block lg:hidden mb-4">
              <h1 className="text-sm sm:text-base font-semibold mb-2">Goals</h1>
              <div className="flex overflow-x-auto" style={{ scrollbarWidth: 'thin' }}>
                {hasGoals ? (
                  <GoalGaugeComponent />
                ) : (
                  <p className="mt-4">No goals have been added. You can <Link to="/goals" className="text-green-600 hover:text-green-700">add a goal here.</Link></p>
                )}
              </div>
            </div>

            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 mb-4">
              <h1 className="text-sm sm:text-base font-semibold mb-2">Workout Statistics</h1>
              <p>Complete a workout to start viewing statistics.</p>
            </div>

            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 mb-4">
              <h1 className="text-sm sm:text-base font-semibold mb-2">Exercises</h1>
              <p>Complete a workout for detailed exercise logs.</p>
            </div>

          </div>
          <div className="w-1/4 hidden lg:block">
            <dl className="ml-5 grid grid-cols-1 gap-4">
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 ">
                <h1 className="text-sm sm:text-base font-semibold mb-2">Goals</h1>
                {hasGoals ? (
                  <GoalGaugeComponent />
                ) : (
                  <p className="mt-4">No goals have been added. You can <Link to="/goals" className="text-green-600 hover:text-green-700">add a goal here.</Link></p>
                )}
              </div>
            </dl>
          </div>
        </div>
      </div>

      {showOnboardingModal && (
        <div className="fixed inset-0 bg-white bg-opacity-100 flex items-start justify-center z-50 overflow-auto">
          {onboardingStep === 1 && (
            <UserNameComponent moveToNextStep={moveToNextStep} />
          )}
          {onboardingStep === 2 && (
            <ProfilePictureComponent moveToNextStep={moveToNextStep} />
          )}
          {onboardingStep === 3 && (
            <InterestsComponent moveToNextStep={moveToNextStep} />
          )}
          {onboardingStep === 4 && (
            <UserDataComponent moveToNextStep={moveToNextStep} />
          )}
        </div>
      )}
    </Layout>
  );
}
export default HomePage;
