import React from 'react';

function Modal({ isOpen, close, children }) {
    if (!isOpen) return null;

    const handleOverlayClick = (event) => {
        if (event.target === event.currentTarget) {
            close();
        }
    };

    return (
        <div
            className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-hidden`}
            onClick={handleOverlayClick}
        >
            <div className="bg-white rounded-md m-8 max-w-3xl max-h-[90vh] overflow-y-auto">
                {/* Header area with close button aligned to the right */}
                <div className="flex justify-between items-center">
                    {/* Placeholder for potential title or spacing div */}
                    <div></div>
                    <button
                        onClick={close}
                        className="text-2xl font-bold pr-4 pt-4"
                    >
                        &times;
                    </button>
                </div>
                {children}
            </div>
        </div>
    );
}

export default Modal;
