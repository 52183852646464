import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useWorkout } from './WorkoutContext';
import CreateAWorkoutComponent from './CreateAWorkoutComponent'; 

function ProgramWorkoutModal({ isOpen, onClose, selectedDay, selectedWorkout, programId, programDetails }) {
    const navigate = useNavigate();
    const { resetWorkoutState, handleSaveProgramWorkout } = useWorkout(); 

    const handleCancel = () => {
        resetWorkoutState();
        navigate('/create-a-program');
        onClose(); 
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-40 z-50 flex items-start justify-end text-left">
            <div className="bg-white p-4 shadow-lg relative w-full h-full max-w-xl" style={{ maxHeight: '100vh', overflowY: 'auto', backgroundColor: '#F5F5F5' }} >
                <div className="flex justify-between items-end mb-6 mt-2">
                    <div className="text-2xl font-semibold text-gray-900">Day {selectedDay}</div>
                    <p>W {selectedWorkout}</p>
                </div>
                <CreateAWorkoutComponent 
                onCancel={handleCancel} 
                programId={programId} 
                selectedDay={selectedDay}
                onSaveWorkout={() => handleSaveProgramWorkout(selectedDay, {
                    programName: programDetails.programName,
                    programDescription: programDetails.programDescription,
                    coverImageFile: programDetails.coverImageFile
                }, onClose)}
                 />
            </div>
        </div>
    );
}

export default ProgramWorkoutModal;
