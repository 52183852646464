import React, { useEffect, useRef, useState } from 'react';
import Layout from './Layout';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs, query, addDoc, where } from 'firebase/firestore';
import VisibilityDropdown from './VisibilityDropdown';
import { getAuth } from 'firebase/auth';
import { app2 } from '../firebase-config';
import WorkoutCard from './WorkoutCard'; // Ensure this is imported correctly

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function WorkoutsPage() {
    const navigate = useNavigate();
    const db = getFirestore(app2);
    const auth = getAuth(app2);
    const [workouts, setWorkouts] = useState([]);
    const [copiedWorkouts, setCopiedWorkouts] = useState([]);
    const [selectedVisibility, setSelectedVisibility] = useState('All');

    useEffect(() => {
        const fetchWorkouts = async (visibility) => {
            const currentUser = auth.currentUser;
            if (!currentUser) return;

            let q = query(collection(db, `users/${currentUser.uid}/workouts`));
            if (visibility !== 'All') {
                q = query(q, where('visibility', '==', visibility));
            }

            const querySnapshot = await getDocs(q);
            setWorkouts(querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            })));
        };

        fetchWorkouts(selectedVisibility);
    }, [db, auth.currentUser, selectedVisibility]);

    useEffect(() => {
        const fetchCopiedWorkouts = async () => {
            const currentUser = auth.currentUser;
            if (!currentUser) return;

            const copiedWorkoutsRef = collection(db, `users/${currentUser.uid}/copiedWorkouts`);
            const querySnapshot = await getDocs(copiedWorkoutsRef);
            setCopiedWorkouts(querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            })));
        };

        fetchCopiedWorkouts();
    }, [db, auth.currentUser]);

    const createNewWorkout = () => {
        navigate('/workouts/create-a-workout');
    };

    const createNewProgram = async () => {
        const currentUser = auth.currentUser;
        if (!currentUser) {
            console.error("No user signed in");
            return;
        }

        try {
            const docRef = await addDoc(collection(db, `users/${currentUser.uid}/programs`), {
                createdAt: new Date(),
            });
            navigate(`/create-a-program/${docRef.id}`);
        } catch (error) {
            console.error("Error creating new program:", error);
        }
    };

    return (
        <Layout>
            <div className="max-w-7xl mx-auto">
                <div className="my-4 sm:p-0 pl-4 pt-4 pr-4">
                    <button className="bg-fitmania-green text-black px-4 py-2 mb-4 rounded-md hover:bg-fitmania-green-dark transition duration-150 ease-in-out"
                        onClick={createNewWorkout}>
                        Create a workout
                    </button>
                    {/* <button className="bg-fitmania-green text-black px-4 py-2 mb-4 ml-4 rounded-md hover:bg-fitmania-green-dark transition duration-150 ease-in-out"
                        onClick={createNewProgram}>
                        Create a program
                    </button> */}
                </div>
                <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 mb-4">
                    <div className="flex flex-col items-start mb-4">
                        <h1 className="text-base sm:text-lg font-semibold mb-4">My Workouts</h1>
                        <VisibilityDropdown
                            db={db}
                            userId={auth.currentUser.uid}
                            collectionName="workouts"
                            onVisibilityChange={setSelectedVisibility}
                        />
                    </div>
                    <div className="flex flex-wrap">
                        {workouts.length > 0 ? (
                            <div className="flex flex-nowrap sm:flex-wrap -mx-2 overflow-x-auto" style={{ scrollbarWidth: 'thin' }}>
                                {workouts.map((workout) => (
                                    <div className="flex-shrink-0">
                                        <WorkoutCard
                                            key={workout.id}
                                            workout={workout} />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="-mt-4">No saved workouts. Start by creating a workout or program.</p>
                        )}
                    </div>
                </div>
                <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 mb-4">
                    <h1 className="text-base sm:text-lg font-semibold mb-4">My Copied Workouts</h1>
                    <div className="flex flex-wrap">
                        {copiedWorkouts.length > 0 ? (
                            <div className="flex flex-nowrap sm:flex-wrap -mx-2 overflow-x-auto" style={{ scrollbarWidth: 'thin' }}>
                                {copiedWorkouts.map((workout) => (
                                    <div className="flex-shrink-0" key={workout.id}>
                                        <WorkoutCard
                                            workout={workout}
                                            copied // Pass the copied prop
                                        />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="-mt-4">No copied workouts.</p>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default WorkoutsPage;
