import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import { getFirestore, doc, getDoc, deleteDoc, updateDoc, runTransaction, collection, query, getDocs, where } from 'firebase/firestore';
import { app2 as firebaseApp } from '../firebase-config';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronLeft, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { Switch } from '@headlessui/react';

import { getAuth } from 'firebase/auth';
import { app2 } from '../firebase-config';
import { useNavigate } from 'react-router-dom';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import ConfirmDialog from './ConfirmDialog';
import useFullscreenMedia from './useFullscreenMedia';
import ShareWorkoutComponent from './ShareWorkoutComponent';
import Tooltip from './Tooltip';
import ProfilePictureComponent from './ProfilePictureComponent';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function WorkoutPage() {
    const [coverImageUrl, setCoverImageUrl] = useState('');
    const [workoutName, setWorkoutName] = useState('');
    const [workoutDescription, setWorkoutDescription] = useState('');
    const [exercises, setExercises] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState({});
    const [areAllExercisesOpen, setAreAllExercisesOpen] = useState(false);
    const [isImageFullscreen, setIsImageFullscreen] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    const [showVisibilityConfirmDialog, setShowVisibilityConfirmDialog] = useState(false);

    const [fullScreenMedia, setFullScreenMedia] = useState({ url: '', type: '' });

    // Initialize the fullscreen media hook
    const { requestImageFullscreen, requestVideoFullscreen } = useFullscreenMedia();
    const [creatorFirstName, setCreatorFirstName] = useState('');
    const [creatorLastName, setCreatorLastName] = useState('');
    const [creatorProfilePic, setCreatorProfilePic] = useState('');
    const [creatorUsername, setCreatorUsername] = useState('');

    const [visibility, setVisibility] = useState(null);
    const [proposedVisibility, setProposedVisibility] = useState(null);  // State to hold the proposed visibility change.

    const navigate = useNavigate();

    const toggleImageFullscreen = () => {
        setIsImageFullscreen(!isImageFullscreen);
    };

    const { workoutId } = useParams();
    const auth = getAuth(app2);
    const db = getFirestore(app2);
    const storage = getStorage(app2);

    const toggleAllExerciseDropdowns = () => {
        setAreAllExercisesOpen(!areAllExercisesOpen);
        setIsDropdownOpen(exercises.reduce((acc, _, index) => {
            acc[index] = !areAllExercisesOpen;
            return acc;
        }, {}));
    };

    useEffect(() => {
        const fetchWorkoutDetails = async (userID) => {
            const db = getFirestore(firebaseApp);
            const docRef = doc(db, 'users', userID, 'workouts', workoutId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                setCoverImageUrl(data.coverImage);
                setWorkoutName(data.name);
                setWorkoutDescription(data.description);
                setExercises(data.exercises || []);
                setVisibility(data.visibility || 'Private');

                const dropdownStates = data.exercises.reduce((acc, exercise, index) => {
                    acc[index] = false;
                    return acc;
                }, {});
                setIsDropdownOpen(dropdownStates);

                // Fetch the user who created the workout using the 'createdBy' field
                if (data.createdBy) {
                    const userDocRef = doc(db, 'users', data.createdBy);
                    const userDocSnap = await getDoc(userDocRef);
                    if (userDocSnap.exists()) {
                        const userData = userDocSnap.data();
                        setCreatorFirstName(userData.firstName); // Assuming 'firstName' is the field name
                        setCreatorLastName(userData.lastName); // Assuming 'lastName' is the field name
                        setCreatorProfilePic(userData.profilePicture || '');
                        setCreatorUsername(userData.userName || ''); // Assuming 'username' is the field name

                        console.log("Username set to:", userData.username); // Debug log
                    } else {
                        console.log("Creator's user document not found");
                    }
                }

            } else {
                console.log("No such document!");
            }
        };

        const currentUser = auth.currentUser;
        if (currentUser && workoutId) {
            fetchWorkoutDetails(currentUser.uid);
        } else {
            console.log("User is not signed in or workoutId is missing.");
        }

    }, [workoutId, auth]);

    // Function to handle the actual visibility change after confirmation
    const confirmVisibilityChange = async () => {
        setShowVisibilityConfirmDialog(false);
        setVisibility(proposedVisibility); // Apply the proposed visibility change
        try {
            const currentUser = auth.currentUser;
            if (currentUser && workoutId) {
                const docRef = doc(db, 'users', currentUser.uid, 'workouts', workoutId);
                await updateDoc(docRef, { visibility: proposedVisibility });

                // Update visibility of all related activities
                await runTransaction(db, async (transaction) => {
                    const activitiesQuery = query(collection(db, `users/${currentUser.uid}/workoutsCompleted`), where('workoutId', '==', workoutId));
                    const activitiesSnapshot = await getDocs(activitiesQuery);
                    activitiesSnapshot.forEach((activityDoc) => {
                        transaction.update(activityDoc.ref, { visibility: proposedVisibility });
                    });
                });

                console.log('Visibility updated successfully');
            }
        } catch (error) {
            console.error('Error updating visibility:', error);
        }
    };

    // Function to cancel the visibility change
    const cancelVisibilityChange = () => {
        setShowVisibilityConfirmDialog(false);
        setProposedVisibility(null); // Reset the proposed visibility
    };

    // Function to prompt for confirmation before changing visibility
    const promptVisibilityChange = () => {
        const nextVisibility = visibility === 'Private' ? 'Public' : 'Private';
        setProposedVisibility(nextVisibility);
        setShowVisibilityConfirmDialog(true);
    };

    const handleVisibilityChange = async () => {
        try {
            const currentUser = auth.currentUser;
            if (currentUser && workoutId) {
                const newVisibility = visibility === 'Private' ? 'Public' : 'Private'; // Toggle visibility
                setVisibility(newVisibility); // Update visibility state

                const docRef = doc(db, 'users', currentUser.uid, 'workouts', workoutId);
                await updateDoc(docRef, { visibility: newVisibility }); // Update visibility in the database
                console.log('Visibility updated successfully');
            }
        } catch (error) {
            console.error('Error updating visibility:', error);
        }
    };

    const promptDeleteWorkout = () => {
        setShowDeleteConfirmDialog(true);
    };

    const deleteWorkoutAndImage = async () => {
        const currentUser = auth.currentUser;
        if (currentUser && workoutId) {
            try {
                await deleteDoc(doc(db, 'users', currentUser.uid, 'workouts', workoutId));

                if (coverImageUrl) {
                    const matches = coverImageUrl.match(/\/o\/([^?]+)/);
                    if (matches) {
                        const imagePath = decodeURIComponent(matches[1]);
                        const imageRef = ref(storage, imagePath);
                        await deleteObject(imageRef);
                    }
                }

                console.log('Workout and image deleted successfully');
                navigate('/workouts');
            } catch (error) {
                console.error("Error during workout and image deletion:", error);
            }
        }
        setShowDeleteConfirmDialog(false);
    };

    const cancelDeletion = () => {
        setShowDeleteConfirmDialog(false);
    };

    const toggleDropdown = (exerciseIndex) => {
        setIsDropdownOpen({
            ...isDropdownOpen,
            [exerciseIndex]: !isDropdownOpen[exerciseIndex]
        });
    };

    const handleCreatorClick = () => {
        navigate('/profile');
    };

    return (
        <Layout>
            <div className="mt-5 w-full max-w-xl">
                <div className="flex justify-between mb-4 sm:p-0 pl-4 pt-4 pr-4">
                    <button onClick={() => navigate(-1)} style={{ color: 'white', fontSize: '18px', background: 'none', border: 'none' }}>
                        <FontAwesomeIcon icon={faChevronLeft} style={{ color: 'black' }} />
                    </button>
                    <div>
                        <button
                            onClick={() => navigate(`/workout/${workoutId}/active`)}
                            type="button"
                            className="bg-fitmania-green text-black py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-fitmania-green-dark"
                        >
                            Start Workout
                        </button>
                    </div>
                </div>
                <div className="bg-white px-8 py-8 shadow rounded-lg overflow-hidden">
                    <div className="flex justify-between items-start">
                        <div className="flex items-center mb-4">
                            {coverImageUrl ? (
                                <img src={coverImageUrl} alt="Cover" className="h-40 bg-gray-100 border text-gray-900 rounded-md cursor-pointer" onClick={toggleImageFullscreen} />
                            ) : (
                                <div className="h-40 bg-gray-100 border text-gray-900 rounded-md flex items-center justify-center">Loading image...</div>
                            )}
                        </div>
                        <ShareWorkoutComponent
                            baseUrl={`${window.location.origin}`}
                            workoutId={workoutId}
                            username={creatorUsername}
                        />
                    </div>
                    {isImageFullscreen && (
                        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={toggleImageFullscreen}>
                            <img src={coverImageUrl} alt="Fullscreen Cover" className="max-w-full max-h-full" />
                        </div>
                    )}
                    <div>
                        <p className="text-base font-semibold pt-2 pr-2">Name:</p>
                        <p className="text-left mt-2">{workoutName || 'Loading name...'}</p>
                        <hr className="mt-4 mb-4" />
                    </div>
                    <div>
                        <p className="text-base font-semibold pt-2 pr-2">Description:</p>
                        <p className="text-left mt-2">{workoutDescription || 'Loading description...'}</p>
                        <hr className="mt-4 mb-4" />
                    </div>
                    <div className="flex justify-between items-center mt-4 mb-4">
                        <div className="text-left text-base font-semibold">Exercises</div>
                        {exercises.length > 1 && (
                            <button
                                onClick={toggleAllExerciseDropdowns}
                                className="bg-gray-200 p-2 rounded-full shadow inline-flex items-center justify-center"
                            >
                                <FontAwesomeIcon icon={areAllExercisesOpen ? faChevronUp : faChevronDown} className="text-gray-800" />
                            </button>
                        )}
                    </div>
                    {exercises.map((exercise, exerciseIndex) => (
                        <div key={exerciseIndex} className="mb-2">
                            <div className="flex items-start">
                                <div className="flex flex-col flex-1">
                                    <button
                                        onClick={() => toggleDropdown(exerciseIndex)}
                                        className="flex items-center justify-between w-full text-left text-lg font-medium text-gray-900 focus:outline-none p-4 rounded-md bg-gray-200"
                                    >
                                        <div className="flex flex-col">
                                            <h1 className="text-base">
                                                {exercise.name}
                                            </h1>
                                            <span className="font-normal text-sm">
                                                {exercise.sets ? `${exercise.sets.length} sets` : 'No sets'}
                                            </span>
                                        </div>
                                        <FontAwesomeIcon icon={isDropdownOpen[exerciseIndex] ? faChevronUp : faChevronDown} className="ml-2" />
                                    </button>
                                    {isDropdownOpen[exerciseIndex] && (
                                        <>
                                            {exercise.mediaItems && exercise.mediaItems.length > 0 && (
                                                <div className="flex items-center space-x-4 mt-2 overflow-auto" style={{ scrollbarWidth: 'thin' }}>
                                                    {exercise.mediaItems.map((mediaItem, index) => (
                                                        <div key={index} className="flex-shrink-0 mb-2 relative">
                                                            {mediaItem.type === 'image' ? (
                                                                <img
                                                                    src={mediaItem.url}
                                                                    alt={`Exercise Media ${index + 1}`}
                                                                    className="h-40 w-auto object-cover rounded cursor-pointer"
                                                                    onClick={() => requestImageFullscreen(mediaItem.url)}
                                                                />
                                                            ) : (
                                                                <video
                                                                    src={mediaItem.url}
                                                                    autoPlay
                                                                    playsInline
                                                                    muted
                                                                    preload
                                                                    controls
                                                                    loop
                                                                    className="h-40 w-auto rounded cursor-pointer"
                                                                    onClick={(e) => requestVideoFullscreen(e.currentTarget)}
                                                                />
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                            {exercise.note && (
                                                <div className="border border-gray rounded-md p-4 mt-2">
                                                    <div className="flex justify-between items-center">
                                                        <p className="text-base">{exercise.note}</p>
                                                    </div>
                                                </div>
                                            )}
                                            {exercise.sets.map((set, setIndex) => (
                                                <div key={setIndex} className="border border-black rounded-md p-2 mt-2 mb-2">
                                                    <div className="border border-gray rounded-md p-4 mb-2">
                                                        <div className="flex justify-between items-center mb-4">
                                                            <p className="text-left text-base font-semibold text-black">Set {setIndex + 1}:</p>
                                                        </div>
                                                        {set.repetitions && (
                                                            <div className="flex justify-between items-center mb-2">
                                                                <span className="text-gray-900 text-base">Repetitions:</span>
                                                                <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: 'auto' }}>
                                                                    {set.repetitions}
                                                                </p>
                                                            </div>
                                                        )}
                                                        {set.resistance && set.resistance.units && set.resistance.type && (
                                                            <div className="flex justify-between items-center mb-2">
                                                                <span className="text-gray-900 text-base">Resistance:</span>
                                                                <div className="flex items-center">
                                                                    <p className="text-sm text-center text-black bg-gray-100 rounded px-1 py-1 mr-2">
                                                                        {set.resistance.units}
                                                                    </p>
                                                                    <p className="text-sm text-center text-black bg-gray-100 rounded px-1 py-1 mr-2">
                                                                        {set.resistance.type}
                                                                    </p>
                                                                    <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: '38px' }}>
                                                                        {set.resistance.value}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {set.timer && (
                                                            <div className="flex justify-between items-center mb-2">
                                                                <span className="text-gray-900 text-base">Timer:</span>
                                                                <div className="flex items-center">
                                                                    <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '85px', height: 'auto' }}>
                                                                        {set.timer}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {set.distance && set.distance.units && set.distance.value && (
                                                            <div className="flex justify-between items-center mb-2">
                                                                <span className="text-gray-900 text-base">Distance:</span>
                                                                <div className="flex items-center">
                                                                    <p className="text-sm text-center text-black bg-gray-100 rounded px-1 py-1 mr-2">
                                                                        {set.distance.units}
                                                                    </p>
                                                                    <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: 'auto' }}>
                                                                        {set.distance.value}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {set.rest && (
                                                        <div className="border border-gray rounded-md p-4 mt-2">
                                                            <div className="flex justify-between items-center">
                                                                <span className="text-gray-900 text-base">Rest:</span>
                                                                <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: 'auto' }}>
                                                                    {set.rest}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {set.note && (
                                                        <div className="border border-gray rounded-md p-4 py-6 mt-2">
                                                            <div className="flex justify-between items-center">
                                                                <p className="text-base">{set.note}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                    <hr className="mt-4 mb-4" />
                    <div className="flex items-center justify-center cursor-pointer" onClick={handleCreatorClick}>
                        <ProfilePictureComponent className="w-10 h-10 rounded-full" />
                        <p className="ml-2">Created by {creatorFirstName}</p>
                    </div>
                </div>
                <div className="bg-white px-8 py-8 shadow rounded-lg overflow-hidden mt-4 max-w-xl flex justify-between">
                    <div className="flex flex-row">
                        <h1 className="font-semibold">Visibility</h1>
                        <Tooltip content="Public workouts are visible on your profile.">
                            <button className="text-gray-400 hover:text-gray-700 focus:outline-none ml-4">
                                <FontAwesomeIcon icon={faCircleInfo} />
                            </button>
                        </Tooltip>
                    </div>
                    <div className="flex flex-row gap-2 justify-center items-center">
                        <span className={visibility === 'Private' ? 'text-black' : 'text-gray-400'}>Private</span>
                        <Switch
                            checked={visibility === 'Public'}
                            onChange={promptVisibilityChange}
                            className={classNames(
                                visibility === 'Public' ? 'bg-fitmania-green-dark' : 'bg-gray-200',
                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none'
                            )}
                        >
                            <span className="sr-only">Toggle Visibility</span>
                            <span
                                className={classNames(
                                    visibility === 'Public' ? 'translate-x-5' : 'translate-x-0',
                                    'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                )}
                            />
                        </Switch>
                        <span className={visibility === 'Public' ? 'text-black' : 'text-gray-400'}>Public</span>
                    </div>
                </div>
                <div className="text-end mt-4 mr-4">
                    <button
                        className="bg-gray-200 mr-2 text-black py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-gray-300"
                    >
                        Edit Workout
                    </button>
                    <button
                        onClick={promptDeleteWorkout}
                        className="bg-red-500 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-red-700"
                    >
                        Delete Workout
                    </button>
                </div>
                {showVisibilityConfirmDialog && (
                    <ConfirmDialog
                        title={`Are you sure you want to change this workout's visibility to "${proposedVisibility}"?`}
                        message="You can change this again at any time."
                        onConfirm={confirmVisibilityChange}
                        onCancel={cancelVisibilityChange}
                        confirmText="Yes"
                        cancelText="Cancel"
                    />
                )}
                {showDeleteConfirmDialog && (
                    <ConfirmDialog
                        title="Are you sure you want to permanently delete this workout?"
                        message="This action cannot be undone."
                        onConfirm={deleteWorkoutAndImage}
                        onCancel={cancelDeletion}
                        confirmText="Yes"
                        cancelText="No"
                    />
                )}
            </div>
        </Layout>
    );
}

export default WorkoutPage;
