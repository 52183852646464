import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from '../firebase-config';

export default function ResetPasswordPage() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Check your email for the password reset link');
    } catch (error) {
      console.error(error.message);
      setError("Failed to send password reset email. Please double-check your email address.");
    }
  };

  return (
    <div className="flex min-h-screen bg-white items-center justify-center">
      <form onSubmit={handleResetPassword} className="max-w-xs w-full space-y-8">
        <div className="max-w-sm w-full flex flex-col gap-4">
          <Link to="/">
            <img
              className="mx-auto h-12 w-auto"
              src={`${process.env.PUBLIC_URL}/images/Fitmania F Logo.png`}
              alt="Fitmania Logo"
            />
          </Link>
          <h2 className="mt-4 text-3xl font-bold text-gray-700 text-center">
            Reset your password
          </h2>

          <input
            type="email"
            name="email"
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            className="mt-4 pl-4 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-400 text-base sm:leading-6 outline-none"
            placeholder="Email address"
          />

          <button
            type="submit"
            className="w-full inline-flex justify-center rounded-md border border-transparent bg-fitmania-green px-4 py-3 text-sm font-medium text-black hover:bg-fitmania-green-dark focus:outline-none focus-visible:ring-2 focus-visible:ring-fitmania-green-dark focus-visible:ring-offset-2"
          >
            Reset password
          </button>

          {message && (
            <p className="text-sm text-green-500 text-center">
              {message}
            </p>
          )}

          {error && (
            <p className="text-sm text-red-500 text-center">
              {error}
            </p>
          )}

          <p className="mt-4 text-center text-sm text-gray-500">
            <Link to="/signin" className="font-semibold leading-6 text-green-500 hover:text-green-600">
              Back to login
            </Link>
          </p>

        </div>
      </form>
    </div>
  );
}
