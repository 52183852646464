import React, { useState, useEffect } from 'react';
import { collection, getDocs, getFirestore, query, where, doc, getDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { app2 } from '../firebase-config';
import { useNavigate } from 'react-router-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import more from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';

more(Highcharts);
solidGauge(Highcharts);

function GoalGaugeComponent({ userId, visibility, isPublicProfile = false }) {
    const db = getFirestore(app2);
    const [goals, setGoals] = useState([]);
    const [username, setUsername] = useState('');
    const auth = getAuth(app2);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGoalsData = async (uid) => {
            try {
                const goalsRef = collection(db, `users/${uid}/goals`);
                let goalsQuery;

                if (visibility === 'public') {
                    goalsQuery = query(goalsRef, where('visibility', '==', 'Public'));
                } else {
                    goalsQuery = goalsRef;
                }

                const goalSnaps = await getDocs(goalsQuery);
                const fetchedGoals = goalSnaps.docs.map(doc => {
                    const goalData = doc.data();
                    return {
                        id: doc.id,
                        visibility: goalData.visibility,
                        name: goalData.goalName,
                        urlFriendlyName: goalData.urlFriendlyName,
                        percentage: goalData.percentageCompleted ? parseFloat(goalData.percentageCompleted) : 0,
                        currentStatus: goalData.currentStatus,
                        targetMilestone: goalData.targetMilestone,
                    };
                });

                setGoals(fetchedGoals);
            } catch (error) {
                console.error("Failed to fetch goals:", error);
                setGoals([]); // Consider setting an error state instead
            }
        };

        const fetchUserDetails = async (uid) => {
            const userDoc = await getDoc(doc(db, `publicProfiles`, uid));
            if (userDoc.exists()) {
                const userData = userDoc.data();
                setUsername(userData.userName);
            }
        };

        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            const fetchUserId = userId || (user ? user.uid : null);
            if (fetchUserId) {
                await fetchUserDetails(fetchUserId);
                await fetchGoalsData(fetchUserId);
            } else {
                setGoals([]);
            }
        });

        return () => unsubscribe(); // Cleanup subscription on unmount
    }, [auth, db, userId, visibility]);

    const renderGauge = (goal) => {
        const options = {
            chart: {
                type: 'solidgauge',
            },
            title: null,
            pane: {
                center: ['50%', '85%'],
                size: '160%',
                startAngle: -90,
                endAngle: 90,
                background: {
                    backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#F5F5F7',
                    innerRadius: '90%',
                    outerRadius: '100%',
                    shape: 'arc',
                    borderWidth: 0,
                    borderColor: null
                }
            },
            tooltip: {
                enabled: false
            },
            yAxis: {
                stops: [
                    [0.1, '#16A34A'], // green
                    [0.5, '#16A34A'], // green
                    [0.9, '#16A34A'] // green
                ],
                lineWidth: 0,
                tickWidth: 0,
                minorTickInterval: null,
                tickAmount: 2,
                labels: {
                    y: 16,
                    formatter: function () {
                        // Hide labels for 0 and 100
                        if (this.value === 0 || this.value === 100) {
                            return '';
                        }
                        return this.value;
                    }
                },
                min: 0,
                max: 100,
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: -40,
                        borderWidth: 0,
                        useHTML: true
                    },
                    innerRadius: '90%',
                    radius: '100%'
                }
            },
            credits: {
                enabled: false
            },
            series: [{
                name: 'Completion',
                data: [goal.percentage],
                dataLabels: {
                    format: '<div style="text-align:center"><span style="font-size:20px">{y}%</span></div>'
                }
            }]
        };

        return (
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                containerProps={{ style: { height: "125px", width: "200px", margin: 0, padding: 0 } }}
            />
        );
    };

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <>
            {goals.length > 0 ? (
                goals.map((goal) => (
                    <div key={goal.id} onClick={() => handleNavigation(isPublicProfile ? `/goal/${username}/${goal.id}` : `/goals/${goal.urlFriendlyName}`)} className="sm:text-base font-normal p-1 flex flex-col items-center cursor-pointer">
                        {renderGauge(goal)}
                        {goal.name}
                    </div>
                ))
            ) : (
                <div className="mt-2 text-center text-sm sm:text-base">
                    No saved goals.
                </div>
            )}
        </>
    );
}

export default GoalGaugeComponent;
