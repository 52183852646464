import React, { useState, useEffect, useMemo } from 'react';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { app2 } from '../../firebase-config';

function InterestsComponent({ moveToNextStep }) {
    const [selectedInterests, setSelectedInterests] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const interests = [
        "Badminton", "Barre", "Basketball", "Cross Training", "Kickboxing", 
        "Soccer", "Dance", "Surfing", "Tennis", "Football", 
        "Archery", "Baseball", "Bowling", "Boxing", "Climbing", "Cricket", 
        "Running", "Sprinting", "Skiing", "Curling", 
        "Fencing", "Golf", "Gymnastics", "HIIT", "Hiking", "Hockey", 
        "Cycling/Spinning", "Walking", "Lacrosse", "Martial Arts", "Swimming", 
        "Paddling", "Pickleball", "Pilates", "Racquetball", "Rugby", "Skating", 
        "Snowboarding", "Squash", "Tai Chi", "Track & Field", "Volleyball", 
        "Water Polo", "Wrestling", "Yoga", "Aerobics", "Calisthenics", "Diving",
        "Judo", "Kettlebell Training", "Zumba", "Handball", "Kendo", "Biking",
        "Parkour", "Powerlifting", "Rollerblading", "Scuba Diving", "Skateboarding",
        "Ultimate Frisbee", "Ballet", "Canoeing", "Crossfit", "Kayaking", "Krav Maga",
        "Tae Bo", "Strength Training", "Plyometrics", "Bodybuilding"
    ];

    useEffect(() => {
        const fetchInterests = async () => {
            const auth = getAuth(app2);
            const db = getFirestore(app2);
            const user = auth.currentUser;

            if (user) {
                const userRef = doc(db, 'users', user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setSelectedInterests(userData.interests || []);
                }
            }
        };

        fetchInterests();
    }, []);

    const handleSelectInterest = (interest) => {
        setSelectedInterests(prevSelected => {
            if (prevSelected.includes(interest)) {
                return prevSelected.filter(i => i !== interest); // Remove interest if already selected
            } else {
                const newSelected = [...prevSelected, interest]; // Add interest if not already selected
                setSearchTerm(''); // Clear search term upon selection
                return newSelected;
            }
        });
    };

    const isContinueDisabled = selectedInterests.length < 3;

    const handleChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const isSearchTermNotInInterests = searchTerm && !interests.some(interest => interest.toLowerCase().includes(searchTerm));

    const sortedInterests = useMemo(() => {
        return [...interests].sort((a, b) => {
            const aSelected = selectedInterests.includes(a);
            const bSelected = selectedInterests.includes(b);
            const aMatch = a.toLowerCase().includes(searchTerm);
            const bMatch = b.toLowerCase().includes(searchTerm);

            if (aSelected && !bSelected) {
                return -1;
            } else if (!aSelected && bSelected) {
                return 1;
            } else if (aMatch && !bMatch) {
                return -1;
            } else if (!aMatch && bMatch) {
                return 1;
            }
            return 0;
        });
    }, [selectedInterests, searchTerm, interests]);

    const handleContinue = async () => {
        const auth = getAuth(app2);
        const db = getFirestore(app2);
        const user = auth.currentUser;

        if (user) {
            const userRef = doc(db, 'users', user.uid);
            const publicProfileRef = doc(db, 'publicProfiles', user.uid);

            try {
                await setDoc(userRef, { interests: selectedInterests }, { merge: true });
                await setDoc(publicProfileRef, { interests: selectedInterests }, { merge: true });
                moveToNextStep(); // Move to the next step after saving
            } catch (error) {
                console.error("Error saving interests: ", error);
            }
        }
    };

    return (
        <div className="flex flex-col bg-white pt-20 pb-20 p-4 gap-8 max-w-lg min-h-screen">
            <div className="flex flex-col gap-2">
                <div className="flex flex-row justify-between">
                    <h1 className="text-2xl font-semibold">Choose your interests</h1>
                    <button 
                        className={`px-3 py-2 rounded-full ${
                            isContinueDisabled ? 'bg-gray-200' : 'bg-fitmania-green'
                        }`}
                        onClick={handleContinue}
                        disabled={isContinueDisabled}
                    >
                        Continue ({selectedInterests.length})
                    </button>
                </div>
                <span className="text-gray-500">Please select three or more to proceed.</span>
                <div className="flex items-center mt-2 w-full">
                    <input
                        type="text"
                        placeholder="Search interests..."
                        className="py-2 px-4 w-full border border-gray-300 rounded-full"
                        value={searchTerm}
                        onChange={handleChange}
                    />
                    {isSearchTermNotInInterests && (
                        <button 
                            className="bg-fitmania-green hover:bg-fitmania-green-dark h-8 w-8 flex justify-center items-center rounded-full text-black"
                            style={{ marginLeft: '-38px' }} // Adjust based on size and styling
                        >
                            +
                        </button>
                    )}
                </div>
            </div>
            <div className="flex flex-wrap gap-2">
                {sortedInterests.map(interest => (
                    <button 
                        key={interest} 
                        className={`rounded-full px-3 py-2 ${
                            selectedInterests.includes(interest) ? 'bg-fitmania-green' : 'bg-gray-200 hover:bg-gray-300'
                        } ${searchTerm && interest.toLowerCase().includes(searchTerm) ? 'ring-2 ring-fitmania-green ring-offset-2' : ''}`
                        }
                        onClick={() => handleSelectInterest(interest)}
                    >
                        {interest}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default InterestsComponent;
