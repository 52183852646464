import React, { useState, useEffect, useRef } from 'react';
import Layout from './Layout';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlay,
    faPause,
    faHeart,
    faFireFlameCurved,
    faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { faCircleInfo, faCamera, } from '@fortawesome/pro-regular-svg-icons';
import ConfirmDialog from './ConfirmDialog';
import { getFirestore, doc, getDoc, setDoc, collection, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAuth } from 'firebase/auth';
import { app2 } from '../firebase-config';
import { useFileUpload } from './FileUpload';
import useFullscreenMedia from './useFullscreenMedia';
import WorkoutPageEmbed from './WorkoutPageEmbed';
import Modal from './Modal';
import RestModal from './RestModal';
import TimerModal from './TimerModal';
import TimerModalTF from './TimerModalTF';

const formatCountdown = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

function ActiveWorkoutPage() {

    const navigate = useNavigate();
    const { workoutId } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const copied = searchParams.get('copied') === 'true';

    const [isPlaying, setIsPlaying] = useState(true);
    const [startTime, setStartTime] = useState(Date.now());
    const [elapsedTime, setElapsedTime] = useState(0);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [exercises, setExercises] = useState([]);
    const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0);
    
    const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 1024px)").matches);
    const [mediaPreviewUrl, setMediaPreviewUrl] = useState('');
    const [mediaFiles, setMediaFiles] = useState([]); // Assuming you might handle multiple files
    const fileInputRef = useRef(null);

    const { createObjectUrl, revokeObjectUrl } = useFileUpload();
    const { requestVideoFullscreen, requestImageFullscreen } = useFullscreenMedia();

    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
    const [isRestModalOpen, setIsRestModalOpen] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [intervalId, setIntervalId] = useState(null);
    const [restCompleted, setRestCompleted] = useState({});

    const [isTimerModalOpen, setIsTimerModalOpen] = useState(false);
    const [timerCountdown, setTimerCountdown] = useState("00:00");

    const [timerIntervalId, setTimerIntervalId] = useState(null);
    const [timerCompleted, setTimerCompleted] = useState({});

    const [isTimerModalTFOpen, setIsTimerModalTFOpen] = useState(false);
    const [currentSetIndex, setCurrentSetIndex] = useState(0);

    const [isValidationModalOpen, setIsValidationModalOpen] = useState(false);
    const [validationMessage, setValidationMessage] = useState("");


    const handleOpenInfoModal = () => {
        setIsInfoModalOpen(true);
    };

    const handleCloseInfoModal = () => {
        setIsInfoModalOpen(false);
    };

    async function uploadMedia(media) {
        if (!media.file) {
            throw new Error("No file available for upload.");
        }

        const storage = getStorage(app2);
        const storageRef = ref(storage, `completedWorkouts/${getAuth(app2).currentUser.uid}/${Date.now()}_${media.file.name}`);

        if (media.file instanceof File || media.file instanceof Blob) {
            await uploadBytes(storageRef, media.file);
        } else {
            throw new Error('The provided file is neither a File nor a Blob.');
        }

        return getDownloadURL(storageRef);
    }



    const handleStartRestClick = (restString, exerciseIndex, setIndex) => {
        const timeParts = restString.split(':');
        const restDuration = parseInt(timeParts[0], 10) * 60 + parseInt(timeParts[1], 10);

        if (!Number.isInteger(restDuration) || restDuration <= 0) {
            console.error("Invalid rest duration:", restDuration);
            return;
        }

        clearInterval(intervalId);
        setCountdown(restDuration);
        setIsRestModalOpen(true);
        const id = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown <= 1) {
                    clearInterval(id);
                    setCountdown(0);
                    
                    setRestCompleted(prev => ({
                        ...prev,
                        [`${exerciseIndex}-${setIndex}`]: true
                    }));
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);
        setIntervalId(id);
    };

    const handleCloseRestModal = () => {
        setIsRestModalOpen(false);
        clearInterval(intervalId);
        if (countdown > 0) {
            setRestCompleted(prev => ({ ...prev, [currentExerciseIndex]: true }));
        }
    };

    const handleSaveTime = (exerciseIndex, setIndex) => (savedTime) => {
        updateSetInput(exerciseIndex, setIndex, 'timer', savedTime);
        setTimerCompleted(prev => ({
            ...prev,
            [`${exerciseIndex}-${setIndex}`]: true
        }));
        handleCloseTFModal();
    };

    const handleStartTimerClick = (timerString, exerciseIndex, setIndex) => {
        const timeParts = timerString.split(':');
        const timerDuration = parseInt(timeParts[0], 10) * 60 + parseInt(timeParts[1], 10);
        if (!Number.isInteger(timerDuration) || timerDuration <= 0) {
            console.error("Invalid timer duration:", timerDuration);
            return;
        }
        clearInterval(timerIntervalId);
        setTimerCountdown(timerDuration);
        setIsTimerModalOpen(true);
        const id = setInterval(() => {
            setTimerCountdown(prevCountdown => {
                if (prevCountdown <= 1) {
                    clearInterval(id);
                    setTimerCountdown(0);
                    setTimerCompleted(prev => ({
                        ...prev,
                        [`${exerciseIndex}-${setIndex}`]: true
                    }));
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);
        setTimerIntervalId(id);
    };

    const handleStartTimerTFClick = (exerciseIndex, setIndex) => {
        setCurrentSetIndex(setIndex); // Keep track of the current set index
        setIsTimerModalTFOpen(true);
        setTimerCountdown(0);
    };

    const handleCloseTimerModal = () => {
        setIsTimerModalOpen(false);
        clearInterval(timerIntervalId);
        setTimerCountdown("00:00");  // Reset the countdown if needed
    };

    const handleCloseTFModal = () => {
        setIsTimerModalTFOpen(false); // Close the TF timer modal
     
    };

    useEffect(() => {
        return () => clearInterval(timerIntervalId);
    }, [timerIntervalId]);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(min-width: 1024px)");
        const handler = (e) => setIsDesktop(e.matches);
        mediaQuery.addListener(handler);
        return () => mediaQuery.removeListener(handler);
    }, []);

    useEffect(() => {
        let interval = null;
        if (isPlaying) {
            interval = setInterval(() => {
                // Directly calculate elapsed time based on the current time minus the start time
                setElapsedTime(Date.now() - startTime);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isPlaying, startTime]);

    // Toggle between play and pause, and also handle setting/resetting the start time
    const togglePlayPause = () => {
        if (!isPlaying) {
           
            setStartTime(Date.now() - elapsedTime);
        }
        setIsPlaying(!isPlaying);
    };

    const formatTime = (time) => {
        let seconds = Math.floor(time / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        seconds = seconds % 60;
        minutes = minutes % 60;

        return [hours, minutes, seconds]
            .map(v => v < 10 ? "0" + v : v)
            .filter((v, i) => v !== "00" || i > 0)
            .join(":");
    };

    useEffect(() => {
        const fetchWorkoutDetails = async () => {
            const db = getFirestore(app2);
            const auth = getAuth(app2);
            const user = auth.currentUser;
            if (user) {
                const collectionPath = copied ? 'copiedWorkouts' : 'workouts';
                const docRef = doc(db, 'users', user.uid, collectionPath, workoutId);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    const exercisesWithEmptyFlag = data.exercises.map(exercise => ({
                        ...exercise,
                        sets: exercise.sets.map(set => ({
                            ...set,
                            userInputIsEmpty: true,
                            userInputResistanceIsEmpty: true,
                            userInputDistanceIsEmpty: true
                        }))
                    }));
                    setExercises(exercisesWithEmptyFlag);
                } else {
                    console.log("No such document!");
                }
            }
        };

        fetchWorkoutDetails();
    }, [workoutId, copied]);

    const handleNextExercise = () => {
        if (currentExerciseIndex < exercises.length - 1) {
            setCurrentExerciseIndex(currentExerciseIndex + 1);
        } else {
            console.log('Reached the end of the workout');
        }
    };

    const handlePreviousExercise = () => {
        if (currentExerciseIndex > 0) {
            setCurrentExerciseIndex(currentExerciseIndex - 1);
        }
    };

    // Adjustments for dynamic exercise names based on currentExerciseIndex
    const currentExerciseName = exercises[currentExerciseIndex]?.name || 'Loading exercise name...';
    const nextExerciseName = exercises[currentExerciseIndex + 1]?.name || 'End of Workout';
    const currentExercise = exercises[currentExerciseIndex];

    const handleSetNoteChange = (event, exerciseIndex, setIndex) => {
        const newUserNote = event.target.value;
        // Update the userNote for the specific set in the specific exercise
        updateSetInput(exerciseIndex, setIndex, 'userNote', newUserNote);

        // Adjust the textarea's height based on its content
        const target = event.target;
        target.style.height = 'inherit'; // Reset the height to recalculate
        target.style.height = `${target.scrollHeight}px`; // Set the height to the scroll height
    };

    // Function to update input values for a specific set within an exercise
    const updateSetInput = (exerciseIndex, setIndex, inputField, inputValue) => {
        setExercises(currentExercises => {
            return currentExercises.map((exercise, eIndex) => {
                if (eIndex === exerciseIndex) {
                    const updatedSets = exercise.sets.map((set, sIndex) => {
                        if (sIndex === setIndex) {
                            const isEmpty = inputValue.trim() === '';
                            return {
                                ...set,
                                [inputField]: inputValue,
                                [`${inputField}IsEmpty`]: isEmpty
                            };
                        }
                        return set;
                    });
                    return { ...exercise, sets: updatedSets };
                }
                return exercise;
            });
        });
    };

    // Function to open file input dialog
    const handleFileInputClick = () => {
        fileInputRef.current.click();
    };

    // Function to handle file selection
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const url = createObjectUrl(file);
            const mediaType = file.type.startsWith('video/') ? 'video' : 'image';
            // Store both the URL for preview and the actual file object for later upload
            setMediaFiles(prevFiles => [...prevFiles, { url, type: mediaType, file: file }]);
        }
    };



    const renderMediaItem = (mediaFile, index) => (
        <div key={index} className={`flex-shrink-0 ${index !== mediaFiles.length - 1 ? 'mr-4' : ''}`}>
            <div className="relative">
                {mediaFile.type === 'video' ? (
                    <video
                        src={mediaFile.url}
                        className="h-40 w-auto object-cover rounded cursor-pointer"
                        onClick={() => requestVideoFullscreen(document.querySelector(`video[src='${mediaFile.url}']`))}
                        autoPlay
                        playsInline
                        muted
                        preload
                        controls
                        loop
                    />
                ) : (
                    <img
                        src={mediaFile.url}
                        alt="Uploaded Media"
                        className="h-40 w-auto object-cover rounded cursor-pointer"
                        onClick={() => requestImageFullscreen(mediaFile.url)}
                    />
                )}
                <button onClick={() => removeMedia(index)} className="absolute top-0 right-0 flex m-1 bg-white rounded-full">
                    <FontAwesomeIcon icon={faTimesCircle} />
                </button>
            </div>
        </div>
    );

    // Optional: Function to remove an uploaded media file
    const removeMedia = (index) => {
        const fileToRemove = mediaFiles[index];
        if (fileToRemove) {
            revokeObjectUrl(fileToRemove.url);
            setMediaFiles(prevFiles => prevFiles.filter((_, idx) => idx !== index));
        }
    };

    const validateAllFieldsFilled = () => {
        let message = "";
        for (let exerciseIndex = 0; exerciseIndex < exercises.length; exerciseIndex++) {
            const exercise = exercises[exerciseIndex];
            for (let setIndex = 0; setIndex < exercise.sets.length; setIndex++) {
                const set = exercise.sets[setIndex];
                if (set.repetitions && set.userInputIsEmpty) {
                    message += `Exercise ${exerciseIndex + 1}, Set ${setIndex + 1}: Repetitions\n`;
                }
                if (set.resistance && set.userInputResistanceIsEmpty && (set.resistance.units && set.resistance.type)) {
                    message += `Exercise ${exerciseIndex + 1}, Set ${setIndex + 1}: Resistance\n`;
                }
                if (set.distance && set.userInputDistanceIsEmpty && (set.distance.units && set.distance.value)) {
                    message += `Exercise ${exerciseIndex + 1}, Set ${setIndex + 1}: Distance\n`;
                }
                // Add similar formatting for other attributes like timer
                if (set.timer && set.userInputTimerIsEmpty) { // Assuming you track timer input emptiness
                    message += `Exercise ${exerciseIndex + 1}, Set ${setIndex + 1}: Timer\n`;
                }
            }
        }

        if (message !== "") {
            setValidationMessage(message);
            setIsValidationModalOpen(true);
            return false;
        }
        return true;
    };






    const handleFinishWorkout = async () => {
        if (!validateAllFieldsFilled()) {
            return; 
        }

        const db = getFirestore(app2);
        const auth = getAuth(app2);
        const user = auth.currentUser;

        if (!user) {
            console.error("User not authenticated");
            return;
        }

        // Upload all media files and collect their URLs
        const mediaUploadPromises = mediaFiles.map(async file => {
            const url = await uploadMedia(file);
            return { url, type: file.type }; 
        });
        const mediaUrls = await Promise.all(mediaUploadPromises);

        // Calculate the elapsed time in seconds
        const totalElapsedTimeInSeconds = Math.floor(elapsedTime / 1000);

        const completedAt = new Date();  // Current date and time as completion time
        const startedAt = new Date(completedAt.getTime() - (totalElapsedTimeInSeconds * 1000));  // Calculate the start time

        const workoutData = {
            workoutId,
            mediaUrls, // Include media URLs at the workout level
            exercises: exercises.map(exercise => ({
                name: exercise.name,
                sets: exercise.sets.map(set => {
                    let newSet = {};
                    if (set.userInput) newSet.repetitions = set.userInput;
                    // Save resistance with its units and type
                    if (set.userInputResistance) newSet.resistance = {
                        value: set.userInputResistance,
                        units: set.resistance.units, 
                        type: set.resistance.type, 
                    };
                    // Save distance with its units
                    if (set.userInputDistance) newSet.distance = {
                        value: set.userInputDistance,
                        units: set.distance.units,
                    };
                    if (set.timer) newSet.timer = set.timer;
                    if (set.userNote) newSet.note = set.userNote;
                    if (set.rest) newSet.rest = set.rest;
                    return newSet;
                })
            })),
            workoutDuration: totalElapsedTimeInSeconds,
            completedAt: completedAt,
            startedAt: startedAt,
            copied: copied
        };

        console.log("Attempting to save workout data:", workoutData);

        try {
            const workoutsCompletedRef = collection(db, 'users', user.uid, 'workoutsCompleted');
            await addDoc(workoutsCompletedRef, workoutData);
            console.log("Workout saved successfully!");
            navigate('/activity'); // Redirect the user after saving
        } catch (error) {
            console.error("Error saving workout:", error);
        }
    };

    const preventNonNumericInput = (e) => {
        const currentValue = e.target.value;
        if (
            (e.key === '.' && currentValue.includes('.')) || // Prevent entering more than one '.'
            (!/[0-9]/.test(e.key) && e.key !== '.' && e.key !== "Backspace" && e.key !== "Enter") 
        ) {
            e.preventDefault();
        }
    };





    const PageContent = (
        <div>
            <div className="w-full max-w-xl">
                <div className="bg-white py-8 shadow rounded-lg overflow-hidden">

                    <div className="px-8 flex justify-between mb-8">
                        <button
                            onClick={() => setShowConfirmDialog(true)}
                            className="font-semibold text-red-500 hover:text-red-600"
                        >
                            End
                        </button>

                        <div>
                            {currentExerciseIndex > 0 && (
                                <button
                                    onClick={handlePreviousExercise}
                                    className="font-semibold text-gray-400 mr-4"
                                >
                                    Previous
                                </button>
                            )}
                            {currentExerciseIndex < exercises.length - 1 ? (
                                <button
                                    onClick={handleNextExercise}
                                    className="font-semibold text-fitmania-green hover:text-fitmania-green-dark"
                                >
                                    Next
                                </button>
                            ) : (
                                <button
                                    onClick={handleFinishWorkout}
                                    className="font-semibold text-fitmania-green hover:text-fitmania-green-dark"
                                >
                                    Finish
                                </button>
                            )}
                        </div>
                    </div>

                    {/* Media upload and view */}
                    <div className={`bg-gray-100 flex overflow-auto ${mediaFiles.length > 0 ? 'mb-8 px-8 py-4' : ''} whitespace-nowrap`} style={{ scrollbarWidth: 'thin' }}>
                        {mediaFiles.map(renderMediaItem)}
                    </div>


                    {showConfirmDialog && (
                        <ConfirmDialog
                            title="Are you sure you want to cancel?"
                            message="All un-saved progress will be lost."
                            onConfirm={() => navigate(-1)}
                            onCancel={() => setShowConfirmDialog(false)}
                            confirmText="Yes"
                            cancelText="No"
                        />
                    )}

                    <div className="px-8 flex justify-between mb-4">

                        <div className="flex items-center">
                            <button
                                onClick={togglePlayPause}
                                className="bg-gray-200 h-6 w-6 rounded hover:bg-gray-300 mr-2"
                            >
                                <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} style={{ color: 'black' }} />
                            </button>
                            <h1 className="text-3xl font-normal">{formatTime(elapsedTime)}</h1>
                        </div>
                        <div className="flex items-center">
                            <button

                                className="bg-gray-200 h-6 w-6 rounded hover:bg-gray-300 mr-2"
                            >
                                <FontAwesomeIcon icon={faHeart} style={{ color: 'black' }} />
                            </button>
                            <h1 className="text-3xl font-normal text-gray-300">000</h1>
                        </div>
                        <div className="flex items-center">
                            <button

                                className="bg-gray-200 h-6 w-6 rounded hover:bg-gray-300 mr-2"
                            >
                                <FontAwesomeIcon icon={faFireFlameCurved} style={{ color: 'black' }} />
                            </button>
                            <h1 className="text-3xl font-normal text-gray-300">000</h1>
                        </div>

                    </div>

                    <div className=" px-8 flex justify-between items-center mb-2">
                        <div className="flex items-center">
                            <h1 className="text-2xl font-semibold">{currentExerciseIndex + 1}: {currentExerciseName}</h1>
                            <button
                                className="text-gray-400 hover:text-gray-700 focus:outline-none ml-2"
                                onClick={handleOpenInfoModal}
                            >
                                <FontAwesomeIcon icon={faCircleInfo} />
                            </button>
                        </div>
                        <button
                            className="focus:outline-none ml-2"
                            onClick={() => fileInputRef.current && fileInputRef.current.click()}
                        >
                            <FontAwesomeIcon icon={faCamera} size="lg" />
                        </button>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            accept="image/*,video/*"
                            onChange={handleFileChange}
                        />
                    </div>

                    <div className="px-8 flex justify-between items-center">
                        <h1 className="text-sm font-normal mb-4">Next: {nextExerciseName}</h1>
                    </div>

                    {currentExercise?.note && (
                        <div className="mx-8 border border-gray rounded-md p-4 mt-2">
                            <div className="flex justify-between items-center">
                                <p>{currentExercise.note}</p>
                            </div>
                        </div>
                    )}

                    {currentExercise?.sets.map((set, setIndex) => (
                        <div key={setIndex} className="mx-8 border border-black rounded-md p-2 mt-2 mb-2">
                            <div className="border border-gray rounded-md p-4 mb-2">
                                <div className="flex justify-between items-center mb-4">
                                    <p className="text-left text-lg font-semibold text-black">Set {setIndex + 1}:</p>
                                </div>

                                {set.repetitions && (
                                    <div className="flex justify-between items-center mb-2">
                                        <span className="text-gray-900 text-lg">Repetitions:</span>
                                        {set.repetitions === "TF" ? (
                                            <input
                                                type="tel"
                                                placeholder="TF" 
                                                onKeyDown={preventNonNumericInput}
                                                value={set.userInput || ''}  
                                                onChange={(e) => updateSetInput(currentExerciseIndex, setIndex, 'userInput', e.target.value)}
                                                className={`text-center text-gray-900 text-lg rounded px-1 py-1 w-12 outline-none ${set.userInputIsEmpty ? 'border border-red-500' : 'border border-black'}`}
                                                style={{ borderRadius: '5px', width: '65px', height: 'auto' }}
                                            />
                                        ) : (
                                            <input
                                                type="tel"
                                                placeholder={set.repetitions.toString()}
                                                onKeyDown={preventNonNumericInput}
                                                value={set.userInput || ''} 
                                                onChange={(e) => updateSetInput(currentExerciseIndex, setIndex, 'userInput', e.target.value)}
                                                className={`text-center text-gray-900 text-lg rounded px-1 py-1 w-12 outline-none ${set.userInputIsEmpty ? 'border border-red-500' : 'border border-black'}`}
                                                style={{ borderRadius: '5px', width: '65px', height: 'auto' }}
                                            />
                                        )}
                                    </div>
                                )}

                                {/* Removed set.resistance.value && from the end to allow for variable resistance input by end user */}
                                {set.resistance && set.resistance.units && set.resistance.type && (
                                    <div className="flex justify-between items-center mb-2">
                                        <span className="text-gray-900 text-lg">Resistance:</span>
                                        <div className="flex items-center">
                                            <p className="text-sm text-center text-black bg-gray-100 rounded px-1 py-1  mr-2">
                                                {set.resistance.units}
                                            </p>
                                            <p className="text-sm text-center text-black bg-gray-100 rounded px-1 py-1  mr-2">
                                                {set.resistance.type}
                                            </p>
                                            <input
                                                type="text"
                                                pattern="[0-9]*\.?[0-9]{0,1}"  
                                                inputMode="decimal"  
                                                placeholder={set.resistance.value.toString()}
                                                onKeyDown={preventNonNumericInput}
                                                value={set.userInputResistance || ''} 
                                                onChange={(e) => updateSetInput(currentExerciseIndex, setIndex, 'userInputResistance', e.target.value)}
                                                className={`text-center text-gray-900 text-lg rounded px-1 py-1 w-12 outline-none ${set.userInputResistanceIsEmpty ? 'border border-red-500' : 'border border-black'
                                                    }`}
                                                style={{ borderRadius: '5px', width: '65px', height: 'auto' }}
                                            />
                                        </div>
                                    </div>
                                )}

                                {set.timer && (
                                    <div className="flex justify-between items-center mb-2">
                                        <span className="text-gray-900 text-lg">Timer:</span>
                                        <div className="flex justify-between">
                                            {timerCompleted[`${currentExerciseIndex}-${setIndex}`] ? (
                                                <span className="mr-4 bg-gray-100 rounded-full py-1 px-2 text-sm text-black flex justify-center items-center">
                                                    Timer completed
                                                </span>
                                            ) : set.timer.toUpperCase() === "OPEN" ? (
                                                <button
                                                    className="mr-4 bg-blue-400 rounded-full py-1 px-2 text-sm hover:bg-blue-500"
                                                    onClick={() => handleStartTimerTFClick(currentExerciseIndex, setIndex)}
                                                >
                                                    Start Timer
                                                </button>
                                            ) : (
                                                <button
                                                    className="mr-4 bg-blue-400 rounded-full py-1 px-2 text-sm hover:bg-blue-500"
                                                    onClick={() => handleStartTimerClick(set.timer, currentExerciseIndex, setIndex)}
                                                >
                                                    Start Timer
                                                </button>
                                            )}
                                            <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '80px', height: 'auto' }}>
                                                {set.timer}
                                            </p>
                                        </div>
                                    </div>
                                )}


                                {set.distance && set.distance.units && set.distance.value && (
                                    <div className="flex justify-between items-center mb-2">
                                        <span className="text-gray-900 text-lg">Distance:</span>
                                        <div className="flex items-center">
                                            <p className="text-sm text-center text-black bg-gray-100 rounded px-1 py-1 mr-2">
                                                {set.distance.units}
                                            </p>
                                            <input
                                                type="text"
                                                pattern="[0-9]*\.?[0-9]{0,1}"  // Allow numeric values and limit to one decimal place
                                                inputMode="decimal"  // Bring up the numeric keypad with a decimal point on mobile devices
                                                placeholder={set.distance.value.toString()}
                                                onKeyDown={preventNonNumericInput}
                                                value={set.userInputDistance || ''} // Assuming a new property for user input
                                                onChange={(e) => updateSetInput(currentExerciseIndex, setIndex, 'userInputDistance', e.target.value)}

                                                className={`text-center text-gray-900 text-lg rounded px-1 py-1 w-12 outline-none ${set.userInputDistanceIsEmpty ? 'border border-red-500' : 'border border-black'
                                                    }`}
                                                style={{ borderRadius: '5px', width: '65px', height: 'auto' }}
                                            />
                                        </div>
                                    </div>
                                )}

                            </div>

                            {set.rest && (
                                <div className="border border-gray rounded-md p-4 mt-2">
                                    <div className="flex justify-between items-center">
                                        <span className="text-gray-900 text-lg">Rest:</span>
                                        <div className="flex justify-between">
                                            {!restCompleted[`${currentExerciseIndex}-${setIndex}`] ? (
                                                <button
                                                    className="mr-4 bg-fitmania-green rounded-full py-1 px-2 text-sm hover:bg-fitmania-green-dark"
                                                    onClick={() => handleStartRestClick(set.rest.toString(), currentExerciseIndex, setIndex)}
                                                >
                                                    Start rest
                                                </button>
                                            ) : (
                                                <span className="mr-4 bg-gray-100 rounded-full py-1 px-2 text-sm flex justify-center items-center">
                                                    Rest completed
                                                </span>
                                            )}
                                            <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: 'auto' }}>
                                                {set.rest}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {set.note && (
                                <div className="border border-gray rounded-md p-4 py-6 mt-2">
                                    <div className="flex justify-between items-center">
                                        <p>{set.note}</p>
                                    </div>
                                </div>
                            )}

                            <div className="border border-gray rounded-md p-2 py-4 mt-2">
                                <div className="flex justify-between items-center">
                                    <textarea
                                        className="auto-grow-textarea w-full p-2 rounded-md focus:outline-none focus:border-gray-500"
                                        placeholder="Enter a set note..."
                                        rows="1"
                                        value={set.userNote || ''} // Bind to the userNote property
                                        onChange={(event) => handleSetNoteChange(event, currentExerciseIndex, setIndex)}
                                    ></textarea>
                                </div>
                            </div>

                        </div>
                    ))}

                    {isInfoModalOpen && (
                        <Modal isOpen={isInfoModalOpen} close={handleCloseInfoModal}>
                            <WorkoutPageEmbed />
                        </Modal>
                    )}

                    {isRestModalOpen && (
                        <RestModal
                            isOpen={isRestModalOpen}
                            close={handleCloseRestModal}
                            countdown={countdown}
                            className="fixed top-0 left-0 w-full h-full bg-green-500 flex items-center justify-center z-50"
                        >
                            <div className="text-black text-center">
                                <h1 className="text-5xl mb-4">Rest:</h1>
                                <h1 className="text-8xl mb-4">{formatCountdown(countdown)}</h1>
                                <p className="text-2xl">Time Remaining</p>
                            </div>
                        </RestModal>
                    )}

                    {isTimerModalOpen && (
                        <TimerModal
                            isOpen={isTimerModalOpen}
                            close={handleCloseTimerModal}
                            countdown={timerCountdown}
                        />
                    )}

                    {isTimerModalTFOpen && (
                        <TimerModalTF
                            isOpen={isTimerModalTFOpen}
                            close={handleCloseTFModal}
                            onSave={handleSaveTime(currentExerciseIndex, currentSetIndex)}
                            timerCountdown={timerCountdown}
                        />
                    )}

                    <Modal isOpen={isValidationModalOpen} close={() => setIsValidationModalOpen(false)}>
                        <div className="pl-4 pr-4 pb-4">
                            <h2 className="text-lg font-bold mb-4">Missing Inputs</h2>
                            <p>Please fill in all required fields:</p>
                            <pre className="whitespace-pre-wrap text-red-500">{validationMessage}</pre>
                        </div>
                    </Modal>

                </div>
            </div>
        </div>

    );

    return isDesktop ? (
        <Layout>
            {PageContent}
        </Layout>
    ) : (
        <>
            {PageContent}
        </>
    );

}

export default ActiveWorkoutPage;
