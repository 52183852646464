import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PublicLayout from './PublicLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBadgeCheck } from '@fortawesome/pro-regular-svg-icons';

function PublicHomePage() {
    const navigate = useNavigate();

    const handleSignIn = () => {
        navigate('/signin'); 
    };

    const handleSignUp = () => {
        navigate('/signup'); 
    };

    return (
        <PublicLayout>
            <div className="max-w-8xl px-4 py-4 md:px-16 md:py-16 mx-auto flex flex-col gap-8">
                <div className="flex flex-col md:flex-row justify-center pb-16 gap-8 md:gap-44 items-center">
                    <div className="flex flex-col justify-center items-center md:items-start gap-8">
                        <h1 className="text-4xl md:text-5xl font-semibold">Keep Movin'</h1>
                        <p className="text-lg md:text-xl max-w-xl text-center md:text-left">Explore and create personalized workouts, track your performance, analyze your progress with our comprehensive workout statistics and scheduling tools, and challenge your friends to our one-of-a-kind fitness challenges!</p>
                        <button onClick={handleSignUp} className="px-4 py-3 bg-black text-white rounded-lg font-semibold hover:bg-gray-800">Sign Up</button>
                    </div>
                    <div className="order-first md:order-last">
                        <img src={`${process.env.PUBLIC_URL}/images/Fitmania Connected.png`} alt="Fitmania Logo" className="max-w-xs md:max-w-lg" />
                    </div>
                </div>
                <div className="flex flex-col md:flex-row justify-center pb-16 gap-8 md:gap-44 items-center">
                    <div>
                        <img src={`${process.env.PUBLIC_URL}/images/Fitmania_Workouts_Image-p-500.png`} alt="Fitmania Logo" className="max-w-40 md:max-w-64" />
                    </div>
                    <div className="flex flex-col justify-center items-center md:items-start gap-8">
                        <h1 className="text-4xl md:text-5xl font-semibold max-w-md">Workout</h1>
                        <p className="text-lg md:text-xl max-w-md">Record, track, and share your workouts</p>
                        <div className="flex flex-col gap-4">
                            <p className="text-lg md:text-xl"><FontAwesomeIcon icon={faBadgeCheck} className="mr-4 icon-large" />Effortless recording and tracking</p>
                            <p className="text-lg md:text-xl"><FontAwesomeIcon icon={faBadgeCheck} className="mr-4 icon-large" />Track your progress</p>
                            <p className="text-lg md:text-xl"><FontAwesomeIcon icon={faBadgeCheck} className="mr-4 icon-large" />Interact with the community</p>
                        </div>
                        <button onClick={handleSignIn} className="px-4 py-3 bg-black text-white rounded-lg font-semibold hover:bg-gray-800">Workout now</button>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row justify-center pb-16 gap-8 md:gap-44 items-center">

                    <div className="flex flex-col justify-center items-center md:items-start gap-8">
                        <h1 className="text-4xl md:text-5xl font-semibold max-w-md">First-of-its-kind</h1>
                        <p className="text-lg md:text-xl max-w-xl text-center md:text-left">Challenge your friends to fitness challenges powered by Fitmania AI</p>
                        <div className="flex flex-col gap-4">
                            <p className="text-lg md:text-xl"><FontAwesomeIcon icon={faBadgeCheck} className="mr-4 icon-large" />Pushups</p>
                            <p className="text-lg md:text-xl"><FontAwesomeIcon icon={faBadgeCheck} className="mr-4 icon-large" />Squats</p>
                            <p className="text-lg md:text-xl"><FontAwesomeIcon icon={faBadgeCheck} className="mr-4 icon-large" />More challenges coming soon!</p>
                        </div>
                        <button onClick={handleSignIn} className="px-4 py-3 bg-black text-white rounded-lg font-semibold hover:bg-gray-800">Try a challenge</button>
                    </div>
                    <div className="order-first md:order-last">
                        <img src={`${process.env.PUBLIC_URL}/images/Fitmania Pushup Gif.gif`} alt="Fitmania Logo" className="max-w-40 md:max-w-64" />
                    </div>
                </div>
                <div className="flex flex-col md:flex-row justify-center pb-16 gap-8 md:gap-44 items-center">
                    <div className="">
                        <img src={`${process.env.PUBLIC_URL}/images/Fitmania Blog Image.png`} alt="Fitmania Logo" className="max-w-64 md:max-w-96" />
                    </div>
                    <div className="flex flex-col justify-center items-center md:items-start gap-8">
                        <h1 className="text-4xl md:text-5xl font-semibold max-w-md text-center">Fitness content you can trust</h1>
                        <p className="text-lg md:text-xl max-w-xl text-center md:text-left">Discover content guaranteed to help you achieve your goals and keep you accountable to staying active.</p>
                        <Link to="/blog" className="px-4 py-3 bg-black text-white rounded-lg font-semibold hover:bg-gray-800">Read here</Link>
                    </div>

                </div>
            </div>
        </PublicLayout>

    );
}

export default PublicHomePage;
