import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import Layout from './Layout';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { collection, query, where, getDocs, getFirestore, doc, getDoc } from 'firebase/firestore';
import { app2 } from '../firebase-config';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

function CalendarPage() {
  const [calendarApi, setCalendarApi] = useState(null);
  const [view, setView] = useState('dayGridMonth');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const auth = getAuth(app2);
  const navigate = useNavigate();

  const handleEventClick = (clickInfo) => {
    
    if (clickInfo.event.extendedProps.copied) {
      navigate(`/activity/completed/copied/${clickInfo.event.id}`);
    } else {
      navigate(`/activity/completed/${clickInfo.event.id}`);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        fetchUserAndWorkouts(user.uid);
      } else {
        console.error("User is not authenticated.");
        setEvents([]);
      }
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [auth]);

  const fetchUserAndWorkouts = async (userID) => {
    const db = getFirestore(app2);

    const workoutsCol = collection(db, 'users', userID, 'workoutsCompleted');
    const querySnapshot = await getDocs(workoutsCol);
    const workoutsPromises = querySnapshot.docs.map(async (docSnapshot) => {
      const data = docSnapshot.data();
      const timestamp = data.startedAt; // Assuming this is a Firestore Timestamp
      const workoutId = data.workoutId;
      const parsedDate = timestamp.toDate(); // Converts the Timestamp to a JavaScript Date object
      const workoutRefPath = data.copied ? `users/${userID}/copiedWorkouts` : `users/${userID}/workouts`;
      const workoutRef = doc(db, workoutRefPath, workoutId);
      const workoutSnap = await getDoc(workoutRef);
      const workoutName = workoutSnap.exists() ? workoutSnap.data().name : 'Unnamed Workout';

      return {
        title: workoutName,
        start: parsedDate,
        id: docSnapshot.id,
        copied: data.copied
      };
    });

    const workouts = await Promise.all(workoutsPromises);
    setEvents(workouts);
  };

  const goToToday = () => {
    calendarApi.today();
    setCurrentDate(new Date());
  };

  const goToNext = () => {
    calendarApi.next();
    setCurrentDate(calendarApi.getDate());
  };

  const goToPrev = () => {
    calendarApi.prev();
    setCurrentDate(calendarApi.getDate());
  };

  const changeView = (newView) => {
    setView(newView);
    calendarApi.changeView(newView);
    setCurrentDate(calendarApi.getDate());
  };

  const formattedDate = currentDate.toLocaleDateString('default', { month: 'long', year: 'numeric' });

  return (
    <Layout>
      <div className=" bg-white shadow rounded-lg overflow-hidden max-w-7xl mx-auto sm:m-0 ml-4 mt-4 mr-4">
        <div className="flex justify-between items-center p-4">
          <div className="flex items-center">
            <h2 className="text-lg sm:text-xl font-semibold">{formattedDate}</h2>
          </div>
          <div className="flex items-center">
            <IconButton onClick={goToPrev}><ChevronLeftIcon /></IconButton>
            <IconButton onClick={goToNext}><ChevronRightIcon /></IconButton>
            <button onClick={goToToday} className="p-2 mx-6 border border-black rounded">Today</button>
            <Box sx={{ minWidth: 100 }}>
              <FormControl fullWidth>
                <InputLabel id="view-select-label">View</InputLabel>
                <Select
                  labelId="view-select-label"
                  id="view-select"
                  value={view}
                  label="View"
                  onChange={(e) => changeView(e.target.value)}
                >
                  <MenuItem value="dayGridMonth">Month</MenuItem>
                  <MenuItem value="timeGridWeek">Week</MenuItem>
                  <MenuItem value="timeGridDay">Day</MenuItem>
                  <MenuItem value="listMonth">List</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
          initialView={view}
          headerToolbar={false}
          ref={(calendarRef) => setCalendarApi(calendarRef?.getApi())}
          initialDate={currentDate}
          navLinks={true}
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          events={events}
          eventClick={handleEventClick}
        />
      </div>
    </Layout>
  );
}

export default CalendarPage;
