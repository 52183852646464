// Import statements
import React, { useState } from 'react';
import Layout from './Layout';
import { Switch } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import { app2 } from '../firebase-config';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

function CreateNewGoalPage() {
  const [isRemindersEnabled, setIsRemindersEnabled] = useState(false);
  const navigate = useNavigate();
  const db = getFirestore(app2);
  const [visibility, setVisibility] = useState('Private'); // Default to 'Private'


  const [formValues, setFormValues] = useState({
    goalName: '',
    startDate: '',
    completionDate: '',
    currentStatus: '',
    targetMilestone: '',
    unitsOfMeasurement: '',
    activityFrequency: '',
    motivationNote: '',
    remindersEnabled: isRemindersEnabled,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSaveGoal = async () => {
    const auth = getAuth(app2);
    const user = auth.currentUser;

    if (!user) {
      console.error("No user logged in!");
      return;
    }

    try {
      const urlFriendlyName = formValues.goalName.toLowerCase().replace(/\s+/g, '-');
      await addDoc(collection(db, "users", user.uid, "goals"), {
        ...formValues,
        remindersEnabled: isRemindersEnabled,
        urlFriendlyName: urlFriendlyName,
        visibility,
      });
      navigate(-1);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <Layout>
      <div className="max-w-lg p-4 sm:p-0">
        <h3 className="text-xl font-medium leading-6 text-gray-900 mt-4">Create New Goal</h3>

        <div className="mt-8">
          <div>
            <label htmlFor="goal-name" className="block text-sm font-medium leading-6 text-gray-900">
              Goal Name
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="goalName"
                id="goal-name"
                className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-fitmania-green outline-none sm:text-sm sm:leading-6"
                placeholder="Enter your goal (e.g., 'Run 5k', 'Lose 10 pounds')"
                onChange={handleInputChange}
                value={formValues.goalName}
              />
            </div>
          </div>

          <div className="mt-8">
            <label htmlFor="start-date" className="block text-sm font-medium leading-6 text-gray-900">
              Start Date
            </label>
            <div className="text-sm text-gray-500">
              When do you want to start tracking this goal?
            </div>
            <div className="mt-2">
              <input
                type="date"
                name="startDate"
                id="start-date"
                className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-fitmania-green outline-none sm:text-sm sm:leading-6"
                placeholder="Select start date"
                onChange={handleInputChange}
                value={formValues.startDate}
              />
            </div>
          </div>

          <div className="mt-8">
            <label htmlFor="completion-date" className="block text-sm font-medium leading-6 text-gray-900">
              Completion Date
            </label>
            <div className="text-sm text-gray-500">
              When do you want to achieve this goal by?
            </div>
            <div className="mt-2">
              <input
                type="date"
                name="completionDate"
                id="completion-date"
                className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-fitmania-green outline-none sm:text-sm sm:leading-6"
                onChange={handleInputChange}
                value={formValues.completionDate}
              />
            </div>
          </div>

          <div className="mt-8">
            <label htmlFor="current-status" className="block text-sm font-medium leading-6 text-gray-900">
              Current Status
            </label>
            <div className="text-sm text-gray-500">
              This will be saved as your initial check in for this goal.
            </div>
            <div className="mt-2">
              <input
                type="text"
                name="currentStatus"
                id="current-status"
                className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-fitmania-green outline-none sm:text-sm sm:leading-6"
                placeholder="Where are you now?"
                onChange={handleInputChange}
                value={formValues.currentStatus}
              />
            </div>
          </div>

          <div className="mt-8">
            <label htmlFor="current-status" className="block text-sm font-medium leading-6 text-gray-900">
              Target Milestone
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="targetMilestone"
                id="target-milestone"
                className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-fitmania-green outline-none sm:text-sm sm:leading-6"
                placeholder="What's your target?"
                onChange={handleInputChange}
                value={formValues.targetMilestone}
              />
            </div>
          </div>

          <div className="mt-8">
            <label htmlFor="units-of-measurement" className="block text-sm font-medium leading-6 text-gray-900">
              Units of Measurement
            </label>
            <div className="mt-2">
              <select
                id="units-of-measurement"
                name="unitsOfMeasurement"
                className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-fitmania-green outline-none sm:text-sm sm:leading-6"
                defaultValue=""
                onChange={handleInputChange}
                value={formValues.unitsOfMeasurement}
              >
                <option value="" disabled>Select an option</option>
                <option>Kilograms (kg)</option>
                <option>Pounds (lb)</option>
                <option>Meters (m)</option>
                <option>Kilometers (km)</option>
                <option>Miles (mi)</option>
                <option>Yards (yd)</option>
                <option>Feet (ft)</option>
                <option>Hours (hr)</option>
                <option>Minutes (min)</option>
                <option>Seconds (sec)</option>
                <option>Other</option>
              </select>
            </div>
          </div>

          <div className="mt-8">
            <label htmlFor="activity-frequency" className="block text-sm font-medium leading-6 text-gray-900">
              Activity Frequency
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="activityFrequency"
                id="activity-frequency"
                className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-fitmania-green outline-none sm:text-sm sm:leading-6"
                placeholder="How often will you work on this goal? ('Daily', '3 times per week')"
                onChange={handleInputChange}
                value={formValues.activityFrequency}
              />
            </div>
          </div>
          <div className="mt-8">
            <label htmlFor="motivation-note" className="block text-sm font-medium leading-6 text-gray-900">
              Motivation Note
            </label>
            <div className="mt-2">
              <textarea
                name="motivationNote"
                id="motivation-note"
                rows="3"
                className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-fitmania-green outline-none sm:text-sm sm:leading-6"
                placeholder="Why is this goal important to you?"
                onChange={handleInputChange}
                value={formValues.motivationNote}
              ></textarea>
            </div>
          </div>
          <div className="mt-8">
            <div className="flex justify-between items-center">
              <div>
                <div className="text-sm font-medium leading-6 text-gray-900">Get reminders to stay on track with your goal.</div>
                <div className="text-sm text-gray-500">Set reminders</div>
              </div>
              <Switch
                checked={isRemindersEnabled}
                onChange={setIsRemindersEnabled}
                className={`${isRemindersEnabled ? 'bg-fitmania-green' : 'bg-gray-200'
                  } relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out`}
              >
                <span className="sr-only">Enable reminders</span>
                <span
                  className={`${isRemindersEnabled ? 'translate-x-6' : 'translate-x-1'
                    } inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out`}
                />
              </Switch>
            </div>
          </div>

          <div className="mt-4">
            <div className="flex justify-between items-center">
              <div>
                <label className="block text-sm font-medium text-gray-900">Visibility</label>
                <div className="text-sm text-gray-500">Should this goal be public?</div>
              </div>
              <Switch
                checked={visibility === 'Public'}
                onChange={() => setVisibility(visibility === 'Private' ? 'Public' : 'Private')}
                className={`${visibility === 'Public' ? 'bg-fitmania-green' : 'bg-gray-200'
                  } relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out`}
              >
                <span className="sr-only">Toggle Visibility</span>
                <span
                  className={`${visibility === 'Public' ? 'translate-x-6' : 'translate-x-1'
                    } inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out`}
                />
              </Switch>
            </div>
          </div>




        </div>
        <div className="mt-8 flex justify-end gap-4">
          <button
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent bg-gray-200 px-4 py-2 text-sm text-black hover:bg-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent bg-fitmania-green px-4 py-2 text-sm text-black hover:bg-fitmania-green-dark focus:outline-none focus-visible:ring-2 focus-visible:ring-fitmania-green-dark focus-visible:ring-offset-2"
            onClick={handleSaveGoal}

          >
            Save Goal
          </button>
        </div>
      </div>

    </Layout>
  );
}

export default CreateNewGoalPage;
