import React, { useState, useEffect } from 'react';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { getFirestore, collection, addDoc, getDocs } from 'firebase/firestore';
import { app2 } from '../firebase-config';
import { getAuth } from 'firebase/auth';


function ExercisesComponent({ onExerciseSelected }) {
  const [exercises, setExercises] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedExercise, setSelectedExercise] = useState(null);

  const db = getFirestore(app2);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchExercises = async () => {
      setIsLoading(true);
      const querySnapshot = await getDocs(collection(db, "exercises"));
      const fetchedExercises = [];
      querySnapshot.forEach((doc) => {
        fetchedExercises.push({ id: doc.id, exerciseName: doc.data().exerciseName });
      });
      setExercises(fetchedExercises);
      setIsLoading(false);
    };

    fetchExercises();
  }, []);



  const capitalizeWords = (str) => str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

  const normalizeText = (text) => text.toLowerCase().replace(/[\W_]+/g, "");

  const filteredExercises = exercises.filter(exercise =>
    exercise.exerciseName && normalizeText(exercise.exerciseName).includes(normalizeText(searchQuery))
  );


  const handleExerciseSelect = (id) => {
    setSelectedExercise(id === selectedExercise ? null : id);
  };

  const handleRightArrowClick = () => {
    if (selectedExercise) {
      const selectedExerciseDetail = exercises.find(exercise => exercise.id === selectedExercise);
      onExerciseSelected(selectedExerciseDetail);
    }
  };

  const handleAddNewExercise = async () => {
    const capitalizedExerciseName = capitalizeWords(searchQuery);
    if (!capitalizedExerciseName.trim()) {
      console.log("Exercise name cannot be empty.");
      return;
    }

    // Accessing the current user from Firebase Authentication
    const auth = getAuth(app2); // Make sure to pass your Firebase app instance if needed
    const user = auth.currentUser;

    if (!user) {
      console.log("No user logged in");
      return; // Exit the function if there's no logged-in user
    }

    // User's email
    const userEmail = user.email;
    // Current timestamp
    const addedOn = new Date();

    try {
      const docRef = await addDoc(collection(db, "exercises"), {
        exerciseName: capitalizedExerciseName,
        addedByEmail: userEmail, // Include the user's email
        addedOn: addedOn // Include the current timestamp
      });
      console.log(`${capitalizedExerciseName} added to the list with ID: ${docRef.id}`);

      const newExercise = {
        id: docRef.id,
        exerciseName: capitalizedExerciseName
      };

      setExercises(prevExercises => [...prevExercises, newExercise]);


      setSearchQuery("");

      setSelectedExercise(docRef.id);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const isExactMatch = exercises.some(exercise => normalizeText(exercise.exerciseName) === normalizeText(searchQuery));



  return (

    <div className="px-4 sm:px-6 lg:px-8 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
      <div className="flex justify-between mb-4">
        <input
          type="text"
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
          placeholder="Search for an exercise..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button
          onClick={handleRightArrowClick}
          className={`ml-4 flex-shrink-0 inline-flex items-center justify-center p-2 rounded-full ${selectedExercise ? 'bg-fitmania-green text-black hover:bg-fitmania-green-dark' : 'bg-gray-300 text-white'} focus:outline-none focus:ring-2 focus:ring-offset-2 ${selectedExercise ? 'focus:ring-fitmania-green' : 'focus:ring-gray-300'}`}
          disabled={!selectedExercise}
        >
          <ArrowRightIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-4 flow-root ">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 max-h-[400px] overflow-y-auto">
            <table className="min-w-full">
              <thead className="">

              </thead>
              <tbody className="bg-white divide-y divide-gray-100">
                {searchQuery.trim() && !isExactMatch && (
                  <tr onClick={handleAddNewExercise} style={{ cursor: 'pointer' }} className="hover:bg-gray-100">
                    <td className="px-2 py-4 whitespace-nowrap text-md text-fitmania-green-dark">Click here to add "{capitalizeWords(searchQuery)}" to the list.</td>
                  </tr>
                )}
                {filteredExercises.map((exercise) => (
                  <tr
                    key={exercise.id}
                    className={`${exercise.id === selectedExercise ? 'bg-fitmania-green' : 'hover:bg-gray-100'}`}
                    onClick={() => handleExerciseSelect(exercise.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    <td className="px-2 py-4 whitespace-nowrap text-md text-black">{exercise.exerciseName}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  );
}

export default ExercisesComponent;
