import React, { useEffect, useState } from 'react';
import Layout from './Layout';
import { useNavigate, Link } from 'react-router-dom';
import GoalGaugeComponent from './GoalGaugeComponent';
import ActivityCard from './ActivityCard'; // Ensure this is imported correctly
import { getFirestore, doc, getDoc, collection, query, getDocs, orderBy, where, limit } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { app2 } from '../firebase-config';
import ShareProfileComponent from './ShareProfileComponent';
import WorkoutCard from './WorkoutCard'; // Ensure this is imported correctly
import ProfilePictureComponent from './ProfilePictureComponent';

function ProfilePage() {
    const navigate = useNavigate();
    const [recentActivities, setRecentActivities] = useState([]);
    const [myWorkouts, setMyWorkouts] = useState([]);
    const [profilePicture, setProfilePicture] = useState(null);
    const [joinedDate, setJoinedDate] = useState('');
    const [bio, setBio] = useState('');
    const db = getFirestore(app2);
    const auth = getAuth(app2);
    const [location, setLocation] = useState({ city: '', state: '', country: '' });
    const [interests, setInterests] = useState([]);  // State to store user's interests
    const [completedActivitiesCount, setCompletedActivitiesCount] = useState(0);  // State to store the count of activities
    const [streak, setStreak] = useState(0);
    const [username, setUsername] = useState('');
    const [fullName, setFullName] = useState({ firstName: '', lastName: '' });
    const [userId, setUserId] = useState('');

    useEffect(() => {
        const fetchProfileData = async () => {
            const currentUser = auth.currentUser;
            if (!currentUser) {
                navigate('/signin');
                return;
            }
            setUserId(currentUser.uid);

            const publicProfileRef = doc(db, `publicProfiles/${currentUser.uid}`);
            const publicProfileDoc = await getDoc(publicProfileRef);
            if (publicProfileDoc.exists()) {
                const publicProfileData = publicProfileDoc.data();
                setProfilePicture(publicProfileData.profilePicture);
                setUsername(publicProfileData.userName || '@username');
                setFullName({
                    firstName: publicProfileData.firstName || '',
                    lastName: publicProfileData.lastName || '',
                });
                setBio(publicProfileData.bio || '');
                if (publicProfileData.dateJoined) {
                    const joinedDate = new Date(publicProfileData.dateJoined).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
                    setJoinedDate(joinedDate);
                }
                setLocation({
                    city: publicProfileData.selectedCity || '',
                    state: publicProfileData.selectedState || '',
                    country: publicProfileData.selectedCountry || ''
                });
                setInterests(publicProfileData.interests || []);
            }
        };

        fetchProfileData();
    }, [auth.currentUser, navigate, db]);

    useEffect(() => {
        const fetchActivitiesAndWorkouts = async () => {
            const currentUser = auth.currentUser;
            if (!currentUser) {
                setRecentActivities([]);
                setMyWorkouts([]);
                setProfilePicture(null);
                return;
            }

            // Fetch user profile picture
            const publicProfileDoc = await getDoc(doc(db, `publicProfiles/${currentUser.uid}`));
            if (publicProfileDoc.exists() && publicProfileDoc.data().profilePicture) {
                setProfilePicture(publicProfileDoc.data().profilePicture);
            }

            // Fetch the total count of all activities (public and private)
            const workoutsCompletedRef = collection(db, `users/${currentUser.uid}/workoutsCompleted`);
            const totalActivitiesSnapshot = await getDocs(workoutsCompletedRef);
            setCompletedActivitiesCount(totalActivitiesSnapshot.docs.length);  // Set the total count of completed activities

            // Fetch the latest 10 public activities (both copied and non-copied)
            const recentPublicActivitiesQuery = query(
                workoutsCompletedRef,
                where('visibility', '==', 'Public'),
                orderBy("completedAt", "desc"),
                limit(10)
            );
            const recentPublicActivitiesSnapshot = await getDocs(recentPublicActivitiesQuery);

            const activitiesPromises = recentPublicActivitiesSnapshot.docs.map(async (docSnap) => {
                const completedData = docSnap.data();
                const workoutRefPath = completedData.copied ? `users/${currentUser.uid}/copiedWorkouts` : `users/${currentUser.uid}/workouts`;
                const workoutRef = doc(db, workoutRefPath, completedData.workoutId);
                const workoutSnapshot = await getDoc(workoutRef);

                if (workoutSnapshot.exists()) {
                    return {
                        id: docSnap.id,
                        workoutId: completedData.workoutId,
                        name: workoutSnapshot.data().name,
                        coverImage: workoutSnapshot.data().coverImage,
                        completedAt: completedData.completedAt.toDate().toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: '2-digit',
                            hour12: true
                        }),
                        copied: completedData.copied
                    };
                } else {
                    return null;
                }
            });

            // Fetch workouts and filter for visibility
            const workoutsRef = collection(db, `users/${currentUser.uid}/workouts`);
            const workoutsDocs = await getDocs(workoutsRef);
            const workouts = workoutsDocs.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            })).filter(workout => workout.visibility === 'Public'); // Only include Public workouts

            const fetchedActivities = (await Promise.all(activitiesPromises)).filter(Boolean);
            setRecentActivities(fetchedActivities);
            setMyWorkouts(workouts); // Set filtered workouts

            // Calculate current streak
            const activityDates = fetchedActivities.map(activity => new Date(activity.completedAt.split(",")[0])); // Extract dates from activities
            activityDates.sort((a, b) => a - b); // Sort dates in ascending order

            let currentStreak = 0;
            let previousDate = null;

            activityDates.forEach(date => {
                if (previousDate) {
                    const diffTime = Math.abs(date - previousDate);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                    if (diffDays === 1) {
                        currentStreak += 1;
                    } else {
                        currentStreak = 0;
                    }
                } else {
                    currentStreak = 1;
                }
                previousDate = date;
            });

            // Check if the last activity was today or yesterday to maintain the streak
            const today = new Date();
            const lastActivityDate = activityDates[activityDates.length - 1];
            const diffTimeLastActivity = Math.abs(today - lastActivityDate);
            const diffDaysLastActivity = Math.ceil(diffTimeLastActivity / (1000 * 60 * 60 * 24));

            if (diffDaysLastActivity > 1) {
                currentStreak = 0;
            }

            setStreak(currentStreak);
        };

        fetchActivitiesAndWorkouts();
    }, [auth.currentUser, db]);

    return (
        <Layout>
            <div className="max-w-5xl mx-auto">
                <div className="px-4 py-8 bg-white shadow rounded-lg overflow-hidden sm:p-8 mb-4">

                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end gap-4 relative">
                        <div className="flex flex-col sm:flex-row gap-4 sm:gap-8 items-start sm:items-center">
                            <ProfilePictureComponent userId={userId} className="w-24 h-24" />
                            <div className="flex flex-col gap-1">
                                <h1 className="text-base sm:text-lg font-semibold flex items-center">{fullName.firstName} {fullName.lastName}</h1>
                                <span className="text-sm sm:text-base">@{username}</span>
                                <span className="text-sm sm:text-base">Joined {joinedDate || 'Loading...'}</span> {/* Display joined date */}
                                <span className="text-sm sm:text-base">{location.city}, {location.state} ({location.country})</span>
                            </div>
                        </div>
                        <div className="flex gap-2 w-full sm:w-auto">
                            <button
                                className="bg-fitmania-green px-4 py-2 rounded-lg hover:bg-fitmania-green-dark w-1/2 sm:w-auto text-sm sm:text-base "
                                onClick={() => navigate('/edit-profile')}
                            >
                                Edit profile
                            </button>
                            <ShareProfileComponent 
                                baseUrl={`${window.location.origin}/profile`} 
                                itemId={userId} 
                                firstName={fullName.firstName} 
                                lastName={fullName.lastName} 
                                userName={username} 
                            />
                        </div>

                        <div className="absolute top-6 sm:top-4 right-8 sm:right-0 flex gap-8">
                            <Link to="/activity" className="flex flex-col md:flex-row gap-0 sm:gap-1 items-center sm:items-baseline">
                                <span className="text-base sm:text-lg font-semibold">{completedActivitiesCount}</span>
                                <span className="text-sm sm:text-base">activity</span>
                            </Link>
                            <Link to="/calendar" className="flex flex-col md:flex-row gap-0 sm:gap-1 items-center sm:items-baseline">
                                <span className="text-base sm:text-lg font-semibold">{streak}</span>
                                <span className="text-sm sm:text-base">streak</span>
                            </Link>
                            <div className="flex flex-col md:flex-row gap-0 sm:gap-1 items-center sm:items-baseline">
                                <span className="text-base sm:text-lg font-semibold">0</span>
                                <span className="text-sm sm:text-base">followers</span>
                            </div>
                        </div>
                    </div>

                    <hr className="mt-4 mb-4"></hr>
                    {bio && (
                        <div>
                            <h1 className="text-sm sm:text-base font-semibold mb-2">Bio</h1>
                            <span className="text-sm sm:text-base" style={{ whiteSpace: 'pre-line' }}>{bio}</span> {/* Display bio from the database */}
                            <hr className="mt-4 mb-4"></hr>
                        </div>
                    )}
                    
                    <h1 className="text-sm sm:text-base font-semibold mb-4">Interests</h1>
                    <div className="flex flex-row flex-nowrap overflow-x-auto gap-2 items-start pb-2" style={{ scrollbarWidth: 'thin' }}>
                        {interests.map(interest => (
                            <div key={interest} className="px-2 py-1 bg-gray-200 flex-shrink-0 rounded-lg text-sm">{interest}</div>
                        ))}
                    </div>
                    <hr className="mt-4 mb-4"></hr>
                    <div className="mb-2">
                        <h1 className="text-sm sm:text-base font-semibold">Goals</h1>
                        <div className="flex text-sm sm:text-base text-center">
                            <GoalGaugeComponent visibility="public" />
                        </div>
                    </div>
                    <hr className="mt-4 mb-4"></hr>
                    <h1 className="text-sm sm:text-base font-semibold mb-2">Recent Activity</h1>
                    {recentActivities.length > 0 ? (
                        <div className="flex flex-nowrap overflow-x-auto -mx-2" style={{ scrollbarWidth: 'thin' }}>
                            {recentActivities.map((activity) => (
                                <div key={activity.id} className="flex-shrink-0">
                                    <ActivityCard activity={activity} copied={activity.copied} />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p>No workouts have been completed. You can <Link to="/workouts" className="text-green-600 hover:text-green-700">create a workout here</Link>, or <Link to="#" className="text-green-600 hover:text-green-700">find a workout here</Link>.</p>
                    )}
                    <hr className="mt-4 mb-4"></hr>
                    <h1 className="text-sm sm:text-base font-semibold mb-2">My Workouts</h1>
                    {myWorkouts.length > 0 ? (
                        <div className="flex flex-nowrap overflow-x-auto -mx-2" style={{ scrollbarWidth: 'thin' }}>
                            {myWorkouts.map((workout) => (
                                <div className="flex-shrink-0">
                                    <WorkoutCard
                                        key={workout.id}
                                        workout={workout} />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p>No saved workouts. Start by creating a workout or program <Link to="/workouts" className="text-green-600 hover:text-green-700">here </Link>.</p>
                    )}
                    <hr className="mt-4 mb-4"></hr>
                    <h1 className="text-sm sm:text-base font-semibold mb-2">Statistics</h1>
                    <p>Complete an activity to start viewing statistics.</p>
                    <hr className="mt-4 mb-4"></hr>
                    <h1 className="text-sm sm:text-base font-semibold mb-2">Achievements</h1>
                    <p>No achievements.</p>
                </div>
            </div>
        </Layout>
    );
}

export default ProfilePage;
