import React, { useState, useEffect } from 'react';

function TimerModalTF({ isOpen, close, onSave }) {
    const [isRunning, setIsRunning] = useState(false);
    const [timerCountdown, setTimerCountdown] = useState(0);

    useEffect(() => {
        let intervalId;

        if (isRunning) {
            intervalId = setInterval(() => {
                setTimerCountdown(prevCountdown => prevCountdown + 10); // Update in increments of 10 ms
            }, 10); // Every 10 milliseconds
        }

        return () => clearInterval(intervalId);
    }, [isRunning]);

    const toggleTimer = () => {
        setIsRunning(!isRunning);
    };

    const resetTimer = () => {
        setTimerCountdown(0);
        setIsRunning(false); // Optionally stop the timer on reset
    };

    const handleOverlayClick = (event) => {
        if (event.target === event.currentTarget) {
            close();
        }
    };

    const handleSave = () => {
        onSave(formatCountdown(timerCountdown)); // Call onSave prop passed from parent
        close(); // Close modal after saving
    };

    const activeButtonClass = "mt-8 text-xl font-normal text-white bg-black rounded-full py-2 px-4";
    const dimButtonClass = "mt-8 text-xl font-normal text-gray-400 bg-black rounded-full py-2 px-4 opacity-50";

    return (
        <div className="fixed inset-0 bg-blue-400 flex items-center justify-center z-50 overflow-hidden" onClick={handleOverlayClick}>
            <div className="w-full h-full bg-blue-400 flex flex-col" onClick={e => e.stopPropagation()}>
                <div className="flex-grow flex flex-col items-center justify-center p-8">
                    <h1 className="text-5xl mb-4">Timer:</h1>
                    <h1 className="text-8xl mb-4">{formatCountdown(timerCountdown)}</h1>
                    <p className="text-2xl">Time Elapsed</p>
                    <div className="flex justify-center w-full">
                        <button onClick={toggleTimer} className={activeButtonClass}>
                            {isRunning ? 'Stop' : 'Start'}
                        </button>
                        <div style={{ width: '10px' }}></div>
                        <button onClick={resetTimer} className={timerCountdown === 0 ? dimButtonClass : activeButtonClass} disabled={timerCountdown === 0}>
                            Reset
                        </button>
                        <div style={{ width: '10px' }}></div>
                        <button
                            className={isRunning || timerCountdown === 0 ? dimButtonClass : activeButtonClass} disabled={isRunning || timerCountdown === 0}
                            onClick={handleSave}
                        >
                            Save
                        </button>
                        <div style={{ width: '10px' }}></div>
                        <button onClick={close} className={activeButtonClass}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TimerModalTF;

const formatCountdown = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const ms = Math.floor((milliseconds % 1000) / 10); // Show two digits for milliseconds
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}:${ms < 10 ? '0' : ''}${ms}`;
};
