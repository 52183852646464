// ConfirmDialog.js
import React from 'react';

function ConfirmDialog({ title, message, onConfirm, onCancel, confirmText, cancelText }) {
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="bg-white rounded shadow-lg p-5 max-w-xl">
        <h2 className="text-lg font-medium">{title}</h2>
        <p className="mt-2" dangerouslySetInnerHTML={{ __html: message }}></p>
        <div className="flex justify-end mt-8 space-x-2">
          <button 
            onClick={onCancel} 
            className="w-1/2 px-4 py-2 border bg-gray-200 rounded hover:bg-gray-300"
          >
            {cancelText}
          </button>
          <button 
            onClick={onConfirm} 
            className="w-1/2 px-4 py-2 text-red-500 bg-gray-200 rounded hover:bg-gray-300"
          >
            {confirmText}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmDialog;
