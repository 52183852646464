import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import { getFirestore, doc, getDoc, collection, query, where, getDocs, addDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { app2 } from '../firebase-config';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import useFullscreenMedia from './useFullscreenMedia';
import ShareWorkoutComponent from './ShareWorkoutComponent';
import ProfilePictureComponent from './ProfilePictureComponent';
import { onAuthStateChanged } from 'firebase/auth';

function PublicWorkoutPage() {
    const [coverImageUrl, setCoverImageUrl] = useState('');
    const [workoutName, setWorkoutName] = useState('');
    const [workoutDescription, setWorkoutDescription] = useState('');
    const [exercises, setExercises] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState({});
    const [areAllExercisesOpen, setAreAllExercisesOpen] = useState(false);
    const [isImageFullscreen, setIsImageFullscreen] = useState(false);
    const [creatorFirstName, setCreatorFirstName] = useState('');
    const [creatorProfilePic, setCreatorProfilePic] = useState('');
    const [creatorUserId, setCreatorUserId] = useState('');
    const [currentUser, setCurrentUser] = useState(null);
    const [alreadyCopied, setAlreadyCopied] = useState(false);

    const { username, workoutId } = useParams();
    const db = getFirestore(app2);
    const navigate = useNavigate();
    const { requestImageFullscreen, requestVideoFullscreen } = useFullscreenMedia();

    useEffect(() => {
        const auth = getAuth(app2);
        const fetchWorkoutDetails = async () => {
            const userQuery = query(collection(db, 'publicProfiles'), where('userName', '==', username));
            const userSnapshot = await getDocs(userQuery);
            if (!userSnapshot.empty) {
                const userDoc = userSnapshot.docs[0];
                const userId = userDoc.id;
                
                const workoutRef = doc(db, 'users', userId, 'workouts', workoutId);
                const workoutSnap = await getDoc(workoutRef);

                if (workoutSnap.exists()) {
                    const workoutData = workoutSnap.data();
                    setCoverImageUrl(workoutData.coverImage || '');
                    setWorkoutName(workoutData.name || '');
                    setWorkoutDescription(workoutData.description || '');
                    setExercises(workoutData.exercises || []);

                    const dropdownStates = workoutData.exercises.reduce((acc, exercise, index) => {
                        acc[index] = false;
                        return acc;
                    }, {});
                    setIsDropdownOpen(dropdownStates);

                    if (workoutData.createdBy) {
                        const creatorRef = doc(db, 'publicProfiles', workoutData.createdBy);
                        const creatorSnap = await getDoc(creatorRef);
                        if (creatorSnap.exists()) {
                            const creatorData = creatorSnap.data();
                            setCreatorFirstName(creatorData.firstName || '');
                            setCreatorProfilePic(creatorData.profilePicture || '');
                            setCreatorUserId(workoutData.createdBy);
                        } else {
                            console.log("Creator's public profile document not found");
                        }
                    }
                } else {
                    console.log("No such document!");
                }
            } else {
                console.log("No such user!");
            }
        };

        const checkAuthState = () => {
            onAuthStateChanged(auth, async (user) => {
                if (user) {
                    setCurrentUser(user);
                    const copiedWorkoutsRef = collection(db, 'users', user.uid, 'copiedWorkouts');
                    const q = query(copiedWorkoutsRef, where('originalWorkoutId', '==', workoutId));
                    const querySnapshot = await getDocs(q);
                    if (!querySnapshot.empty) {
                        setAlreadyCopied(true);
                    }
                }
            });
        };

        if (workoutId) {
            fetchWorkoutDetails();
        } else {
            console.log("workoutId is missing.");
        }

        checkAuthState();
    }, [username, workoutId, db]);

    const toggleDropdown = (exerciseIndex) => {
        setIsDropdownOpen({
            ...isDropdownOpen,
            [exerciseIndex]: !isDropdownOpen[exerciseIndex]
        });
    };

    const toggleAllExerciseDropdowns = () => {
        setAreAllExercisesOpen(!areAllExercisesOpen);
        setIsDropdownOpen(exercises.reduce((acc, _, index) => {
            acc[index] = !areAllExercisesOpen;
            return acc;
        }, {}));
    };

    const toggleImageFullscreen = () => {
        setIsImageFullscreen(!isImageFullscreen);
    };

    const handleCopyWorkout = async () => {
        const auth = getAuth(app2);
        const currentUser = auth.currentUser;

        if (!currentUser) {
            console.error("No user is logged in.");
            return;
        }

        try {
            const copiedWorkout = {
                coverImage: coverImageUrl,
                name: workoutName,
                description: workoutDescription,
                exercises: exercises,
                createdBy: creatorUserId,
                timestamp: new Date().toISOString(),
                visibility: 'Public', // Or any default value you prefer
                originalWorkoutId: workoutId // Save the original workout ID
            };

            const copiedWorkoutsRef = collection(db, 'users', currentUser.uid, 'copiedWorkouts');
            await addDoc(copiedWorkoutsRef, copiedWorkout);

            console.log("Workout copied successfully.");
            navigate('/workouts'); // Redirect user to the dashboard or any other desired page
        } catch (error) {
            console.error("Error copying workout:", error);
        }
    };

    const handleCreatorClick = () => {
        navigate(`/profile/${username}`);
    };

    return (
        <Layout>
            <div className="mt-5 w-full max-w-xl">
                <div className="flex justify-between mb-4 sm:p-0 pl-4 pt-4 pr-4">
                    <button onClick={() => navigate(-1)} style={{ color: 'white', fontSize: '18px', background: 'none', border: 'none' }}>
                        <FontAwesomeIcon icon={faChevronLeft} style={{ color: 'black' }} />
                    </button>
                    <div>
                        <button
                            onClick={handleCopyWorkout}
                            type="button"
                            className={`py-2 px-4 rounded focus:outline-none focus:shadow-outline ${currentUser && currentUser.uid === creatorUserId || alreadyCopied
                                    ? 'bg-gray-300 text-gray-500'
                                    : 'bg-fitmania-green text-black hover:bg-fitmania-green-dark'
                                }`}
                            disabled={currentUser && currentUser.uid === creatorUserId || alreadyCopied}
                        >
                            {alreadyCopied ? 'Already Copied' : 'Copy Workout'}
                        </button>
                    </div>
                </div>

                <div className="bg-white px-8 py-8 shadow rounded-lg overflow-hidden">
                    <div className="flex justify-between items-start">
                        <div className="flex items-center mb-4">
                            {coverImageUrl ? (
                                <img src={coverImageUrl} alt="Cover" className="h-40 bg-gray-100 border text-gray-900 rounded-md cursor-pointer" onClick={toggleImageFullscreen} />
                            ) : (
                                <div className="h-40 bg-gray-100 border text-gray-900 rounded-md flex items-center justify-center">Loading image...</div>
                            )}
                        </div>
                        <ShareWorkoutComponent baseUrl={`${window.location.origin}`} workoutId={workoutId} username={username} />
                    </div>

                    {isImageFullscreen && (
                        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={toggleImageFullscreen}>
                            <img src={coverImageUrl} alt="Fullscreen Cover" className="max-w-full max-h-full" />
                        </div>
                    )}

                    <div>
                        <p className="text-base font-semibold pt-2 pr-2">Name:</p>
                        <p className="text-left mt-2">{workoutName || 'Loading name...'}</p>
                        <hr className="mt-4 mb-4" />
                    </div>

                    <div>
                        <p className="text-base font-semibold pt-2 pr-2">Description:</p>
                        <p className="text-left mt-2">{workoutDescription || 'Loading description...'}</p>
                        <hr className="mt-4 mb-4" />
                    </div>

                    <div className="flex justify-between items-center mt-4 mb-4">
                        <div className="text-left text-base font-semibold">Exercises</div>
                        {exercises.length > 1 && (
                            <button
                                onClick={toggleAllExerciseDropdowns}
                                className="bg-gray-200 p-2 rounded-full shadow inline-flex items-center justify-center"
                            >
                                <FontAwesomeIcon icon={areAllExercisesOpen ? faChevronUp : faChevronDown} className="text-gray-800" />
                            </button>
                        )}
                    </div>

                    {exercises.map((exercise, exerciseIndex) => (
                        <div key={exerciseIndex} className="mb-2">
                            <div className="flex items-start">
                                <div className="flex flex-col flex-1">
                                    <button
                                        onClick={() => toggleDropdown(exerciseIndex)}
                                        className="flex items-center justify-between w-full text-left text-lg font-medium text-gray-900 focus:outline-none p-4 rounded-md bg-gray-200"
                                    >
                                        <div className="flex flex-col">
                                            <h1 className="text-base">
                                                {exercise.name}
                                            </h1>
                                            <span className="font-normal text-sm">
                                                {exercise.sets ? `${exercise.sets.length} sets` : 'No sets'}
                                            </span>
                                        </div>
                                        <FontAwesomeIcon icon={isDropdownOpen[exerciseIndex] ? faChevronUp : faChevronDown} className="ml-2" />
                                    </button>
                                    {isDropdownOpen[exerciseIndex] && (
                                        <>
                                            {exercise.mediaItems && exercise.mediaItems.length > 0 && (
                                                <div className="flex items-center space-x-4 mt-2 overflow-auto" style={{ scrollbarWidth: 'thin' }}>
                                                    {exercise.mediaItems.map((mediaItem, index) => (
                                                        <div key={index} className="flex-shrink-0 mb-2 relative">
                                                            {mediaItem.type === 'image' ? (
                                                                <img
                                                                    src={mediaItem.url}
                                                                    alt={`Exercise Media ${index + 1}`}
                                                                    className="h-40 w-auto object-cover rounded cursor-pointer"
                                                                    onClick={() => requestImageFullscreen(mediaItem.url)}
                                                                />
                                                            ) : (
                                                                <video
                                                                    src={mediaItem.url}
                                                                    autoPlay
                                                                    playsInline
                                                                    muted
                                                                    preload
                                                                    controls
                                                                    loop
                                                                    className="h-40 w-auto rounded cursor-pointer"
                                                                    onClick={(e) => requestVideoFullscreen(e.currentTarget)}
                                                                />
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                            {exercise.note && (
                                                <div className="border border-gray rounded-md p-4 mt-2">
                                                    <div className="flex justify-between items-center">
                                                        <p className="text-base">{exercise.note}</p>
                                                    </div>
                                                </div>
                                            )}

                                            {exercise.sets.map((set, setIndex) => (
                                                <div key={setIndex} className="border border-black rounded-md p-2 mt-2 mb-2">
                                                    <div className="border border-gray rounded-md p-4 mb-2">
                                                        <div className="flex justify-between items-center mb-4">
                                                            <p className="text-left text-base font-semibold text-black">Set {setIndex + 1}:</p>
                                                        </div>

                                                        {set.repetitions && (
                                                            <div className="flex justify-between items-center mb-2">
                                                                <span className="text-gray-900 text-base">Repetitions:</span>
                                                                <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: 'auto' }}>
                                                                    {set.repetitions}
                                                                </p>
                                                            </div>
                                                        )}
                                                        {set.resistance && set.resistance.units && set.resistance.type && (
                                                            <div className="flex justify-between items-center mb-2">
                                                                <span className="text-gray-900 text-base">Resistance:</span>
                                                                <div className="flex items-center">
                                                                    <p className="text-sm text-center text-black bg-gray-100 rounded px-1 py-1  mr-2">
                                                                        {set.resistance.units}
                                                                    </p>
                                                                    <p className="text-sm text-center text-black bg-gray-100 rounded px-1 py-1  mr-2">
                                                                        {set.resistance.type}
                                                                    </p>
                                                                    <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: '38px' }}>
                                                                        {set.resistance.value}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {set.timer && (
                                                            <div className="flex justify-between items-center mb-2">
                                                                <span className="text-gray-900 text-base">Timer:</span>
                                                                <div className="flex items-center">
                                                                    <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: 'auto' }}>
                                                                        {set.timer}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {set.distance && set.distance.units && set.distance.value && (
                                                            <div className="flex justify-between items-center mb-2">
                                                                <span className="text-gray-900 text-base">Distance:</span>
                                                                <div className="flex items-center">
                                                                    <p className="text-sm text-center text-black bg-gray-100 rounded px-1 py-1 mr-2">
                                                                        {set.distance.units}
                                                                    </p>
                                                                    <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: 'auto' }}>
                                                                        {set.distance.value}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}

                                                    </div>

                                                    {set.rest && (
                                                        <div className="border border-gray rounded-md p-4 mt-2">
                                                            <div className="flex justify-between items-center">
                                                                <span className="text-gray-900 text-base">Rest:</span>
                                                                <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: 'auto' }}>
                                                                    {set.rest}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {set.note && (
                                                        <div className="border border-gray rounded-md p-4 py-6 mt-2">
                                                            <div className="flex justify-between items-center">
                                                                <p className="text-base">{set.note}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                    <hr className="mt-4 mb-4" />
                    <div className="flex items-center justify-center cursor-pointer" onClick={handleCreatorClick}>
                        <ProfilePictureComponent userId={creatorUserId} className="w-10 h-10 rounded-full" />
                        <p className="ml-2">Created by {creatorFirstName}</p>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default PublicWorkoutPage;
