import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { doc, getDoc, setDoc, updateDoc, getFirestore } from 'firebase/firestore';
import { auth } from '../firebase-config';
import { app2 } from '../firebase-config';

export default function SignUpPage() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    });
    const [referralCode, setReferralCode] = useState('');
    const [isReferralValid, setIsReferralValid] = useState(false);
    const [referralError, setReferralError] = useState('');

    const navigate = useNavigate();
    const db = getFirestore(app2);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleReferralChange = (e) => {
        setReferralCode(e.target.value);
        setReferralError(''); // Clear error message when user starts typing
    };

    const handleReferralSubmit = async (e) => {
        e.preventDefault();
        try {
            const docRef = doc(db, 'referralCodes', referralCode);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists() && !docSnap.data().activated) {
                setIsReferralValid(true);
                setReferralCode(referralCode); // Keep the referral code for later use
            } else {
                setReferralError('Invalid or already used referral code');
            }
        } catch (error) {
            console.error('Error validating referral code:', error);
            setReferralError('Error validating referral code');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { firstName, lastName, email, password } = formData;

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            await sendEmailVerification(user);

            // Here we get the current date to store it as the date the user joined
            const dateJoined = new Date().toISOString();

            // Add user document with referral code
            await setDoc(doc(db, "users", user.uid), {
                firstName,
                lastName,
                dateJoined,
                email,
                referralCode, // Store the used referral code
                firstNameLower: firstName.toLowerCase(),
                lastNameLower: lastName.toLowerCase(),
                fullNameLower: `${firstName.toLowerCase()} ${lastName.toLowerCase()}`
            });

            // Create a document in the publicProfiles collection
            await setDoc(doc(db, "publicProfiles", user.uid), {
                firstName,
                lastName,
                dateJoined,
                firstNameLower: firstName.toLowerCase(),
                lastNameLower: lastName.toLowerCase(),
                fullNameLower: `${firstName.toLowerCase()} ${lastName.toLowerCase()}`
            });

            // Update the referral code to mark it as used and add the referee's name
            const referralDocRef = doc(db, 'referralCodes', referralCode);
            await updateDoc(referralDocRef, {
                activated: true,
                referee: `${firstName} ${lastName}`
            });

            navigate('/account-verification');
        } catch (error) {
            console.error("Error signing up:", error.message);
        }
    };

    return (
        <div className="flex min-h-screen bg-white items-center justify-center">
            <form onSubmit={isReferralValid ? handleSubmit : handleReferralSubmit} className="max-w-xs w-full space-y-8">
                <div className="max-w-sm w-full flex flex-col gap-4">
                    <Link to="/">
                        <img
                            className="mx-auto h-12 w-auto"
                            src={`${process.env.PUBLIC_URL}/images/Fitmania F Logo.png`}
                            alt="Fitmania Logo"
                        />
                    </Link>
                    <h2 className="mt-4 text-3xl font-bold text-gray-700 text-center">
                        Create an account
                    </h2>
                    
                    {!isReferralValid ? (
                        <>
                            <p className="mt-4 text-center text-gray-700">
                                Please enter your referral code:
                            </p>
                            <input
                                type="text"
                                name="referralCode"
                                id="referral-code"
                                value={referralCode}
                                onChange={handleReferralChange}
                                className="mt-4 pl-4 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-400 text-base sm:leading-6 outline-none"
                                placeholder="Referral code"
                            />
                            <button
                                type="submit"
                                className="w-full inline-flex justify-center rounded-md border border-transparent bg-fitmania-green px-4 py-3 text-sm font-medium text-black hover:bg-fitmania-green-dark focus:outline-none focus-visible:ring-2 focus-visible:ring-fitmania-green-dark focus-visible:ring-offset-2 outline-none"
                            >
                                Continue
                            </button>
                            {referralError && (
                                <p className="mt-2 text-center text-sm text-red-600">{referralError}</p>
                            )}
                        </>
                    ) : (
                        <>
                            <input
                                type="text"
                                name="firstName"
                                id="first-name"
                                value={formData.firstName}
                                onChange={handleChange}
                                className="mt-4 pl-4 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-400 text-base sm:leading-6 outline-none"
                                placeholder="First name"
                            />
                            <input
                                type="text"
                                name="lastName"
                                id="last-name"
                                value={formData.lastName}
                                onChange={handleChange}
                                className="pl-4 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-400 text-base sm:leading-6 outline-none"
                                placeholder="Last name"
                            />
                            <input
                                type="email"
                                name="email"
                                id="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="pl-4 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-400 text-base sm:leading-6 outline-none"
                                placeholder="Email address"
                            />
                            <input
                                type="password"
                                name="password"
                                id="password"
                                value={formData.password}
                                onChange={handleChange}
                                className="pl-4 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-400 text-base sm:leading-6 outline-none"
                                placeholder="Password"
                            />
                            <button
                                type="submit"
                                className="w-full inline-flex justify-center rounded-md border border-transparent bg-fitmania-green px-4 py-3 text-sm font-medium text-black hover:bg-fitmania-green-dark focus:outline-none focus-visible:ring-2 focus-visible:ring-fitmania-green-dark focus-visible:ring-offset-2 outline-none"
                            >
                                Sign up
                            </button>
                        </>
                    )}
                    {isReferralValid && (
                        <p className="mt-4 text-center text-sm text-gray-500">
                            Already a member?{' '}
                            <Link to="/signin" className="font-semibold leading-6 text-green-500 hover:text-green-600">
                                Sign in
                            </Link>
                        </p>
                    )}
                </div>
            </form>
        </div>
    );
}
