import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, query, getDocs, where } from 'firebase/firestore';
import { app2 } from '../firebase-config';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import MinimalGoalChartComponent from './MinimalGoalChartComponent';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import VisibilityDropdown from './VisibilityDropdown';

function GoalsPage() {
  const navigate = useNavigate();
  const db = getFirestore(app2);
  const [goalsData, setGoalsData] = useState([]);
  const [visibilityFilter, setVisibilityFilter] = useState('All');
  const auth = getAuth(app2); // Initialize auth here

  const formatGoalNameToURL = (goalName) => {
    return goalName.toLowerCase().replace(/\s+/g, '-');
  };

  useEffect(() => {
    const loadGoalsData = async (userId) => {
      let goalsQuery = query(collection(db, `users/${userId}/goals`));
      if (visibilityFilter !== 'All') {
        goalsQuery = query(goalsQuery, where('visibility', '==', visibilityFilter));
      }
      const querySnapshot = await getDocs(goalsQuery);
      const loadedGoalsData = querySnapshot.docs.map(doc => {
        const goal = doc.data();
        return {
          ...goal,
          id: doc.id,
          startDate: format(new Date(goal.startDate), 'PPP'),
          completionDate: format(new Date(goal.completionDate), 'PPP'),
          currentStatus: Number(goal.currentStatus),
          targetMilestone: Number(goal.targetMilestone),
          completionDateFormatted: goal.completionDate ? format(new Date(goal.completionDate), 'PPP') : 'No completion date' // Adding formatted completion date
        };
      });
      setGoalsData(loadedGoalsData);
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        loadGoalsData(user.uid);
      } else {
        setGoalsData([]);
        navigate('/signin');
      }
    });

    return () => unsubscribe();  // Clean up the subscription
  }, [auth, db, navigate, visibilityFilter]); // Add auth here to ensure it's captured in the dependency array

  return (
    <Layout>
      <div className="max-w-7xl mx-auto">
        <div className="sm:p-0 pl-4 pt-4 pr-4">
          <button className="bg-fitmania-green text-black px-4 py-2 my-4 mb-4 rounded-md hover:bg-fitmania-green-dark transition duration-150 ease-in-out " onClick={() => navigate('/goals/create-new-goal')}>
            Create New Goal
          </button>
        </div>
        <div className="bg-white px-6 py-6 shadow rounded-lg overflow-hidden mt-4 max-w-full flex flex-col gap-4">
          <div className="flex flex-col items-start mb-4">
            <h1 className="text-base sm:text-lg font-semibold mb-4">My Goals</h1>
            <VisibilityDropdown
              db={db}
              userId={auth.currentUser?.uid}
              collectionName="goals"
              onVisibilityChange={setVisibilityFilter}
            />
          </div>
          <div className="-mt-8 flex flex-row flex-nowrap gap-4 overflow-x-auto" style={{ scrollbarWidth: 'thin' }}>

            {goalsData.length > 0 ? (
              goalsData.map((goal) => (
                <div key={goal.id} className="min-w-[300px] sm:min-w-[500px] px-4 py-5 bg-white border rounded-lg overflow-hidden sm:p-6 mb-4 mt-6">
                  <Link to={`/goals/${formatGoalNameToURL(goal.goalName)}`}>
                    <button
                      type="button"
                      onClick={() => navigate('/goals/create-new-goal')}
                      className="sm:hidden inline-flex items-center mb-4 text-fitmania-green-dark bg-white border border-fitmania-green-dark px-4 py-2 text-md font-medium rounded-md shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fitmania-green"
                    >
                      + Check In
                    </button>
                    <div className="flex justify-between items-start gap-4">
                      <div className="flex max-w-full">
                        <h1 className="text-base sm:text-lg font-semibold cursor-pointer">{goal.goalName}</h1>
                      </div>
                      <div className="hidden sm:block">
                        <button
                          type="button"
                          onClick={() => navigate('/goals/create-new-goal')}
                          className="inline-flex items-center text-fitmania-green-dark bg-white border border-fitmania-green-dark px-4 py-2 text-md font-medium rounded-md shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fitmania-green"
                        >
                          + Check In
                        </button>
                      </div>
                    </div>
                    <p className="text-sm sm:text-base mb-2 mt-2 sm:mt-0 ">By: {goal.completionDateFormatted}</p>
                    <p className="text-sm sm:text-base mb-8">Starting: {goal.currentStatus} | Goal: {goal.targetMilestone}</p>
                    <MinimalGoalChartComponent goal={goal} className="border" />
                  </Link>
                </div>
              ))
            ) : (
              <p >No saved goals. Please create a goal to get started.</p>
            )}

          </div>
        </div>
      </div>
    </Layout>
  );
}

export default GoalsPage;
