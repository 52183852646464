import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import { getFirestore, doc, getDoc, deleteDoc } from 'firebase/firestore';
import { app2 as firebaseApp } from '../firebase-config';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronLeft, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { getAuth } from 'firebase/auth';
import { app2 } from '../firebase-config';
import { useNavigate } from 'react-router-dom';
import { getStorage, ref, deleteObject } from "firebase/storage";
import ConfirmDialog from './ConfirmDialog';
import useFullscreenMedia from './useFullscreenMedia';

function WorkoutPageEmbed() {
    const [coverImageUrl, setCoverImageUrl] = useState('');
    const [workoutName, setWorkoutName] = useState('');
    const [workoutDescription, setWorkoutDescription] = useState('');
    const [exercises, setExercises] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState({});
    const [areAllExercisesOpen, setAreAllExercisesOpen] = useState(false);
    const [isImageFullscreen, setIsImageFullscreen] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    const [fullScreenMedia, setFullScreenMedia] = useState({ url: '', type: '' });

    // Initialize the fullscreen media hook
    const { requestImageFullscreen, requestVideoFullscreen } = useFullscreenMedia();


    const navigate = useNavigate();

    const toggleImageFullscreen = () => {
        setIsImageFullscreen(!isImageFullscreen);
    };

    const { workoutId } = useParams();
    const auth = getAuth(app2);
    const db = getFirestore(app2);
    const storage = getStorage(app2);

    const toggleAllExerciseDropdowns = () => {
        setAreAllExercisesOpen(!areAllExercisesOpen);
        setIsDropdownOpen(exercises.reduce((acc, _, index) => {
            acc[index] = !areAllExercisesOpen;
            return acc;
        }, {}));
    };

    useEffect(() => {

        const fetchWorkoutDetails = async (userID) => {
            const db = getFirestore(firebaseApp);
            const docRef = doc(db, 'users', userID, 'workouts', workoutId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                setCoverImageUrl(data.coverImage);
                setWorkoutName(data.name);
                setWorkoutDescription(data.description);
                setExercises(data.exercises || []);

                const dropdownStates = data.exercises.reduce((acc, exercise, index) => {
                    acc[index] = false;
                    return acc;
                }, {});
                setIsDropdownOpen(dropdownStates);
            } else {
                console.log("No such document!");
            }
        };

        const currentUser = auth.currentUser;
        if (currentUser && workoutId) {
            fetchWorkoutDetails(currentUser.uid);
        } else {
            console.log("User is not signed in or workoutId is missing.");
        }

    }, [workoutId, auth]);

    const promptDeleteWorkout = () => {
        setShowConfirmDialog(true);
    };

    const deleteWorkoutAndImage = async () => {
        const currentUser = auth.currentUser;
        if (currentUser && workoutId) {
            try {
                await deleteDoc(doc(db, 'users', currentUser.uid, 'workouts', workoutId));

                if (coverImageUrl) {

                    const matches = coverImageUrl.match(/\/o\/([^?]+)/);
                    if (matches) {
                        const imagePath = decodeURIComponent(matches[1]);
                        const imageRef = ref(storage, imagePath);
                        await deleteObject(imageRef);
                    }
                }

                console.log('Workout and image deleted successfully');
                navigate('/workouts');
            } catch (error) {
                console.error("Error during workout and image deletion:", error);
            }
        }
        setShowConfirmDialog(false);
    };

    const cancelDeletion = () => {
        setShowConfirmDialog(false);
    };

    const toggleDropdown = (exerciseIndex) => {
        setIsDropdownOpen({
            ...isDropdownOpen,
            [exerciseIndex]: !isDropdownOpen[exerciseIndex]
        });
    };

    return (
        <>
            
            <div className="w-full max-w-xl">

                <div className="bg-white px-8 pb-8 overflow-hidden">

                    <div className="flex items-center mb-4">
                        {coverImageUrl ? (
                            <img src={coverImageUrl} alt="Cover" className="h-40 bg-gray-100 border text-gray-900 rounded-md cursor-pointer" onClick={toggleImageFullscreen} />
                        ) : (
                            <div className="h-40 bg-gray-100 border text-gray-900 rounded-md flex items-center justify-center">Loading image...</div>
                        )}
                    </div>


                    {isImageFullscreen && (
                        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={toggleImageFullscreen}>
                            <img src={coverImageUrl} alt="Fullscreen Cover" className="max-w-full max-h-full" />
                        </div>
                    )}

                    <div>
                        <p className="font-semibold pt-2 pr-2">Name:</p>
                        <p className="text-left mt-4">{workoutName || 'Loading name...'}</p>
                        <hr className="mt-4 mb-4" />
                    </div>

                    <div>
                        <p className="font-semibold pt-2 pr-2">Description:</p>
                        <p className="text-left mt-4">{workoutDescription || 'Loading description...'}</p>
                        <hr className="mt-4 mb-4" />
                    </div>

                    <div className="flex justify-between items-center mt-4 mb-4">
                        <div className="text-left text-lg font-semibold">Exercises</div>
                        {exercises.length > 1 && (
                            <button
                                onClick={toggleAllExerciseDropdowns}
                                className="bg-gray-200 p-2 rounded-full shadow inline-flex items-center justify-center"
                            >
                                <FontAwesomeIcon icon={areAllExercisesOpen ? faChevronUp : faChevronDown} className="text-gray-800" />
                            </button>
                        )}
                    </div>

                    {exercises.map((exercise, exerciseIndex) => (
                        <div key={exerciseIndex} className="mb-2">
                            <div className="flex items-start">
                                <div className="flex flex-col flex-1">
                                    <button
                                        onClick={() => toggleDropdown(exerciseIndex)}
                                        className="flex items-center justify-between w-full text-left text-lg font-medium text-gray-900 focus:outline-none p-4 rounded-md bg-gray-200"
                                    >
                                        {exercise.name}
                                        <FontAwesomeIcon icon={isDropdownOpen[exerciseIndex] ? faChevronUp : faChevronDown} className="ml-2" />
                                    </button>
                                    {isDropdownOpen[exerciseIndex] && (
                                        <>
                                            {exercise.mediaItems && exercise.mediaItems.length > 0 && (
                                                <div className="flex items-center space-x-4 mt-2 overflow-auto" style={{ scrollbarWidth: 'thin' }}>
                                                    {exercise.mediaItems.map((mediaItem, index) => (
                                                        <div key={index} className="flex-shrink-0 mb-2 relative">
                                                            {mediaItem.type === 'image' ? (
                                                                <img
                                                                    src={mediaItem.url}
                                                                    alt={`Exercise Media ${index + 1}`}
                                                                    className="h-40 w-auto object-cover rounded cursor-pointer"
                                                                    onClick={() => requestImageFullscreen(mediaItem.url)}
                                                                />
                                                            ) : (
                                                                <video
                                                                    src={mediaItem.url}
                                                                    autoPlay
                                                                    playsInline
                                                                    muted
                                                                    preload
                                                                    controls
                                                                    loop
                                                                    className="h-40 w-auto rounded cursor-pointer"
                                                                    onClick={(e) => requestVideoFullscreen(e.currentTarget)}
                                                                />
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                            {exercise.note && (
                                                <div className="border border-gray rounded-md p-4 mt-2">
                                                    <div className="flex justify-between items-center">
                                                        <p>{exercise.note}</p>
                                                    </div>
                                                </div>
                                            )}

                                            {exercise.sets.map((set, setIndex) => (
                                                <div key={setIndex} className="border border-black rounded-md p-2 mt-2 mb-2">
                                                    <div className="border border-gray rounded-md p-4 mb-2">
                                                        <div className="flex justify-between items-center mb-4">
                                                            <p className="text-left text-lg font-semibold text-black">Set {setIndex + 1}:</p>
                                                        </div>

                                                        {set.repetitions && (
                                                            <div className="flex justify-between items-center mb-2">
                                                                <span className="text-gray-900 text-lg">Repetitions:</span>
                                                                <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: 'auto' }}>
                                                                    {set.repetitions}
                                                                </p>
                                                            </div>
                                                        )}
                                                        {/* Removed set.resistance.value && from the end to allow for variable resistance input by end user */}
                                                        {set.resistance && set.resistance.units && set.resistance.type && (
                                                            <div className="flex justify-between items-center mb-2">
                                                                <span className="text-gray-900 text-lg">Resistance:</span>
                                                                <div className="flex items-center">
                                                                    <p className="text-center text-black bg-gray-100 rounded px-2 py-1  mr-2">
                                                                        {set.resistance.units}
                                                                    </p>
                                                                    <p className="text-center text-black bg-gray-100 rounded px-2 py-1  mr-2">
                                                                        {set.resistance.type}
                                                                    </p>
                                                                    <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: '38px' }}>
                                                                        {set.resistance.value}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {set.timer && (
                                                            <div className="flex justify-between items-center mb-2">
                                                                <span className="text-gray-900 text-lg">Timer:</span>
                                                                <div className="flex items-center">
                                                                    <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: 'auto' }}>
                                                                        {set.timer}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {set.distance && set.distance.units && set.distance.value && (
                                                            <div className="flex justify-between items-center mb-2">
                                                                <span className="text-gray-900 text-lg">Distance:</span>
                                                                <div className="flex items-center">
                                                                    <p className="text-center text-black bg-gray-100 rounded px-2 py-1 mr-2">
                                                                        {set.distance.units}
                                                                    </p>
                                                                    <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: 'auto' }}>
                                                                        {set.distance.value}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}

                                                    </div>

                                                    {set.rest && (
                                                        <div className="border border-gray rounded-md p-4 mt-2">
                                                            <div className="flex justify-between items-center">
                                                                <span className="text-gray-900 text-lg">Rest:</span>
                                                                <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: 'auto' }}>
                                                                    {set.rest}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {set.note && (
                                                        <div className="border border-gray rounded-md p-4 py-6 mt-2">
                                                            <div className="flex justify-between items-center">
                                                                <p>{set.note}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}

                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default WorkoutPageEmbed;
