import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { app2 } from '../firebase-config';
import Layout from './Layout';
import GoalChartComponent from './GoalChartComponent';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import ProfilePictureComponent from './ProfilePictureComponent';
import ShareGoalComponent from './ShareGoalComponent';

function PublicGoalPage() {
    const { username, goalId } = useParams();
    const [goalData, setGoalData] = useState(null);
    const [checkIns, setCheckIns] = useState([]);
    const db = getFirestore(app2);
    const navigate = useNavigate();
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const fetchUserIdByUsername = async (username) => {
            const usersCollection = collection(db, 'publicProfiles');
            const userQuery = query(usersCollection, where('userName', '==', username));
            const userSnapshot = await getDocs(userQuery);
            if (userSnapshot.empty) {
                console.error('No such user!');
                return null;
            }
            return userSnapshot.docs[0].id;
        };

        const fetchGoalData = async (uid) => {
            try {
                const goalDoc = doc(db, `users/${uid}/goals`, goalId);
                const goalSnapshot = await getDoc(goalDoc);

                if (goalSnapshot.exists()) {
                    const docData = goalSnapshot.data();
                    const auth = getAuth(app2);

                    // Check if the goal is public or if the user is authenticated
                    if (docData.visibility !== 'Public' && !auth.currentUser) {
                        throw new Error('Missing or insufficient permissions');
                    }
                    
                    const goal = {
                        id: goalSnapshot.id,
                        ...docData,
                        startDate: new Date(docData.startDate + 'T00:00').getTime(),
                        completionDate: new Date(docData.completionDate + 'T00:00').getTime(),
                        currentStatus: parseFloat(docData.currentStatus),
                        targetMilestone: parseFloat(docData.targetMilestone),
                        completionDateFormatted: format(new Date(docData.completionDate + 'T00:00'), 'PPP'),
                    };

                    setGoalData(goal);

                    const checkInsRef = collection(db, `users/${uid}/goals/${goalSnapshot.id}/checkIns`);
                    const checkInsSnapshot = await getDocs(checkInsRef);

                    let additionalCheckIns = checkInsSnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                        date: new Date(doc.data().date + 'T00:00').getTime(),
                    }));

                    additionalCheckIns.sort((a, b) => a.date - b.date);
                    additionalCheckIns = additionalCheckIns.map(ci => ({
                        ...ci,
                        date: format(new Date(ci.date), 'PPP'),
                    }));

                    const initialCheckIn = {
                        date: format(new Date(goal.startDate), 'PPP'),
                        status: goal.currentStatus.toString(),
                        note: 'Initial Check-In',
                        id: 'initial',
                    };

                    setCheckIns([initialCheckIn, ...additionalCheckIns]);
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error('Error fetching goal data:', error);
            }
        };

        if (username) {
            fetchUserIdByUsername(username)
                .then(uid => {
                    if (uid) {
                        setUserId(uid);
                        fetchGoalData(uid);
                    }
                });
        } else {
            console.log("username or goalId is missing.");
        }
    }, [username, goalId, db]);

    if (!goalData) {
        return <Layout>Loading...</Layout>;
    }

    return (
        <Layout>
            <div className="max-w-7xl mx-auto mt-4">
                <button
                    onClick={() => navigate(-1)}
                    style={{ color: 'white', fontSize: '18px', background: 'none', border: 'none' }}
                    className="sm:p-0 pl-4 pt-4 pr-4 mb-4"
                >
                    <FontAwesomeIcon icon={faChevronLeft} style={{ color: 'black' }} />
                </button>
                <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 mb-4">
                    <div className="flex justify-between items-center">
                        <h1 className="text-xl font-semibold mb-2">{goalData.goalName}</h1>
                        <ShareGoalComponent
                            baseUrl={`${window.location.origin}/goal`}
                            userName={username}
                            goalId={goalId}
                            goalName={goalData.goalName}
                        />
                    </div>
                    <p className="text-base mb-2">By: {goalData.completionDateFormatted}</p>
                    <p className="text-base mb-2">Starting: {goalData.currentStatus} | Goal: {goalData.targetMilestone}</p>
                    {goalData.percentageCompleted && (
                        <p className="mb-8 text-base">% to goal: {goalData.percentageCompleted}%</p>
                    )}
                    <GoalChartComponent goal={goalData} userId={userId} />
                </div>

                <div className="px-4 py-5 sm:p-6 bg-white shadow rounded-lg overflow-hidden mb-4">
                    <h1 className="text-lg font-semibold mb-4">Check In History</h1>
                    <div className="mt-4">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead>
                                <tr>
                                    <th className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Date
                                    </th>
                                    <th className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Status
                                    </th>
                                    <th className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Note
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {checkIns.map((checkIn) => (
                                    <tr key={checkIn.id}>
                                        <td className="py-4 whitespace-nowrap text-sm text-gray-500">
                                            {checkIn.date}
                                        </td>
                                        <td className="py-4 whitespace-nowrap text-sm text-gray-500">
                                            {checkIn.status}
                                        </td>
                                        <td className="py-4 whitespace-nowrap text-sm text-gray-500">
                                            {checkIn.note}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default PublicGoalPage;
