import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { getFirestore, doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { app2 } from '../firebase-config'; 
import { getAuth, onAuthStateChanged } from 'firebase/auth';

function GoalChartComponent({ goal, userId }) {
  const db = getFirestore(app2);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: 'line',
      height: 400,
    },
    title: {
      text: null
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Date'
      },
      labels: {
        formatter: function () {
          return Highcharts.dateFormat('%m/%d', this.value);
        }
      },
    },
    yAxis: {
      title: {
        text: 'Units'
      }
    },
    series: [
      {
        name: 'Goal',
        data: [],
        color: '#BEBEBE',
      },
      {
        name: 'Progress',
        data: [],
        color: '#83DA7C',
      }
    ],
    credits: {
      enabled: false
    }
  });

  useEffect(() => {
    const handleResize = () => {
      const mobileHeight = 300;
      const desktopHeight = 400;
      const isMobile = window.innerWidth < 768;
      setChartOptions(prevOptions => ({
        ...prevOptions,
        chart: {
          ...prevOptions.chart,
          height: isMobile ? mobileHeight : desktopHeight,
        },
      }));
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchGoalData = async (uid) => {
      setIsLoading(true);
      setError(null); 
      try {
        const goalRef = doc(db, `users/${uid}/goals`, goal.id);
        const goalSnap = await getDoc(goalRef);

        if (goalSnap.exists()) {
          const goalData = goalSnap.data();
          const startDate = goalData.startDate ? new Date(goalData.startDate).getTime() : null;
          const completionDate = goalData.completionDate ? new Date(goalData.completionDate).getTime() : null;
          const progressData = [[startDate, parseFloat(goalData.currentStatus)]];
          let tickPositions = [startDate, completionDate];

          const checkInsRef = collection(db, `users/${uid}/goals/${goal.id}/checkIns`);
          const querySnapshot = await getDocs(checkInsRef);
          querySnapshot.forEach(doc => {
            const checkInData = doc.data();
            const checkInDate = checkInData.date ? new Date(checkInData.date).getTime() : null;
            const checkInStatus = parseFloat(checkInData.status);
            if (checkInDate && !isNaN(checkInStatus)) {
              progressData.push([checkInDate, checkInStatus]);
              tickPositions.push(checkInDate);
            }
          });

          tickPositions = Array.from(new Set(tickPositions)).sort((a, b) => a - b);
          progressData.sort((a, b) => a[0] - b[0]);

          setChartOptions(prevOptions => ({
            ...prevOptions,
            xAxis: {
              ...prevOptions.xAxis,
              tickPositions: tickPositions,
              labels: {
                formatter: function() {
                  const firstTick = tickPositions[0];
                  const lastTick = tickPositions[tickPositions.length - 1];
                  if (this.value === firstTick || this.value === lastTick) {
                    return Highcharts.dateFormat('%m/%d', this.value);
                  }
                  return Highcharts.dateFormat('%m/%d', this.value);
                }
              }
            },
            yAxis: {
              ...prevOptions.yAxis,
              title: {
                text: goalData.unitsOfMeasurement || 'Units'
              }
            },
            series: [
              {
                ...prevOptions.series[0],
                data: [
                  [startDate, parseFloat(goalData.currentStatus)],
                  [completionDate, parseFloat(goalData.targetMilestone)]
                ]
              },
              {
                ...prevOptions.series[1], 
                data: progressData,
                dataLabels: {
                  enabled: true, 
                  format: '{point.y}',
                  align: 'center',
                  verticalAlign: 'bottom',
                  style: {
                    color: '#008000',
                  }
                }
              }
            ]
          }));
          setIsLoading(false);
        } else {
          setError("Goal not found");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching goal data:", error);
        setError("An error occurred while fetching goal data.");
        setIsLoading(false);
      }
    };

    if (userId) {
      fetchGoalData(userId);
    } else {
      const auth = getAuth(app2);
      onAuthStateChanged(auth, (user) => {
        if (user) {
          fetchGoalData(user.uid);
        } else {
          setError("User not signed in");
          setIsLoading(false);
        }
      });
    }
  }, [db, goal, userId]);

  return (
    <div>
      {error && <div>Error: {error}</div>}
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      )}
    </div>
  );
}

export default GoalChartComponent;
