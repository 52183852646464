import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';  // Import FontAwesomeIcon component
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { app2 } from '../firebase-config';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

function SettingsPage() {
    const navigate = useNavigate();
    const [userId, setUserId] = useState(null);
    const [username, setUsername] = useState(null);

    useEffect(() => {
        const auth = getAuth(app2);
        const db = getFirestore(app2);

        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setUserId(user.uid);
                // Fetch the username from the publicProfiles collection
                const userDocRef = doc(db, 'publicProfiles', user.uid);
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    setUsername(userDocSnap.data().userName);
                } else {
                    console.error('No such document!');
                }
            } else {
                navigate('/signin');
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    const handleProfilePublicView = () => {
        if (userId) {
            navigate(`/profile/${username}`);
        }
    };

    return (
        <Layout>
            <div className="max-w-5xl mx-auto">
                <div className="text-lg sm:text-xl font-semibold text-gray-900 my-4 mb-8 sm:p-0 pl-4 pt-4 pr-4">Settings</div>
                <div className="flex flex-col gap-2 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 mb-4">

                    <button
                        className="text-black text-base py-2 px-4 mt-2 flex justify-between items-center hover:bg-gray-100 rounded-lg"
                        onClick={() => navigate('/edit-profile')}
                    >Edit profile
                        <FontAwesomeIcon icon={faChevronRight} size="sm" />
                    </button>

                    <hr></hr>

                    <button
                        className="text-black text-base py-2 px-4 mt-2 flex justify-between items-center hover:bg-gray-100 rounded-lg"
                        onClick={handleProfilePublicView}
                    >Profile (public view)
                        <FontAwesomeIcon icon={faChevronRight} size="sm" />
                    </button>

                </div>
            </div>
        </Layout>
    );
}

export default SettingsPage;