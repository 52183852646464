import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { getFirestore, doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { app2 } from '../firebase-config'; 
import { getAuth, onAuthStateChanged } from 'firebase/auth';

function MinimalGoalChartComponent({ goal }) {
  const db = getFirestore(app2);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: 'line',
      height: 400,
    },
    title: {
      text: null
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: null
      },
      labels: {
        enabled: false
      },
      lineWidth: 0, // Ensures no line is shown along the x-axis
  tickWidth: 0, // Ensures no tick marks are shown
  minorTickLength: 0, // Ensures no minor ticks are shown
  tickLength: 0 // Ensures no major ticks are shown
    },
    yAxis: {
      title: {
        text: null
      },
      labels: {
        enabled: false // This line will remove the labels from the y-axis
      }
    },
    series: [
      {
        name: 'Goal',
        data: [],
        color: '#BEBEBE',
      },
      {
        name: 'Progress',
        data: [],
        color: '#83DA7C',
      }
    ],
    credits: {
      enabled: false
    },
    legend: {
        enabled: false
    }
  });

  // Function to update chart height based on the window width


  useEffect(() => {
    const handleResize = () => {
      // Define a smaller height for mobile devices, you can adjust the value as needed
      const mobileHeight = 140; // pixels for mobile
      const desktopHeight = 200; // pixels for desktop and larger screens
      const isMobile = window.innerWidth < 768; // 768px is a common breakpoint for mobile devices
      setChartOptions(prevOptions => ({
        ...prevOptions,
        chart: {
          ...prevOptions.chart,
          height: isMobile ? mobileHeight : desktopHeight,
        },
      }));
    };

    // Set the initial size when the component mounts
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const auth = getAuth(app2);

    if (goal && goal.id) {
        const fetchGoalData = async (userId) => {
            setIsLoading(true);
            setError(null); 
            try {
              const goalRef = doc(db, `users/${userId}/goals`, goal.id);
                const goalSnap = await getDoc(goalRef);

                if (goalSnap.exists()) {
                    const goalData = goalSnap.data();
                    const startDate = goalData.startDate ? new Date(goalData.startDate).getTime() : null;
                    const completionDate = goalData.completionDate ? new Date(goalData.completionDate).getTime() : null;
                    const progressData = [[startDate, parseFloat(goalData.currentStatus)]];
                    let tickPositions = [startDate, completionDate]; 

                   
                    const checkInsRef = collection(db, `users/${userId}/goals/${goal.id}/checkIns`);
                    const querySnapshot = await getDocs(checkInsRef);
                    querySnapshot.forEach(doc => {
                        const checkInData = doc.data();
                        const checkInDate = checkInData.date ? new Date(checkInData.date).getTime() : null;
                        const checkInStatus = parseFloat(checkInData.status);
                        if (checkInDate && !isNaN(checkInStatus)) {
                            progressData.push([checkInDate, checkInStatus]);
                            tickPositions.push(checkInDate); 
                        }
                    });

                  
                    tickPositions = Array.from(new Set(tickPositions)).sort((a, b) => a - b);
                    progressData.sort((a, b) => a[0] - b[0]);

                    setChartOptions(prevOptions => ({
                        ...prevOptions,
                        xAxis: {
                            ...prevOptions.xAxis,
                           
                            tickPositions: tickPositions,
                            labels: {
                                enabled: false
                            }
                        },
                        yAxis: {
                            ...prevOptions.yAxis,
                            title: {
                                text: null
                            }
                        },
                        series: [
                            {
                                ...prevOptions.series[0],
                                data: [

                                    [startDate, parseFloat(goalData.currentStatus)],
                                    [completionDate, parseFloat(goalData.targetMilestone)]
                                ]
                            },
                            {
                                ...prevOptions.series[1], 
                                data: progressData,
                                dataLabels: {
                                    enabled: true, 
                                    format: '{point.y}',
                                    align: 'center',
                                    verticalAlign: 'bottom',
                                    style: {
                                        color: '#008000', 
                                    }
                                }
                            }
                        ]
                    }));
                    setIsLoading(false); 
                } else {
                    setError("Goal not found"); 
                    setIsLoading(false);
                }
            } catch (error) {
                console.error("Error fetching goal data:", error);
                setError("An error occurred while fetching goal data.");
                setIsLoading(false);
            }
        };
        
        onAuthStateChanged(auth, (user) => {
          if (user) {
           
            fetchGoalData(user.uid);
          } else {
           
            setError("User not signed in");
            setIsLoading(false);
          }
        });
    }
}, [db, goal]);


  

  return (
    <div>
    {error && <div>Error: {error}</div>}
    {isLoading ? (
      <div>Loading...</div>
    ) : (
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    )}
  </div>
  );
}

export default MinimalGoalChartComponent;
