import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, query, collection, where, getDocs, doc, addDoc, updateDoc, getDoc } from 'firebase/firestore';
import { app2 } from '../firebase-config';
import Layout from './Layout';
import GoalChartComponent from './GoalChartComponent';
import { format } from 'date-fns';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Switch } from '@headlessui/react';
import Tooltip from './Tooltip';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import ConfirmDialog from './ConfirmDialog';
import ShareGoalComponent from './ShareGoalComponent';

function GoalPage() {
  const navigate = useNavigate();
  const { goalNameUrl } = useParams();
  const db = getFirestore(app2);
  const [goalData, setGoalData] = useState(null);
  const [checkInDate, setCheckInDate] = useState('');
  const [currentStatus, setCurrentStatus] = useState('');
  const [checkIns, setCheckIns] = useState([]);
  const [visibility, setVisibility] = useState('Private'); // Default to 'Private'
  const [username, setUsername] = useState('');

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [proposedVisibility, setProposedVisibility] = useState(null);

  const auth = getAuth(app2);

  const promptVisibilityChange = () => {
    const nextVisibility = visibility === 'Private' ? 'Public' : 'Private';
    setProposedVisibility(nextVisibility);
    setShowConfirmDialog(true);
  };

  const confirmVisibilityChange = async () => {
    setShowConfirmDialog(false);
    setVisibility(proposedVisibility);

    const goalRef = doc(db, `users/${auth.currentUser.uid}/goals`, goalData.id);
    try {
      await updateDoc(goalRef, {
        visibility: proposedVisibility
      });
      console.log('Visibility updated successfully');
    } catch (error) {
      console.error('Error updating visibility:', error);
    }
  };

  const cancelVisibilityChange = () => {
    setShowConfirmDialog(false);
  };

  const saveCheckIn = async () => {
    if (!checkInDate || !currentStatus) {
      alert('Please fill in all fields.');
      return;
    }

    const user = auth.currentUser;

    if (user) {
      const checkInCollectionRef = collection(db, `users/${user.uid}/goals/${goalData.id}/checkIns`);

      try {
        const docRef = await addDoc(checkInCollectionRef, {
          date: checkInDate,
          status: currentStatus,
        });

        const newCheckIn = {
          id: docRef.id,
          date: format(new Date(checkInDate + 'T00:00'), 'PPP'),
          status: currentStatus.toString(),
        };

        const latestCheckInValue = parseFloat(currentStatus);
        const initialStatus = parseFloat(goalData.currentStatus);
        const targetMilestone = parseFloat(goalData.targetMilestone);
        const percentageCompleted = ((Math.abs(initialStatus - latestCheckInValue) / Math.abs(initialStatus - targetMilestone)) * 100).toFixed(2);

        const goalRef = doc(db, `users/${user.uid}/goals`, goalData.id);
        await updateDoc(goalRef, {
          percentageCompleted,
        });

        setCheckIns(prevCheckIns => [...prevCheckIns, newCheckIn].sort((a, b) => new Date(a.date) - new Date(b.date)));
        setGoalData({ ...goalData, percentageCompleted });

        setCheckInDate('');
        setCurrentStatus('');
      } catch (error) {
        console.error("Error saving check-in: ", error);
        alert('Failed to save check-in.');
      }
    } else {
      console.log("User not signed in or error fetching user data");
    }
  };

  useEffect(() => {
    const fetchUserDetails = async (uid) => {
      const userDoc = await getDoc(doc(db, `publicProfiles`, uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUsername(userData.userName);
      }
    };

    onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserDetails(user.uid);
        const fetchGoalData = async () => {
          const q = query(collection(db, `users/${user.uid}/goals`), where("urlFriendlyName", "==", goalNameUrl));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const docSnapshot = querySnapshot.docs[0];
            const docData = docSnapshot.data();
            const goal = {
              id: docSnapshot.id,
              ...docData,
              startDate: new Date(docData.startDate + 'T00:00').getTime(),
              completionDate: new Date(docData.completionDate + 'T00:00').getTime(),
              currentStatus: parseFloat(docData.currentStatus),
              targetMilestone: parseFloat(docData.targetMilestone),
              completionDateFormatted: format(new Date(docData.completionDate + 'T00:00'), 'PPP'),
            };

            setVisibility(docData.visibility || 'Private');
            setGoalData(goal);

            const checkInsRef = collection(db, `users/${user.uid}/goals/${docSnapshot.id}/checkIns`);
            const checkInsSnapshot = await getDocs(checkInsRef);

            let additionalCheckIns = checkInsSnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
              date: new Date(doc.data().date + 'T00:00').getTime(),
            }));

            additionalCheckIns.sort((a, b) => a.date - b.date);
            additionalCheckIns = additionalCheckIns.map(ci => ({
              ...ci,
              date: format(new Date(ci.date), 'PPP'),
            }));

            const initialCheckIn = {
              date: format(new Date(goal.startDate), 'PPP'),
              status: goal.currentStatus.toString(),
              note: 'Initial Check-In',
              id: 'initial',
            };

            setCheckIns([initialCheckIn, ...additionalCheckIns]);
          } else {
            console.log("No such document!");
          }
        };

        fetchGoalData();
      } else {
        console.log("User not signed in or error fetching user data");
      }
    });
  }, [goalNameUrl]);

  if (!goalData) {
    return <Layout>Loading...</Layout>;
  }

  return (
    <Layout>
      <div className="max-w-7xl mx-auto mt-4">
        <button
          onClick={() => navigate(-1)}
          style={{ color: 'white', fontSize: '18px', background: 'none', border: 'none' }}
          className="sm:p-0 pl-4 pt-4 pr-4 mb-4"
        >
          <FontAwesomeIcon icon={faChevronLeft} style={{ color: 'black' }} />
        </button>
        <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 mb-4">
          <div className="flex justify-between items-center">
            <h1 className="text-xl font-semibold mb-2">{goalData.goalName}</h1>
            <ShareGoalComponent
              baseUrl={`${window.location.origin}/goal`}
              userName={username}
              goalId={goalData.id}
              goalName={goalData.goalName}
            />
          </div>
          <p className="text-base mb-2">By: {goalData.completionDateFormatted}</p>
          <p className="text-base mb-2">Starting: {goalData.currentStatus} | Goal: {goalData.targetMilestone}</p>
          {goalData.percentageCompleted && (
            <p className="mb-8 text-base">% to goal: {goalData.percentageCompleted}%</p>
          )}
          <GoalChartComponent key={checkIns.length} goal={goalData} checkIns={checkIns} />
        </div>

        <div className="px-4 py-5 sm:p-6 bg-white shadow rounded-lg overflow-hidden mb-4">
          <div className="flex justify-between items-end mb-6">
            <span className="text-lg font-medium">Check In</span>
            <button
              type="button"
              onClick={() => saveCheckIn(checkInDate, currentStatus)}
              className="inline-flex items-center bg-fitmania-green text-black px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm hover:bg-fitmania-green-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-500"
            >
              Save
            </button>
          </div>
          <div className="bg-white rounded-lg overflow-hidden flex items-center">
            <div className="flex flex-col items-start w-1/2">
              <label htmlFor="check-in-date" className="mb-1 font-medium text-base">Date:</label>
              <input
                type="date"
                id="check-in-date"
                name="checkInDate"
                value={checkInDate}
                onChange={(e) => setCheckInDate(e.target.value)}
                className="rounded-md border-gray-300 shadow-sm sm:text-sm focus:outline-none pl-0"
              />
            </div>

            <div className="flex flex-col items-start w-1/2">
              <label htmlFor="current-status" className="mb-1 font-medium text-base">Current progress:</label>
              <input
                type="text"
                id="current-status"
                name="currentStatus"
                value={currentStatus}
                onChange={(e) => setCurrentStatus(e.target.value)}
                placeholder={`${goalData.unitsOfMeasurement || 'status'}`}
                className="rounded-md border-gray-300 shadow-sm sm:text-sm focus:outline-none"
              />
            </div>
          </div>
        </div>

        <div className="px-4 py-5 sm:p-6 bg-white shadow rounded-lg overflow-hidden">
          <h1 className="text-lg font-semibold mb-4">Check In History</h1>
          <div className="mt-4">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Note
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {checkIns.map((checkIn) => (
                  <tr key={checkIn.id}>
                    <td className="py-4 whitespace-nowrap text-sm text-gray-500">
                      {checkIn.date}
                    </td>
                    <td className="py-4 whitespace-nowrap text-sm text-gray-500">
                      {checkIn.status}
                    </td>
                    <td className="py-4 whitespace-nowrap text-sm text-gray-500">
                      {checkIn.note}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="bg-white px-8 py-8 shadow rounded-lg overflow-hidden mt-4 flex justify-between">
          <div className="flex flex-row">
            <h1 className="font-semibold">Visibility</h1>
            <Tooltip content="Public goals are visible on your profile.">
              <button className="text-gray-400 hover:text-gray-700 focus:outline-none ml-4">
                <FontAwesomeIcon icon={faCircleInfo} />
              </button>
            </Tooltip>
          </div>
          <div className="flex flex-row gap-2 justify-center items-center">
            <span className={visibility === 'Private' ? 'text-black' : 'text-gray-400'}>Private</span>
            <Switch
              checked={visibility === 'Public'}
              onChange={promptVisibilityChange}
              className={`${visibility === 'Public' ? 'bg-fitmania-green' : 'bg-gray-200'
                } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none`}
            >
              <span className="sr-only">Toggle Visibility</span>
              <span
                className={`${visibility === 'Public' ? 'translate-x-5' : 'translate-x-0'
                  } pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
            <span className={visibility === 'Public' ? 'text-black' : 'text-gray-400'}>Public</span>
          </div>
          {showConfirmDialog && (
            <ConfirmDialog
              title={`Change visibility to "${proposedVisibility}"?`}
              message="Are you sure you want to change the visibility of this goal?"
              confirmText="Yes"
              cancelText="Cancel"
              onConfirm={confirmVisibilityChange}
              onCancel={cancelVisibilityChange}
            />
          )}
        </div>
      </div>
    </Layout>
  );
}

export default GoalPage;
