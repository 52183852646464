// TimerPickerComponent.js
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faArrowsRotate } from '@fortawesome/pro-solid-svg-icons';

function TimePickerComponent({ onClose, onConfirm, onReset }) {
  // State for hours, minutes, and meridian (AM/PM)
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('');

  // Function to handle input change for hours
  const handleHoursChange = (e) => {
    const { value } = e.target;
    // Check if the value is a number or empty
    if (/^\d*$/.test(value) || value === '') {
      setHours(value);
    }
  };

  // Function to handle input change for minutes
  const handleMinutesChange = (e) => {
    const { value } = e.target;
    // Check if the value is a number, not empty, and less than or equal to 59
    if ((/^\d*$/.test(value) && value <= 59) || value === '') {
      setMinutes(value);
    }
  };

  // Function to handle input change for minutes
  const handleSecondsChange = (e) => {
    const { value } = e.target;
    // Check if the value is a number, not empty, and less than or equal to 59
    if ((/^\d*$/.test(value) && value <= 59) || value === '') {
      setSeconds(value);
    }
  };


  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-gray-600 bg-opacity-40">
      <div className="bg-white p-4 rounded-lg shadow-xl text-center">
      <div className="flex justify-between mb-4">
        <div className="text-lg font-semibold text-left">Enter time</div>
        <div>
        <FontAwesomeIcon icon={faArrowsRotate} className="text-lg cursor-pointer" onClick={onReset} />
        </div>
      </div>

        <div className="flex space-x-2 mb-6">
          {/* Hours input 
          <div className="flex flex-col items-center">
            <input
              type="text"
              value={hours}
              onChange={handleHoursChange}
              className="w-24 h-20 text-center bg-gray-200 rounded outline-none text-4xl focus:bg-white focus:border-2 focus:border-fitmania-green"
              maxLength="2"
              placeholder="00"
            />
            <div className="text-sm text-gray-600 mt-1">Hours</div>
          </div>

          <div className="text-4xl mt-4">:</div> */}

          {/* Minutes input */}
          <div className="flex flex-col items-center">
            <input
              type="tel"
              value={minutes}
              onChange={handleMinutesChange}
              className="w-24 h-20 text-center bg-gray-200 rounded outline-none text-4xl focus:bg-white focus:border-2 focus:border-fitmania-green"
              maxLength="2"
              placeholder="00"
            />
            <div className="text-sm text-gray-600 mt-1">Minutes</div>
          </div>

          <div className="text-4xl mt-4">:</div>

          {/* Seconds input */}
          <div className="flex flex-col items-center">
            <input
              type="tel"
              value={seconds}
              onChange={handleSecondsChange}
              className="w-24 h-20 text-center bg-gray-200 rounded outline-none text-4xl focus:bg-white focus:border-2 focus:border-fitmania-green"
              maxLength="2"
              placeholder="00"
            />
            <div className="text-sm text-gray-600 mt-1">Seconds</div>
          </div>
        </div>

        {/* Action buttons */}
        <div className="flex justify-between space-x-2">
          <div>
            <FontAwesomeIcon icon={faClock} className="text-gray-600 text-lg" />
          </div>
          <div>
            <button
              onClick={onClose}
              className="bg-gray-200 text-black px-2 py-1 rounded mr-2"
            >
              Cancel
            </button>
            <button
              onClick={() => onConfirm(`${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`)} // Ensure we get a MM:SS format
              className="bg-fitmania-green text-black px-2 py-1 rounded"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TimePickerComponent;
