import React, { useEffect } from 'react';
import { useWorkout } from './WorkoutContext';
import Layout from './Layout';
import { useNavigate } from 'react-router-dom';
import CreateAWorkoutComponent from './CreateAWorkoutComponent';
import { Switch } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function CreateNewWorkoutPage() {
  const navigate = useNavigate();
  const { handleSaveWorkout, resetWorkoutState, visibility, setVisibility } = useWorkout();

  useEffect(() => {

    return () => {
      resetWorkoutState();
    };
  }, []);


  const handleCancel = () => {
    resetWorkoutState();

    navigate('/workouts');
  };

  return (
    <Layout>

      <div className="pt-8 sm:pt-4">
        <div className="text-2xl font-semibold px-8 sm:px-0 text-gray-900 mb-4">Create a workout</div>
        <CreateAWorkoutComponent
          onCancel={handleCancel}
          onSaveWorkout={handleSaveWorkout}
        />
        <div className="bg-white px-8 py-8 shadow rounded-lg overflow-hidden mt-4 max-w-xl flex justify-between">
          <h1 className="font-semibold">Visibility</h1>
          <div className="flex flex-row gap-2 justify-center items-center">
            <span className={visibility === 'Private' ? 'text-black' : 'text-gray-400'}>Private</span>
            <Switch
              checked={visibility === 'Public'} // Check if visibility is 'Public'
              onChange={() => setVisibility(visibility === 'Private' ? 'Public' : 'Private')} // Toggle between 'Private' and 'Public'
              className={classNames(
                visibility === 'Public' ? 'bg-fitmania-green-dark' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none'
              )}
            >
              <span className="sr-only">Toggle Visibility</span>
              <span
                className={classNames(
                  visibility === 'Public' ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
              >
                {/* Icons can be customized or removed if not needed */}
              </span>
            </Switch>
            <span className={visibility === 'Public' ? 'text-black' : 'text-gray-400'}>Public</span>
          </div>
        </div>
      </div>



    </Layout>
  );
}

export default CreateNewWorkoutPage;
