import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import { getFirestore, doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronLeft, faChevronUp, faHeart, faFire } from '@fortawesome/free-solid-svg-icons';
import { getAuth } from 'firebase/auth';
import { app2 } from '../firebase-config';
import useFullscreenMedia from './useFullscreenMedia';
import ShareActivityComponent from './ShareActivityComponent';
import ProfilePictureComponent from './ProfilePictureComponent';

function PublicCompletedCopiedWorkoutPage() {
    const [coverImageUrl, setCoverImageUrl] = useState('');
    const [workoutName, setWorkoutName] = useState('');
    const [workoutDescription, setWorkoutDescription] = useState('');
    const [exercises, setExercises] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState({});
    const [areAllExercisesOpen, setAreAllExercisesOpen] = useState(false);
    const [isImageFullscreen, setIsImageFullscreen] = useState(false);
    const [startedAt, setStartedAt] = useState('');
    const [workoutDuration, setWorkoutDuration] = useState('');
    const [originalWorkoutId, setOriginalWorkoutId] = useState('');
    const [creatorProfilePic, setCreatorProfilePic] = useState('');
    const [creatorFirstName, setCreatorFirstName] = useState('');
    const [mediaUrls, setMediaUrls] = useState([]);
    const [userId, setUserId] = useState('');
    const [creatorId, setCreatorId] = useState('');
    const [creatorUsername, setCreatorUsername] = useState('');
    const [completedByFirstName, setCompletedByFirstName] = useState('');
    const [completedByProfilePic, setCompletedByProfilePic] = useState('');

    const { username, activityId } = useParams();
    const auth = getAuth(app2);
    const currentUser = auth.currentUser;
    const db = getFirestore(app2);
    const navigate = useNavigate();
    const { requestImageFullscreen, requestVideoFullscreen } = useFullscreenMedia();

    useEffect(() => {
        const fetchUserIdByUsername = async (username) => {
            const usersCollection = collection(db, 'publicProfiles');
            const userQuery = query(usersCollection, where('userName', '==', username));
            const userSnapshot = await getDocs(userQuery);
            if (userSnapshot.empty) {
                console.error('No such user!');
                return null;
            }
            return userSnapshot.docs[0].id;
        };

        const fetchUsernameByUserId = async (userId) => {
            const userRef = doc(db, 'publicProfiles', userId);
            const userSnapshot = await getDoc(userRef);
            if (userSnapshot.exists()) {
                return userSnapshot.data().userName;
            }
            return null;
        };

        const fetchWorkoutDetails = async (userId) => {
            const completedWorkoutRef = doc(db, 'users', userId, 'workoutsCompleted', activityId);
            const completedWorkoutSnap = await getDoc(completedWorkoutRef);

            if (completedWorkoutSnap.exists()) {
                const completedData = completedWorkoutSnap.data();
                setMediaUrls(completedData.mediaUrls || []);
                setExercises(completedData.exercises || []);
                setStartedAt(completedData.startedAt ?
                    `${new Date(completedData.startedAt.seconds * 1000).toLocaleDateString()} at ${new Date(completedData.startedAt.seconds * 1000).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true, timeZoneName: 'short' })}`
                    : 'No start date recorded');
                setWorkoutDuration(formatDuration(completedData.workoutDuration));
                setOriginalWorkoutId(completedData.workoutId || '');  // Save the original workout ID

                const originalWorkoutRef = doc(db, 'users', userId, 'copiedWorkouts', completedData.workoutId);
                const originalWorkoutSnap = await getDoc(originalWorkoutRef);
                if (originalWorkoutSnap.exists()) {
                    const workoutData = originalWorkoutSnap.data();
                    setCoverImageUrl(workoutData.coverImage || '');
                    setWorkoutName(workoutData.name || '');
                    setWorkoutDescription(workoutData.description || '');
                    if (workoutData.createdBy) {
                        setCreatorId(workoutData.createdBy);
                        const creatorRef = doc(db, 'publicProfiles', workoutData.createdBy);
                        const creatorSnap = await getDoc(creatorRef);
                        if (creatorSnap.exists()) {
                            const creatorData = creatorSnap.data();
                            setCreatorProfilePic(creatorData.profilePicture || '');
                            setCreatorFirstName(creatorData.firstName || '');
                            const creatorUserName = await fetchUsernameByUserId(workoutData.createdBy);
                            setCreatorUsername(creatorUserName);
                        } else {
                            console.log("Creator's public profile not found");
                        }
                    }
                } else {
                    console.log("Original workout document does not exist!");
                }

                // Fetch the profile of the user who completed the workout
                const completedByRef = doc(db, 'publicProfiles', userId);
                const completedBySnap = await getDoc(completedByRef);
                if (completedBySnap.exists()) {
                    const completedByData = completedBySnap.data();
                    setCompletedByFirstName(completedByData.firstName || '');
                    setCompletedByProfilePic(completedByData.profilePicture || '');
                } else {
                    console.log("Profile of the user who completed the workout not found");
                }
            } else {
                console.log("No such document for completed workout!");
            }
        };

        if (username && activityId) {
            fetchUserIdByUsername(username)
                .then(userId => {
                    if (userId) {
                        setUserId(userId);
                        fetchWorkoutDetails(userId);
                    }
                });
        } else {
            console.log("username or activityId is missing.");
        }
    }, [username, activityId, db]);

    const toggleDropdown = (exerciseIndex) => {
        setIsDropdownOpen({
            ...isDropdownOpen,
            [exerciseIndex]: !isDropdownOpen[exerciseIndex]
        });
    };

    const toggleAllExerciseDropdowns = () => {
        setAreAllExercisesOpen(!areAllExercisesOpen);
        setIsDropdownOpen(exercises.reduce((acc, _, index) => {
            acc[index] = !areAllExercisesOpen;
            return acc;
        }, {}));
    };

    const toggleImageFullscreen = () => {
        setIsImageFullscreen(!isImageFullscreen);
    };

    const formatDuration = (duration) => {
        const seconds = duration % 60;
        const minutes = Math.floor(duration / 60) % 60;
        const hours = Math.floor(duration / 3600);

        let formattedDuration = '';
        if (hours > 0) {
            formattedDuration += `${hours}:`;
        }
        formattedDuration += `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

        return formattedDuration;
    };

    const renderMediaItem = (mediaItem, index) => {
        const { url, type } = mediaItem;
        return (
            <div key={index} className="flex-shrink-0 mr-4">
                {type === 'video' ? (
                    <video
                        src={url}
                        autoPlay
                        playsInline
                        muted
                        preload="metadata"
                        controls
                        loop
                        className="h-40 w-auto object-cover rounded cursor-pointer"
                    />
                ) : (
                    <img
                        src={url}
                        alt={`Media ${index}`}
                        className="h-40 w-auto object-cover rounded cursor-pointer"
                    />
                )}
            </div>
        );
    };

    const handleGoToWorkout = async () => {
        try {
            // Fetch the originalWorkoutId from the copied workout document
            const copiedWorkoutRef = doc(db, 'users', userId, 'copiedWorkouts', originalWorkoutId);
            const copiedWorkoutSnap = await getDoc(copiedWorkoutRef);

            if (copiedWorkoutSnap.exists()) {
                const copiedWorkoutData = copiedWorkoutSnap.data();
                const creatorOriginalWorkoutId = copiedWorkoutData.originalWorkoutId;

                // Navigate to the original workout
                navigate(`/workout/${creatorUsername}/${creatorOriginalWorkoutId}`);
            } else {
                console.log("No such document for copied workout!");
            }
        } catch (error) {
            console.error("Error navigating to the original workout:", error);
        }
    };

    return (
        <Layout>
            <div className="mt-4 w-full max-w-xl">
                <div className="flex justify-between mb-4 sm:p-0 pl-4 pt-4 pr-4">
                    <button onClick={() => navigate(-1)} style={{ color: 'white', fontSize: '18px', background: 'none', border: 'none' }}>
                        <FontAwesomeIcon icon={faChevronLeft} style={{ color: 'black' }} />
                    </button>
                    <div>
                        <button
                            onClick={handleGoToWorkout}
                            type="button"
                            className="bg-fitmania-green text-black py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-fitmania-green-dark"
                        >
                            Go to workout
                        </button>
                    </div>
                </div>

                <div className="bg-white py-8 shadow rounded-lg overflow-hidden">
                    <div className="px-8 mb-4 gap-1 flex items-center justify-between">
                        <Link to={`/profile/${username}`} className="flex items-center gap-1">
                            <ProfilePictureComponent userId={userId} className="w-10 h-10 rounded-full" />
                            <p className="ml-2">{completedByFirstName}</p>
                        </Link>
                        <ShareActivityComponent baseUrl={`${window.location.origin}`} userName={username} activityId={activityId} copied={true} />
                    </div>

                    <h1 className="px-8 text-xl mb-2 font-semibold">Workout Summary</h1>

                    <p className="text-sm sm:text-base mb-2 px-8">{startedAt}</p>
                    {mediaUrls.length < 1 && (
                        <hr className="mt-4 mb-4 mx-8 "></hr>
                    )}

                    {mediaUrls.length > 0 && (
                        <div className={`bg-gray-100 mt-4 flex overflow-auto ${mediaUrls.length > 0 ? 'mb-8 px-8 py-4 -mt-4' : ''} whitespace-nowrap`} style={{ scrollbarWidth: 'thin' }}>
                            {mediaUrls.map((url, index) => renderMediaItem(url, index))}
                        </div>
                    )}

                    <div className="flex justify-between px-8">
                        <div className="flex flex-col items-center">
                            <h1 className="text-xl font-semibold">{workoutDuration}</h1>
                            <p className="text-base">Workout Duration</p>
                        </div>
                        <div className="flex flex-col items-center">
                            <h1 className="text-xl text-gray-300">
                                <FontAwesomeIcon icon={faHeart} />
                            </h1>
                            <p className="text-base">Avg. Heart Rate</p>
                        </div>
                        <div className="flex flex-col items-center">
                            <h1 className="text-xl text-gray-300">
                                <FontAwesomeIcon icon={faFire} />
                            </h1>
                            <p className="text-base">Calories</p>
                        </div>
                    </div>
                    <hr className="mt-4 mb-4 mx-8"></hr>

                    <div className="flex items-center mb-4 px-8">
                        {coverImageUrl ? (
                            <img src={coverImageUrl} alt="Cover" className="h-40 bg-gray-100 border text-gray-900 rounded-md cursor-pointer" onClick={toggleImageFullscreen} />
                        ) : (
                            <div className="h-40 bg-gray-100 border text-gray-900 rounded-md flex items-center justify-center">Loading image...</div>
                        )}
                    </div>

                    {isImageFullscreen && (
                        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={toggleImageFullscreen}>
                            <img src={coverImageUrl} alt="Fullscreen Cover" className="max-w-full max-h-full" />
                        </div>
                    )}

                    <div>
                        <p className="text-base font-semibold pt-2 pr-2 px-8">Name:</p>
                        <p className="text-left mt-2 px-8">{workoutName || 'Loading name...'}</p>
                        <hr className="mt-4 mb-4 mx-8" />
                    </div>

                    <div>
                        <p className="text-base font-semibold px-8 pt-2 pr-2">Description:</p>
                        <p className="text-left px-8 mt-2">{workoutDescription || 'Loading description...'}</p>
                        <hr className="mt-4 mb-4 mx-8" />
                    </div>

                    <div className="flex justify-between items-center px-8 mt-4 mb-4">
                        <div className="text-left text-base font-semibold">Exercises</div>
                        {exercises.length > 1 && (
                            <button
                                onClick={toggleAllExerciseDropdowns}
                                className="bg-gray-200 p-2 rounded-full shadow inline-flex items-center justify-center"
                            >
                                <FontAwesomeIcon icon={areAllExercisesOpen ? faChevronUp : faChevronDown} className="text-gray-800" />
                            </button>
                        )}
                    </div>

                    {exercises.map((exercise, exerciseIndex) => (
                        <div key={exerciseIndex} className="mb-2 px-8">
                            <div className="flex items-start">
                                <div className="flex flex-col flex-1">
                                    <button
                                        onClick={() => toggleDropdown(exerciseIndex)}
                                        className="flex items-center justify-between w-full text-left text-lg font-medium text-gray-900 focus:outline-none p-4 rounded-md bg-gray-200"
                                    >
                                        <div className="flex flex-col">
                                            <h1 className="text-base">
                                                {exercise.name}
                                            </h1>

                                            <span className="font-normal text-sm">
                                                {exercise.sets ? `${exercise.sets.length} sets` : 'No sets'}
                                            </span>
                                        </div>
                                        <FontAwesomeIcon icon={isDropdownOpen[exerciseIndex] ? faChevronUp : faChevronDown} className="ml-2" />
                                    </button>
                                    {isDropdownOpen[exerciseIndex] && (
                                        <>
                                            {exercise.mediaItems && exercise.mediaItems.length > 0 && (
                                                <div className="flex items-center space-x-4 mt-2 overflow-auto" style={{ scrollbarWidth: 'thin' }}>
                                                    {exercise.mediaItems.map((mediaItem, index) => (
                                                        <div key={index} className="flex-shrink-0 mb-2 relative">
                                                            {mediaItem.type === 'image' ? (
                                                                <img
                                                                    src={mediaItem.url}
                                                                    alt={`Exercise Media ${index + 1}`}
                                                                    className="h-40 w-auto object-cover rounded cursor-pointer"
                                                                    onClick={() => requestImageFullscreen(mediaItem.url)}
                                                                />
                                                            ) : (
                                                                <video
                                                                    src={mediaItem.url}
                                                                    autoPlay
                                                                    playsInline
                                                                    muted
                                                                    preload
                                                                    controls
                                                                    loop
                                                                    className="h-40 w-auto rounded cursor-pointer"
                                                                    onClick={(e) => requestVideoFullscreen(e.currentTarget)}
                                                                />
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                            {exercise.note && (
                                                <div className="border border-gray rounded-md p-4 mt-2">
                                                    <div className="flex justify-between items-center">
                                                        <p className="text-base">{exercise.note}</p>
                                                    </div>
                                                </div>
                                            )}

                                            {exercise.sets.map((set, setIndex) => (
                                                <div key={setIndex} className="border border-black rounded-md p-2 mt-2 mb-2">
                                                    <div className="border border-gray rounded-md p-4 mb-2">
                                                        <div className="flex justify-between items-center mb-2">
                                                            <p className="text-left text-base font-semibold text-black">Set {setIndex + 1}:</p>
                                                        </div>

                                                        {set.repetitions && (
                                                            <div className="flex justify-between items-center mb-2">
                                                                <span className="text-gray-900 text-base">Repetitions:</span>
                                                                <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: 'auto' }}>
                                                                    {set.repetitions}
                                                                </p>
                                                            </div>
                                                        )}
                                                        {set.resistance && set.resistance.units && set.resistance.type && (
                                                            <div className="flex justify-between items-center mb-2">
                                                                <span className="text-gray-900 text-base">Resistance:</span>
                                                                <div className="flex items-center">
                                                                    <p className="text-sm text-center text-black bg-gray-100 rounded px-1 py-1  mr-2">
                                                                        {set.resistance.units}
                                                                    </p>
                                                                    <p className="text-sm text-center text-black bg-gray-100 rounded px-1 py-1  mr-2">
                                                                        {set.resistance.type}
                                                                    </p>
                                                                    <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: '38px' }}>
                                                                        {set.resistance.value}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {set.timer && (
                                                            <div className="flex justify-between items-center mb-2">
                                                                <span className="text-gray-900 text-base">Timer:</span>
                                                                <div className="flex items-center">
                                                                    <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '85px', height: 'auto' }}>
                                                                        {set.timer}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {set.distance && set.distance.units && set.distance.value && (
                                                            <div className="flex justify-between items-center mb-2">
                                                                <span className="text-gray-900 text-base">Distance:</span>
                                                                <div className="flex items-center">
                                                                    <p className="text-sm text-center text-black bg-gray-100 rounded px-1 py-1 mr-2">
                                                                        {set.distance.units}
                                                                    </p>
                                                                    <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: 'auto' }}>
                                                                        {set.distance.value}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}

                                                    </div>

                                                    {set.rest && (
                                                        <div className="border border-gray rounded-md p-4 mt-2">
                                                            <div className="flex justify-between items-center">
                                                                <span className="text-gray-900 text-base">Rest:</span>
                                                                <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: 'auto' }}>
                                                                    {set.rest}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {set.note && (
                                                        <div className="border border-gray rounded-md p-4 py-6 mt-2">
                                                            <div className="flex justify-between items-center">
                                                                <p className="text-base">{set.note}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                    <hr className="mt-4 mb-4 mx-8" />

                    {currentUser && creatorId === currentUser.uid ? (
                        <Link to="/profile" className="flex items-center justify-center px-8 gap-1">
                            <ProfilePictureComponent userId={creatorId} className="w-10 h-10 rounded-full" />
                            <p className="ml-2">Created by {creatorFirstName}</p>
                        </Link>
                    ) : (
                        <Link to={`/profile/${creatorUsername}`} className="flex items-center justify-center px-8 gap-1">
                            <ProfilePictureComponent userId={creatorId} className="w-10 h-10 rounded-full" />
                            <p className="ml-2">Created by {creatorFirstName}</p>
                        </Link>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default PublicCompletedCopiedWorkoutPage;
