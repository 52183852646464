import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { app2 } from '../firebase-config';

function ProfilePictureComponent({ userId, className, profilePicture, name }) {
    const [userData, setUserData] = useState({
        name: name || '',
        imageUrl: profilePicture || `${process.env.PUBLIC_URL}/images/fitmania_avatar.png`, // Use the provided profilePicture prop
    });
    const auth = getAuth(app2);
    const db = getFirestore(app2);

    useEffect(() => {
        const fetchUserData = async () => {
            if (profilePicture) {
                // If profilePicture prop is provided, no need to fetch data
                return;
            }

            let uid = userId;
            if (!uid) {
                const user = auth.currentUser;
                if (user) {
                    uid = user.uid;
                } else {
                    console.error('No user is logged in and no userId was provided');
                    return;
                }
            }

            const docRef = doc(db, "publicProfiles", uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const data = docSnap.data();
                setUserData({
                    name: `${data.firstName} ${data.lastName}`,
                    imageUrl: data.profilePicture || `${process.env.PUBLIC_URL}/images/fitmania_avatar.png`
                });
            }
        };

        fetchUserData();
    }, [userId, auth, db, profilePicture]); // Added profilePicture as dependency

    const { name: displayName, imageUrl } = userData;

    // Extract width from className assuming className contains something like "w-24 h-24"
    const widthClass = className.split(" ").find(cls => cls.startsWith('w-'));
    const width = widthClass ? parseInt(widthClass.split('-')[1], 10) : 0;
    const fontSize = width ? `${width * 2}px` : '1rem'; // 40% of width as font size

    return (
        <div className="relative">
            {imageUrl !== `${process.env.PUBLIC_URL}/images/fitmania_avatar.png` ? (
                <img
                    className={`rounded-full object-cover ${className}`}
                    src={imageUrl}
                    alt={`${name}'s profile`}
                />
            ) : (
                <div className={`rounded-full bg-fitmania-green flex items-center justify-center ${className}`}>
                    <span className="text-black font-normal leading-none" style={{ fontSize }}>
                        {displayName.charAt(0)}
                    </span>
                </div>
            )}
        </div>
    );
}

export default ProfilePictureComponent;