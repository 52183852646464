import React from 'react';

const ShareGoalComponent = ({ baseUrl, userName, goalId, goalName, buttonText = 'Share' }) => {
  const shareUrl = `${baseUrl}/${userName}/${goalId}`;
  const title = `Check out this goal on Fitmania: ${goalName}`;

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: title,
          text: 'Check out this goal on Fitmania!',
          url: shareUrl,
        });
        console.log('Content shared successfully!');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      // Fallback for browsers that do not support the Web Share API
      alert('Web Share API not supported. Copy the link: ' + shareUrl);
    }
  };

  return (
    <button onClick={handleShare} className="bg-fitmania-green px-4 py-2 rounded-lg hover:bg-fitmania-green-dark text-sm sm:text-base">
      {buttonText}
    </button>
  );
};

export default ShareGoalComponent;
