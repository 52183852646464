import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { app2 } from '../firebase-config';
import useFullscreenMedia from './useFullscreenMedia';
import ProfilePictureComponent from './ProfilePictureComponent';

function CopiedWorkoutPage() {
    const [coverImageUrl, setCoverImageUrl] = useState('');
    const [workoutName, setWorkoutName] = useState('');
    const [workoutDescription, setWorkoutDescription] = useState('');
    const [exercises, setExercises] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState({});
    const [areAllExercisesOpen, setAreAllExercisesOpen] = useState(false);
    const [isImageFullscreen, setIsImageFullscreen] = useState(false);
    const [creatorFirstName, setCreatorFirstName] = useState('');
    const [creatorUserName, setCreatorUserName] = useState('');
    const [creatorUserId, setCreatorUserId] = useState('');
    const [creatorProfilePic, setCreatorProfilePic] = useState('');

    const { workoutId } = useParams();
    const navigate = useNavigate();
    const db = getFirestore(app2);
    const auth = getAuth(app2);
    const { requestImageFullscreen, requestVideoFullscreen } = useFullscreenMedia();

    useEffect(() => {
        const fetchWorkout = async () => {
            const currentUser = auth.currentUser;

            if (!currentUser) {
                console.error("No user is logged in.");
                return;
            }

            const workoutRef = doc(db, `users/${currentUser.uid}/copiedWorkouts`, workoutId);
            const workoutSnap = await getDoc(workoutRef);

            if (workoutSnap.exists()) {
                const workoutData = workoutSnap.data();
                setCoverImageUrl(workoutData.coverImage);
                setWorkoutName(workoutData.name);
                setWorkoutDescription(workoutData.description);
                setExercises(workoutData.exercises || []);
                const createdByUserId = workoutData.createdBy || '';

                const dropdownStates = workoutData.exercises.reduce((acc, exercise, index) => {
                    acc[index] = false;
                    return acc;
                }, {});
                setIsDropdownOpen(dropdownStates);

                // Fetch the user who created the workout using the 'createdBy' field
                if (createdByUserId) {
                    const userDocRef = doc(db, 'publicProfiles', createdByUserId);
                    const userDocSnap = await getDoc(userDocRef);
                    if (userDocSnap.exists()) {
                        const userData = userDocSnap.data();
                        setCreatorFirstName(userData.firstName || '');
                        setCreatorUserName(userData.userName || '');
                        setCreatorProfilePic(userData.profilePicture || '');
                        setCreatorUserId(createdByUserId);
                    } else {
                        console.log("Creator's public profile document not found");
                    }
                }
            } else {
                console.log("No such document!");
            }
        };

        fetchWorkout();
    }, [workoutId, auth, db]);

    const toggleDropdown = (exerciseIndex) => {
        setIsDropdownOpen({
            ...isDropdownOpen,
            [exerciseIndex]: !isDropdownOpen[exerciseIndex]
        });
    };

    const toggleAllExerciseDropdowns = () => {
        setAreAllExercisesOpen(!areAllExercisesOpen);
        setIsDropdownOpen(exercises.reduce((acc, _, index) => {
            acc[index] = !areAllExercisesOpen;
            return acc;
        }, {}));
    };

    const toggleImageFullscreen = () => {
        setIsImageFullscreen(!isImageFullscreen);
    };

    return (
        <Layout>
            <div className="mt-5 w-full max-w-xl">
                <div className="flex justify-between mb-4 sm:p-0 pl-4 pt-4 pr-4">
                    <button onClick={() => navigate(-1)} style={{ color: 'white', fontSize: '18px', background: 'none', border: 'none' }}>
                        <FontAwesomeIcon icon={faChevronLeft} style={{ color: 'black' }} />
                    </button>
                    <div>
                        <button
                            onClick={() => navigate(`/workout/${workoutId}/active?copied=true`)}
                            type="button"
                            className="bg-fitmania-green text-black py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-fitmania-green-dark"
                        >
                            Start Workout
                        </button>
                    </div>
                </div>

                <div className="bg-white px-8 py-8 shadow rounded-lg overflow-hidden">
                    <div className="flex justify-between items-start">
                        <div className="flex items-center mb-4">
                            {coverImageUrl ? (
                                <img src={coverImageUrl} alt="Cover" className="h-40 bg-gray-100 border text-gray-900 rounded-md cursor-pointer" onClick={toggleImageFullscreen} />
                            ) : (
                                <div className="h-40 bg-gray-100 border text-gray-900 rounded-md flex items-center justify-center">Loading image...</div>
                            )}
                        </div>
                    </div>

                    {isImageFullscreen && (
                        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={toggleImageFullscreen}>
                            <img src={coverImageUrl} alt="Fullscreen Cover" className="max-w-full max-h-full" />
                        </div>
                    )}

                    <div>
                        <p className="text-base font-semibold pt-2 pr-2">Name:</p>
                        <p className="text-left mt-2">{workoutName || 'Loading name...'}</p>
                        <hr className="mt-4 mb-4" />
                    </div>

                    <div>
                        <p className="text-base font-semibold pt-2 pr-2">Description:</p>
                        <p className="text-left mt-2">{workoutDescription || 'Loading description...'}</p>
                        <hr className="mt-4 mb-4" />
                    </div>

                    <div className="flex justify-between items-center mt-4 mb-4">
                        <div className="text-left text-base font-semibold">Exercises</div>
                        {exercises.length > 1 && (
                            <button
                                onClick={toggleAllExerciseDropdowns}
                                className="bg-gray-200 p-2 rounded-full shadow inline-flex items-center justify-center"
                            >
                                <FontAwesomeIcon icon={areAllExercisesOpen ? faChevronUp : faChevronDown} className="text-gray-800" />
                            </button>
                        )}
                    </div>

                    {exercises.map((exercise, exerciseIndex) => (
                        <div key={exerciseIndex} className="mb-2">
                            <div className="flex items-start">
                                <div className="flex flex-col flex-1">
                                    <button
                                        onClick={() => toggleDropdown(exerciseIndex)}
                                        className="flex items-center justify-between w-full text-left text-lg font-medium text-gray-900 focus:outline-none p-4 rounded-md bg-gray-200"
                                    >
                                        <div className="flex flex-col">
                                            <h1 className="text-base">
                                                {exercise.name}
                                            </h1>
                                            <span className="font-normal text-sm">
                                                {exercise.sets ? `${exercise.sets.length} sets` : 'No sets'}
                                            </span>
                                        </div>
                                        <FontAwesomeIcon icon={isDropdownOpen[exerciseIndex] ? faChevronUp : faChevronDown} className="ml-2" />
                                    </button>
                                    {isDropdownOpen[exerciseIndex] && (
                                        <>
                                            {exercise.mediaItems && exercise.mediaItems.length > 0 && (
                                                <div className="flex items-center space-x-4 mt-2 overflow-auto" style={{ scrollbarWidth: 'thin' }}>
                                                    {exercise.mediaItems.map((mediaItem, index) => (
                                                        <div key={index} className="flex-shrink-0 mb-2 relative">
                                                            {mediaItem.type === 'image' ? (
                                                                <img
                                                                    src={mediaItem.url}
                                                                    alt={`Exercise Media ${index + 1}`}
                                                                    className="h-40 w-auto object-cover rounded cursor-pointer"
                                                                    onClick={() => requestImageFullscreen(mediaItem.url)}
                                                                />
                                                            ) : (
                                                                <video
                                                                    src={mediaItem.url}
                                                                    autoPlay
                                                                    playsInline
                                                                    muted
                                                                    preload
                                                                    controls
                                                                    loop
                                                                    className="h-40 w-auto rounded cursor-pointer"
                                                                    onClick={(e) => requestVideoFullscreen(e.currentTarget)}
                                                                />
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                            {exercise.note && (
                                                <div className="border border-gray rounded-md p-4 mt-2">
                                                    <div className="flex justify-between items-center">
                                                        <p className="text-base">{exercise.note}</p>
                                                    </div>
                                                </div>
                                            )}

                                            {exercise.sets.map((set, setIndex) => (
                                                <div key={setIndex} className="border border-black rounded-md p-2 mt-2 mb-2">
                                                    <div className="border border-gray rounded-md p-4 mb-2">
                                                        <div className="flex justify-between items-center mb-4">
                                                            <p className="text-left text-base font-semibold text-black">Set {setIndex + 1}:</p>
                                                        </div>

                                                        {set.repetitions && (
                                                            <div className="flex justify-between items-center mb-2">
                                                                <span className="text-gray-900 text-base">Repetitions:</span>
                                                                <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: 'auto' }}>
                                                                    {set.repetitions}
                                                                </p>
                                                            </div>
                                                        )}
                                                        {set.resistance && set.resistance.units && set.resistance.type && (
                                                            <div className="flex justify-between items-center mb-2">
                                                                <span className="text-gray-900 text-base">Resistance:</span>
                                                                <div className="flex items-center">
                                                                    <p className="text-sm text-center text-black bg-gray-100 rounded px-1 py-1  mr-2">
                                                                        {set.resistance.units}
                                                                    </p>
                                                                    <p className="text-sm text-center text-black bg-gray-100 rounded px-1 py-1  mr-2">
                                                                        {set.resistance.type}
                                                                    </p>
                                                                    <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: '38px' }}>
                                                                        {set.resistance.value}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {set.timer && (
                                                            <div className="flex justify-between items-center mb-2">
                                                                <span className="text-gray-900 text-base">Timer:</span>
                                                                <div className="flex items-center">
                                                                    <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: 'auto' }}>
                                                                        {set.timer}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {set.distance && set.distance.units && set.distance.value && (
                                                            <div className="flex justify-between items-center mb-2">
                                                                <span className="text-gray-900 text-base">Distance:</span>
                                                                <div className="flex items-center">
                                                                    <p className="text-sm text-center text-black bg-gray-100 rounded px-1 py-1 mr-2">
                                                                        {set.distance.units}
                                                                    </p>
                                                                    <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: 'auto' }}>
                                                                        {set.distance.value}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}

                                                    </div>

                                                    {set.rest && (
                                                        <div className="border border-gray rounded-md p-4 mt-2">
                                                            <div className="flex justify-between items-center">
                                                                <span className="text-gray-900 text-base">Rest:</span>
                                                                <p className="text-center text-gray-900 text-lg border border-black rounded px-1 py-1 w-12" style={{ borderRadius: '5px', width: '65px', height: 'auto' }}>
                                                                    {set.rest}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {set.note && (
                                                        <div className="border border-gray rounded-md p-4 py-6 mt-2">
                                                            <div className="flex justify-between items-center">
                                                                <p className="text-base">{set.note}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                    <hr className="mt-4 mb-4" />
                    <div className="flex items-center justify-center">
                    <Link to={`/profile/${creatorUserName}`} className="flex items-center gap-1">
                        <ProfilePictureComponent userId={creatorUserId} className="w-10 h-10 rounded-full" />
                        <p className="ml-2">Created by {creatorFirstName}</p>
                        </Link>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default CopiedWorkoutPage;
