import React from 'react';

function TimerModal({ isOpen, close, countdown }) {
    if (!isOpen) return null;

    const handleOverlayClick = (event) => {
        if (event.target === event.currentTarget) {
            close();
        }
    };

    // Only add the 'vibrate' class when countdown reaches zero.
    const buttonClass = countdown === 0 ? "vibrate mt-8 text-xl font-normal text-white bg-black rounded-full py-2 px-4" : "mt-8 text-xl font-normal text-white bg-black rounded-full py-2 px-4";

    return (
        <div className="fixed inset-0 bg-blue-400 flex items-center justify-center z-50 overflow-hidden" onClick={handleOverlayClick}>
            <div className="w-full h-full bg-blue-400 flex flex-col" onClick={e => e.stopPropagation()}>
                <div className="flex-grow flex flex-col items-center justify-center p-8">
                    <h1 className="text-5xl mb-4">Timer:</h1>
                    <h1 className="text-8xl mb-4">{formatCountdown(countdown)}</h1>
                    <p className="text-2xl">Time Remaining</p>
                    <button onClick={close} className={buttonClass}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
}

export default TimerModal;

const formatCountdown = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};
