import React, { useState, useEffect, useRef } from 'react'; // Import useRef
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, getFirestore, setDoc, deleteDoc } from 'firebase/firestore';
import { app2 } from '../firebase-config';
import Layout from './Layout';
import ConfirmDialog from './ConfirmDialog';

function EditProfileUsernamePage() {

    const navigate = useNavigate();
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    useEffect(() => {
        const fetchUsername = async () => {
            const auth = getAuth(app2);
            const db = getFirestore(app2);
            const user = auth.currentUser;

            if (user) {
                const userRef = doc(db, 'users', user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setUsername(userData.userName || '');
                }
            } else {
                navigate('/signin');
            }
        };

        fetchUsername();
    }, [navigate]);


    const handleCancel = () => {
        navigate('/edit-profile');
    };

    const handleSave = async () => {
        setError('');
        setLoading(true);
        const trimmedUsername = inputValue.trim().toLowerCase(); // Convert username to lowercase

        if (trimmedUsername) {
            const auth = getAuth(app2);
            const user = auth.currentUser;
            const db = getFirestore(app2);
            const userNameRef = doc(db, 'userNames', trimmedUsername);

            try {
                // Check if the username already exists
                const docSnap = await getDoc(userNameRef);
                if (docSnap.exists()) {
                    setError('Username is already taken. Please try another.');
                } else {
                    // Username is available
                    // Save the username in the user's profile
                    const userRef = doc(db, 'users', user.uid);
                    const publicProfileRef = doc(db, 'publicProfiles', user.uid);

                    await setDoc(userRef, { userName: trimmedUsername }, { merge: true });
                    await setDoc(publicProfileRef, { userName: trimmedUsername }, { merge: true });

                    // Create a new document in the 'userNames' collection
                    await setDoc(userNameRef, { userId: user.uid });

                    // If the username has changed, delete the old username from the 'userNames' collection
                    if (trimmedUsername !== username.toLowerCase()) {
                        const oldUserNameRef = doc(db, 'userNames', username.toLowerCase());
                        await deleteDoc(oldUserNameRef);
                    }

                    // Navigate back to the profile edit page
                    navigate('/edit-profile');
                }
            } catch (error) {
                console.error('Error updating username:', error);
                setError('Failed to save username. Please try again.');
            } finally {
                setLoading(false);
            }
        } else {
            setError('Please enter a valid username.');
            setLoading(false);
        }
    };

    const handleSaveClick = async () => {
        setError('');
        const trimmedUsername = inputValue.trim().toLowerCase(); // Convert inputValue to lowercase

        if (trimmedUsername === username.toLowerCase()) {
            setError('You already own this username.');
            return;
        }

        const db = getFirestore(app2);
        const userNameRef = doc(db, 'userNames', trimmedUsername);

        try {
            // Check if the new username already exists
            const docSnap = await getDoc(userNameRef);
            if (docSnap.exists()) {
                setError('Username is already taken. Please try another.');
            } else {
                setShowConfirmDialog(true);
            }
        } catch (error) {
            console.error('Error checking username:', error);
            setError('Failed to check username. Please try again.');
        }
    };

    const handleConfirm = () => {
        setShowConfirmDialog(false);
        handleSave();
    };

    const handleCancelConfirm = () => {
        setShowConfirmDialog(false);
    };

    useEffect(() => {
        // Update save button state based on inputValue
        const isSaveEnabled = inputValue.trim().length > 0;
        setIsSaveEnabled(isSaveEnabled);
    }, [inputValue]);


    return (
        <Layout>
            <div className="max-w-lg mx-auto">
                <div className="flex justify-between items-center mx-4 sm:mx-0">
                    <div className="text-lg sm:text-xl font-semibold text-gray-900 my-4 mb-8 sm:p-0 pt-4 pr-4">Edit username</div>
                    <button onClick={handleCancel} className="bg-gray-200 p-2 rounded-lg">Cancel</button>
                </div>
                <div className="flex flex-col gap-2 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 mb-4 items-center">
                    <div className="flex flex-row gap-8 items-start">
                        <div className="flex flex-col items-center gap-4">
                            <input
                                type="text"
                                placeholder={username}
                                value={inputValue} // Set value to inputValue
                                onChange={(e) => setInputValue(e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded"
                                required
                            />
                            {error && <p className="text-red-500">{error}</p>}
                            <button
                                onClick={handleSaveClick}
                                disabled={!isSaveEnabled || loading}
                                className={`mt-2 mb-4 py-2 px-4 rounded-lg focus:outline-none ${isSaveEnabled ? 'bg-fitmania-green text-black' : 'bg-gray-200 text-gray-400'}`}
                            >
                                {loading ? 'Saving...' : 'Save username'}
                            </button>
                        </div>
                    </div>

                </div>
                {showConfirmDialog && (
                    <ConfirmDialog
                        title="Confirm Change"
                        message={`Are you sure you want to change your username from <span class="font-semibold text-red-500">${username}</span> to <span class="font-semibold text-red-500">${inputValue}</span>? By clicking "Yes" you acknowledge that the username, <span class="font-semibold text-red-500">${username}</span>, will become available for another user to claim.`}
                        onConfirm={handleConfirm}
                        onCancel={handleCancelConfirm}
                        confirmText="Yes"
                        cancelText="No"
                    />
                )}
            </div>
        </Layout>
    );
}

export default EditProfileUsernamePage;
